"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ml_props_panel = exports.separator = void 0;
const m_header_1 = require("../../core/ui/for_ml/m_header");
const mpts_panel_1 = require("./panel_core/mpts_panel");
const m_nf_1 = require("../../core/ui/for_ml/m_nf");
const m_qf_1 = require("../../core/ui/for_ml/m_qf");
const m_empl_1 = require("../../core/ui/for_ml/m_empl");
const m_tags_1 = require("../../core/ui/for_ml/m_tags");
const m_comment_1 = require("../../core/ui/for_ml/m_comment");
const func_1 = require("../../assest/func");
const m_comb_1 = require("../../core/ui/for_ml/m_comb");
const u_header_1 = require("../../core/ui/for_union/u_header");
const u_nita_1 = require("../../core/ui/for_union/u_nita");
const u_repRate_1 = require("../../core/ui/for_union/u_repRate");
const u_colors_1 = require("../../core/ui/for_union/u_colors");
const u_shres_1 = require("../../core/ui/for_union/u_shres");
const m_alias_1 = require("../../core/ui/for_ml/m_alias");
const ui_inp_1 = require("../../core/ui/for_ui/ui_inp");
const app_1 = require("../../scripts/app");
const core_1 = require("../../core/core");
const fnc = __importStar(require("../../scripts/func"));
const separator = (p) => {
    return p.appendChild((0, func_1.bh)({ tag: 'div', classList: ['mpts-props-panel-h-separator'] }));
};
exports.separator = separator;
var ml_props_panel;
(function (ml_props_panel) {
    let _props;
    ml_props_panel.create = (parent, obj) => {
        document.querySelectorAll('.props-panel-main').forEach(el => el.remove());
        // create default panel
        _props = mpts_panel_1.mpts_panel.create(parent, [['', [300, 200], [0, 0, 0, 0]], []]);
        _props.style.right = '16px';
        _props.style.top = '16px';
        _props.style.left = 'auto';
        _props.style.width = '228px';
        _props.style.height = '708px';
        _props.classList.add('props-panel-main');
        if (obj === null || obj === void 0 ? void 0 : obj.rst)
            return;
        const _header = _props.querySelector('.mpts-panel-header');
        const _content = _props.querySelector('.mpts-panel-content');
        const _footer = _props.querySelector('.mpts-panel-footer');
        if (!obj) {
            return _props;
        }
        else {
            if (obj.m) {
                // _header.appendChild(bh({ tag: 'div', textContent: 'headerContent' }))
                // create header
                if (obj.m.core.group !== core_1.ml.Group.blank) {
                    if (_content.appendChild((0, m_header_1.m_propsHeader)(obj.m)).childNodes.length > 0) {
                        (0, exports.separator)(_content);
                    }
                }
                else {
                    (0, exports.separator)(_content).style.borderBottomColor = 'rgba(172, 172, 172, 0)';
                }
                // quality factors
                if (_content.appendChild((0, m_nf_1.m_combos)(obj.m)).childNodes.length > 0) {
                    (0, exports.separator)(_content);
                }
                // numerical factors
                if (_content.appendChild((0, m_qf_1.m_sliders)(obj.m)).childNodes.length > 0) {
                    (0, exports.separator)(_content);
                }
                // emploiment
                if (_content.appendChild((0, m_empl_1.m_overlapped)(obj.m)).childNodes.length > 0) {
                    (0, exports.separator)(_content);
                }
                // tags
                if (_content.appendChild((0, m_tags_1.m_tags)(obj.m)).childNodes.length > 0) {
                    (0, exports.separator)(_content);
                }
                // comment
                if (_content.appendChild((0, m_comment_1.m_tarea)(obj.m)).childNodes.length > 0) {
                    if (obj.m.core.combined.items.length === 0) {
                        (0, exports.separator)(_content);
                    }
                }
                // combined
                if (obj.m.core.combined.items.length > 0) {
                    _content.appendChild((0, m_comb_1.m_comb)(obj.m));
                    (0, exports.separator)(_content);
                }
                if (_content.appendChild((0, m_alias_1.m_alias)(obj.m)).childNodes.length > 0) {
                    // separator(_props)
                }
                // _footer.appendChild(bh({ tag: 'div', textContent: 'footerContent' }))
                setMicroelementFooter(_footer, obj.m);
                return;
            }
            if (obj.u) {
                if (_props.appendChild((0, u_header_1.u_propsHeader)(obj.u)).childNodes.length > 0) {
                    (0, exports.separator)(_props);
                }
                if (_props.appendChild((0, u_repRate_1.u_repRate)(obj.u)).childNodes.length > 0) {
                    (0, exports.separator)(_props);
                }
                if (_props.appendChild((0, u_nita_1.u_notITAction)(obj.u)).childNodes.length > 0) {
                    (0, exports.separator)(_props);
                }
                if (_props.appendChild((0, m_comment_1.m_tarea)(obj.u)).childNodes.length > 0) {
                    // separator(_props)
                }
                if (_props.appendChild((0, u_shres_1.u_shres)(obj.u)).childNodes.length > 0) {
                    (0, exports.separator)(_props);
                }
                if (_props.appendChild((0, u_colors_1.u_color)(obj.u)).childNodes.length > 0) {
                    (0, exports.separator)(_props);
                }
                return;
            }
            if (obj.inp) {
                if (_props.appendChild((0, ui_inp_1.ui_inp)(app_1.app.getApp().lang, obj.inp)).childNodes.length > 0) {
                    (0, exports.separator)(_props);
                }
            }
        }
    };
    ml_props_panel.updateFooter = (m) => {
        setMicroelementFooter(_props, m);
    };
})(ml_props_panel || (exports.ml_props_panel = ml_props_panel = {}));
// #region Microelement footer ========================================================================= Microelement footer
const setMicroelementFooter = (cont, m) => {
    cont.querySelectorAll('.mpts-panel-footer-content').forEach(el => el.remove());
    const _rc = (0, func_1.bh)({ tag: 'div', classList: ['mpts-panel-footer-content'] });
    const _result = (0, func_1.bh)({ tag: 'div', classList: ['mpts-panel-footer-result'], });
    // buttons
    // separator(_result)
    _result.appendChild(setMicroelementButtons(m));
    (0, exports.separator)(_result);
    // result
    const _rr = core_1.ml.getResult(m);
    const _rs1 = (0, func_1.bh)({ tag: 'span', textContent: 'Result : ', });
    const _rs2 = (0, func_1.bh)({ tag: 'span', textContent: fnc.crnd(_rr * 0.06) });
    const _rs3 = (0, func_1.bh)({ tag: 'span', textContent: ` \\ ${fnc.crnd(_rr)}` });
    _rs1.style.color = 'rgb(172, 172, 172)';
    _rs3.style.color = 'rgb(172, 172, 172)';
    _result.appendChild(_rs1);
    _result.appendChild(_rs2);
    _result.appendChild(_rs3);
    _rc.appendChild(_result);
    // id
    const _id = (0, func_1.bh)({ tag: 'div', classList: ['mpts-panel-footer-id'], textContent: fnc.trstr(m.core.id, 29, fnc.toTrimPos.mid), });
    _id.style.color = 'rgb(172, 172, 172)';
    _rc.appendChild(_id);
    cont.appendChild(_rc);
    return cont;
};
const setMicroelementButtons = (m) => {
    const icons = {
        save: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#494949" class="mpts-icon"><path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" /></svg>`,
        upload: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#494949" class="mpts-icon"><path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" /></svg>`,
        focus: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#494949" class="mpts-icon"><path stroke-linecap="round" stroke-linejoin="round" d="M7.5 3.75H6A2.25 2.25 0 0 0 3.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0 1 20.25 6v1.5m0 9V18A2.25 2.25 0 0 1 18 20.25h-1.5m-9 0H6A2.25 2.25 0 0 1 3.75 18v-1.5M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" /></svg>`
    };
    const parseSVG = (svgString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(svgString, "image/svg+xml");
        return doc.documentElement;
    };
    const createButton = (title, icon, onClick) => {
        const btn = (0, func_1.bh)({ tag: 'button', classList: ['mpts-panel-footer-button'], attributes: { title } });
        const svgIcon = parseSVG(icon);
        btn.appendChild(svgIcon);
        btn.addEventListener('pointerdown', (e) => onClick());
        return btn;
    };
    const saveBtn = createButton('save', icons.save, () => m.pres.save());
    const uploadBtn = createButton('upload', icons.upload, () => m.pres.load());
    const cont = (0, func_1.bh)({ tag: 'div', classList: ['mpts-panel-footer-buttons'] });
    cont.appendChild(saveBtn);
    cont.appendChild(uploadBtn);
    return cont;
};
// #endregion Microelement footer ========================================================================= Microelement footer
