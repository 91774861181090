"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.link = void 0;
const mobx_1 = require("mobx");
const core_1 = require("../../core/core");
const app_1 = require("../../scripts/app");
const func_1 = require("../../scripts/func");
const presentation_1 = require("./presentation");
const store_1 = require("./store");
var link;
(function (link) {
    link.updateLinks = (_id) => {
        store_1.store.link.items.map(l => {
            if (l.core.from === _id || l.core.to === _id) {
                update(l.core.from, l.core.to);
            }
        });
    };
    let ca = [];
    const linkCanvas = document.getElementById('mpts-link-canvas');
    // addind link handler to element
    link.linkHandler = (el) => {
        el.view.addEventListener('pointerdown', (e) => {
            if (e.altKey) {
                if (el.core.coreType === core_1.CoreTypes.step) {
                    console.log('step is more ready');
                }
                e.preventDefault();
                e.stopImmediatePropagation();
                linkCreator(el).add();
            }
        });
    };
    const getCenterAndDOMRect = (el, uiid) => {
        const _br = el.querySelector(`.baseRect.${uiid}`);
        const _b = _br.getBoundingClientRect();
        const _canvas_t = (0, func_1.getSvgTranslate3d)(app_1.app.getApp().ui.mpts.item.svgCanvas);
        _b.x -= 24 + _canvas_t.x;
        _b.y -= 40 + _canvas_t.y;
        return {
            c: [_b.x + _b.width / 2, _b.y + _b.height / 2],
            r: _b
        };
    };
    // 1 - 12 sectors, first sector on X +++
    const getSector = (from, to) => {
        const dx = to[0] - from[0];
        const dy = to[1] - from[1];
        if (dx === 0 && dy === 0)
            return 0;
        let angle = Math.atan2(dy, dx) * 180 / Math.PI;
        if (angle < 0) {
            angle += 360;
        }
        const sector = Math.floor((angle + 15) / 30) % 12;
        return sector + 1;
    };
    function drawPath(from, to, mode = 'path') {
        if (from === to)
            return;
        // items
        const _from = store_1.store.all.getItem(from);
        const _to = store_1.store.all.getItem(to);
        if (!_from || !_to)
            return;
        // items center and rect
        const _cpar = getCenterAndDOMRect(_from.view, _from.pres.uiid);
        const _tpar = getCenterAndDOMRect(_to.view, _to.pres.uiid);
        const setLine = () => {
            const _l = [..._cpar.c, ..._tpar.c];
            const p1 = (0, func_1.getRectWithLineIntersection)(_cpar.r, _l)[0];
            const p2 = (0, func_1.getRectWithLineIntersection)(_tpar.r, _l)[0];
            return `M ${(0, func_1.rbb)(p1.point[0], 8)},${(0, func_1.rbb)(p1.point[1], 8)} L ${(0, func_1.rbb)(p2.point[0], 8)},${(0, func_1.rbb)(p2.point[1], 8)}`;
        };
        if (mode === 'line') {
            return setLine();
        }
        if (Math.abs(_cpar.c[0] - _tpar.c[0]) < _tpar.r.width * 0.7 || Math.abs(_cpar.c[1] - _tpar.c[1]) < _tpar.r.height * 0.7) {
            // console.log('distance is less than half of width and height');
            return setLine();
        }
        const _sector = getSector([_cpar.c[0], _cpar.c[1]], [_tpar.c[0], _tpar.c[1]]);
        let path = ``;
        // if sector 1, 4, 7, 10
        if (_sector === 1) {
            path += `M ${_cpar.c[0] + _cpar.r.width / 2},${_cpar.c[1]} L ${_tpar.c[0] - _tpar.r.width / 2},${_tpar.c[1]}`;
        }
        if (_sector === 4) {
            path += `M ${_cpar.c[0]},${_cpar.c[1] + _cpar.r.height / 2} L ${_tpar.c[0]},${_tpar.c[1] - _tpar.r.height / 2}`;
        }
        if (_sector === 7) {
            path += `M ${_cpar.c[0] - _cpar.r.width / 2},${_cpar.c[1]} L ${_tpar.c[0] + _tpar.r.width / 2},${_tpar.c[1]}`;
        }
        if (_sector === 10) {
            path += `M ${_cpar.c[0]},${_cpar.c[1] - _cpar.r.height / 2} L ${_tpar.c[0]},${_tpar.c[1] + _tpar.r.height / 2}`;
        }
        // if sector 2, 3, 5, 6, 8, 9, 11, 12
        if (_sector === 2) {
            path += `M ${_cpar.c[0] + _cpar.r.width / 2},${_cpar.c[1]} 
            L ${_tpar.c[0] - 8}, ${_cpar.c[1]} 
            Q ${_tpar.c[0]}, ${_cpar.c[1]} ${_tpar.c[0]}, ${_cpar.c[1] + 8} 
            L ${_tpar.c[0]}, ${_tpar.c[1] - _tpar.r.height / 2}`;
        }
        if (_sector === 3) {
            path += `M ${_cpar.c[0]}, ${_cpar.c[1] + _cpar.r.height / 2}
            L ${_cpar.c[0]}, ${_tpar.c[1] - 8}
            Q ${_cpar.c[0]}, ${_tpar.c[1]} ${_cpar.c[0] + 8}, ${_tpar.c[1]}
            L ${_tpar.c[0] - _tpar.r.width / 2}, ${_tpar.c[1]}`;
        }
        if (_sector === 5) {
            path += `M ${_cpar.c[0]}, ${_cpar.c[1] + _cpar.r.height / 2}
            L ${_cpar.c[0]}, ${_tpar.c[1] - 8}
            Q ${_cpar.c[0]}, ${_tpar.c[1]} ${_cpar.c[0] - 8}, ${_tpar.c[1]}
            L ${_tpar.c[0] + _tpar.r.width / 2}, ${_tpar.c[1]}`;
        }
        if (_sector === 6) {
            path += `M ${_cpar.c[0] - _cpar.r.width / 2}, ${_cpar.c[1]}
            L ${_tpar.c[0] + 8}, ${_cpar.c[1]}
            Q ${_tpar.c[0]}, ${_cpar.c[1]} ${_tpar.c[0]}, ${_cpar.c[1] + 8}
            L ${_tpar.c[0]}, ${_tpar.c[1] - _tpar.r.height / 2}`;
        }
        if (_sector === 8) {
            path += `M ${_cpar.c[0] - _cpar.r.width / 2}, ${_cpar.c[1]}
            L ${_tpar.c[0] + 8}, ${_cpar.c[1]}
            Q ${_tpar.c[0]}, ${_cpar.c[1]} ${_tpar.c[0]}, ${_cpar.c[1] - 8}
            L ${_tpar.c[0]}, ${_tpar.c[1] + _tpar.r.height / 2}`;
        }
        if (_sector === 9) {
            path += `M ${_cpar.c[0]}, ${_cpar.c[1] - _cpar.r.height / 2}
            L ${_cpar.c[0]}, ${_tpar.c[1] + 8}
            Q ${_cpar.c[0]}, ${_tpar.c[1]} ${_cpar.c[0] - 8}, ${_tpar.c[1]}
            L ${_tpar.c[0] + _tpar.r.width / 2}, ${_tpar.c[1]}`;
        }
        if (_sector === 11) {
            path += `M ${_cpar.c[0]}, ${_cpar.c[1] - _cpar.r.height / 2}
            L ${_cpar.c[0]}, ${_tpar.c[1] + 8}
            Q ${_cpar.c[0]}, ${_tpar.c[1]} ${_cpar.c[0] + 8}, ${_tpar.c[1]}
            L ${_tpar.c[0] - _tpar.r.width / 2}, ${_tpar.c[1]}`;
        }
        if (_sector === 12) {
            path += `M ${_cpar.c[0] + _cpar.r.width / 2}, ${_cpar.c[1]}
            L ${_tpar.c[0] - 8}, ${_cpar.c[1]}
            Q ${_tpar.c[0]}, ${_cpar.c[1]} ${_tpar.c[0]}, ${_cpar.c[1] - 8}
            L ${_tpar.c[0]}, ${_tpar.c[1] + _tpar.r.height / 2}`;
        }
        return path;
    }
    const draw = (from, to) => {
        const _from = store_1.store.all.getItem(from);
        const _to = store_1.store.all.getItem(to);
        if (!_from || !_to)
            return;
        // remove link if exists
        let _oi = linkCanvas.querySelectorAll(`.link.${_from.pres.uiid}.${_to.pres.uiid}`)[0];
        if (_oi)
            _oi.remove();
        const _d = drawPath(from, to);
        const _path = (0, func_1.csvg)('path', {
            d: _d,
            'stroke-width': 1.5,
            // stroke: '#494949',
            // stroke: '#30638E',
            stroke: '#459ED9',
            'marker-start': 'url(#circle)',
            'marker-end': 'url(#arrow)',
            fill: 'none',
        }, [pathClass]);
        const _cover = (0, func_1.csvg)('path', {
            d: _d,
            'stroke-width': 10,
            stroke: 'transparent',
            fill: 'none',
            'pointer-events': 'stroke',
            'cursor': 'pointer',
            'tabindex': 0,
        }, [pathClassCover]);
        _cover.addEventListener('keydown', (e) => {
            console.log('keydown', e.key);
            _path.setAttribute('stroke', '#FF0000');
        });
        _cover.addEventListener('pointerdown', (e) => {
            let _hl = _linkGroup.querySelector('.mpts_link_highlight');
            if (_hl) {
                _hl.remove();
                return;
            }
            const _hlshape = (0, func_1.csvg)('path', {
                d: _d,
                'stroke-width': 10,
                stroke: 'rgba(158, 158, 158, 0.5)',
                fill: 'none',
                'pointer-events': 'none',
            }, ['mpts_link_highlight']);
            _linkGroup.insertBefore(_hlshape, _linkGroup.firstElementChild);
        });
        const _linkGroup = document.createElementNS(func_1.NS, 'g');
        _linkGroup.classList.add('link');
        _linkGroup.classList.add(_from.pres.uiid);
        _linkGroup.classList.add(_to.pres.uiid);
        _linkGroup.setAttribute('pointer-events', 'all');
        _linkGroup.appendChild(_path);
        _linkGroup.appendChild(_cover);
        linkCanvas.appendChild(_linkGroup);
        return _linkGroup;
    };
    const update = (from, to) => {
        draw(from, to);
    };
    const createLink = (from, to) => {
        return draw(from, to);
    };
    // instance to create link
    const linkCreator = (el) => {
        function _extend(...objs) {
            return Object.assign({}, ...objs);
        }
        return {
            add: () => {
                console.log('add link', el.core.id);
                ca.push(el.core.id);
                ca.forEach((el) => {
                    console.log(el);
                });
                // if link is ready
                if (ca.length == 2) {
                    console.log('link is ready');
                    // create link
                    const _link = { core: { id: crypto.randomUUID(), from: ca[0], to: ca[1] }, type: 'path', duration: 0, coment: '', target: core_1.tg.Target.processing, mech: core_1.tg.Mechanization.manualLabour };
                    const T0 = (0, mobx_1.observable)(_link, {}, { deep: true });
                    const _pres = presentation_1.presentation.create();
                    const T1 = (0, mobx_1.observable)(_pres, {}, { deep: true });
                    const _cd = { dragController: { allow: true } };
                    const _view = createLink(ca[0], ca[1]);
                    const T4 = _extend(T0, T1, _cd, { view: _view });
                    store_1.store.link.add(T4);
                    // reset array
                    ca = [];
                }
                ;
            },
            reset: () => {
                console.log('reset link');
                ca = [];
            },
            arr: ca
        };
    };
    // classes for link and cover
    const pathClass = 'mpts_link';
    const pathClassCover = 'mpts_link_cover';
    link.getStage = () => {
        return ca.length == 0 ? 0 : ca.length == 1 ? 1 : -2;
    };
})(link || (exports.link = link = {}));
