"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDragController = createDragController;
const core75_1 = require("./core75");
/**
 * Создает контроллер перетаскивания для SVG-элемента, обновляющий логическую позицию
 * через MobX‑хранилище.
 * @param el SVG-элемент, который перетаскивается.
 * @param store MobX‑хранилище, содержащее координаты (x, y).
 * @param options Опции перетаскивания.
 */
function createDragController(el, options) {
    let isDragging = false;
    let startX = 0, startY = 0;
    let initialTranslateX = 0, initialTranslateY = 0;
    let currentTranslateX = 0, currentTranslateY = 0;
    let animationFrameId = null;
    // Значения по умолчанию, объединенные с переданными опциями.
    let opts = Object.assign({ enabled: true, snapToGrid: false, gridSize: 8, axis: "both", boundaries: {} }, options);
    function attachListeners() {
        el.addEventListener('pointerdown', onPointerDown);
        el.addEventListener('pointermove', onPointerMove);
    }
    function detachListeners() {
        document.removeEventListener("pointerdown", onPointerDown);
        document.removeEventListener("pointermove", onPointerMove);
        document.removeEventListener("pointerup", onPointerUp);
        document.removeEventListener("pointercancel", onPointerUp);
    }
    /**
     * Преобразует координаты указателя в локальную систему координат родительского элемента,
     * учитывая scale контейнера.
     */
    function getLocalPoint(e) {
        const svg = el.ownerSVGElement;
        const pt = svg.createSVGPoint();
        pt.x = e.clientX;
        pt.y = e.clientY;
        const parentCTM = el.parentElement.getScreenCTM();
        return pt.matrixTransform(parentCTM.inverse());
    }
    /**
   * Ограничивает значение val между min и max.
   */
    function clamp(val, min, max) {
        if (min !== undefined && val < min)
            return min;
        if (max !== undefined && val > max)
            return max;
        return val;
    }
    /**
     * Округляет значение до ближайшего кратного gridSize.
     */
    function roundByGrid(val) {
        const grid = opts.gridSize || 8;
        return Math.round(val / grid) * grid;
    }
    /**
     * Обновляет позицию элемента:
     * - Применяет CSS‑transform для визуального перемещения.
     * - Обновляет логическую позицию через MobX‑хранилище.
     */
    function updatePosition(newX, newY) {
        el.style.transform = `translate3d(${newX}px, ${newY}px, 0)`;
        core75_1.model.store.setPosition(el.dataset.uiid, [newX, newY]);
    }
    const onPointerDown = (e) => {
        if (!opts.enabled)
            return;
        isDragging = true;
        const localPoint = getLocalPoint(e);
        startX = localPoint.x;
        startY = localPoint.y;
        const style = window.getComputedStyle(el);
        let initialTranslate = { x: 0, y: 0 };
        if (style.transform && style.transform !== "none") {
            const matrix = new DOMMatrixReadOnly(style.transform);
            initialTranslate = { x: matrix.m41, y: matrix.m42 };
        }
        initialTranslateX = initialTranslate.x;
        initialTranslateY = initialTranslate.y;
        currentTranslateX = initialTranslate.x;
        currentTranslateY = initialTranslate.y;
        el.dispatchEvent(new CustomEvent("dragStart", {
            detail: {
                startX,
                startY,
                initialTranslateX,
                initialTranslateY,
            },
        }));
        // console.log("dragStart");
        el.style.willChange = "transform";
        el.setPointerCapture(e.pointerId);
        document.addEventListener("pointerup", onPointerUp);
        document.addEventListener("pointercancel", onPointerUp);
    };
    const onPointerMove = (e) => {
        if (!isDragging)
            return;
        if (animationFrameId)
            return;
        // animationFrameId = requestAnimationFrame(() => {
        // console.log("dragMove");
        // console.log(el.style.transform);
        const localPoint = getLocalPoint(e);
        const rawDx = localPoint.x - startX;
        const rawDy = localPoint.y - startY;
        // console.log(rawDx, rawDy);
        // Учет оси для минимального перемещения
        // if (opts.axis === "x" && Math.abs(rawDx) < opts.gridSize!) {
        //     animationFrameId = null;
        //     return;
        // }
        // if (opts.axis === "y" && Math.abs(rawDy) < opts.gridSize!) {
        //     animationFrameId = null;
        //     return;
        // }
        // if (opts.axis === "both" && Math.abs(rawDx) < opts.gridSize! && Math.abs(rawDy) < opts.gridSize!) {
        //     animationFrameId = null;
        //     return;
        // }
        let dx = rawDx;
        let dy = rawDy;
        // Если ограничено перемещение по оси
        if (opts.axis === "x") {
            dy = 0;
        }
        else if (opts.axis === "y") {
            dx = 0;
        }
        // Привязка к сетке, если включена
        if (opts.snapToGrid || e.shiftKey) {
            dx = roundByGrid(dx);
            dy = roundByGrid(dy);
        }
        let newX = initialTranslateX + dx;
        let newY = initialTranslateY + dy;
        // Ограничение по границам
        if (opts.boundaries) {
            newX = clamp(newX, opts.boundaries.minX, opts.boundaries.maxX);
            newY = clamp(newY, opts.boundaries.minY, opts.boundaries.maxY);
        }
        currentTranslateX = newX;
        currentTranslateY = newY;
        updatePosition(newX, newY);
        document.getElementById('mpts-counter').innerText = `x: ${newX}, y: ${newY}`;
        el.dispatchEvent(new CustomEvent("dragMove", {
            detail: { newX, newY, dx, dy, axis: opts.axis },
        }));
        // animationFrameId = null;
        // });
    };
    const onPointerUp = (e) => {
        if (!isDragging)
            return;
        isDragging = false;
        const snappedX = roundByGrid(currentTranslateX);
        const snappedY = roundByGrid(currentTranslateY);
        currentTranslateX = snappedX;
        currentTranslateY = snappedY;
        updatePosition(snappedX, snappedY);
        el.releasePointerCapture(e.pointerId);
        el.style.willChange = "auto";
        el.dispatchEvent(new CustomEvent("dragEnd", {
            detail: { finalX: currentTranslateX, finalY: currentTranslateY },
        }));
        document.removeEventListener("pointerup", onPointerUp);
        document.removeEventListener("pointercancel", onPointerUp);
        // detachListeners();
    };
    if (opts.enabled) {
        attachListeners();
    }
    return {
        setEnabled(enabled) {
            opts.enabled = enabled;
            if (enabled) {
                attachListeners();
            }
            else {
                detachListeners();
            }
        },
        updateOptions(newOptions) {
            opts = Object.assign(Object.assign({}, opts), newOptions);
        },
        destroy() {
            detachListeners();
        },
    };
}
