"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.input = void 0;
const mobx_1 = require("mobx");
const sim_1 = require("../../core/sim");
const func_1 = require("../../scripts/func");
const presentation_1 = require("./presentation");
const store_1 = require("./store");
var input;
(function (input) {
    input.setSelectViewForInput = (inp, select) => {
        const _inp = inp.view.querySelector(`.mpts_input.${inp.pres.uiid}`);
        if (select) {
            _inp.setAttribute('fill', 'rgb(3, 167, 255)');
        }
        else {
            _inp.setAttribute('fill', 'rgb(147, 183, 190)');
        }
    };
    // instance
    input.create = (x, y) => {
        function _extend(...objs) {
            return Object.assign({}, ...objs);
        }
        const _i1 = sim_1.sim.input.create();
        const T0 = (0, mobx_1.observable)(_i1, {}, { deep: true });
        const _pres = presentation_1.presentation.create();
        const T1 = (0, mobx_1.observable)(_pres, {}, { deep: true });
        const _view = input.createView(x, y, _pres.pres.uiid);
        _view.id = _i1.core.id;
        const _cd = { dragController: { allow: true } };
        const T4 = _extend(_cd, T1, { view: _view }, T0);
        T4.view.addEventListener('pointerdown', (e) => {
            console.log(store_1.store.input.selected[0]);
            store_1.store.input.addSelected(T4);
            input.setSelectViewForInput(T4, true);
        });
        store_1.store.input.add(T4);
        return T4;
    };
    // view
    input.createView = (x, y, _uiid) => {
        // create group for circle
        const inputGroup = document.createElementNS(func_1.NS, 'g');
        inputGroup.classList.add('input');
        inputGroup.classList.add(_uiid);
        // create circle
        const _input = (0, func_1.csvg)('circle', {
            cx: x,
            cy: y,
            r: 16,
            fill: '#93B7BE',
            stroke: 'black',
            'stroke-width': 1,
            'pointer-events': 'all',
        }, ['mpts_input', 'baseRect', _uiid]);
        inputGroup.appendChild(_input);
        (0, func_1.enableDrag)(inputGroup);
        return inputGroup;
    };
})(input || (exports.input = input = {}));
