"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.header = void 0;
const utr = __importStar(require("../../core/utr"));
const app_1 = require("../../scripts/app");
var header;
(function (header) {
    // DOM elements
    const _elements = {
        logo: {
            cont: document.getElementById('mpts-logo'),
            main: document.getElementById('mpts-logo-main'),
            chip: document.getElementById('mpts-logo-chip'),
            trace: document.getElementById('mpts-logo-trace')
        },
        buttons: {
            cont: document.getElementById('mpts-header-items'),
            home: document.getElementById('mpts_home_button'),
            docs: document.getElementById('mpts_docs_button'),
            roadmap: document.getElementById('mpts_roadmap_button'),
            prices: document.getElementById('mpts_prices_button'),
            app: document.getElementById('mpts_app_button'),
            signin: document.getElementById('mtps_signin_button')
        }
    };
    header.create = (l) => {
        const initButtons = () => {
            _elements.buttons.signin.addEventListener('pointerdown', () => {
                app_1.app.getApp().setView(app_1.AppView.signin);
            });
            _elements.buttons.home.addEventListener('pointerdown', () => {
                app_1.app.getApp().setView(app_1.AppView.home);
            });
            _elements.buttons.docs.addEventListener('pointerdown', () => {
                app_1.app.getApp().setView(app_1.AppView.docs);
            });
            _elements.buttons.app.addEventListener('pointerdown', () => {
                app_1.app.getApp().setView(app_1.AppView.app);
            });
            _elements.logo.cont.addEventListener('pointerdown', () => {
                foldLogo();
            });
        };
        setLang(l);
        initButtons();
        underLine();
        return {
            id: crypto.randomUUID(),
            translate: setLang,
            setUserName: setUserName,
            allowApp: allowApp,
            init: initButtons,
            foldLogo: foldLogo,
            underLine: underLine,
        };
    };
    // translate header ui 
    const setLang = (l) => {
        const _nw = utr.getPref(l, utr.UIType.header);
        // translate logo
        const trLogo = (l) => {
            _elements.logo.main.textContent = _nw.logo.main;
            _elements.logo.chip.textContent = _nw.logo.chip;
            _elements.logo.trace.textContent = _nw.logo.trace;
        };
        // translate buttons
        const trButtons = (l) => {
            _elements.buttons.home.textContent = _nw.buttons.home;
            _elements.buttons.docs.textContent = _nw.buttons.docs;
            _elements.buttons.roadmap.textContent = _nw.buttons.roadmap;
            _elements.buttons.prices.textContent = _nw.buttons.prices;
            _elements.buttons.app.textContent = _nw.buttons.app;
            _elements.buttons.signin.textContent = _nw.buttons.signin;
        };
        trLogo(l);
        trButtons(l);
    };
    // set user name
    const setUserName = (name) => {
        if (!name) {
            _elements.buttons.signin.textContent = 'Sign in';
            _elements.buttons.signin.classList.add('highlited');
            _elements.buttons.signin.classList.remove('userlogged');
        }
        else {
            _elements.buttons.signin.textContent = name;
            _elements.buttons.signin.classList.remove('highlited');
            _elements.buttons.signin.classList.add('userlogged');
        }
    };
    // allow app button
    const allowApp = (v) => {
        if (v) {
            _elements.buttons.app.classList.remove('disabled');
        }
        else {
            _elements.buttons.app.classList.add('disabled');
        }
    };
    // handle header pointer events
    const _header = document.getElementById('mpts-header-cont');
    _header.addEventListener('pointerover', () => {
        shrinked ? setShrink(false, false) : null;
    });
    _header.addEventListener('pointerout', () => {
        shrinked ? setShrink(true, false) : null;
    });
    let shrinked = false;
    const setShrink = (v, t = true) => {
        if (v) {
            t ? shrinked = true : null;
            _elements.buttons.cont.classList.add('shrink');
            _elements.buttons.signin.classList.add('shrink');
            return;
        }
        t ? shrinked = false : null;
        _elements.buttons.cont.classList.remove('shrink');
        _elements.buttons.signin.classList.remove('shrink');
    };
    // hide buttons expect logo and user
    const foldLogo = () => {
        const lcl = _elements.logo.cont.classList;
        const cc = 'colapsed';
        let sl = utr.getPref(app_1.app.getApp().lang, utr.UIType.header);
        const fold = () => {
            setShrink(true);
            _elements.logo.main.innerText = sl.logo.main.at(0);
            _elements.logo.chip.innerText = sl.logo.chip.at(0);
            _elements.logo.trace.innerText = sl.logo.trace.at(0);
        };
        const unfold = () => {
            setShrink(false);
            _elements.logo.main.innerText = sl.logo.main;
            _elements.logo.chip.innerText = sl.logo.chip;
            _elements.logo.trace.innerText = sl.logo.trace;
        };
        if (lcl.contains(cc)) {
            unfold();
            lcl.remove(cc);
        }
        else {
            fold();
            lcl.add(cc);
        }
    };
    // pointer down handler, set underline
    const underLine = (n) => {
        const _btns = document.querySelectorAll('.header-item');
        _btns.forEach((el) => {
            el.addEventListener('pointerdown', () => {
                _btns.forEach(el1 => { el1.classList.remove('active'); });
                el.classList.add('active');
            });
        });
        if (n) {
            _btns.forEach(el1 => { el1.classList.remove('active'); });
            _btns[n].classList.add('active');
        }
    };
})(header || (exports.header = header = {}));
