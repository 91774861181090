"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.footer = void 0;
const tr_1 = require("../../core/tr");
const utr = __importStar(require("../../core/utr"));
const app_1 = require("../../scripts/app");
var footer;
(function (footer) {
    // DOM elemnts
    const elements = {
        policy: document.getElementById('mpt-copyright-pp'),
        licence: document.getElementById('mpt-copyright-lic'),
        email: document.getElementById('mpt-support-email'),
        tg: document.getElementById('mpt-support-tg'),
        langs: document.getElementById('mpts-langs'),
        langsdrop: document.getElementById('lang-drop-content')
    };
    footer.create = (l) => {
        // handle email create
        elements.email.addEventListener('pointerdown', function (event) {
            emailSendHandler(event);
        });
        // handle languades switcher
        langSwitchHandler();
        return {
            id: crypto.randomUUID(),
            translate: setLang
        };
    };
    const langSwitchHandler = () => {
        const allLanguages = elements.langs.querySelectorAll(".language");
        const languagesArray = Array.from(allLanguages);
        const enabledLanguages = languagesArray.filter(lang => !lang.classList.contains("disabled"));
        enabledLanguages.forEach(el => {
            el.addEventListener('pointerdown', () => {
                var _a;
                enabledLanguages.forEach(cel => cel.classList.remove('selected'));
                el.classList.add('selected');
                const newLang = el.getAttribute('data-lang').toLowerCase();
                app_1.app.getApp().translate(tr_1.lang[newLang]);
                if (elements.langsdrop.contains(el)) {
                    const targetNode = (_a = elements.langs) === null || _a === void 0 ? void 0 : _a.children[1];
                    if (elements.langs && el) {
                        if (targetNode && elements.langs.contains(targetNode)) {
                            elements.langs.insertBefore(el, targetNode);
                            elements.langsdrop.append(targetNode);
                        }
                    }
                }
            });
        });
    };
    // translate
    const setLang = (l) => {
        const _nw = utr.getPref(l, utr.UIType.footer);
        let emh = elements.email.childNodes;
        emh[0].textContent = _nw.support + ' : ';
        emh[1].textContent = _nw.writeemail;
        elements.tg.childNodes[0].textContent = _nw.tg;
        elements.licence.childNodes[0].textContent = _nw.licensed + ' : ';
        elements.policy.childNodes[0].textContent = _nw.privacypolicy;
    };
    // email send button handler
    const emailSendHandler = (event) => {
        event.preventDefault();
        const subject = `Support Request ${'ID-' + Math.random().toString(36).slice(2, 9)}`;
        const mailtoLink = `mailto:support@metaprodtrace.com?subject=${encodeURIComponent(subject)}`; //&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
    };
})(footer || (exports.footer = footer = {}));
