"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.home = void 0;
const tr_1 = require("../../core/tr");
const app_1 = require("../../scripts/app");
const func_1 = require("../../scripts/func");
const demoarea_1 = require("../demoarea/demoarea");
var home;
(function (home) {
    let _home = null;
    const _element = {
        main: {
            cont: document.getElementById('ui-layer')
        },
        home: {
            cont: null,
            baner: null,
            demoarea: null,
            signInButton: null,
            nextPageButton: null,
        }
    };
    const initHeader = (l) => __awaiter(this, void 0, void 0, function* () {
        _element.home.cont ? _element.home.cont.remove() : null;
        // fetch the homeBaner.html file
        const _h = yield (0, func_1._fetch)(`content/${tr_1.lang[l]}/homeBaner.html`);
        // set observ for the fetched element
        (0, func_1.setObserv)(_element.main.cont, 'mpts-home', [
            initHomeElms,
            initSignInButton,
            initNextPageButton,
            initDemoArea,
        ]);
        //  append the fetched HTML element to the main container
        _element.main.cont.prepend(_h);
    });
    const initHomeElms = () => {
        _element.home.cont = document.getElementById('mpts-home');
        _element.home.baner = document.getElementById('mpts-home-header-text');
        _element.home.demoarea = document.getElementById('mpts-demozone');
        _element.home.signInButton = document.getElementById('mpts-home-header-user');
        _element.home.nextPageButton = document.getElementById('mtps-fromhome-todoc');
    };
    const initSignInButton = () => {
        // add event listener to sign in button for log in
        _element.home.signInButton.addEventListener('pointerdown', () => {
            console.log('sign in button clicked');
            // navigate to the sign in page
            app_1.app.getApp().setView(app_1.AppView.signin);
            // set the header underline to the sign in button
            app_1.app.getApp().ui.header.underLine(5);
        });
    };
    const initDemoArea = () => {
        // create the demo area
        const _demo = demoarea_1.demoarea.create(app_1.app.getApp().lang);
    };
    // add event listener to the next page button
    // to navigate to the docs page
    const initNextPageButton = () => {
        _element.home.nextPageButton.addEventListener('pointerdown', () => {
            app_1.app.getApp().setView(app_1.AppView.docs);
            app_1.app.getApp().ui.header.underLine(1);
        });
    };
    // translate the page to the given language
    const setLang = (l) => __awaiter(this, void 0, void 0, function* () {
        _element.home.cont.remove();
        initHeader(l);
    });
    // remove sign in button if user is loged in
    const checkSignIn = () => {
        if (app_1.app.getApp().logedIn) {
            _element.home.signInButton.style.display = 'none';
        }
        else {
            _element.home.signInButton.style.display = '';
        }
    };
    // show or hide the page
    const _show = (v = true) => {
        if (v) {
            _element.home.cont.style.display = 'flex';
            checkSignIn();
        }
        else {
            _element.home.cont.style.display = 'none';
        }
    };
    home.create = (l = tr_1.lang.eng) => {
        initHeader(l);
        _home = {
            id: crypto.randomUUID(),
            item: _element,
            show: _show,
            translate: setLang,
        };
        return _home;
    };
})(home || (exports.home = home = {}));
