"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.u_shres = exports.showUnionResult = void 0;
const mobx_1 = require("mobx");
const func_1 = require("../../../assest/func");
const app_1 = require("../../../scripts/app");
const func_2 = require("../../../scripts/func");
const core_1 = require("../../core");
const tr_1 = require("../../tr");
const tcheckbox_1 = require("../baseitems/tcheckbox");
const showUnionResult = (m) => {
    // remove
    m.view.querySelectorAll(`.union-result.${m.pres.uiid}`).forEach((c) => {
        c.remove();
    });
    if (!m.pres.result.show)
        return;
    // result
    const _res = core_1.un.getFullResult(m, true);
    // multiply _res values by 0.06
    // for (let key in _res) {
    //     _res[key].value *= 0.06
    // }
    // first line
    // const _fl = `f:${crnd(_res.full)} h:${crnd(_res.humn)} m:${crnd(_res.mach)} p:${crnd(_res.part)}`
    const _fl = `h:${(0, func_2.crnd)(_res.humn)} m:${(0, func_2.crnd)(_res.mach)} p:${(0, func_2.crnd)(_res.part)}`;
    // second line
    const _sl = `m ${(0, func_2.crnd)(_res.mach)} p ${(0, func_2.crnd)(_res.part)}`;
    let _br1 = m.view.querySelector(`.baseRect.${m.pres.uiid}`);
    const _brBBox1 = _br1.getBBox();
    // group for resulted text
    const group = document.createElementNS(func_2.NS, 'g');
    group.classList.add('union-result');
    group.classList.add(`${m.pres.uiid}`);
    const _comm = (0, func_2.csvg)('text', {
        'x': (0, func_2.rbb)(_brBBox1.x, 8),
        'y': (0, func_2.rbb)(_brBBox1.y + _brBBox1.height, 8),
    }, ['union-result-text', `${m.pres.uiid}`]);
    // add text lines to comment
    const lineHeight = 16;
    const _t = (0, func_2.csvg)('tspan', {
        'x': _brBBox1.x,
        'dy': lineHeight,
        // fill: 'rgb(153, 153, 153)',
    }, ['union-result-text-line', `${m.pres.uiid}`]);
    _t.textContent = _fl;
    _comm.appendChild(_t);
    // const _t1 = csvg('tspan', {
    //     'x': _brBBox1.x,
    //     'dy': lineHeight,
    // }, ['union-result-text-line', `${m.pres.uiid}`])
    // _t1.textContent = _sl
    // _comm.appendChild(_t1);
    group.appendChild(_comm);
    m.view.appendChild(group);
};
exports.showUnionResult = showUnionResult;
const u_shres = (m) => {
    // create default panel
    const cont = (0, func_1.bh)({ tag: 'div', classList: ['mpt_slider_topcont'] });
    // translate microelement
    const _tr = (0, tr_1.tr_u)(app_1.app.getApp().lang, m);
    // export type tchVT = [text: string, noval: string, yesval: string, val: boolean]
    const _over = (0, tcheckbox_1.tcheckbox)([_tr.shres, _tr.shres, _tr.shres, m.pres.result.show]).view;
    _over.addEventListener('change', (e) => {
        (0, mobx_1.runInAction)(() => {
            if (e.target.checked) {
                m.pres.result.show = true;
            }
            else {
                m.pres.result.show = false;
            }
        });
    });
    cont.appendChild(_over);
    return cont;
};
exports.u_shres = u_shres;
