"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.u_propsHeader = void 0;
const mobx_1 = require("mobx");
const func_1 = require("../../../assest/func");
// element
let cont;
const u_propsHeader = (u) => {
    // remove if exist
    cont ? cont.remove() : 0;
    cont = (0, func_1.bh)({
        tag: 'div', classList: ['mpt_headtop-topcont'], id: crypto.randomUUID(), children: [
            {
                tag: 'div',
                classList: ['mpt_theaderCon'],
                id: crypto.randomUUID(),
                children: [{
                        tag: 'div',
                        classList: ['mpt_theaderlabelCont'],
                    },
                    {
                        tag: 'div', classList: ['mpt_multBoxCont'],
                        children: [{ tag: 'label', classList: ['mpt_multBoxLabel'], textContent: 'x' },
                            {
                                tag: 'input',
                                classList: ['mpt_theadermultBox'],
                                attributes: {
                                    type: 'number',
                                    step: '1',
                                    value: u.core.multiplier.toString()
                                }
                            }]
                    }]
            },
        ]
    });
    const _nc = cont.querySelector('.mpt_theaderCon');
    _nc.style.flexDirection = 'column';
    _nc.style.setProperty('justify-content', 'unset', 'important');
    cont.querySelector('.mpt_theadermultBox').addEventListener('input', (e) => {
        (0, mobx_1.runInAction)(() => {
            u.core.multiplier = Number(e.target.value);
        });
    });
    return cont;
};
exports.u_propsHeader = u_propsHeader;
