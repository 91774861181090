"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.m_propsHeader = void 0;
const mobx_1 = require("mobx");
const func_1 = require("../../../assest/func");
const app_1 = require("../../../scripts/app");
const tr_1 = require("../../tr");
/** element instance */
let cont;
const m_propsHeader = (m) => {
    // remove if exist
    cont ? cont.remove() : 0;
    let _tr = (0, tr_1.tr_ml)(app_1.app.getApp().lang, m);
    cont = (0, func_1.bh)({
        tag: 'div', classList: ['mpt_headtop-topcont'], id: crypto.randomUUID(), children: [
            {
                tag: 'div',
                classList: ['mpt_theaderCon'],
                id: crypto.randomUUID(),
                children: [{
                        tag: 'div',
                        classList: ['mpt_theaderlabelCont'],
                        children: [{
                                tag: 'label', classList: ['mpt_theaderMainLabel'], textContent: _tr.group[1]
                            },
                            {
                                tag: 'label', classList: ['mpt_theaderAuxLabel'], textContent: _tr.extension[1]
                            }]
                    },
                    {
                        tag: 'div', classList: ['mpt_multBoxCont'],
                        children: [{ tag: 'label', classList: ['mpt_multBoxLabel'], textContent: 'x' },
                            {
                                tag: 'input',
                                classList: ['mpt_theadermultBox'],
                                attributes: {
                                    type: 'number',
                                    step: '1',
                                    value: m.core.multiplier.toString()
                                }
                            }]
                    }]
            },
        ]
    });
    cont.querySelector('.mpt_theadermultBox').addEventListener('input', (e) => {
        (0, mobx_1.runInAction)(() => {
            m.core.multiplier = Number(e.target.value);
        });
    });
    return cont;
};
exports.m_propsHeader = m_propsHeader;
