"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.docsnavbar = void 0;
const func_1 = require("../../assest/func");
const app_1 = require("../../scripts/app");
const func_2 = require("../../scripts/func");
var docsnavbar;
(function (docsnavbar) {
    const _cl = {
        nav: 'mpts-docsnavbar',
        navItem: 'mpts-docsnavbar-item',
        prev: 'mpts-docsnavbar-prev',
        curr: 'mpts-docsnavbar-curr',
        next: 'mpts-docsnavbar-next',
    };
    const _elements = {
        nav: null,
        prev: null,
        curr: null,
        next: null,
    };
    let _map = null;
    let _pointer = 1;
    const move = (d, b = 'smooth') => {
        switch (d) {
            case 'down':
                _pointer-- === 1 ? _pointer = 1 : null;
                break;
            case 'stay':
                break;
            case 'up':
                _pointer++ === _map.length - 1 ? _pointer = _map.length - 1 : null;
                break;
        }
        app_1.app.getApp().ui.docs.setToc(_map[_pointer].key, b);
    };
    docsnavbar.create = (p, v) => {
        _map = (0, func_2.flattenTOC)(v);
        _map.forEach((el) => { el.val.replace(/_/g, " "); });
        const _nav = (0, func_1.bh)({
            tag: 'div',
            id: 'mpts-docsnavbar',
            children: [
                { tag: 'button', id: _cl.prev, classList: ['mpts-docsnavbar-item'], textContent: '<< ...' },
                { tag: 'button', id: _cl.curr, classList: ['mpts-docsnavbar-item'] },
                { tag: 'button', id: _cl.next, classList: ['mpts-docsnavbar-item'] },
            ]
        });
        if (!p)
            return;
        p.appendChild(_nav);
        _elements.nav = document.getElementById(_cl.nav);
        _elements.prev = document.getElementById(_cl.prev);
        _elements.curr = document.getElementById(_cl.curr);
        _elements.next = document.getElementById(_cl.next);
        _elements.curr.addEventListener('pointerdown', () => {
            move('stay');
            setValue(_map[_pointer].key);
        });
        _elements.next.addEventListener('pointerdown', () => {
            move('up');
            setValue(_map[_pointer].key);
        });
        _elements.prev.addEventListener('pointerdown', () => {
            move('down');
            setValue(_map[_pointer].key);
        });
        init();
        return {
            element: _nav,
            setValue: setValue
        };
    };
    const setValue = (id = 'd1') => {
        let id_i = _map.findIndex(el => el.key === id);
        id_i === -1 ? _pointer = 1 : _pointer = id_i;
        let p_plus = (_pointer + 1) === _map.length ? ' ... >>' : ` ${_map[_pointer + 1].val} >>`;
        let p_minus = (_pointer - 1) === 0 ? '<< ...' : `<< ${_map[_pointer - 1].val}`;
        _elements.prev.textContent = p_minus;
        _elements.curr.textContent = `${_map[_pointer].val}`;
        _elements.next.textContent = p_plus;
        (0, func_2.setCookie)('mpts-docsnavbar', _map[_pointer].key, 365);
    };
    const init = () => {
        const id = (0, func_2.getCookie)('mpts-docsnavbar');
        id ? _pointer = _map.findIndex(el => el.key === id) : _pointer = 1;
        move('stay', 'instant');
        setValue(id);
    };
})(docsnavbar || (exports.docsnavbar = docsnavbar = {}));
