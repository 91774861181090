"use strict";
/**
The System provides the ability to fully and unambiguously
determine the complexity of performing a technological operation,
actions or intentions to perform them
by presenting complex and diverse personnel actions,
machines and mechanisms, management personnel and the operation
of various types of management documents
through combinations of simple and primary elements
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.mlt = exports.st = exports.time = exports.un = exports.nita = exports.ins = exports.comb = exports.ml = exports.cb = exports.tg = exports.nf = exports.qf = exports.cm = exports.CoreTypes = void 0;
const mobx_1 = require("mobx");
/**
The System for determining the time of execution of a technological operation
based on the application of the approach of using microelements of the labor process
a microelement is understood as such an element of the labor process
that it is impractical to further dissect
it is determined by the group, extension (subgroup),
qualitative and quantitative factors, and the overlap indicator is also defined for it,
analytical factors can be determined or not
this is a software implementation of the algorithm for creating the labor intensity of one element,
is a part and the basis for creating the labor intensity of an operation
and a complete technological process later
thus, the basis is created for the simulation of a complete tree of objects
that make up the technological, operational process of manufacturing a product, creating added value

    Copyright © 2020 Iurii Dzhugostranskii. All rights reserved.
    Copyright © 2021 Iurii Dzhugostranskii. All rights reserved.
    Copyright © 2023 Iurii Dzhugostranskii. All rights reserved.
    Copyright © 2024 Iurii Dzhugostranskii. All rights reserved.
    Copyright © 2024 Iurii Dzhugostranskii, metaprodtrace. All rights reserved.
    Copyright © 2025 Iurii Dzhugostranskii, Metaprodtrace, Metaprod | trace. All rights reserved.
        /no OPP unlike before
*/
//#region  CoreTypes
/** basic elements for creating an object - 'microelement' */
var CoreTypes;
(function (CoreTypes) {
    /** factor describing quality features or limitations
        not having absolute, objective units of measurement
        for example, caution, can be - 'with caution' or 'without caution' */
    CoreTypes[CoreTypes["qualityFactor"] = 0] = "qualityFactor";
    /** 'with caution' or 'without caution' for example */
    CoreTypes[CoreTypes["qualityFactorValue"] = 1] = "qualityFactorValue";
    /** list of factors specific to microelement, at this point its
        already have a power */
    CoreTypes[CoreTypes["qualityFactorList"] = 2] = "qualityFactorList";
    /** meas unit - m, mm, kg etc.*/
    CoreTypes[CoreTypes["unit"] = 3] = "unit";
    /** factor describes the physical limitations,
        quantitative characterization has a value and unit of measurement
        such as length (mm), weight (kg) */
    CoreTypes[CoreTypes["numericalFactor"] = 4] = "numericalFactor";
    /** list of numerical factors specific to microelement */
    CoreTypes[CoreTypes["numericalFactorList"] = 5] = "numericalFactorList";
    CoreTypes[CoreTypes["group"] = 6] = "group";
    CoreTypes[CoreTypes["extension"] = 7] = "extension";
    //? ------ >
    CoreTypes[CoreTypes["multiplier"] = 8] = "multiplier";
    CoreTypes[CoreTypes["vac"] = 9] = "vac";
    CoreTypes[CoreTypes["comment"] = 10] = "comment";
    CoreTypes[CoreTypes["employment"] = 11] = "employment";
    /** tags */
    CoreTypes[CoreTypes["mechanization"] = 12] = "mechanization";
    CoreTypes[CoreTypes["target"] = 13] = "target";
    /** a microelement is understood as such an element of the labor process
        that it is impractical to further dissect
        it is determined by the group, extension (subgroup),
        qualitative and quantitative factors, and the overlap indicator is also defined for it,
        analytical factors can be determined or not */
    CoreTypes[CoreTypes["microelement"] = 14] = "microelement";
    //? the following elements are an extension of the basic system
    //? and serve to describe the transitions and as a result the operation
    CoreTypes[CoreTypes["intensity"] = 15] = "intensity";
    CoreTypes[CoreTypes["notInTimeAction"] = 16] = "notInTimeAction";
    CoreTypes[CoreTypes["union"] = 17] = "union";
    /** extension of Step group for a more detailed
          description of the actions performed in the described time period */
    CoreTypes[CoreTypes["stepType"] = 18] = "stepType";
    /** a Step 'property' indicating the type of time it generates */
    CoreTypes[CoreTypes["stepGroup"] = 19] = "stepGroup";
    /** group of microelements
          the object is not formally an extension of the kernel, it is just an array of basic elements
          but it is the creation of this element that begins the ability
          to provide to calculate time indicators. */
    CoreTypes[CoreTypes["step"] = 20] = "step";
    CoreTypes[CoreTypes["time"] = 21] = "time";
    //! mixed sim types
    CoreTypes[CoreTypes["input"] = 22] = "input";
    CoreTypes[CoreTypes["output"] = 23] = "output";
})(CoreTypes || (exports.CoreTypes = CoreTypes = {}));
/** modules, functions, constants that are common to the assembly */
var cm;
(function (cm) {
    /**
     * random ID
     */
    cm.ID = () => self.crypto.randomUUID();
    //! the base unit of time is one thousandth of a minute,
    //! to translate the value into seconds, you must multiply by 0.06
    //! =====================================
    cm.MAGIC = 0.06;
    //! =====================================
})(cm || (exports.cm = cm = {}));
//#region QualityFactor
/** A qualitative factor is an indicator of a trace element
    that is not directly measured in numbers but has verbal descriptive characteristics.
    for example, the caution factor - can be either with caution or without caution
    for example, the joint density factor - can be either tight, loose or very tight
    the implementation below describes the values and the factors themselves,
    and also provides a description of the final list of the characteristic for the trace element
 */
var qf;
(function (qf) {
    /** quality factors possible values */
    let QualityFactorValue;
    (function (QualityFactorValue) {
        QualityFactorValue[QualityFactorValue["withoutCaution"] = 0] = "withoutCaution";
        QualityFactorValue[QualityFactorValue["withCaution"] = 1] = "withCaution";
        QualityFactorValue[QualityFactorValue["dc_low"] = 2] = "dc_low";
        QualityFactorValue[QualityFactorValue["dc_medium"] = 3] = "dc_medium";
        QualityFactorValue[QualityFactorValue["dc_high"] = 4] = "dc_high";
        QualityFactorValue[QualityFactorValue["do_low"] = 5] = "do_low";
        QualityFactorValue[QualityFactorValue["do_medium"] = 6] = "do_medium";
        QualityFactorValue[QualityFactorValue["do_high"] = 7] = "do_high";
        QualityFactorValue[QualityFactorValue["conveniently"] = 8] = "conveniently";
        QualityFactorValue[QualityFactorValue["inconveniently"] = 9] = "inconveniently";
        QualityFactorValue[QualityFactorValue["veryInconveniently"] = 10] = "veryInconveniently";
        QualityFactorValue[QualityFactorValue["freely"] = 11] = "freely";
        QualityFactorValue[QualityFactorValue["cramped"] = 12] = "cramped";
        QualityFactorValue[QualityFactorValue["veryCramped"] = 13] = "veryCramped";
        QualityFactorValue[QualityFactorValue["loose"] = 14] = "loose";
        QualityFactorValue[QualityFactorValue["tight"] = 15] = "tight";
        QualityFactorValue[QualityFactorValue["veryTight"] = 16] = "veryTight";
        QualityFactorValue[QualityFactorValue["small"] = 17] = "small";
        QualityFactorValue[QualityFactorValue["average"] = 18] = "average";
        QualityFactorValue[QualityFactorValue["large"] = 19] = "large";
        QualityFactorValue[QualityFactorValue["mass"] = 20] = "mass";
    })(QualityFactorValue = qf.QualityFactorValue || (qf.QualityFactorValue = {}));
    /** quality factors */
    let QualityFactor;
    (function (QualityFactor) {
        QualityFactor[QualityFactor["caution"] = 0] = "caution";
        QualityFactor[QualityFactor["degreeOfControl"] = 1] = "degreeOfControl";
        QualityFactor[QualityFactor["degreeOfOrientation"] = 2] = "degreeOfOrientation";
        QualityFactor[QualityFactor["conveninceOfWork"] = 3] = "conveninceOfWork";
        QualityFactor[QualityFactor["crampedness"] = 4] = "crampedness";
        QualityFactor[QualityFactor["connectionDensity"] = 5] = "connectionDensity";
        QualityFactor[QualityFactor["productionType"] = 6] = "productionType";
    })(QualityFactor = qf.QualityFactor || (qf.QualityFactor = {}));
    /** correspondence of quality factors and their acceptable values */
    const qfvdictionary = [
        [0, [0, 1]],
        [1, [2, 3, 4]],
        [2, [5, 6, 7]],
        [3, [8, 9, 10]],
        [4, [11, 12, 13]],
        [5, [14, 15, 16]],
        [6, [20, 19, 18, 17]],
    ];
    /** 'qf' - value item creation */
    qf.createValue = (k, p) => {
        return {
            kind: k,
            power: p,
        };
    };
    /** 'qf' - item creation */
    qf.create = (n) => {
        let _list = [];
        const found = qfvdictionary.find((el) => el[0] == n[0]);
        if (found) {
            found[1].forEach((el, i) => {
                _list.push(qf.createValue(el, n[1][i]));
            });
        }
        // set average by default for production type
        let v = n[0] == 6 ? 2 : 0;
        return {
            kind: n[0],
            values: _list,
            value: _list[v].kind,
        };
    };
    qf.getPower = (f) => {
        const found = f.values.find((el) => el.kind === f.value);
        return found ? found.power : 0;
    };
    /** create list of factors for specific microelements */
    qf.createList = (n) => {
        let _list = [];
        n.forEach((el) => _list.push(qf.create(el)));
        return {
            values: _list,
        };
    };
    /** change curr value of quality factor
     //! missing verification */
    qf.setValue = (l, i, v) => {
        let _qf = l.values.find((el) => el.kind == i);
        if (_qf) {
            _qf.value = v;
        }
    };
})(qf || (exports.qf = qf = {}));
//#region Numerical factor
/** all about - numeriacal factor */
var nf;
(function (nf) {
    /** enumeration of possible types of units */
    let Unit;
    (function (Unit) {
        Unit[Unit["mm"] = 0] = "mm";
        Unit[Unit["m"] = 1] = "m";
        Unit[Unit["kg"] = 2] = "kg";
        Unit[Unit["degree"] = 3] = "degree";
        Unit[Unit["Nm"] = 4] = "Nm";
        /** */
        Unit[Unit["pcs"] = 5] = "pcs";
        Unit[Unit["s"] = 6] = "s";
        Unit[Unit["percent"] = 7] = "percent";
    })(Unit = nf.Unit || (nf.Unit = {}));
    /** create unit */
    nf.createUnit = (k) => {
        return { kind: k };
    };
    /** types of measured factors */
    let NumericalFactor;
    (function (NumericalFactor) {
        NumericalFactor[NumericalFactor["movingDistance"] = 0] = "movingDistance";
        NumericalFactor[NumericalFactor["transitionDistance"] = 1] = "transitionDistance";
        NumericalFactor[NumericalFactor["objectMass"] = 2] = "objectMass";
        NumericalFactor[NumericalFactor["largestSideSize"] = 3] = "largestSideSize";
        NumericalFactor[NumericalFactor["angelOfRotation"] = 4] = "angelOfRotation";
        NumericalFactor[NumericalFactor["penetrationLength"] = 5] = "penetrationLength";
        NumericalFactor[NumericalFactor["threadDiameter"] = 6] = "threadDiameter";
        NumericalFactor[NumericalFactor["appliedForce"] = 7] = "appliedForce";
    })(NumericalFactor = nf.NumericalFactor || (nf.NumericalFactor = {}));
    /** correspondence of the numerical factors and the unit of measurement
      [NumericalFactor, Unit] values from corresp. enums */
    const nfunitdictionary = [
        [0, 0],
        [1, 1],
        [2, 2],
        [3, 0],
        [4, 3],
        [5, 0],
        [6, 0],
        [7, 4],
    ];
    /** create simple numerical factor */
    nf.create = (n, v) => {
        const found = nfunitdictionary.find((el) => el[0] == n[0]);
        if (!found) {
            throw new Error(`Element not found in nfunitdictionary for kind ${n[0]}`);
        }
        let _u = nf.createUnit(found[1]);
        return {
            kind: n[0],
            unit: _u,
            min: n[1][0],
            max: n[1][1],
            power: n[1][2],
            value: v ? v : n[1][0],
        };
    };
    /** enumeration of numerical factors corresponding to element  */
    nf.createList = (n) => {
        let _list = [];
        n.forEach((el) => _list.push(nf.create(el)));
        return {
            values: _list,
        };
    };
})(nf || (exports.nf = nf = {}));
//#region  Tags
/** mechanization, target purpose  ...etc */
var tg;
(function (tg) {
    //! its not used
    //? 2023-24 used !
    // export const ntdic: [number, number][] = [[0, 1], [1, 2], [2, 3], [3, 4]]
    tg.trgDic = [
        [0, 0.3],
        [1, 0.5],
        [2, 0.7],
        [3, 1.6],
        [4, 1.4],
        [5, 1.4],
    ];
    tg.mechDic = [
        [0, 0.5],
        [1, 0.6],
        [2, 0.7],
        [3, 0.8],
        [4, 0.9],
        [5, 1.1],
    ];
    /** list of active tags */
    let Tags;
    (function (Tags) {
        Tags[Tags["mechanization"] = 0] = "mechanization";
        Tags[Tags["target"] = 1] = "target";
    })(Tags = tg.Tags || (tg.Tags = {}));
    let Mechanization;
    (function (Mechanization) {
        Mechanization[Mechanization["manualLabour"] = 0] = "manualLabour";
        Mechanization[Mechanization["manualMechanized"] = 1] = "manualMechanized";
        Mechanization[Mechanization["machineManual"] = 2] = "machineManual";
        Mechanization[Mechanization["machine"] = 3] = "machine";
        Mechanization[Mechanization["automated"] = 4] = "automated";
        Mechanization[Mechanization["automatic"] = 5] = "automatic";
    })(Mechanization = tg.Mechanization || (tg.Mechanization = {}));
    let Target;
    (function (Target) {
        Target[Target["processing"] = 0] = "processing";
        Target[Target["inspection"] = 1] = "inspection";
        Target[Target["monitoring"] = 2] = "monitoring";
        Target[Target["movement"] = 3] = "movement";
        Target[Target["information"] = 4] = "information";
    })(Target = tg.Target || (tg.Target = {}));
    tg.createValue = (k, p = 0) => {
        return { kind: k, power: p };
    };
    /** create tag instance */
    tg.create = (k, objp, 
    /** element index from corresponding enum */
    v) => {
        let r = [];
        objp.forEach((el) => r.push({ kind: el[0], power: el[1] }));
        return { coreType: k, values: r, value: v };
    };
    /**
     * @returns list of tags specified for microelement
     * @version 0.1 added ability customize tags according to group and extension
     * @version 0.2 removed ability customize tags according to group and extension
     */
    tg.createList = (g) => {
        let r = { values: [] };
        // control mechanization for machine time
        if (g == 21) {
            r.values.push(tg.create(Tags.mechanization, [[5, 1.1]], 5));
            r.values.push(tg.create(Tags.target, tg.trgDic, tg.mechDic[0][0]));
            return r;
        }
        else {
            r.values.push(tg.create(Tags.mechanization, tg.mechDic, tg.mechDic[0][0]));
        }
        r.values.push(tg.create(Tags.target, tg.trgDic, tg.mechDic[0][0]));
        return r;
    };
})(tg || (exports.tg = tg = {}));
// #region combined
var cb;
(function (cb_1) {
    //? 202...-25 used !
    cb_1.cb_dic = [
        [{ g: 0, e: 35, c: { t: 'Q', k: 1, v: [2, 3, 4] } },
            { g: 0, e: 35, c: { t: 'Q', k: 1, v: [2, 3, 4] } }, 0.7],
        [{ g: 0, e: 35, c: { t: 'Q', k: 1, v: [2, 3, 4] } },
            { g: 1, e: null, c: { t: 'Q', k: 1, v: [2, 3, 4] } }, 0.5]
    ];
    cb_1.fcomb = (r) => {
        // console.log('fcomb', r.core.group, r.core.extension);
        const F1 = cb.cb_dic.filter(el => el[0].g == r.core.group &&
            el[0].e == r.core.extension &&
            el[0].c.t == 'Q' ?
            true :
            true &&
                r.core.qualityFactors.values.find(qf => qf.kind == el[0].c.k));
        return F1;
    };
    /** create combined microelement */
    cb_1.create = () => {
        return {
            items: [],
            add: (cb, i) => {
                cb.items.push(i);
            },
            remove: (cb, i) => {
                console.log('remove combined');
                const _i = cb.items.findIndex((el) => el.core.id == i.core.id);
                if (_i === -1)
                    return;
                cb.items.splice(_i, 1);
            },
            removeByIndex: (cb, i) => {
                if (i === -1)
                    return;
                cb.items.splice(i, 1);
            },
            removeAll: (cb) => {
                cb.items = [];
            },
            insert: (cb, i, index) => {
                // index == 0 ? cb.items.unshift(i) :
                cb.items.splice(index, 0, i);
            },
        };
    };
})(cb || (exports.cb = cb = {}));
//#region  Microelment
/** microelement */
var ml;
(function (ml) {
    /** enumeration of groups is given in accordance with the source text,
      added groups 'delay', 'machineTime' to expand functionality */
    let Group;
    (function (Group) {
        Group[Group["reachByHand"] = 0] = "reachByHand";
        Group[Group["move"] = 1] = "move";
        Group[Group["turn"] = 2] = "turn";
        Group[Group["rotate"] = 3] = "rotate";
        Group[Group["install"] = 4] = "install";
        Group[Group["disconnect"] = 5] = "disconnect";
        Group[Group["take"] = 6] = "take";
        Group[Group["walk"] = 7] = "walk";
        Group[Group["rotateTheTorso"] = 8] = "rotateTheTorso";
        Group[Group["takeALook"] = 9] = "takeALook";
        Group[Group["release"] = 10] = "release";
        Group[Group["pressByHand"] = 11] = "pressByHand";
        Group[Group["setOnSize"] = 12] = "setOnSize";
        Group[Group["pressByLeg"] = 13] = "pressByLeg";
        Group[Group["moveLeg"] = 14] = "moveLeg";
        Group[Group["bendDown"] = 15] = "bendDown";
        Group[Group["straightenUp"] = 16] = "straightenUp";
        Group[Group["sitDown"] = 17] = "sitDown";
        Group[Group["getUp"] = 18] = "getUp";
        Group[Group["gaze"] = 19] = "gaze";
        //
        Group[Group["delay"] = 20] = "delay";
        Group[Group["machineTime"] = 21] = "machineTime";
        //
        Group[Group["blank"] = 22] = "blank";
    })(Group = ml.Group || (ml.Group = {}));
    /** enumerate avalible 'extension' items */
    let Extension;
    (function (Extension) {
        Extension[Extension["throughSpaceWeightLessThan1KG"] = 0] = "throughSpaceWeightLessThan1KG";
        Extension[Extension["throughSpaceWeightMoreThan1KG"] = 1] = "throughSpaceWeightMoreThan1KG";
        Extension[Extension["knockback"] = 2] = "knockback";
        Extension[Extension["overTheSurface"] = 3] = "overTheSurface";
        Extension[Extension["toolByTheTreatedSurface"] = 4] = "toolByTheTreatedSurface";
        Extension[Extension["overTheRollerTable"] = 5] = "overTheRollerTable";
        Extension[Extension["intoTheSpace"] = 6] = "intoTheSpace";
        Extension[Extension["aroundTheAxis"] = 7] = "aroundTheAxis";
        Extension[Extension["onASurfaceInAHorizontalPlane"] = 8] = "onASurfaceInAHorizontalPlane";
        Extension[Extension["onASurfaceInAVerticalPlane"] = 9] = "onASurfaceInAVerticalPlane";
        Extension[Extension["handle"] = 10] = "handle";
        Extension[Extension["byHand"] = 11] = "byHand";
        Extension[Extension["byScrewdriver"] = 12] = "byScrewdriver";
        Extension[Extension["byWrench"] = 13] = "byWrench";
        Extension[Extension["flywheel"] = 14] = "flywheel";
        Extension[Extension["onThePlane"] = 15] = "onThePlane";
        Extension[Extension["onTheShaftOrInTheHole"] = 16] = "onTheShaftOrInTheHole";
        Extension[Extension["take"] = 17] = "take";
        Extension[Extension["undertake"] = 18] = "undertake";
        Extension[Extension["intercept"] = 19] = "intercept";
        Extension[Extension["walk"] = 20] = "walk";
        Extension[Extension["walkWithTheTrolleyOrHoist"] = 21] = "walkWithTheTrolleyOrHoist";
        Extension[Extension["walkUpTheStrairs1Step"] = 22] = "walkUpTheStrairs1Step";
        Extension[Extension["removeHandFromObject"] = 23] = "removeHandFromObject";
        Extension[Extension["unclenchFingers"] = 24] = "unclenchFingers";
        Extension[Extension["PBH_withoutForce"] = 25] = "PBH_withoutForce";
        Extension[Extension["PBH_withForce"] = 26] = "PBH_withForce";
        Extension[Extension["PBL_withoutForce"] = 27] = "PBL_withoutForce";
        Extension[Extension["PBL_withForce"] = 28] = "PBL_withForce";
        Extension[Extension["upToKnees"] = 29] = "upToKnees";
        Extension[Extension["upToTheFloor"] = 30] = "upToTheFloor";
        Extension[Extension["fromTheKnee"] = 31] = "fromTheKnee";
        Extension[Extension["fromTheFloor"] = 32] = "fromTheFloor";
        Extension[Extension["_"] = 35] = "_";
    })(Extension = ml.Extension || (ml.Extension = {}));
    /** possible designation options for employment factor */
    let Employment;
    (function (Employment) {
        Employment[Employment["nonOverlapped"] = 0] = "nonOverlapped";
        Employment[Employment["overlapped"] = 1] = "overlapped";
    })(Employment = ml.Employment || (ml.Employment = {}));
    /** value storage for first time microelement creation */
    /**
     @example
     [1, 0, 0.27,
        [[0, [1, 1.2]], [1, [1, 1.1, 1.2]], [6, [1, 1.1, 1.2, 1.4]],],
        [[0, [25, 750, 0.5]], [3, [1, 2000, 0.08]],],],
     //? tba
     */
    //prettier-ignore
    ml.mldic = [
        [0, 35, 0.36, [[0, [1, 1.2]], [1, [1, 1.1, 1.2]], [6, [1, 1.1, 1.2, 1.4]],], [[0, [25, 750, 0.5]]],],
        [1, 0, 0.27, [[0, [1, 1.2]], [1, [1, 1.1, 1.2]], [6, [1, 1.1, 1.2, 1.4]],], [[0, [25, 750, 0.5]], [3, [1, 2000, 0.08]],],],
        [1, 1, 0.23, [[0, [1, 1.2]], [1, [1, 1.1, 1.2]], [6, [1, 1.1, 1.2, 1.4]],], [[0, [25, 750, 0.56]], [3, [1, 2000, 0.08]], [2, [1, 20, 0.26]],],],
        [1, 2, 0.13, [[0, [1, 1.2]], [6, [1, 1.1, 1.2, 1.4]],], [[0, [25, 750, 0.5]], [3, [1, 2000, 0.08]], [2, [0.01, 20, 0.26]],],],
        [1, 3, 0.23, [[0, [1, 1.2]], [6, [1, 1.1, 1.2, 1.4]],], [[0, [25, 750, 0.56]], [2, [0.01, 80, 0.18]], [3, [1, 2000, 0, 0.08]],],],
        [1, 4, 0.47, [[0, [1, 1.2]], [1, [1, 1.1, 1.2]], [6, [1, 1.1, 1.2, 1.4]],], [[0, [25, 750, 0.5]]],],
        [1, 5, 0.23, [[0, [1, 1.2]], [6, [1, 1.1, 1.2, 1.4]],], [[0, [25, 750, 0.05]], [2, [0.01, 80, 0.1]], [3, [1, 2000, 0.08]],],],
        [2, 6, 0.75, [[0, [1, 1.2]], [6, [1, 1.1, 1.2, 1.4]],], [[2, [0.001, 20, 0.37]], [4, [30, 180, 0.35]],],],
        [2, 7, 1.28, [[6, [1, 1.1, 1.2, 1.4]]], [[4, [30, 180, 0.35]], [7, [10, 200, 0.37]],],],
        [2, 8, 0.3, [[0, [1, 1.2]], [6, [1, 1.1, 1.2, 1.4]],], [[2, [1, 80, 0.18]], [3, [1, 2000, 0.27]], [4, [30, 180, 0.28]],],],
        [2, 9, 8, [[0, [1, 1.2]], [6, [1, 1.1, 1.2, 1.4]],], [[2, [0.01, 80, 0.27]]],],
        [2, 10, 0.43, [[6, [1, 1.1, 1.2, 1.4]]], [[3, [1, 500, 0.23]], [4, [30, 180, 0.35]], [7, [10, 200, 0.37]],],],
        [3, 11, 3.15, [[1, [1.6, 2.5]], [6, [1, 1.1, 1.2, 1.4]],], [[6, [2, 48, 0.4]]],],
        [3, 12, 2.84, [[4, [1.6, 2.5]], [6, [1, 1.1, 1.2, 1.4]],], [[6, [2, 15, 0.4]]],],
        [3, 13, 7.56, [[4, [1, 1.6, 2.5]], [6, [1, 1.1, 1.2, 1.4]],], [[6, [2, 28, 0.4]]],],
        [3, 14, 0.37, [[6, [1, 1.1, 1.2, 1.4]]], [[4, [30, 360, 0.25]], [6, [40, 500, 0.23]], [7, [10, 200, 0.25]],],],
        [4, 15, 0.29, [[0, [1, 1.2]], [2, [1, 2, 4]], [3, [1, 1.2, 1.3]], [6, [1, 1.1, 1.2, 1.4]],], [[2, [0.01, 20, 0.35]], [3, [1, 1000, 0.37]],],],
        [4, 16, 0.95, [[0, [1, 1.5]], [2, [1, 1.2, 1.8]], [3, [1, 1.2, 1.3]], [5, [1, 1.2, 2]], [6, [1, 1.1, 1.2, 1.4]],], [[2, [0.01, 20, 0.2]], [5, [1, 750, 0.44]],],],
        [5, 35, 0.54, [[0, [1, 1.5]], [3, [1, 1.2, 1.3]], [5, [1, 1.2, 2]], [6, [1, 1.1, 1.2, 1.4]],], [[2, [0.01, 20, 0.2]], [5, [1, 750, 0.52]],],],
        [6, 17, 0.95, [[0, [1, 1.5]], [1, [1, 1.2, 1.6]], [3, [1, 1.2, 1.3]], [6, [1, 1.1, 1.2, 1.4]],], [[2, [0.01, 20, 0.27]], [3, [1, 2000, 0.18]],],],
        [6, 18, 2, [[6, [1, 1.1, 1.2, 1.4]]], []],
        [6, 19, 3.5, [[6, [1, 1.1, 1.2, 1.4]]], []],
        [10, 23, 0, [[6, [1, 1.1, 1.2, 1.4]]], []],
        [10, 24, 1.2, [[6, [1, 1.1, 1.2, 1.4]]], []],
        [11, 25, 3, [[6, [1, 1.1, 1.2, 1.4]]], []],
        [11, 26, 9, [[6, [1, 1.1, 1.2, 1.4]]], []],
        [12, 35, 30, [[6, [1, 1.1, 1.2, 1.4]]], []],
        [8, 35, 0.35, [[0, [1, 1.2]], [6, [1, 1.1, 1.2, 1.4]],], [[2, [0.01, 20, 0.05]], [4, [45, 180, 0.85]],],],
        [15, 29, 7, [[6, [1, 1.1, 1.2, 1.4]]], []],
        [15, 30, 15, [[6, [1, 1.1, 1.2, 1.4]]], []],
        [16, 31, 9, [[6, [1, 1.1, 1.2, 1.4]]], []],
        [16, 32, 17, [[6, [1, 1.1, 1.2, 1.4]]], []],
        [17, 35, 20, [[6, [1, 1.1, 1.2, 1.4]]], []],
        [18, 35, 25, [[6, [1, 1.1, 1.2, 1.4]]], []],
        [7, 20, 13, [[0, [1, 1.2]], [4, [1, 1.2, 1.2]], [6, [1, 1.1, 1.2, 1.4]],], [[2, [0.01, 20, 0.1]]],],
        [7, 21, 16.5, [[4, [1, 1.2, 1.2]], [6, [1, 1.1, 1.2, 1.4]],], [[1, [1, 200, 1]]],],
        [7, 22, 12, [[0, [1, 1.2]], [4, [1, 1.2, 1.2]], [6, [1, 1.1, 1.2, 1.4]],], [[2, [0.01, 20, 0.15]]],],
        [14, 35, 7, [[6, [1, 1.1, 1.2, 1.4]]], []],
        [13, 27, 5, [[6, [1, 1.1, 1.2, 1.4]]], []],
        [13, 28, 10, [[6, [1, 1.1, 1.2, 1.4]]], []],
        [9, 35, 0.68, [[6, [1, 1.1, 1.2, 1.4]]], [[0, [100, 1000, 0.4]]],],
        [19, 35, 4, [[6, [1, 1.1, 1.2, 1.4]]], []],
        [20, 35, 16.666661, [], []],
        [21, 35, 16.666661, [], []],
        [22, 35, 0, [], []]
    ];
    /** restore microelement */
    ml.restore = (d) => {
        const _d = d;
        return _d;
    };
    /** first time created microelement by 'group' and 'extension'  */
    ml.create = (g, e) => {
        let n = ml.mldic.find((el) => el[0] == g && el[1] == e);
        return {
            core: {
                id: cm.ID(),
                coreType: CoreTypes.microelement,
                group: n[0],
                extension: n[1],
                vac: n[2],
                qualityFactors: qf.createList(n[3]),
                numericalFactors: nf.createList(n[4]),
                multiplier: 1,
                employment: 0,
                comment: '',
                tags: tg.createList(g),
                combined: cb.create(), //? 2019-24 used !
            }
        };
    };
    /** calculating the execution time by multiply 'vac',
        qualityFactors and numericalFactors results, and current multiplier*/
    ml.getResult = (m) => {
        // if (m.core.employment == ml.Employment.overlapped) {
        //     return 0
        // }
        // if (m.core.multiplier == 0) {
        //     return 0
        // }
        const _getone = (m) => {
            return m.core.numericalFactors.values.length != 0 ||
                m.core.qualityFactors.values.length != 0
                ? m.core.qualityFactors.values
                    .map((el) => qf.getPower(el))
                    .concat(m.core.numericalFactors.values.map((el) => Math.pow(el.value, el.power)))
                    .reduce((a, b) => a * b) *
                    m.core.vac *
                    m.core.multiplier
                : m.core.vac * m.core.multiplier;
        };
        const currentResult = _getone(m);
        const childrenResult = m.core.combined.items.reduce((sum, child) => {
            return sum + ml.getResult(child);
        }, 0);
        return currentResult + childrenResult;
    };
    /**
     * string representation of calculation
     */
    ml.getCompStr = (m) => {
        // string for quality factors
        let qfstr = '';
        if (m.core.qualityFactors.values.length == 0) {
            qfstr = '1';
        }
        else {
            m.core.qualityFactors.values.forEach((el, i) => {
                let tqf1 = el.values
                    .find((el1) => el1.kind == el.value);
                if (!tqf1) {
                    throw new Error(`Element with kind ${el.value} not found in el.values`);
                }
                let tqf = tqf1.power.toString();
                if (i < m.core.qualityFactors.values.length - 1) {
                    qfstr += tqf + '*';
                }
                else {
                    qfstr += tqf;
                }
            });
        }
        // string for numerical factors
        let nfstr = '';
        if (m.core.numericalFactors.values.length == 0) {
            nfstr = '1';
        }
        else {
            m.core.numericalFactors.values.forEach((el, i) => {
                if (i < m.core.numericalFactors.values.length - 1) {
                    nfstr += `${el.value}^${el.power}*`;
                }
                else {
                    nfstr += `${el.value}^${el.power}`;
                }
            });
        }
        return `${m.core.vac}*(${qfstr})*(${nfstr})*${m.core.multiplier}`;
    };
})(ml || (exports.ml = ml = {}));
//#region ML Combination
// sometimes microelements can be performed in parallel
// in this case one overlaps the other and coefficients are applied
var comb;
(function (comb) {
    const combDic = [
        [[ml.Group.reachByHand, ml.Extension._], [ml.Group.reachByHand, ml.Extension._], 0.7],
        [[ml.Group.reachByHand, ml.Extension._], [ml.Group.move, ml.Extension.intoTheSpace], 0.5],
        [[ml.Group.reachByHand, ml.Extension._], [ml.Group.move, ml.Extension.overTheSurface], 0.5],
        [[ml.Group.reachByHand, ml.Extension._], [ml.Group.turn, ml.Extension.handle], 0.5],
        [[ml.Group.reachByHand, ml.Extension._], [ml.Group.turn, ml.Extension.flywheel], 0.5],
        [[ml.Group.reachByHand, ml.Extension._], [ml.Group.take, ml.Extension._], 1],
        [[ml.Group.reachByHand, ml.Extension._], [ml.Group.release, ml.Extension._], 1],
        //
        [[ml.Group.move, ml.Extension.intoTheSpace], [ml.Group.reachByHand, ml.Extension._], 0.5],
        [[ml.Group.move, ml.Extension.intoTheSpace], [ml.Group.move, ml.Extension.intoTheSpace], 0.6],
        [[ml.Group.move, ml.Extension.intoTheSpace], [ml.Group.move, ml.Extension.overTheSurface], 0.5],
        [[ml.Group.move, ml.Extension.intoTheSpace], [ml.Group.turn, ml.Extension.handle], 0.4],
        [[ml.Group.move, ml.Extension.intoTheSpace], [ml.Group.turn, ml.Extension.flywheel], 0.2],
        [[ml.Group.move, ml.Extension.intoTheSpace], [ml.Group.take, ml.Extension._], 1],
        [[ml.Group.move, ml.Extension.intoTheSpace], [ml.Group.release, ml.Extension._], 1],
        //
        [[ml.Group.move, ml.Extension.overTheSurface], [ml.Group.reachByHand, ml.Extension._], 0.5],
        [[ml.Group.move, ml.Extension.overTheSurface], [ml.Group.move, ml.Extension.intoTheSpace], 0.5],
        [[ml.Group.move, ml.Extension.overTheSurface], [ml.Group.move, ml.Extension.overTheSurface], 0.6],
        [[ml.Group.move, ml.Extension.overTheSurface], [ml.Group.take, ml.Extension._], 1],
        [[ml.Group.move, ml.Extension.overTheSurface], [ml.Group.release, ml.Extension._], 1],
        //
        [[ml.Group.turn, ml.Extension.handle], [ml.Group.reachByHand, ml.Extension._], 0.5],
        [[ml.Group.turn, ml.Extension.handle], [ml.Group.move, ml.Extension.intoTheSpace], 0.4],
        [[ml.Group.turn, ml.Extension.handle], [ml.Group.turn, ml.Extension.handle], 0.7],
        [[ml.Group.turn, ml.Extension.handle], [ml.Group.turn, ml.Extension.flywheel], 0.5],
        [[ml.Group.turn, ml.Extension.handle], [ml.Group.take, ml.Extension._], 1],
        [[ml.Group.turn, ml.Extension.handle], [ml.Group.release, ml.Extension._], 1],
        //
        [[ml.Group.turn, ml.Extension.flywheel], [ml.Group.reachByHand, ml.Extension._], 0.3],
        [[ml.Group.turn, ml.Extension.flywheel], [ml.Group.move, ml.Extension.intoTheSpace], 0.2],
        [[ml.Group.turn, ml.Extension.flywheel], [ml.Group.turn, ml.Extension.handle], 0.5],
        [[ml.Group.turn, ml.Extension.flywheel], [ml.Group.turn, ml.Extension.flywheel], 0.7],
        [[ml.Group.turn, ml.Extension.flywheel], [ml.Group.take, ml.Extension.take], 1],
        [[ml.Group.turn, ml.Extension.flywheel], [ml.Group.release, ml.Extension._], 1],
        //
        [[ml.Group.install, ml.Extension.onThePlane], [ml.Group.install, ml.Extension.onThePlane], 0.6],
        [[ml.Group.install, ml.Extension.onThePlane], [ml.Group.release, ml.Extension._], 1],
        //
        [[ml.Group.install, ml.Extension.onTheShaftOrInTheHole], [ml.Group.install, ml.Extension.onTheShaftOrInTheHole], 0.5],
        [[ml.Group.install, ml.Extension.onTheShaftOrInTheHole], [ml.Group.release, ml.Extension._], 1],
        //
        [[ml.Group.disconnect, ml.Extension._], [ml.Group.disconnect, ml.Extension._], 0.5],
        [[ml.Group.disconnect, ml.Extension._], [ml.Group.release, ml.Extension._], 1],
        //
        [[ml.Group.take, ml.Extension._], [ml.Group.release, ml.Extension._], 0.6],
        //
        [[ml.Group.take, ml.Extension.take], [ml.Group.release, ml.Extension._], 0.6],
        //
        // [[ml.Group.reachByHand, ml.Extension._], [ml.Group.reachByHand, ml.Extension._], 0.7],
        // [[ml.Group.reachByHand, ml.Extension._], [ml.Group.reachByHand, ml.Extension._], 0.7],
        // [[ml.Group.reachByHand, ml.Extension._], [ml.Group., ml.Extension.], 0],
        // [[ml.Group., ml.Extension.], [ml.Group., ml.Extension.], 0],
        // [[ml.Group., ml.Extension.], [ml.Group., ml.Extension.], 0],
        // [[ml.Group., ml.Extension.], [ml.Group., ml.Extension.], 0],
        // [[ml.Group., ml.Extension.], [ml.Group., ml.Extension.], 0],
        // [[ml.Group., ml.Extension.], [ml.Group., ml.Extension.], 0],
        // [[ml.Group., ml.Extension.], [ml.Group., ml.Extension.], 0],
        // [[ml.Group., ml.Extension.], [ml.Group., ml.Extension.], 0],
    ];
})(comb || (exports.comb = comb = {}));
//#region Intensity
/**
 *
 * THE ACTION IS STRICTLY CONTINUOUS
 *
 * Usually the action is repeated every beat, according to a certain duration.
 * Those. action lasting 2 minutes is repeated every two minutes
 *
 * then that's how it is if (quantity == 1 )
 * if the quantity is greater than 1, then the action is repeated every N time
 * for example if (quantity == 3 ) then actions on 1 and 2 are skipped
 *
 * time...
 *
 *
 * */
var ins;
(function (ins) {
    /** base for mli and union */
    let base;
    (function (base) {
        let IntensityGroup;
        (function (IntensityGroup) {
            IntensityGroup[IntensityGroup["quantity"] = 0] = "quantity";
            IntensityGroup[IntensityGroup["time"] = 1] = "time";
            IntensityGroup[IntensityGroup["percent"] = 2] = "percent";
        })(IntensityGroup = base.IntensityGroup || (base.IntensityGroup = {}));
        /** relation beetwen intensity and units and default values */
        const IntensityUnit = [
            [IntensityGroup.quantity, nf.Unit.pcs],
            [IntensityGroup.time, nf.Unit.s],
            [IntensityGroup.percent, nf.Unit.percent]
        ];
        const IntensityDefault = [
            [IntensityGroup.quantity, 1],
            [IntensityGroup.time, 1]
        ];
        const getUbyInt = (i) => {
            return IntensityUnit.filter(el => el[0] == i)[0][1];
        };
        const getDefVal = (i) => {
            let r = IntensityDefault.filter(el => el[0] == i)[0][1];
            return IntensityDefault.filter(el => el[0] == i)[0][1];
        };
        /**
         * create intensity
         * @param t Group - quantity | time
         * @param v value
         * @param u unit
         * @returns intensity
         */
        base.create = (t, v) => {
            t ? t : t = IntensityGroup.quantity;
            let _un = nf.createUnit(getUbyInt(t));
            return ({
                coreType: CoreTypes.intensity,
                t: t,
                v: v ? v : v = getDefVal(t),
                u: _un
            });
        };
        base.changeGroup = (i, t) => {
            (0, mobx_1.runInAction)(() => {
                i.t = t;
            });
            i.u = nf.createUnit(getUbyInt(t));
            return i;
        };
    })(base = ins.base || (ins.base = {}));
})(ins || (exports.ins = ins = {}));
//#endregion
//#region NOT IN TIME ACTION
/** not in time action */
var nita;
(function (nita_1) {
    /**
     * stop - error as a fact
     * burn - not pass to the next level
     * pass - imideatly send to next
     * stack - store until can be free
     */
    let NotInTimeAction;
    (function (NotInTimeAction) {
        NotInTimeAction[NotInTimeAction["stop"] = 0] = "stop";
        NotInTimeAction[NotInTimeAction["burn"] = 1] = "burn";
        NotInTimeAction[NotInTimeAction["pass"] = 2] = "pass";
        NotInTimeAction[NotInTimeAction["stack"] = 3] = "stack";
    })(NotInTimeAction = nita_1.NotInTimeAction || (nita_1.NotInTimeAction = {}));
    nita_1.nitaDefValue = NotInTimeAction.stop;
    /**
     * what to do if the work cannot be released
     * @param nita typed action
     */
    nita_1.create = (nita = nita_1.nitaDefValue) => {
        return {
            id: cm.ID(),
            coretype: CoreTypes.notInTimeAction,
            nita: nita
        };
    };
})(nita || (exports.nita = nita = {}));
//#endregion
//#region Union 
/**
 * union of mli
 */
var un;
(function (un) {
    // change intensity
    un.changeIntensity = (u, i) => {
        (0, mobx_1.runInAction)(() => {
            u.core.intensity = i;
        });
        ins.base.changeGroup(u.core.intensity, i.t);
        return u;
    };
    /**
     * simple Union of basic microelements
     * @param mls at least 2 mli
     * @param comm comment
     */
    un.create = (mls, i, n, comm, multiplier) => {
        const mr = mls;
        return {
            core: {
                coreType: CoreTypes.union,
                id: cm.ID(),
                comment: comm ? comm : '',
                mliset: mr,
                multiplier: multiplier ? multiplier : 1,
                intensity: i ? ins.base.create(i) : ins.base.create(),
                nita: n ? nita.create(n) : nita.create()
            }
        };
    };
    /** adds microelement to union */
    un.addItem = (s, i) => {
        if (!s.core.mliset.find((el) => el.core.id == i.core.id))
            s.core.mliset.push(i);
        return s;
    };
    /** remove microelement from union, by object or id  */
    un.removeItem = (s, i) => {
        let d = -1;
        if (typeof i == 'object') {
            d = s.core.mliset.indexOf(i);
        }
        if (typeof i == 'string') {
            d = s.core.mliset.findIndex((el) => el.core.id == i);
        }
        if (d != -1)
            s.core.mliset.splice(d, 1);
        return s;
    };
    un.getFullResult = (u, s = false) => {
        return {
            full: s ? un.getResult(u, time.TechTimes.full).value * 0.06 : un.getResult(u, time.TechTimes.full).value,
            humn: s ? un.getResult(u, time.TechTimes.humn).value * 0.06 : un.getResult(u, time.TechTimes.humn).value,
            mach: s ? un.getResult(u, time.TechTimes.mach).value * 0.06 : un.getResult(u, time.TechTimes.mach).value,
            part: s ? un.getResult(u, time.TechTimes.part).value * 0.06 : un.getResult(u, time.TechTimes.part).value
        };
    };
    /**
     * timed result for union
     */
    un.getResult = (u, tt = time.TechTimes.full) => {
        switch (tt) {
            case time.TechTimes.full:
                return {
                    timeType: tt,
                    value: u.core.mliset
                        .map((el) => { return ml.getResult(el); })
                        .reduce((a, b) => a + b) * u.core.multiplier
                };
            case time.TechTimes.humn:
                return {
                    timeType: tt,
                    value: u.core.mliset
                        .map((el) => {
                        if (el.core.group != ml.Group.machineTime) {
                            return ml.getResult(el);
                        }
                        else {
                            return 0;
                        }
                    })
                        .reduce((a, b) => a + b) * u.core.multiplier
                };
            case time.TechTimes.mach:
                return {
                    timeType: tt,
                    value: u.core.mliset
                        .map((el) => {
                        if (el.core.group == ml.Group.machineTime) {
                            return ml.getResult(el);
                        }
                        else {
                            return 0;
                        }
                    })
                        .reduce((a, b) => a + b) * u.core.multiplier
                };
            case time.TechTimes.part:
                return {
                    timeType: tt,
                    value: u.core.mliset
                        .map((el) => {
                        if (el.core.employment != ml.Employment.overlapped) {
                            return ml.getResult(el);
                        }
                        else {
                            return 0;
                        }
                    })
                        .reduce((a, b) => a + b) * u.core.multiplier
                };
            default:
                return {
                    timeType: time.TechTimes.full,
                    value: 0
                };
        }
    };
})(un || (exports.un = un = {}));
//#region Time 
var time;
(function (time) {
    let TechTimes;
    (function (TechTimes) {
        TechTimes["full"] = "full";
        TechTimes["humn"] = "humn";
        TechTimes["mach"] = "mach";
        TechTimes["part"] = "part";
    })(TechTimes = time.TechTimes || (time.TechTimes = {}));
})(time || (exports.time = time = {}));
//#endregion
//#region Step
/** step - a group of elements aimed at performing
    a certain selected part of the operation */
var st;
(function (st) {
    /** used for calculating time types */
    let StepGroup;
    (function (StepGroup) {
        StepGroup[StepGroup["main"] = 0] = "main";
        StepGroup[StepGroup["preparing"] = 1] = "preparing";
        StepGroup[StepGroup["setupAndOther"] = 2] = "setupAndOther";
    })(StepGroup = st.StepGroup || (st.StepGroup = {}));
    /** step designated purpose */
    let StepType;
    (function (StepType) {
        // ================================================================================================ main
        /** the main time describes labor costs aimed to changing the subject of labor */
        StepType[StepType["main"] = 1] = "main";
        /** auxiliary time for the preparation of the workplace, materials,
            object of labor directly necessary to enable the performance
            of actions defined by the main time */
        StepType[StepType["auxiliary"] = 2] = "auxiliary";
        // ================================================================================================ preparing
        /** receiving tasks, reporting, including transferring
            information to accounting and operational systems */
        StepType[StepType["organizational"] = 3] = "organizational";
        /** service specific to this technological operation */
        StepType[StepType["workPlaceMaintenence"] = 4] = "workPlaceMaintenence";
        /** in russian it mean - 'подготовительно-заключительное',
            to calculate the value must be divided by the volume of the party */
        StepType[StepType["preparing"] = 5] = "preparing";
        // ================================================================================================ setupAndOther
        /** full stop for readjustment and preparation for release of another product */
        StepType[StepType["setup"] = 6] = "setup";
        /** Separately allocated time for personnel hygiene in production facilities
            associated with contamination, unhealthy conditions or long working cycles */
        StepType[StepType["restAndPersonalNeeds"] = 7] = "restAndPersonalNeeds";
        /** Time-outs due to technology and organization of the production process */
        StepType[StepType["technologicalNeeds"] = 8] = "technologicalNeeds";
        /** the time spent on the maintenance of the equipment
            required exactly and only to perform a specific operation that includes this transition */
        StepType[StepType["equipmentMaintenance"] = 9] = "equipmentMaintenance";
    })(StepType = st.StepType || (st.StepType = {}));
    /** corresponding type and group */
    const sgt = [
        [1, 0],
        [2, 0],
        [3, 1],
        [4, 1],
        [5, 1],
        [6, 2],
        [7, 2],
        [8, 2],
        [9, 2],
    ];
    /** create new step instance */
    st.create = (t = 1, c = '...') => {
        const found = sgt.find((el) => el[0] == t);
        if (!found) {
            throw new Error(`Element with type ${t} not found in sgt`);
        }
        return {
            core: {
                id: cm.ID(),
                coreType: CoreTypes.step,
                type: t,
                group: found[1],
                content: c,
                items: [],
                multiplier: 1,
                comment: '',
            }
        };
    };
    /** adds microelement to step */
    st.addItem = (s, i) => {
        if (!s.core.items.find((el) => el.core.id == i.core.id))
            s.core.items.push(i);
        return s;
    };
    /** remove microelement from step, by object or id  */
    st.removeItem = (s, i) => {
        let d = -1;
        if (typeof i == 'string') {
            d = s.core.items.findIndex((el) => el.core.id == i);
        }
        if (d != -1)
            s.core.items.splice(d, 1);
        return s;
    };
    /** set type and group for step */
    st.setType = (s, t) => {
        s.core.type = t;
        const found = sgt.find((el) => el[0] == t);
        if (!found) {
            throw new Error(`Element with type ${t} not found in sgt`);
        }
        s.core.group = found[1];
        return s;
    };
    /** get step times, optional 'tt' get specific type */
    // export const getStepTimes = (s: st.sti, tt?: sttt): stti => {
    //     if (tt) {
    //         return [_gsp(s, tt)]
    //     }
    //     let r: stti = []
    //     r.push(_gsp(s, 'full'))
    //     r.push(_gsp(s, 'humn'))
    //     r.push(_gsp(s, 'mach'))
    //     r.push(_gsp(s, 'part'))
    //     return r
    // }
    // get specified time
    // const _gsp = (s: st.sti, tt: sttt): sttpi => {
    // switch (tt) {
    //     // simple summ all microelement durations
    //     case 'full':
    //         let _mtel = s.core.items.map((el) => ml.getResult(el))
    //         return {
    //             pt: 'full',
    //             v:
    //                 _mtel.length > 0
    //                     ? _mtel.reduce((a, b) => a + b) * s.core.multiplier
    //                     : 0,
    //         }
    //     // simple summ all microelement durations expect 'ml.Group.machineTime'
    //     case 'humn':
    //         let _mtel1 = s.core.items.filter(
    //             (el) => el.core.group != ml.Group.machineTime
    //         )
    //         return {
    //             pt: 'humn',
    //             v:
    //                 _mtel1.length > 0
    //                     ? _mtel1
    //                         .map((el) => ml.getResult(el))
    //                         .reduce((a, b) => a + b)
    //                     : 0,
    //         }
    //     // simple summ all microelement durations expect 'ml.Group.machineTime'
    //     case 'mach':
    //         let _mtel2 = s.core.items.filter(
    //             (el) => el.core.group == ml.Group.machineTime
    //         )
    //         return {
    //             pt: 'mach',
    //             v:
    //                 _mtel2.length > 0
    //                     ? _mtel2
    //                         .map((el) => ml.getResult(el))
    //                         .reduce((a, b) => a + b)
    //                     : 0,
    //         }
    //     // valid, calendar duration, sum all duration except for
    //     // the elements that have a sign of 'Emploiment' == overlapped
    //     case 'part':
    //         let _mtel3 = s.core.items.filter(
    //             (el) => el.core.employment != ml.Employment.overlapped
    //         )
    //         return {
    //             pt: 'part',
    //             v:
    //                 _mtel3.length > 0
    //                     ? _mtel3
    //                         .map((el) => ml.getResult(el))
    //                         .reduce((a, b) => a + b)
    //                     : 0,
    //         }
    //     default:
    //         return { pt: 'full', v: 27111981 }
    // }
    // }
})(st || (exports.st = st = {}));
//#endregion
//#region  DEMO
const mlt = () => {
    const m = ml.create(ml.Group.reachByHand, ml.Extension._);
    const m1 = ml.create(ml.Group.machineTime, ml.Extension._);
    m1.core.multiplier = 3;
    m1.core.employment = ml.Employment.overlapped;
    const m3 = ml.create(ml.Group.disconnect, ml.Extension._);
    m3.core.multiplier = 5;
    const s = st.create(1, 'Bring some water');
    st.addItem(s, m);
    st.addItem(s, m1);
    st.addItem(s, m3);
    return m;
};
exports.mlt = mlt;
