"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.trstr = exports.toTrimPos = exports.setObserv = exports._fetch = exports.checkPassword = exports.checkEmail = exports.getHash = void 0;
exports.executeFunctions = executeFunctions;
exports.parseTOCJSON = parseTOCJSON;
exports.flattenTOC = flattenTOC;
exports.setCookie = setCookie;
exports.getCookie = getCookie;
/**
 * Generates a hash of the input string using specified algorithm
 * @param _s - Input string to hash
 * @param _alg - Hash algorithm to use (defaults to SHA-256)
 * @throws Error if invalid algorithm specified or hashing fails
 * @returns Promise<string> - Hexadecimal hash string
 */
const getHash = (_s_1, ...args_1) => __awaiter(void 0, [_s_1, ...args_1], void 0, function* (_s, _alg = 'SHA-256') {
    // Input validation
    if (!_s) {
        throw new Error('Input string cannot be empty');
    }
    const supportedAlgorithms = ['SHA-1', 'SHA-256', 'SHA-384', 'SHA-512'];
    if (!supportedAlgorithms.includes(_alg)) {
        throw new Error(`Unsupported algorithm: ${_alg}. Use one of: ${supportedAlgorithms.join(', ')}`);
    }
    try {
        const utf8 = new TextEncoder().encode(_s);
        const hashBuffer = yield crypto.subtle.digest(_alg, utf8);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hashHex;
    }
    catch (error) {
        throw new Error(`Failed to generate hash: ${error.message}`);
    }
});
exports.getHash = getHash;
/**
 * Validates email address
 * Requirements:
 * - Maximum length 254 characters
 * - Valid email format according to RFC 5322
 * - Supports IDN (International Domain Names)
 * - Supports IP literal addresses
 * @param _email - Email address to validate
 * @returns EmailValidationResult - Validation result with potential errors
 */
const checkEmail = (_email) => {
    const errors = [];
    if (!_email) {
        errors.push('Email address cannot be empty');
        return { isValid: false, errors };
    }
    if (_email.length > 254) {
        errors.push('Email address cannot be longer than 254 characters');
        return { isValid: false, errors };
    }
    try {
        const normalizedEmail = _email.split('@').map(part => {
            try {
                return part.includes('xn--') || /[^\x00-\x7F]/.test(part)
                    ? new URL('http://' + part).hostname
                    : part;
            }
            catch (_a) {
                return part;
            }
        }).join('@');
        const emailPattern = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
        if (!emailPattern.test(normalizedEmail)) {
            errors.push('Invalid email format. Please check if email address is correct');
        }
    }
    catch (error) {
        errors.push('Error processing email address: ' + error.message);
    }
    return {
        isValid: errors.length === 0,
        errors
    };
};
exports.checkEmail = checkEmail;
/**
 * Validates password strength
 * Requirements:
 * - Minimum length 12 characters
 * - Contains at least 2 digits
 * - Contains at least 2 uppercase letters
 * - Contains at least 2 special characters
 */
const checkPassword = (_password) => {
    const errors = [];
    const validations = [
        {
            test: (pwd) => pwd.length >= 12 && pwd.length <= 128,
            message: 'Password must be between 12 and 128 characters'
        },
        {
            test: (pwd) => (pwd.match(/[0-9]/g) || []).length >= 2,
            message: 'Password must contain at least 2 digits'
        },
        {
            test: (pwd) => (pwd.match(/[A-Z]/g) || []).length >= 2,
            message: 'Password must contain at least 2 uppercase letters'
        },
        {
            test: (pwd) => (pwd.match(/[^0-9a-zA-Z]/g) || []).length >= 2,
            message: 'Password must contain at least 2 special characters'
        }
    ];
    validations.forEach(validation => {
        if (!validation.test(_password)) {
            errors.push(validation.message);
        }
    });
    return {
        isValid: errors.length === 0,
        errors
    };
};
exports.checkPassword = checkPassword;
/**
 * Fetches an HTML file from the given path and returns it as an HTMLElement.
 *
 * @param {string} p - The path to the HTML file to fetch.
 * @returns {Promise<HTMLElement>} - A promise that resolves to the fetched HTMLElement.
 */
const _fetch = (p) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        // Fetch the HTML file from the given path
        const response = yield fetch(p);
        // Check if the response is not ok (status code is not in the range 200-299)
        if (!response.ok) {
            console.error("Fetch error:", p);
            return null;
            // throw new Error("Network response was not ok");
        }
        // Parse the response text as HTML
        const data = yield response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, 'text/html');
        // Return the first child of the body element as an HTMLElement
        return doc.body.firstChild;
    }
    catch (error) {
        // Log any errors that occur during the fetch or parsing process
        console.error("Fetch error:", error);
        return null;
    }
});
exports._fetch = _fetch;
function executeFunctions(funcs) {
    funcs.forEach(func => func());
}
// set observer
const setObserv = (parent, childID, todo) => {
    const observer = new MutationObserver((mutations, observer) => {
        mutations.forEach(mutation => {
            if (mutation.type === 'childList') {
                const newElement = document.querySelector(`#${childID}`);
                if (newElement) {
                    observer.disconnect();
                    executeFunctions(todo);
                }
            }
            ;
        });
    });
    observer.observe(parent, { childList: true, subtree: true });
};
exports.setObserv = setObserv;
/**
 * Transforms a nested JSON structure into a Node's. parseTOCJSON
 */
function parseTOCJSON(json) {
    const root = { key: json.key, val: json.val, children: [] };
    const lookup = new Map();
    lookup.set(root.key, root);
    const stack = [];
    if (json.sub)
        stack.push({ parent: root, sub: json.sub });
    while (stack.length > 0) {
        const { parent, sub } = stack.pop();
        for (const node of sub) {
            const child = { key: node.key, val: node.val, children: [] };
            parent.children.push(child);
            lookup.set(child.key, child);
            if (node.sub) {
                stack.push({ parent: child, sub: node.sub });
            }
        }
    }
    return { root, lookup };
}
/**
 * Returns a flat array of TOCItem elements from the given ParsedTree.
 * @param parsedTree - Result of parseTOCJSON
 * @returns TOCItem[] - Flat array of elements
 */
function flattenTOC(parsedTree) {
    const result = [];
    function traverse(node) {
        result.push(node);
        if (node.children) {
            node.children.forEach(traverse);
        }
    }
    traverse(parsedTree.root);
    return result;
}
/**
 * Sets a cookie with the given name and value.
 * Optionally, sets an expiration in days.
 *
 * @param name - The name of the cookie.
 * @param value - The value to store in the cookie.
 * @param days - (Optional) Number of days until the cookie expires.
 */
function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${encodeURIComponent(value)}${expires}; path=/`;
}
/**
 * Retrieves the value of a cookie by its name.
 *
 * @param name - The name of the cookie.
 * @returns The cookie's value if found, otherwise null.
 */
function getCookie(name) {
    const nameEQ = `${name}=`;
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
        const trimmed = cookie.trim();
        if (trimmed.startsWith(nameEQ)) {
            return decodeURIComponent(trimmed.substring(nameEQ.length));
        }
    }
    return null;
}
/**
 * part of string
 */
var toTrimPos;
(function (toTrimPos) {
    toTrimPos[toTrimPos["start"] = 0] = "start";
    toTrimPos[toTrimPos["mid"] = 1] = "mid";
    toTrimPos[toTrimPos["end"] = 2] = "end";
})(toTrimPos || (exports.toTrimPos = toTrimPos = {}));
/**
 * trimming a string to a specific length
 * @param str string to trm
 * @param targetLen resulted lenght
 * @param trpos part of string to trim
 * @param char wildcard
 * @returns string of specified size
  //! @abstract not good at edge values
 */
const trstr = (str = crypto.randomUUID(), targetLen = 16, trpos = toTrimPos.end, char = '...') => {
    // Return unchanged if string is already within target length
    if (str.length <= targetLen)
        return str;
    const effectiveLen = targetLen - char.length;
    if (effectiveLen <= 0)
        return char.slice(0, targetLen);
    switch (trpos) {
        case toTrimPos.start: // trim from start: show ending part
            return char + str.slice(-effectiveLen);
        case toTrimPos.mid: { // trim middle: split preserved parts
            const left = Math.floor(effectiveLen / 2);
            const right = effectiveLen - left;
            return str.slice(0, left) + char + str.slice(-right);
        }
        case toTrimPos.end: // trim from end: show beginning part
        default:
            return str.slice(0, effectiveLen) + char;
    }
};
exports.trstr = trstr;
