"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tcheckbox = void 0;
const el = (v) => {
    let _el = document.createElement(v.t);
    v.cl.split(" ").forEach(c => _el.classList.add(c));
    v.id ? (_el.setAttribute('id', self.crypto.randomUUID())) : 0;
    v.txt ? _el.innerText = v.txt : 0;
    return _el;
};
/** tchVT = [text: string, noval: string, yesval: string, val: boolean] */
const tcheckbox = (attr) => {
    // container
    const _chCon = el({ t: 'div', cl: 'mpt_flipswitch', id: true });
    // label
    const _chMainLabel = el({ t: 'div', cl: 'mpt_flipswitch-MainLabel', txt: '' });
    // container for switch
    const _swCont = el({ t: 'label', cl: 'mpt_switch' });
    // nput with inner parts and switch 
    const _chCh = el({ t: 'input', cl: 'mpt_tswInput' });
    _chCh.setAttribute('type', 'checkbox');
    // _chCh.checked = true
    const _chSw = el({ t: 'span', cl: 'slider round' });
    _swCont.appendChild(_chCh);
    _swCont.appendChild(_chSw);
    _chCon.appendChild(_chMainLabel);
    _chCon.appendChild(_swCont);
    if (attr[3]) {
        _chCh.setAttribute('checked', '');
        _chCh.checked = true;
    }
    else {
        _chCh.removeAttribute('checked');
        _chCh.checked = false;
    }
    if (_chCh.checked) {
        _chMainLabel.innerText = '' + attr[2];
    }
    else {
        _chMainLabel.innerText = '' + attr[1];
    }
    _chCh.onchange = () => {
        if (_chCh.checked) {
            _chMainLabel.innerText = '' + attr[2];
        }
        else {
            _chMainLabel.innerText = '' + attr[1];
        }
    };
    return { view: _chCon, switch: _chCh };
};
exports.tcheckbox = tcheckbox;
