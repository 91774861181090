"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.store = void 0;
const mobx_1 = require("mobx");
const microelement_1 = require("./microelement");
const core_1 = require("../../core/core");
const ml_prosp_panel_1 = require("../mpts_panel/ml_prosp_panel");
const m_comment_1 = require("../../core/ui/for_ml/m_comment");
const mark_1 = require("../../core/ui/mark");
const m_comb_1 = require("../../core/ui/for_ml/m_comb");
const union_1 = require("./union");
const step_1 = require("./step");
const link_1 = require("./link");
const m_alias_1 = require("../../core/ui/for_ml/m_alias");
const input_1 = require("./input");
exports.store = (0, mobx_1.observable)({
    all: {
        items: [],
        selected: [],
        addSelected: (0, mobx_1.action)((item) => {
            exports.store.all.resetSelection();
            exports.store.all.selected.push(item);
            item.pres.selected = true;
        }),
        resetSelection: (0, mobx_1.action)(() => {
            exports.store.all.selected.forEach((i) => {
                i.pres.selected = false;
            });
            exports.store.all.selected = [];
            // store.ml.resetSelection()
            microelement_1.microelement.selectMicroelement(undefined);
            // 
            exports.store.union.resetSelection();
            exports.store.output.resetSelection();
            exports.store.link.resetSelection();
            exports.store.step.resetSelection();
            exports.store.input.resetSelection();
            ml_prosp_panel_1.ml_props_panel.create('mpts-cont', { rst: true });
        }),
        add: (0, mobx_1.action)((item) => {
            console.log('totaly added');
            link_1.link.linkHandler(item);
            exports.store.all.items.push(item);
        }),
        remove: (0, mobx_1.action)((item) => {
            const _i = exports.store.all.items.findIndex(i => i.core.id === item.core.id);
            if (_i === -1)
                return;
            exports.store.all.items.splice(_i, 1);
        }),
        getItem: (id) => {
            return exports.store.all.items.find(i => i.core.id === id);
        },
    },
    output: {
        items: [],
        selected: [],
        addSelected: (0, mobx_1.action)((item) => {
            exports.store.output.resetSelection();
            exports.store.output.selected.push(item);
            item.pres.selected = true;
        }),
        resetSelection: (0, mobx_1.action)(() => {
            exports.store.output.selected.forEach((i) => {
                i.pres.selected = false;
            });
            exports.store.output.selected = [];
        }),
        add: (0, mobx_1.action)((item) => {
            exports.store.output.items.push(item);
            exports.store.all.add(item);
        }),
        remove: (0, mobx_1.action)((item) => {
            const _i = exports.store.output.items.findIndex(i => i.core.id === item.core.id);
            if (_i === -1)
                return;
            exports.store.output.items.splice(_i, 1);
        }),
        getItem: (id) => {
            return exports.store.output.items.find(i => i.core.id === id);
        }
    },
    input: {
        items: [],
        selected: [],
        addSelected: (0, mobx_1.action)((item) => {
            exports.store.input.resetSelection();
            exports.store.input.selected.push(item);
            item.pres.selected = true;
            ml_prosp_panel_1.ml_props_panel.create('mpts-cont', { inp: exports.store.input.selected[0] });
        }),
        resetSelection: (0, mobx_1.action)(() => {
            exports.store.input.selected.forEach((i) => {
                input_1.input.setSelectViewForInput(i, false);
                i.pres.selected = false;
            });
            exports.store.input.selected = [];
        }),
        add: (0, mobx_1.action)((item) => {
            exports.store.input.items.push(item);
            exports.store.all.add(item);
        }),
        remove: (0, mobx_1.action)((item) => {
            const _i = exports.store.input.items.findIndex(i => i.core.id === item.core.id);
            if (_i === -1)
                return;
            exports.store.input.items.splice(_i, 1);
        }),
        getItem: (id) => {
            return exports.store.input.items.find(i => i.core.id === id);
        }
    },
    ml: {
        items: [],
        selected: [],
        addSelected: (0, mobx_1.action)((item) => {
            exports.store.ml.resetSelection();
            exports.store.ml.selected.push(item);
            item.pres.selected = true;
            ml_prosp_panel_1.ml_props_panel.create('mpts-cont', { m: exports.store.ml.selected[0] });
        }),
        refreshSelected: (0, mobx_1.action)(() => {
            ml_prosp_panel_1.ml_props_panel.create('mpts-cont', { m: exports.store.ml.selected[0] });
        }),
        resetSelection: (0, mobx_1.action)(() => {
            exports.store.ml.selected.forEach((m) => {
                m.pres.selected = false;
            });
            exports.store.ml.selected = [];
        }),
        removeSelected: (0, mobx_1.action)((item) => {
            const _i = exports.store.ml.selected.findIndex(i => i.core.id === item.core.id);
            if (_i === -1)
                return;
            item.pres.selected = false;
            exports.store.ml.selected.splice(_i, 1);
        }),
        add: (0, mobx_1.action)((item) => {
            exports.store.ml.items.push(item);
            exports.store.all.add(item);
        }),
        remove: (0, mobx_1.action)((item) => {
            const remove = (item) => {
                item.core.combined.items.forEach((i) => {
                    console.log('combined should be removed');
                });
            };
            remove(item);
            const _i = exports.store.ml.items.findIndex(i => i.core.id === item.core.id);
            if (_i === -1)
                return;
            exports.store.ml.items.splice(_i, 1);
            exports.store.ml.removeSelected(item);
        }),
        getItem: (id) => {
            return exports.store.ml.items.find(i => i.core.id === id);
        },
        refreshItems: (0, mobx_1.action)(() => {
            exports.store.ml.items.forEach((m) => {
                (0, mark_1.marksGroup)(m);
                (0, m_comment_1.showComment)(m);
                exports.store.ml.removeSelected(m);
            });
        })
    },
    union: {
        items: [],
        selected: [],
        add: (0, mobx_1.action)((item) => {
            exports.store.union.items.push(item);
            exports.store.all.add(item);
        }),
        addSelected: (0, mobx_1.action)((item) => {
            exports.store.union.resetSelection();
            exports.store.union.selected.push(item);
            item.pres.selected = true;
        }),
        resetSelection: (0, mobx_1.action)(() => {
            exports.store.union.selected.forEach((u) => {
                u.pres.selected = false;
            });
            exports.store.union.selected = [];
        }),
        remove: (0, mobx_1.action)((item) => {
            const _i = exports.store.union.items.findIndex(i => i.core.id === item.core.id);
            if (_i === -1)
                return;
            exports.store.union.items.splice(_i, 1);
        }),
        getItem: (id) => {
            return exports.store.union.items.find(i => i.core.id === id);
        },
        addItemToUnion: (0, mobx_1.action)((u, m, i) => {
            if (i !== -1) {
                u.core.mliset.splice(i, 0, m);
            }
            else {
                u.core.mliset.push(m);
            }
        }),
    },
    step: {
        items: [],
        selected: [],
        add: (0, mobx_1.action)((item) => {
            exports.store.step.items.push(item);
            exports.store.all.add(item);
        }),
        remove: (0, mobx_1.action)((item) => {
            const _i = exports.store.step.items.findIndex(i => i.core.id === item.core.id);
            if (_i === -1)
                return;
            exports.store.step.items.splice(_i, 1);
        }),
        getItem: (id) => {
            return exports.store.step.items.find(i => i.core.id === id);
        },
        addItemToStep: (0, mobx_1.action)((s, m, i) => {
            if (i !== -1) {
                s.core.items.splice(i, 0, m);
            }
            else {
                s.core.items.push(m);
            }
        }),
        addSelected: (0, mobx_1.action)((item) => {
            exports.store.step.resetSelection();
            exports.store.step.selected.push(item);
            item.pres.selected = true;
        }),
        resetSelection: (0, mobx_1.action)(() => {
            exports.store.step.selected.forEach((s) => {
                s.pres.selected = false;
            });
            exports.store.step.selected = [];
        }),
    },
    link: {
        items: [],
        selected: [],
        add: (0, mobx_1.action)((item) => {
            exports.store.link.items.push(item);
        }),
        remove: (0, mobx_1.action)((item) => {
            const _i = exports.store.link.items.findIndex(i => i.core.id === item.core.id);
            if (_i === -1)
                return;
            exports.store.link.items.splice(_i, 1);
        }),
        getItem: (id) => {
            return exports.store.link.items.find(i => i.core.id === id);
        },
        addSelected: (0, mobx_1.action)((item) => {
            exports.store.link.resetSelection();
            exports.store.link.selected.push(item);
            item.pres.selected = true;
        }),
        resetSelection: (0, mobx_1.action)(() => {
            exports.store.link.selected.forEach((l) => {
                l.pres.selected = false;
            });
            exports.store.link.selected = [];
        }),
    }
});
// count all items
(0, mobx_1.autorun)(() => {
    if (exports.store.ml.items.length === 0) {
        document.getElementById('mpts-counter').innerText = '0';
        return;
    }
    document.getElementById('mpts-counter').innerText = exports.store.ml.items.length.toString() + ' ' + Date.now().toString();
});
// handle changes in selected items
(0, mobx_1.autorun)(() => {
    if (exports.store.ml.selected.length === 0) {
        document.getElementById('mpts-counter-value').innerText = '0';
        return;
    }
    let _r = core_1.ml.getResult(exports.store.ml.selected[0]);
    document.getElementById('mpts-counter-value').innerText = (_r * 0.06).toFixed(2).toString() + ' / ' + _r.toFixed(2).toString();
});
// comment, marks
(0, mobx_1.autorun)(() => {
    var _a;
    if (exports.store.ml.selected.length === 0)
        return;
    (0, mark_1.marksGroup)(exports.store.ml.selected[0]);
    (0, m_comment_1.showComment)(exports.store.ml.selected[0]);
    (0, m_comb_1.setCombinedView)(exports.store.ml.selected[0]);
    (0, microelement_1.shakeJail)(exports.store.ml.selected[0]);
    // if element locked in Combine, - update Anchor rect and connected lines
    if (((_a = exports.store.ml.selected[0]) === null || _a === void 0 ? void 0 : _a.pres.captured.state) === 'locked') {
        // updateJail(store.ml.selected[0]?.pres?.captured.invaider, store.ml.selected[0])
    }
});
// =========================================================================================== ! GOOD
// read microelement properties, use only if  store.ml.selected[0] exists
const readMlprops = () => {
    const selectedItem = exports.store.ml.selected[0];
    Object.entries(selectedItem.pres.combined).forEach(([key, value]) => {
        `${key}: ${value}`;
    });
    Object.entries(selectedItem.pres.comment).forEach(([key, value]) => {
        `${key}: ${value}`;
    });
    Object.entries(selectedItem.pres.aliasName).forEach(([key, value]) => {
        `${key}: ${value}`;
    });
    Object.entries(selectedItem.core).forEach(([key, value]) => {
        `${key}: ${value}`;
    });
};
// update links on element move
(0, mobx_1.reaction)(() => exports.store.all.items.map((i) => i.pres.xy), (newValues, prevValues) => {
    newValues.forEach((newValue, index) => {
        if (prevValues && newValue !== prevValues[index]) {
            link_1.link.updateLinks(exports.store.all.items[index].core.id);
        }
    });
});
// try to catch union move event
(0, mobx_1.reaction)(() => exports.store.union.items.map((u) => u.pres.xy), (newValues, prevValues) => {
    newValues.forEach((newValue, index) => {
        if (prevValues && newValue !== prevValues[index]) {
            if (exports.store.union.items[index].pres.inStep.step) {
                step_1.step.updateStepField(exports.store.union.items[index].pres.inStep.step);
            }
        }
    });
});
// update on aliasname
(0, mobx_1.autorun)(() => {
    const selectedItem = exports.store.ml.selected[0];
    if (!selectedItem)
        return;
    (0, m_alias_1.m_setAliasName)(selectedItem);
    (0, mark_1.marksGroup)(selectedItem);
    if (selectedItem.pres.comment.show) {
        (0, m_comment_1.showComment)(selectedItem);
    }
    if (selectedItem.pres.combined.show) {
        (0, microelement_1.shakeJail)(selectedItem);
    }
});
// update step rect
(0, mobx_1.autorun)(() => {
    const selectedItem = exports.store.ml.selected[0];
    if (!selectedItem)
        return;
    const inStep = selectedItem.pres.inStep;
    if (!inStep.step)
        return;
    selectedItem.pres.xy;
    readMlprops();
    step_1.step.updateStepField(inStep.step);
});
// update union cover on MLs changes
(0, mobx_1.autorun)(() => {
    const selectedItem = exports.store.ml.selected[0];
    if (!selectedItem)
        return;
    const inUnion = selectedItem.pres.inUnion;
    if (!inUnion)
        return;
    // if (inUnion.union.core.mliset.length) {
    // union.refreshUnion(inUnion.union);
    // link.updateLinks(inUnion.union.core.id)
    // }
    if (inUnion.state === 'captured') {
        readMlprops();
        union_1.union.refreshUnion(inUnion.union);
        link_1.link.updateLinks(inUnion.union.core.id);
        (0, mark_1.marksGroup)(inUnion.union);
        (0, m_comment_1.showComment)(inUnion.union);
        console.log('UNION RESULT:', core_1.un.getFullResult(inUnion.union));
        union_1.union.showResult(inUnion.union);
        if (inUnion.union.pres.inStep.step) {
            step_1.step.updateStepField(inUnion.union.pres.inStep.step);
        }
    }
});
// update jail on MLs changes
(0, mobx_1.autorun)(() => {
    var _a, _b;
    const selectedItem = exports.store.ml.selected[0];
    if (!selectedItem)
        return;
    if (selectedItem.pres.locked && selectedItem.pres.captured.state === 'captured') {
        readMlprops();
        (0, microelement_1.updateJail)(selectedItem.pres.captured.invaider, selectedItem);
        (0, microelement_1.shakeJail)((_b = (_a = exports.store.ml.selected[0]) === null || _a === void 0 ? void 0 : _a.pres) === null || _b === void 0 ? void 0 : _b.captured.invaider);
    }
});
// update connection on ml changes
(0, mobx_1.autorun)(() => {
    const selectedItem = exports.store.ml.selected[0];
    if (!selectedItem)
        return;
    readMlprops();
    link_1.link.updateLinks(selectedItem.core.id);
});
// 
(0, mobx_1.autorun)(() => {
    const selectedUnion = exports.store.union.selected[0];
    if (!selectedUnion)
        return;
    selectedUnion.core.multiplier;
    selectedUnion.core.mliset.length;
    selectedUnion.core.intensity;
    (0, mark_1.marksGroup)(selectedUnion);
    (0, m_comment_1.showComment)(selectedUnion);
    union_1.union.showResult(selectedUnion);
    console.log('union changees grab by autorun');
});
