"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.union = void 0;
const core_1 = require("../../core/core");
const app_1 = require("../../scripts/app");
const func_1 = require("../../scripts/func");
const microelement_1 = require("./microelement");
const presentation_1 = require("./presentation");
const store_1 = require("./store");
const mobx_1 = require("mobx");
const u_shres_1 = require("../../core/ui/for_union/u_shres");
const ml_prosp_panel_1 = require("../mpts_panel/ml_prosp_panel");
var union;
(function (union) {
    union.create = (m) => {
        function _extend(...objs) {
            return Object.assign({}, ...objs);
        }
        // create union by adding microelements
        const _u = core_1.un.create(m);
        const T0 = (0, mobx_1.observable)(_u, {}, { deep: true });
        const _pres = presentation_1.presentation.create();
        _pres.pres.color = 'rgb(147, 183, 190)';
        const T1 = (0, mobx_1.observable)(_pres, {}, { deep: true });
        const _view = createUnionTopCover(m, _pres.pres.uiid, _pres.pres.color, _pres.pres.unionHeader);
        _view.id = _u.core.id;
        const _cd = { dragController: { allow: true } };
        const T4 = _extend(_cd, T1, { view: _view }, T0);
        app_1.app.getApp().ui.mpts.item.svgCanvas.appendChild(T4.view);
        store_1.store.union.add(T4);
        dropHandler(T4);
        pointerDownHandler(T4);
        return T4;
    };
    // behavior for pointerdown event
    const pointerDownHandler = (u) => {
        let pressTimer = null;
        let delayTimer = null;
        let startTime = null;
        const DELAY_BEFORE_HOLD = 200;
        const HOLD_DURATION = 200;
        let isPressing = false;
        let _tc = u.view.querySelector(`.baseRect.${u.pres.uiid}`);
        let _abb = _tc.getBBox();
        let _abb_transform = (0, func_1.getSvgTranslate3d)(u.view);
        let _line;
        const maxWidth = () => { return _abb.width; };
        const reset = () => {
            isPressing = false;
            startTime = null;
            if (delayTimer !== null) {
                clearTimeout(delayTimer);
                delayTimer = null;
            }
            if (pressTimer !== null) {
                clearTimeout(pressTimer);
                pressTimer = null;
            }
            clearProgressLine();
        };
        const createProgressLine = () => {
            _abb = u.view.querySelector(`.baseRect.${u.pres.uiid}`).getBBox();
            _abb_transform = (0, func_1.getSvgTranslate3d)(u.view.querySelector(`.baseRect.${u.pres.uiid}`));
            const _y = _abb.y;
            _line = (0, func_1._c_l)(u.view, _abb.x, _y, _abb.x, _y, 8, '#FFA564', [`jailed-progress`, `${u.pres.uiid}`]);
        };
        const clearProgressLine = () => {
            if (_line) {
                _line.remove();
                _line = null;
            }
        };
        const updateProgressLine = (dx) => {
            _line.setAttribute('x2', String((maxWidth() * dx) + _abb.x + _abb_transform.x));
        };
        const updateProgress = () => {
            if (!isPressing || startTime === null)
                return;
            const elapsedTime = Date.now() - startTime;
            const progress = Math.min(elapsedTime / HOLD_DURATION, 1);
            if (!_line) {
                createProgressLine();
            }
            updateProgressLine(progress);
            if (progress < 1) {
                requestAnimationFrame(updateProgress);
            }
        };
        const handlePointerDown = (e) => {
            // ! ================================================================== setSelectView
            union.selectUnion(u);
            console.log('ALARMED POINTER DOWN IN UNION');
            // !! prevent default
            // e.stopPropagation();
            isPressing = true;
            delayTimer = window.setTimeout(() => {
                startTime = Date.now();
                console.log('UNION DELAY TIMER startted');
                pressTimer = window.setTimeout(() => {
                    console.log('ALARM THEY HOLD ME, IaM UNION');
                    handleElementSeletion(e);
                    reset();
                }, HOLD_DURATION);
                requestAnimationFrame(updateProgress);
            }, DELAY_BEFORE_HOLD);
        };
        const handleElementSeletion = (e) => {
            const lastMouseX = e.clientX;
            const lastMouseY = e.clientY;
            const elements = document.elementsFromPoint(lastMouseX, lastMouseY);
            const _m = elements[1].closest('.microelement');
            if (!_m) {
                return;
            }
            // ================================================= MICROELEMENT TO FREE
            const _mToFree = store_1.store.ml.getItem(_m.id);
            if (!e.shiftKey)
                microelement_1.microelement.selectMicroelement(_mToFree);
            if (e.shiftKey) {
                console.log('FROM UNION ESCAPE');
                document.querySelectorAll('.free-line').forEach((el) => el.remove());
                let _i = 0;
                _mToFree.pres.inUnion.union.core.mliset.forEach((el, i) => {
                    if (el.core.id == _mToFree.core.id) {
                        _i = i;
                    }
                });
                (0, mobx_1.runInAction)(() => {
                    _mToFree.pres.inUnion.state = 'run';
                });
                app_1.app.getApp().ui.mpts.item.svgCanvas.appendChild(_mToFree.view);
                (0, mobx_1.runInAction)(() => {
                    core_1.un.removeItem(u, _mToFree.core.id);
                });
                const clc = [e.clientX, e.clientY];
                const cp = _mToFree.view.querySelector(`.baseRect.${_mToFree.pres.uiid}`).getBoundingClientRect();
                (0, func_1.updateTranslate3d)(_mToFree.view, clc[0] - cp.x - 16, clc[1] - cp.y - 8);
                const pointerDownEvent = new PointerEvent('pointerdown', {
                    bubbles: false,
                    cancelable: true,
                    clientX: e.clientX,
                    clientY: e.clientY,
                    pointerId: e.pointerId
                });
                _mToFree.view.dispatchEvent(pointerDownEvent);
                const pointerUpEvent = new PointerEvent('pointerup', {
                    bubbles: false,
                    cancelable: true,
                    clientX: e.clientX,
                    clientY: e.clientY,
                    pointerId: e.pointerId
                });
                u.view.dispatchEvent(pointerUpEvent);
                (0, mobx_1.runInAction)(() => {
                    _mToFree.pres.inUnion.union = null;
                    _mToFree.pres.inUnion.xy = [0, 0];
                    _mToFree.pres.inUnion.state = 'free';
                });
                // check qty of microelements in union
                if (u.core.mliset.length === 1) {
                    // move last one to free
                    const _lastId = u.core.mliset[0].core.id;
                    const _last = store_1.store.ml.getItem(_lastId);
                    app_1.app.getApp().ui.mpts.item.svgCanvas.appendChild(_last.view);
                    const clc = [e.clientX, e.clientY];
                    const cp = _last.view.querySelector(`.baseRect.${_last.pres.uiid}`).getBoundingClientRect();
                    (0, func_1.updateTranslate3d)(_last.view, clc[0] - cp.x - 16, clc[1] - cp.y - 24);
                    (0, mobx_1.runInAction)(() => {
                        core_1.un.removeItem(u, _lastId);
                        _last.pres.inUnion.union = null;
                        _last.pres.inUnion.xy = [0, 0];
                        _last.pres.inUnion.state = 'free';
                    });
                    // remove union if no microelements
                    store_1.store.union.remove(u);
                    u.view.remove();
                    return;
                }
                union.refreshUnion(u);
                return;
            }
        };
        u.view.addEventListener('pointerdown', handlePointerDown);
        u.view.addEventListener('pointerup', reset);
        u.view.addEventListener('pointercancel', reset);
        // u.view.addEventListener('dragMove', () => shakeJail(invaider))
        u.view.addEventListener('dragMove', reset);
        // handler for in union elements link update
        u.view.addEventListener('dragMove', (e) => {
            u.core.mliset.forEach((el) => {
                let _elm = store_1.store.all.getItem(el.core.id);
                (0, mobx_1.runInAction)(() => {
                    _elm.pres.xy = [e.detail.x, e.detail.y];
                });
            });
        });
        // u.view.addEventListener('dragStart', reset)
        u.view.addEventListener('dragEnd', (e) => {
            (0, mobx_1.runInAction)(() => {
                // remind position
                let _u_bb = u.view.getBBox();
                u.pres.inUnion.xy = [_u_bb.x, _u_bb.y];
            });
        });
    };
    // remid position for union
    const dropHandler = (u) => {
        // remind position
        let _u_bb = u.view.getBBox();
        u.pres.inUnion.xy = [_u_bb.x, _u_bb.y];
        u.view.addEventListener('pointerup', (e) => {
            let _u_bb = u.view.getBBox();
            u.pres.inUnion.xy = [_u_bb.x, _u_bb.y];
        });
    };
    // external function to refresh union
    union.refreshUnion = (u) => {
        const _vw = union.unionsGetViews(u);
        restoreUnionStack(u, _vw);
        updateTopCover(_vw, u.pres.uiid, u.pres.color, u.pres.unionHeader);
    };
    // ========================================================================== SELECTION
    const setSelectView = (u) => {
        // highlight .baseRect
        const _tc = u.view.querySelector(`.topCover1.baseRect.${u.pres.uiid}`);
        _tc.classList.add('selected');
        // _tc.style.strokeWidth = '2'
        _tc.style.stroke = 'rgb(0, 0, 0)';
        _tc.style.strokeDasharray = 'none';
        // move _tc to front
        _tc.parentElement.insertBefore(_tc, null);
    };
    const removeSelectedView = (u) => {
        // remove highlight .baseRect
        const _tc = u.view.querySelector(`.topCover1.baseRect.${u.pres.uiid}`);
        _tc.classList.remove('selected');
        _tc.style.stroke = 'rgb(172, 172, 172)';
        // _tc.style.strokeWidth = '1'
        _tc.style.strokeDasharray = '2, 2';
        // move _tc to back
        // _tc.parentElement.insertBefore(_tc, _tc.parentElement.lastElementChild)
        // move side to front
        u.view.querySelector(`.sideHighlight.${u.pres.uiid}`).parentElement.insertBefore(u.view.querySelector(`.sideHighlight.${u.pres.uiid}`), null);
    };
    union.selectUnion = (u, p = true) => {
        store_1.store.union.items.forEach(el => {
            removeSelectedView(el);
        });
        setSelectView(u);
        store_1.store.union.addSelected(u);
        if (!p)
            return;
        ml_prosp_panel_1.ml_props_panel.create('mpts-cont', { u: store_1.store.union.selected[0] });
    };
    // ========================================================================== SELECTION
    // insert on first position
    let ff = 0;
    union.addItem = (u, m, i) => {
        u.view.appendChild(m.view);
        if (i !== -1) {
            store_1.store.union.addItemToUnion(u, m, i + 1);
        }
        else {
            console.log('ALARMED INSERT ON -1');
            ff = 1;
            store_1.store.union.addItemToUnion(u, m);
            return;
        }
        ff = 0;
    };
    // get views for union
    union.unionsGetViews = (u) => {
        const _r = [];
        u.core.mliset.forEach(m => _r.push(store_1.store.ml.getItem(m.core.id)));
        return _r;
    };
    const getTopCover = (mls, prid, _c, hv) => {
        // remove all unionRect
        const gg = document.querySelector(`.unionRect.${prid}`);
        document.querySelectorAll(`.topCover1.baseRect.${prid}`).forEach(el => el.remove());
        document.querySelectorAll(`.sideHighlight.${prid}`).forEach(el => el.remove());
        document.querySelectorAll(`.clipPath.${prid}`).forEach(el => el.remove());
        document.querySelectorAll(`.headerGroup.${prid}`).forEach(el => el.remove());
        // create union, group of microelements
        let unionGroup;
        if (!gg) {
            unionGroup = document.createElementNS(func_1.NS, 'g');
            unionGroup.classList.add('unionRect');
            // unionGroup.classList.add('topCover1');
            unionGroup.classList.add(prid);
            unionGroup.setAttribute('pointer-events', 'all');
        }
        else {
            unionGroup = gg;
        }
        restoreUnionStack(null, mls);
        const _bb = getBBForMlList(mls, hv);
        const topCover = document.createElementNS(func_1.NS, 'rect');
        topCover.classList.add('baseRect');
        topCover.classList.add('topCover1');
        topCover.classList.add(prid);
        topCover.setAttribute('fill', 'rgba(255, 255, 255, 0.3)');
        topCover.setAttribute('stroke', 'rgb(172, 172, 172)');
        topCover.setAttribute('stroke-width', '1');
        topCover.setAttribute('stroke-dasharray', '2, 2');
        topCover.setAttribute('x', `${_bb.x - 8}`);
        topCover.setAttribute('y', `${_bb.y}`);
        topCover.setAttribute('width', `${_bb.width + 8}`);
        topCover.setAttribute('height', `${_bb.height}`);
        topCover.setAttribute('rx', '4');
        //========================================================================================== SIDE HIGH LIGHT
        const _side = (0, func_1.csvg)('rect', {
            x: _bb.x - 8,
            y: _bb.y,
            width: 15,
            height: _bb.height,
            fill: _c,
            stroke: _c,
            'stroke-width': '1',
            rx: 4,
        }, ['sideHighlight', `${prid}`]);
        // ========================================================================================== HEADER
        mls.forEach(el => { unionGroup.appendChild(el.view); });
        unionGroup.appendChild(topCover);
        unionGroup.appendChild(_side);
        return unionGroup;
    };
    const updateTopCover = (mls, prid, _c, hv) => {
        getTopCover(mls, prid, _c, hv);
    };
    // create top cover for union
    const createUnionTopCover = (mls, prid, _c, hv) => {
        const _tc = getTopCover(mls, prid, _c, hv);
        (0, func_1.enableDrag)(_tc);
        return _tc;
    };
    // get bb for microelement with translate -> .baseRect.${el.pres.uiid}
    const getElBB = (el, cl = 'baseRect') => {
        const _bb = el.view.querySelector(`.${cl}.${el.pres.uiid}`).getBBox();
        const _bbt = (0, func_1.getSvgTranslate3d)(el.view);
        _bb.x += _bbt.x;
        _bb.y += _bbt.y;
        return _bb;
    };
    // find top left item and move all items to this X and Y = i * 16
    const restoreUnionStack = (u, mls) => {
        console.log('union stack restored');
        if (ff === 1 && u) {
            let ff_b = getElBB(mls[1]);
            let ff_a = getElBB(mls[0]);
            let dx = ff_b.x - ff_a.x;
            let dy = ff_b.y - ff_a.y;
            (0, func_1.updateTranslate3d)(mls[0].view, dx, dy);
            ff = 0;
        }
        const _bb = getElBB(mls[0]);
        for (let index = 1; index < mls.length; index++) {
            const el = mls[index];
            const _bb1 = getElBB(el);
            const dx = _bb.x - _bb1.x;
            const dy = _bb.y - _bb1.y + index * 16;
            (0, func_1.updateTranslate3d)(el.view, dx, dy);
        }
    };
    // Calculates the bounding box for a list of Microelements.
    const getBBForMlList = (mls, hv) => {
        let bb = { x: null, y: null, width: null, width1: null, height: null };
        mls.forEach(m => {
            const _bb = getElBB(m);
            bb.x === null ? bb.x = _bb.x : _bb.x < bb.x ? bb.x = _bb.x : null;
            bb.y === null ? bb.y = _bb.y : _bb.y < bb.y ? bb.y = _bb.y : null;
            bb.width === null ? bb.width = _bb.width : _bb.width > bb.width ? bb.width = _bb.width : null;
        });
        bb.height = mls.length * 16;
        if (hv !== '') {
            const _ht = (0, func_1.countStringWidth)(hv);
            bb.width1 = bb.width > _ht ? bb.width : _ht;
        }
        return bb;
    };
    union.showResult = (u) => {
        (0, u_shres_1.showUnionResult)(u);
    };
})(union || (exports.union = union = {}));
