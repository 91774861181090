"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shakeJail = exports.microelement = exports.updateJail = void 0;
const mobx_1 = require("mobx");
const core_1 = require("../../core/core");
const tr_1 = require("../../core/tr");
const app_1 = require("../../scripts/app");
const func_1 = require("../../scripts/func");
const presentation_1 = require("./presentation");
const store_1 = require("./store");
const ml_prosp_panel_1 = require("../mpts_panel/ml_prosp_panel");
const mark_1 = require("../../core/ui/mark");
const m_comb_1 = require("../../core/ui/for_ml/m_comb");
const union_1 = require("./union");
const updateJail = (invaider, prisoner) => {
    const _coreJail = invaider.core.combined;
    const j = invaider.view.querySelector(`.jailed-items.${invaider.pres.uiid}`);
    if (!j)
        return;
    // anchore rect for jailed items
    const _anchor_rect = Array.from(j.children)
        .find(el => el.classList.contains('mpts_jail_anchor_rect') && el.classList.contains(invaider.pres.uiid));
    const _anchor_rect_b = _anchor_rect.getBBox();
    // add prisoner to jail
    if (prisoner) {
        j.insertBefore(prisoner.view, j.lastElementChild);
        // get invaider.view .baseRect
        const _ibb = prisoner.view.querySelector(`.microelement > .baseRect.${prisoner.pres.uiid}`);
        const _ibbBBox = _ibb.getBBox();
        const _ibbTransform = (0, func_1.getSvgTranslate3d)(prisoner.view);
        const dx = _anchor_rect_b.x - _ibbBBox.x - _ibbTransform.x;
        const dy = _anchor_rect_b.y - _ibbBBox.y - _ibbTransform.y;
        if (!(dx == 0 && dy == 0)) {
            (0, func_1.updateTranslate3d)(prisoner.view, dx, dy);
            (0, func_1.updateTranslate3d)(prisoner.view, 0, _coreJail.items.findIndex(el => el.core.id === prisoner.core.id) * 16);
        }
    }
    // update anchor rect
    const _nw = j.querySelectorAll('.microelement > .baseRect');
    const _nwFL = Array.from(invaider.core.combined.items).map((el) => el.core.id);
    const _nwF = Array.from(_nw).filter(el => _nwFL.includes(el.parentElement.id));
    // const _nwVal = Array.from(_nw).map((el) => el.getBBox().width)
    const _nwVal = Array.from(_nwF).map((el) => el.getBBox().width);
    const _maxWidth = Math.max(..._nwVal);
    _anchor_rect.style.width = `${_maxWidth}px`;
    _anchor_rect.style.height = `${invaider.core.combined.items.length * 16}px`;
    // move _anchor_rect to front
    j.appendChild(_anchor_rect);
};
exports.updateJail = updateJail;
// #region microelement
var microelement;
(function (microelement) {
    const microelementKeys = ['view', 'dragController', 'core', 'pres'];
    function isMicroelement(arg) {
        return microelementKeys.every(key => key in arg);
    }
    microelement.isMicroelement = isMicroelement;
    // #region createView
    /** Microelement visual representation */
    microelement.createView = (name, x, y) => {
        //? translate the name
        let nrep = (0, tr_1.tr_ml)(app_1.app.getApp().lang, name);
        // calculate text length
        const textWidth = (0, func_1.countStringWidth)(nrep.group[1]);
        //! new in 2025
        //! create group for rectangles 
        //! such as Main Microelement rect and Marks rect 
        const rectGroup = document.createElementNS(func_1.NS, 'g');
        rectGroup.classList.add('baseRect');
        // create rectangle
        const rect = document.createElementNS(func_1.NS, 'rect');
        rect.classList.add('mainRect');
        rect.setAttribute('x', x.toString());
        rect.setAttribute('y', y.toString());
        rect.setAttribute('width', textWidth.toString());
        rect.setAttribute('height', '16');
        rect.setAttribute('rx', '4');
        rect.style.fill = 'rgb(255, 255, 255)';
        rect.style.stroke = 'rgb(172, 172, 172)';
        // create text
        const textEl = document.createElementNS(func_1.NS, 'text');
        textEl.classList.add('baseText');
        // move text to the right and down
        textEl.setAttribute('x', (x + 4).toString());
        textEl.setAttribute('y', (y + 12).toString());
        textEl.style.fill = 'black';
        textEl.textContent = nrep.group[1];
        // resulted group
        const group = document.createElementNS(func_1.NS, 'g');
        group.classList.add('microelement');
        group.tabIndex = 0;
        // set id
        // send(MessageTypes.getId, group)
        // combine elements
        rectGroup.appendChild(rect);
        group.appendChild(rectGroup);
        group.appendChild(textEl);
        if (isMicroelement(name)) {
            rect.classList.add(`${name.pres.uiid}`);
            rectGroup.classList.add(`${name.pres.uiid}`);
            textEl.classList.add(`${name.pres.uiid}`);
        }
        return group;
    };
    // #region createMicroElement
    function createMicroElement(_ml, x, y) {
        function _extend(...objs) {
            return Object.assign({}, ...objs);
        }
        //? new CORE element 
        const bml = core_1.ml.create(_ml.g, _ml.ex);
        //? create view - it is SVG representation of the element
        const _view = microelement.createView(bml, x, y);
        // #region DEBUG
        //? IMPORTANT
        //! IMPORTANT
        // console.log('view id', _view.id);
        _view.id = bml.core.id;
        //? drag controller
        const _cd = { dragController: { allow: true } };
        //? presentation
        const _pr = presentation_1.presentation.create();
        _view.querySelectorAll('.baseRect')[0].classList.add(`${_pr.pres.uiid}`);
        _view.querySelectorAll('.mainRect')[0].classList.add(`${_pr.pres.uiid}`);
        _view.querySelectorAll('.baseText')[0].classList.add(`${_pr.pres.uiid}`);
        // create microelement object observable
        const T0 = (0, mobx_1.observable)(bml, {}, { deep: true });
        // create presentation object observable
        const T3 = (0, mobx_1.observable)(_pr, {}, { deep: true });
        // Changed merge order to avoid T0 overriding view property
        const T1 = _extend(_cd, T3, { view: _view }, T0);
        eventHandler(T1);
        combination(T1);
        return T1;
    }
    microelement.createMicroElement = createMicroElement;
    // #region select
    const bringToFront = (_m) => { _m.view.parentElement.insertBefore(_m.view, null); };
    // set and remove select view manualy
    const setSelectView = (_m) => {
        const baseRect = _m.view.querySelector(`.baseRect.${_m.pres.uiid} > .mainRect`);
        baseRect.style.stroke = 'rgb(0, 0, 0)';
        baseRect.style.fill = '#03a7ff';
        const baseText = _m.view.querySelector(`.baseText.${_m.pres.uiid}`);
        baseText.style.fill = 'white';
    };
    const removeSelectView = (_m) => {
        const baseRect = _m.view.querySelector(`.baseRect.${_m.pres.uiid} > .mainRect`);
        baseRect.style.stroke = 'rgb(172, 172, 172)';
        baseRect.style.fill = 'rgb(255, 255, 255)';
        const baseText = _m.view.querySelector(`.baseText.${_m.pres.uiid}`);
        baseText.style.fill = 'black';
    };
    microelement.selectMicroelement = (_m) => {
        store_1.store.ml.items.forEach((el) => {
            removeSelectView(el);
            store_1.store.ml.removeSelected(el);
        });
        if (!_m)
            return;
        setSelectView(_m);
        store_1.store.ml.addSelected(_m);
        const closestParent = _m.view.closest('.unionRect');
        if (closestParent) {
            const _union = store_1.store.union.items.find((el) => el.core.id === closestParent.id);
            if (_union) {
                union_1.union.selectUnion(_union, false);
            }
        }
    };
    const eventHandler = (_m) => {
        // add focus in pointer up
        _m.view.addEventListener('pointerup', (e) => {
            _m.view.focus();
        });
        // click on the element
        _m.view.addEventListener('pointerdown', (e) => {
            bringToFront(_m);
            microelement.selectMicroelement(_m);
        });
        // key event
        _m.view.addEventListener('keydown', (e) => {
            console.log(_m.view);
            if (e.key === 'Delete' || e.key === 'Backspace') {
                _m.view.remove();
                store_1.store.ml.remove(_m);
                ml_prosp_panel_1.ml_props_panel.create('mpts-cont');
            }
            if (e.key === 'C' || e.key === 'С') {
                (0, mobx_1.runInAction)(() => {
                    e.shiftKey ? store_1.store.ml.selected[0].pres.comment.show = !store_1.store.ml.selected[0].pres.comment.show : null;
                });
            }
            if (e.key === 'O' || e.key === 'Щ') {
                console.log('O');
                (0, mobx_1.runInAction)(() => {
                    if (e.shiftKey) {
                        if (store_1.store.ml.selected[0].core.employment === core_1.ml.Employment.overlapped) {
                            store_1.store.ml.selected[0].core.employment = core_1.ml.Employment.nonOverlapped;
                        }
                        else {
                            store_1.store.ml.selected[0].core.employment = core_1.ml.Employment.overlapped;
                        }
                    }
                });
            }
            store_1.store.ml.refreshSelected();
        });
    };
    // #region combination
    const combination = (m) => {
        // array for store elements with its .getBBox()
        let _cbbArr = [];
        // setup main element bbox
        let mainBBox = null;
        /**
         * click on the element to drag
         * store all elements with its .getBBox()
         * add translation to bbox
         */
        // #region combination dragstart
        m.view.addEventListener('dragStart', (e) => {
            //! clear array
            _cbbArr = [];
            // init main element bbox
            mainBBox = m.view.querySelector('.baseRect').getBBox();
            const CNV_T = (0, func_1.getSvgTranslate3d)(app_1.app.getApp().ui.mpts.item.svgCanvas);
            store_1.store.ml.items.forEach((el) => {
                if (el.view === m.view)
                    return;
                let _cbb = el.view.querySelector('.baseRect').getBoundingClientRect();
                // add translation to bbox
                _cbb.x -= 24 + CNV_T.x;
                _cbb.y -= 40 + CNV_T.y;
                // fill array
                _cbbArr.push({ el: el, cbb: _cbb });
            });
        });
        // #dragMove region combination 
        /**
         * when element is moving
         * find nearest element with the same x
         * highlight it with blue rectangle
         */
        let indexToInsert = null;
        m.view.addEventListener('dragMove', (e) => {
            var _a, _b;
            // remove combination-path
            (_a = m.view.parentElement.querySelectorAll('.combination-path')) === null || _a === void 0 ? void 0 : _a.forEach((el) => el === null || el === void 0 ? void 0 : el.remove());
            (_b = m.view.parentElement.querySelectorAll('.mpts_temp_circle')) === null || _b === void 0 ? void 0 : _b.forEach((el) => el === null || el === void 0 ? void 0 : el.remove());
            // get bbox of dragging element
            let _x = mainBBox.x + (0, func_1.getSvgTranslate3d)(m.view).x;
            let _y = mainBBox.y + (0, func_1.getSvgTranslate3d)(m.view).y;
            // handle insert to jail 
            if (e.detail.ctrl) {
                let _neForInsert = _cbbArr.find((el) => {
                    if (el.el.pres.captured.state === 'free')
                        return false;
                    return Math.abs(el.cbb.y - 8 - _y) <= 16 && Math.abs(el.cbb.x + el.cbb.width / 2 - 8 - _x) < 16;
                });
                if (_neForInsert) {
                    (0, func_1._c_l)(m.view.parentElement, _neForInsert.cbb.x - 16, _neForInsert.cbb.y, _neForInsert.cbb.x + _neForInsert.cbb.width + 16, _neForInsert.cbb.y, 8, 'rgba(255, 0, 0, 0.5)', ['combination-path']);
                    //! rize captured flag
                    (0, mobx_1.runInAction)(() => {
                        m.pres.captured.state = 'captured';
                        m.pres.captured.invaider = _neForInsert.el.pres.captured.invaider;
                        indexToInsert = _neForInsert.el;
                    });
                    return;
                }
                else {
                    //! drop captured flag
                    (0, mobx_1.runInAction)(() => {
                        m.pres.captured.state = 'free';
                        m.pres.captured.invaider = null;
                        indexToInsert = null;
                    });
                }
            }
            // find nearest element with the same y and x difference less than 10
            let _neForCombine = _cbbArr.find((el) => {
                return Math.abs(el.cbb.y - _y) <= 16 && Math.abs(el.cbb.x + el.cbb.width - _x) < 16;
            });
            // ================================================================================================================================== COMBINE
            if (_neForCombine) {
                // !================================
                // ! deny combine with machineTime
                // !================================
                if (_neForCombine.el.core.group === core_1.ml.Group.machineTime)
                    return;
                if (m.core.group === core_1.ml.Group.machineTime)
                    return;
                let _endCenrtX = _neForCombine.cbb.x + _neForCombine.cbb.width;
                let _endCenrtY = _neForCombine.cbb.y + _neForCombine.cbb.height - 8;
                let _d = `m${_endCenrtX} ${_endCenrtY}h${0}`;
                const _path = (0, func_1.csvg)('path', {
                    d: _d,
                    fill: 'none',
                    stroke: 'rgba(255, 0, 0, 0.5)',
                    'stroke-width': '32',
                    'stroke-linecap': 'round',
                    style: 'color: red;'
                }, ['combination-path']);
                m.view.parentElement.appendChild(_path);
                //! rize captured flag
                (0, mobx_1.runInAction)(() => {
                    m.pres.captured.state = 'captured';
                    m.pres.captured.invaider = _neForCombine.el;
                });
            }
            else {
                //! drop captured flag
                (0, mobx_1.runInAction)(() => {
                    m.pres.captured.state = 'free';
                    m.pres.captured.invaider = null;
                });
            }
            // find nearest element with the same x
            // and y difference less than 10
            let _neForUnion = _cbbArr.find((el) => {
                if (el.el.pres.captured.state === 'captured')
                    return false;
                return Math.abs(el.cbb.x - _x) <= 16 && Math.abs(el.cbb.y + 16 - _y) < 10;
            });
            // ================================================================================================================================== UNION
            if (_neForUnion) {
                // marker
                let dy = 0;
                if (e.detail.ctrl) {
                    dy = 16;
                }
                const _uc = (0, func_1._c_c)(m.view.parentElement, _neForUnion.cbb.x, _neForUnion.cbb.y + 16 - dy, 16, 'rgba(34, 203, 22, 0.50)');
                m.view.parentElement.appendChild(_uc);
                UnionFlag = _neForUnion.el;
            }
            else {
                UnionFlag = null;
            }
        });
        let UnionFlag = null;
        // #region dragEnd  
        /**
         * when element end draging analize captured state
         * create correcponding union
         *! - combine
         *! - technique
         *
         */
        m.view.addEventListener('dragEnd', (e) => {
            // console.log('dragEnd +++');
            var _a, _b;
            const _if = e.detail.ctrl ? true : null;
            if (UnionFlag) {
                // remove .mpts_temp_circle
                (_a = m.view.parentElement.querySelectorAll('.mpts_temp_circle')) === null || _a === void 0 ? void 0 : _a.forEach((el) => el === null || el === void 0 ? void 0 : el.remove());
                if (UnionFlag.pres.inUnion.state === 'free') {
                    let _u = null;
                    if (_if) {
                        _u = union_1.union.create([m, UnionFlag]);
                    }
                    else {
                        _u = union_1.union.create([UnionFlag, m]);
                    }
                    (0, mobx_1.runInAction)(() => {
                        UnionFlag.pres.inUnion.state = 'captured';
                        UnionFlag.pres.inUnion.union = _u;
                        UnionFlag.pres.locked = true;
                        m.pres.inUnion.state = 'captured';
                        m.pres.inUnion.union = _u;
                        m.pres.locked = true;
                    });
                    return;
                }
                else {
                    // insert to union
                    let _i = UnionFlag.pres.inUnion.union.core.mliset.findIndex((el) => el.core.id === UnionFlag.core.id);
                    union_1.union.addItem(UnionFlag.pres.inUnion.union, m, _if ? _i - 1 : _i);
                    (0, mobx_1.runInAction)(() => {
                        m.pres.inUnion.state = 'captured';
                        m.pres.inUnion.union = UnionFlag.pres.inUnion.union;
                        m.pres.locked = true;
                    });
                }
                return;
            }
            else {
                UnionFlag = null;
            }
            // if captured state is true and invaider is not null
            if (m.pres.captured.state === 'captured' && m.pres.captured.invaider) {
                (0, mobx_1.runInAction)(() => {
                    m.pres.combined.show = true;
                });
                (_b = m.view.parentElement.querySelectorAll('.combination-path')) === null || _b === void 0 ? void 0 : _b.forEach((el) => el === null || el === void 0 ? void 0 : el.remove());
                microelement.selectMicroelement(m.pres.captured.invaider);
                capture(m.pres.captured.invaider, m, _if);
                // ! SHOUD BE MOVED TO CAPTURE FUNCTION
                m.pres.locked = true;
                // ! SHOUD BE MOVED TO CAPTURE FUNCTION
                //  ! ONLLY FOR SWITCHER UPDATE
                // ! SHOUD BE MOVED TO CAPTURE FUNCTION
                //  ! ONLLY FOR SWITCHER UPDATE
                store_1.store.ml.refreshSelected();
            }
            _cbbArr = [];
            mainBBox = null;
        });
        const capture = (invaider, prisoner, insert) => {
            // base store for prisoners
            const _coreJail = invaider.core.combined;
            // for example we have one item on entrance
            // lets prepare rect for jailed items
            // if it is new prisoner - prepare rect add item to jail
            // if we already have jailed items, recount rect
            const prepareJail = () => {
                console.log('prepareJail');
                let found1 = _coreJail.items.find((el) => el.core.id === prisoner.core.id);
                if (!found1) {
                    if (!insert) {
                        _coreJail.add(_coreJail, prisoner);
                    }
                    else {
                        let _i = _coreJail.items.findIndex((el) => el.core.id === indexToInsert.core.id);
                        if (_i !== -1)
                            (_coreJail.insert(_coreJail, prisoner, _i));
                    }
                }
                else {
                    return;
                }
                if (!invaider.pres.combined.show) {
                    (0, mobx_1.runInAction)(() => {
                        invaider.pres.combined.show = true;
                    });
                }
                // is jail container NOT exist
                if (!invaider.view.querySelectorAll(`.jailed-items.${invaider.pres.uiid}`).length) {
                    // build group for jailed items
                    const jaulGroup = document.createElementNS(func_1.NS, 'g');
                    jaulGroup.classList.add(`jailed-items`);
                    jaulGroup.classList.add(`${invaider.pres.uiid}`);
                    // add jail to invaider
                    invaider.view.appendChild(jaulGroup);
                    (0, func_1.enableDrag)(jaulGroup);
                    // get invaider main rect and transform
                    const _ibb = invaider.view.querySelector(`.microelement > .baseRect.${invaider.pres.uiid}`);
                    const _ibbBBox = _ibb.getBBox();
                    // add anchor rect for jailed items
                    const _anchor_rect = (0, func_1.csvg)('rect', {
                        x: _ibbBBox.x + _ibbBBox.width + 80,
                        y: _ibbBBox.y,
                        width: 16,
                        height: 16,
                        // fill: 'red',
                        rx: 4,
                        stroke: 'rgb(172, 172, 172)',
                        'stroke-width': '1',
                        fill: 'rgba(255, 255, 255, 0.3)',
                        'stroke-dasharray': '2, 2',
                    }, ['mpts_jail_anchor_rect', `${invaider.pres.uiid}`]);
                    jaulGroup.appendChild(_anchor_rect);
                    // add prisoner to jail
                    jaulGroup.insertBefore(prisoner.view, jaulGroup.lastElementChild);
                    // bbox and transform for prisoner
                    let _pbbr = prisoner.view.querySelector(`.microelement > .baseRect.${prisoner.pres.uiid}`);
                    const _pbb = _pbbr.getBBox();
                    const _pbb_transform = (0, func_1.getSvgTranslate3d)(prisoner.view);
                    // bbox and transform for jail with prisoner
                    const _anchor_rect_b = _anchor_rect.getBBox();
                    // update rect
                    _anchor_rect.setAttribute('height', _pbb.height.toString());
                    _anchor_rect.setAttribute('width', _pbb.width.toString());
                    // calculate translate for prisoner, update translate
                    const dx = _anchor_rect_b.x - _pbb.x - _pbb_transform.x;
                    const dy = _anchor_rect_b.y - _pbb.y - _pbb_transform.y;
                    (0, func_1.updateTranslate3d)(prisoner.view, dx, dy);
                    // ===========================================================
                    // ================jaulGroup EVENTs ==========================
                    // ===========================================================
                    addJailBehaviors(jaulGroup, _anchor_rect);
                    // ===========================================================
                    // ===========================================================
                    // ===========================================================
                }
                else {
                    console.log('i am not a first one');
                    console.log('insert', insert);
                    (0, exports.updateJail)(invaider, prisoner);
                    if (insert) {
                        restoreStack(invaider);
                    }
                }
                if (prisoner.core.combined.items.length) {
                    prisoner.core.combined.items.forEach((el) => {
                        if (isMicroelement(el)) {
                            // console.log('i am a prisoner');
                            capture(prisoner, el, null);
                            // console.log('setCombinedView');
                            (0, m_comb_1.setCombinedView)(prisoner);
                            (0, mark_1.marksGroup)(prisoner);
                            (0, exports.updateJail)(invaider, prisoner);
                        }
                    });
                }
            };
            // ======================================================================================== LIKE GROUP ...
            const addJailBehaviors = (jail, anchor) => {
                // ======================================================================= PRESS PROGRESS
                let pressTimer = null;
                let delayTimer = null;
                let startTime = null;
                const DELAY_BEFORE_HOLD = 200;
                const HOLD_DURATION = 200;
                let isPressing = false;
                let _abb = anchor.getBBox();
                let _abb_transform = (0, func_1.getSvgTranslate3d)(anchor);
                let _line;
                const maxWidth = () => { return _abb.width; };
                const reset = () => {
                    isPressing = false;
                    startTime = null;
                    if (delayTimer !== null) {
                        clearTimeout(delayTimer);
                        delayTimer = null;
                    }
                    if (pressTimer !== null) {
                        clearTimeout(pressTimer);
                        pressTimer = null;
                    }
                    clearProgressLine();
                };
                const createProgressLine = () => {
                    _abb = anchor.getBBox();
                    _abb_transform = (0, func_1.getSvgTranslate3d)(anchor);
                    const _y = _abb.y + _abb_transform.y;
                    _line = (0, func_1._c_l)(jail, _abb.x + _abb_transform.x, _y, _abb.x + _abb_transform.x, _y, 8, '#FFA564', [`jailed-progress`, `${invaider.pres.uiid}`]);
                };
                const clearProgressLine = () => {
                    if (_line) {
                        _line.remove();
                        _line = null;
                    }
                };
                const updateProgressLine = (dx) => {
                    _line.setAttribute('x2', String((maxWidth() * dx) + _abb.x + _abb_transform.x));
                };
                const updateProgress = () => {
                    if (!isPressing || startTime === null)
                        return;
                    const elapsedTime = Date.now() - startTime;
                    const progress = Math.min(elapsedTime / HOLD_DURATION, 1);
                    if (!_line) {
                        createProgressLine();
                    }
                    updateProgressLine(progress);
                    if (progress < 1) {
                        requestAnimationFrame(updateProgress);
                    }
                };
                //  #region shakeJail
                // ======================================================================= MAIN POINTERDOWN
                const handlePointerDown = (e) => {
                    e.stopPropagation();
                    isPressing = true;
                    delayTimer = window.setTimeout(() => {
                        startTime = Date.now();
                        pressTimer = window.setTimeout(() => {
                            console.log('ALARM THEY HOLD ME');
                            handleElementSeletion(e);
                            reset();
                        }, HOLD_DURATION);
                        requestAnimationFrame(updateProgress);
                    }, DELAY_BEFORE_HOLD);
                };
                const handleElementSeletion = (e) => {
                    const lastMouseX = e.clientX;
                    const lastMouseY = e.clientY;
                    const elements = document.elementsFromPoint(lastMouseX, lastMouseY);
                    const _m = elements[1].closest('.microelement');
                    if (!_m) {
                        return;
                    }
                    // ================================================= MICROELEMENT TO FREE
                    const _mToFree = store_1.store.ml.getItem(_m.id);
                    if (!e.shiftKey)
                        microelement.selectMicroelement(_mToFree);
                    if (e.shiftKey) {
                        document.querySelectorAll('.free-line').forEach((el) => el.remove());
                        let _i = 0;
                        _mToFree.pres.captured.invaider.core.combined.items.forEach((el, i) => {
                            if (el.core.id == _mToFree.core.id) {
                                _i = i;
                            }
                        });
                        (0, mobx_1.runInAction)(() => {
                            _mToFree.pres.captured.state = 'run';
                        });
                        app_1.app.getApp().ui.mpts.item.svgCanvas.appendChild(_mToFree.view);
                        const clc = [e.clientX, e.clientY];
                        const cp = _mToFree.view.querySelector(`.baseRect.${_mToFree.pres.uiid}`).getBoundingClientRect();
                        (0, func_1.updateTranslate3d)(_mToFree.view, clc[0] - cp.x - 16, clc[1] - cp.y - 8);
                        const pointerDownEvent = new PointerEvent('pointerdown', {
                            bubbles: false,
                            cancelable: true,
                            clientX: e.clientX,
                            clientY: e.clientY,
                            pointerId: e.pointerId
                        });
                        _mToFree.view.dispatchEvent(pointerDownEvent);
                        const pointerUpEvent = new PointerEvent('pointerup', {
                            bubbles: false,
                            cancelable: true,
                            clientX: e.clientX,
                            clientY: e.clientY,
                            pointerId: e.pointerId
                        });
                        const myInvaider = _mToFree.pres.captured.invaider;
                        myInvaider.view.querySelector(`.jailed-items.${myInvaider.pres.uiid}`).dispatchEvent(pointerUpEvent);
                        myInvaider.core.combined.remove(myInvaider.core.combined, _mToFree);
                        (0, mobx_1.runInAction)(() => {
                            _mToFree.pres.captured.invaider = null;
                            _mToFree.pres.captured.state = 'free';
                            _mToFree.pres.locked = false;
                        });
                        restoreStack(myInvaider);
                        return;
                    }
                };
                jail.addEventListener('pointerdown', handlePointerDown);
                jail.addEventListener('pointerup', reset);
                jail.addEventListener('pointercancel', reset);
                jail.addEventListener('dragMove', () => (0, exports.shakeJail)(invaider));
                jail.addEventListener('dragMove', reset);
                jail.addEventListener('dragStart', reset);
                jail.addEventListener('dragEnd', (e) => {
                    (0, mobx_1.runInAction)(() => {
                        m.pres.combined.xy = [e.detail.snappedX, e.detail.snappedY];
                    });
                });
            };
            prepareJail();
            (0, exports.shakeJail)(invaider);
        };
    };
})(microelement || (exports.microelement = microelement = {}));
const restoreStack = (invaider) => {
    (0, exports.updateJail)(invaider, null);
    const _anchor = invaider.view.querySelector(`.jailed-items.${invaider.pres.uiid} > .mpts_jail_anchor_rect`);
    if (!_anchor)
        return;
    const _anchor_b = _anchor.getBBox();
    const _anchor_transform = (0, func_1.getSvgTranslate3d)(_anchor);
    invaider.core.combined.items.forEach((el, i) => {
        const _m = store_1.store.ml.getItem(el.core.id);
        const _m_b = _m.view.querySelector(`.microelement > .baseRect.${_m.pres.uiid}`).getBBox();
        const _m_transform = (0, func_1.getSvgTranslate3d)(_m.view);
        const dx = (_anchor_b.x + _anchor_transform.x) - (_m_b.x + _m_transform.x);
        const dy = (_anchor_b.y + _anchor_transform.y) - (_m_b.y + _m_transform.y) + 16 * i;
        (0, func_1.updateTranslate3d)(_m.view, dx, dy);
    });
    _anchor.style.height = `${invaider.core.combined.items.length * 16}px`;
    console.log('shake after restoreStack');
    (0, exports.shakeJail)(invaider);
};
const shakeJail = (invaider) => {
    if (!invaider)
        return;
    if (!invaider.pres.combined.show)
        return;
    // remove rect around jailed items 
    invaider.view.querySelectorAll(`.jailed-rect.${invaider.pres.uiid}`).forEach((el) => el.remove());
    // remove circles between invaider and jailed items
    invaider.view.querySelectorAll(`.jailed-conn-circle.${invaider.pres.uiid}`).forEach((el) => el.remove());
    // remove line between invaider and jailed items
    invaider.view.querySelectorAll(`.jailed-conn-line.${invaider.pres.uiid}`).forEach((el) => el.remove());
    // remove sideline between invaider and jailed items
    invaider.view.querySelectorAll(`.jailed-conn-sideline.${invaider.pres.uiid}`).forEach((el) => el.remove());
    if (invaider.core.combined.items.length <= 0) {
        const _anchor_rect = invaider.view.querySelector(`.jailed-items.${invaider.pres.uiid}`);
        if (_anchor_rect)
            _anchor_rect.remove();
        return;
    }
    //! ================================================================================================ FULL JAIL
    // get jailed items
    const _jail = invaider.view.querySelector(`.jailed-items.${invaider.pres.uiid}`);
    if (!_jail)
        return;
    // get jailed items transform
    const _jailTransform = (0, func_1.getSvgTranslate3d)(_jail);
    //! ================================================================================================ ANCHORE RECT
    const _anchor_rect = invaider.view.querySelector(`.jailed-items.${invaider.pres.uiid} > .mpts_jail_anchor_rect`);
    if (!_anchor_rect)
        return;
    const _anchor_rect_b = _anchor_rect.getBBox();
    // add _jailTransform.x - _jailTransform.y to _anchor_rect_b, return new DOMRect
    const _jailRealBbox = new DOMRect(_anchor_rect_b.x + _jailTransform.x, _anchor_rect_b.y + _jailTransform.y, _anchor_rect_b.width, _anchor_rect_b.height);
    // ========================================================================= IT IS JAIL CENTER =========================
    const _jailCenterXY = [
        _anchor_rect_b.x + _anchor_rect_b.width / 2 + _jailTransform.x,
        _anchor_rect_b.y + _anchor_rect_b.height / 2 + _jailTransform.y
    ];
    //! ================================================================================================ INVAIDER
    const _ibb = invaider.view.querySelector(`.baseRect.${invaider.pres.uiid}`);
    const _ibbBBox = _ibb.getBBox();
    // ==========================================================================IT IS INVAIDERS CENTER ====================
    const _invCenterXY = [
        _ibbBBox.x + _ibbBBox.width / 2,
        _ibbBBox.y + _ibbBBox.height / 2
    ];
    // line beetwen centers
    const _centersLine = [..._invCenterXY, ..._jailCenterXY];
    // points where centers line cross invaider and jail bbox
    const p1 = (0, func_1.getRectWithLineIntersection)(_ibbBBox, _centersLine)[0];
    const p2 = (0, func_1.getRectWithLineIntersection)(_jailRealBbox, _centersLine)[0];
    if (!p1 || !p2)
        return;
    // create connection line
    const _line = (0, func_1.csvg)('line', {
        'x1': (0, func_1.rbb)(p1.point[0], 8),
        'y1': (0, func_1.rbb)(p1.point[1], 8),
        'x2': (0, func_1.rbb)(p2.point[0], 8),
        'y2': (0, func_1.rbb)(p2.point[1], 8),
        // 'stroke': '#03A7FF',
        'stroke': '#003D5B',
        'stroke-width': '1',
        'stroke-dasharray': '5, 5',
        'marker-end': 'url(#circle)',
        'marker-start': 'url(#circle)',
    }, [`jailed-conn-line`, `${invaider.pres.uiid}`]);
    // add line to invaider
    invaider.view.appendChild(_line);
    if (!p1 || !p2)
        return;
    // create sideline 
    // p1 p2 have propoerty side
    // build lineside coordinates
    let _xy = { from: [0, 0], to: [0, 0] };
    const dx = 4;
    switch (p2.side) {
        case 'left':
            _xy.from = [_jailRealBbox.x - dx, _jailRealBbox.y];
            _xy.to = [_jailRealBbox.x - dx, _jailRealBbox.y + _jailRealBbox.height];
            break;
        case 'right': // right    
            _xy.from = [_jailRealBbox.x + dx + _jailRealBbox.width, _jailRealBbox.y];
            _xy.to = [_jailRealBbox.x + dx + _jailRealBbox.width, _jailRealBbox.y + _jailRealBbox.height];
            break;
        case 'top': // top
            _xy.from = [_jailRealBbox.x, _jailRealBbox.y - dx];
            _xy.to = [_jailRealBbox.x + _jailRealBbox.width, _jailRealBbox.y - dx];
            break;
        case 'bottom': // bottom
            _xy.from = [_jailRealBbox.x, _jailRealBbox.y + _jailRealBbox.height + dx];
            _xy.to = [_jailRealBbox.x + _jailRealBbox.width, _jailRealBbox.y + _jailRealBbox.height + dx];
            break;
        default:
            break;
    }
    const _sideline = (0, func_1.csvg)('line', {
        'x1': _xy.from[0],
        'y1': _xy.from[1],
        'x2': _xy.to[0],
        'y2': _xy.to[1],
        // 'stroke': '#03A7FF80',
        'stroke': 'rgba(0, 61, 91, 0)',
        'stroke-width': `8`,
        'pointer-events': 'none',
    }, [`jailed-conn-sideline`, `${invaider.pres.uiid}`]);
    invaider.view.appendChild(_sideline);
};
exports.shakeJail = shakeJail;
