"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.m_comb = exports.setCombinedView = void 0;
const func_1 = require("../../../assest/func");
const app_1 = require("../../../scripts/app");
const tr_1 = require("../../tr");
const tcheckbox_1 = require(".././baseitems/tcheckbox");
const mobx_1 = require("mobx");
const setCombinedView = (m) => {
    const _m_comb = m.view.querySelector(`.jailed-items.${m.pres.uiid}`);
    const _m_comb_sideline = m.view.querySelector(`.jailed-conn-line.${m.pres.uiid}`);
    const _m_comb_line = m.view.querySelector(`.jailed-conn-sideline.${m.pres.uiid}`);
    if (m.pres.combined.show === true) {
        if (_m_comb) {
            _m_comb.style.display = '';
        }
        _m_comb_sideline ? _m_comb_sideline.style.display = '' : null;
        _m_comb_line ? _m_comb_line.style.display = '' : null;
        return;
    }
    if (m.pres.combined.show === false) {
        if (_m_comb)
            (_m_comb.style.display = 'none');
        _m_comb_sideline ? _m_comb_sideline.style.display = 'none' : null;
        _m_comb_line ? _m_comb_line.style.display = 'none' : null;
        return;
    }
};
exports.setCombinedView = setCombinedView;
const m_comb = (m) => {
    // create default panel
    const cont = (0, func_1.bh)({ tag: 'div', classList: ['mpt_slider_topcont'], id: 'mpts_comb_switch' });
    // translate microelement
    const _tr = (0, tr_1.tr_ml)(app_1.app.getApp().lang, m);
    const _v = _tr.comb;
    // export type tchVT = [text: string, noval: string, yesval: string, val: boolean]
    const _over = (0, tcheckbox_1.tcheckbox)([_v, _v, _v, m.pres.combined.show]).view;
    _over.addEventListener('change', (e) => {
        (0, mobx_1.runInAction)(() => {
            if (e.target.checked) {
                m.pres.combined.show = true;
                (0, exports.setCombinedView)(m);
            }
            else {
                m.pres.combined.show = false;
                (0, exports.setCombinedView)(m);
            }
            // console.log('m.pres.combined', m.pres.combined.show);
        });
    });
    cont.appendChild(_over);
    return cont;
};
exports.m_comb = m_comb;
