"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.m_combos = void 0;
const mobx_1 = require("mobx");
const func_1 = require("../../../assest/func");
const app_1 = require("../../../scripts/app");
const tr_1 = require("../../tr");
const tcombo_1 = require(".././baseitems/tcombo");
const m_combos = (m) => {
    // create default panel
    const cont = (0, func_1.bh)({ tag: 'div' });
    // create combo for quality factors
    if (m.core.qualityFactors.values.length <= 0) {
        // empty cont for no quality factors
        return cont;
    }
    else {
        // translate microelement
        const _tr = (0, tr_1.tr_ml)(app_1.app.getApp().lang, m);
        // create combo for each quality factor
        m.core.qualityFactors.values.forEach((qf, i) => {
            let _sel = qf.values.findIndex(v => v.kind === qf.value);
            const _combo = (0, tcombo_1.tcombo)({ top: _tr.qf[1][i][1], ch: [..._tr.qf[1][i][3]] }, _sel);
            _combo.addEventListener('change', (e) => {
                (0, mobx_1.runInAction)(() => {
                    qf.value = qf.values[e.target.value].kind;
                });
            });
            cont.appendChild(_combo);
        });
    }
    return cont;
};
exports.m_combos = m_combos;
