"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.demoarea = void 0;
var demoarea;
(function (demoarea) {
    demoarea.create = (l) => {
        // const r = ml_items_panel.create(app.getApp().ui.home.item.home.demoarea.id, [36, 260], l)
        const setLang = (l) => {
            // r.remove()
            // ml_items_panel.create(app.getApp().ui.home.item.home.demoarea.id, [36, 260], l)
        };
        return {
            id: crypto.randomUUID(),
            translate: setLang
        };
    };
})(demoarea || (exports.demoarea = demoarea = {}));
