"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.user = exports.app = exports.AppView = void 0;
// Code Created with love to my son, and my wife, and my mother
const tr_1 = require("../core/tr");
const docs_1 = require("../site_components/docs/docs");
const footer_1 = require("../site_components/footer/footer");
const header_1 = require("../site_components/header/header");
const home_1 = require("../site_components/home/home");
const login1_1 = require("../site_components/login/login1");
const mpts_1 = require("../site_components/mpts/mpts");
var AppView;
(function (AppView) {
    AppView[AppView["home"] = 0] = "home";
    AppView[AppView["docs"] = 1] = "docs";
    AppView[AppView["roadmap"] = 2] = "roadmap";
    AppView[AppView["prices"] = 3] = "prices";
    AppView[AppView["app"] = 4] = "app";
    AppView[AppView["signin"] = 5] = "signin";
})(AppView || (exports.AppView = AppView = {}));
/** core front app item */
var app;
(function (app_1) {
    let app = null;
    app_1.getApp = (l = tr_1.lang.eng) => {
        return app ? app : create(l);
    };
    const create = (l) => {
        const _header = header_1.header.create(l);
        const _footer = footer_1.footer.create(l);
        const _home = home_1.home.create(l);
        const _docs = docs_1.docs.create(l);
        const _login = login1_1.login.create(l);
        const _user = user.setUser({ email: '' });
        const _mpts = mpts_1.mpts.create(l);
        app = {
            id: crypto.randomUUID(),
            lang: l,
            logedIn: false,
            // logedIn: true,
            user: _user,
            translate: _translate,
            setView: _setView,
            ui: {
                header: _header,
                footer: _footer,
                home: _home,
                docs: _docs,
                signin: _login,
                mpts: _mpts
            }
        };
        return app;
    };
    /** translate all the app */
    const _translate = (newLang) => {
        app.lang = newLang;
        app.ui.header.translate(newLang);
        app.ui.footer.translate(newLang);
        app.ui.home.translate(newLang);
        app.ui.docs.translate(newLang);
    };
    /** check if user is loged in */
    const _checkLoginStatus = () => {
        // check if user is loged in
        return app.logedIn;
    };
    const _setView = (v) => {
        switch (v) {
            case AppView.home:
                console.log('home');
                app.ui.docs.show(false);
                app.ui.signin.show(false);
                app.ui.mpts.show(false);
                app.ui.home.show(true);
                break;
            case AppView.docs:
                app.ui.home.show(false);
                app.ui.signin.show(false);
                app.ui.mpts.show(false);
                app.ui.docs.show(true);
                break;
            case AppView.roadmap:
                break;
            case AppView.prices:
                break;
            case AppView.app:
                app.ui.home.show(false);
                app.ui.docs.show(false);
                app.ui.signin.show(false);
                app.ui.mpts.show(true);
                break;
            case AppView.signin:
                app.ui.home.show(false);
                app.ui.docs.show(false);
                app.ui.mpts.show(false);
                app.ui.signin.show(true);
                break;
        }
    };
})(app || (exports.app = app = {}));
var user;
(function (user) {
    let _user = {
        id: '',
        email: '',
        name: '',
        key: ''
    };
    user.setUser = (val) => {
        _user.email = val.email;
        val.id ? _user.id = val.id : null;
        val.name ? _user.name = val.name : null;
        val.key ? _user.key = val.key : null;
        return _user;
    };
})(user || (exports.user = user = {}));
