"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.step = void 0;
const mobx_1 = require("mobx");
const core_1 = require("../../core/core");
const tr_1 = require("../../core/tr");
const app_1 = require("../../scripts/app");
const func_1 = require("../../scripts/func");
const presentation_1 = require("./presentation");
const store_1 = require("./store");
var step;
(function (step) {
    // initial view of step
    const createStepView = (x, y, uiid, _name) => {
        // base text length
        const _nameLength = (0, func_1.countStringWidth)(_name);
        // create group for rect
        const rectGroup = document.createElementNS(func_1.NS, 'g');
        rectGroup.classList.add('stepBaseRect');
        rectGroup.classList.add(uiid);
        // create rect
        const rect = (0, func_1.csvg)('rect', {
            x: (0, func_1.rbb)(x, 8),
            y: (0, func_1.rbb)(y, 8),
            width: _nameLength + 8,
            height: 16,
            fill: 'white',
            stroke: 'rgb(172, 172, 172)',
            'stroke-width': '1',
            rx: 4
        }, ['stepMainRect', `${uiid}`]);
        // create text
        const text = (0, func_1.csvg)('text', {
            x: x + 4,
            y: y + 9,
            fill: 'black',
            'font-size': '12px',
            'text-anchor': 'left',
            'dominant-baseline': 'middle',
        }, ['stepText', `${uiid}`]);
        text.textContent = _name;
        // rect to input line ================================== CONNECT RECT WITH INPUT
        const _inputLine = (0, func_1.csvg)('line', {
            x1: x + _nameLength + 8,
            y1: y + 8,
            x2: x + _nameLength + 8 + 64 - 8,
            y2: y + 8,
            stroke: 'black',
            'stroke-width': '1',
            'stroke-dasharray': '5, 5',
            'marker-end': 'url(#circle)',
            'marker-start': 'url(#circle)',
        }, ['stepInputLine', `${uiid}`]);
        // create group for rect
        const contentGroup = document.createElementNS(func_1.NS, 'g');
        contentGroup.classList.add('stepContentGroup');
        contentGroup.classList.add(uiid);
        contentGroup.setAttribute('pointer-events', 'none');
        const _input = (0, func_1.csvg)('circle', {
            cx: (0, func_1.rbb)(x + _nameLength + 8 + 64, 8),
            cy: (0, func_1.rbb)(y + 8, 8),
            r: 8,
            fill: '#93B7BE',
            stroke: '#494949',
            'stroke-width': '1',
            'pointer-events': 'all',
        }, ['stepInput', `${uiid}`]);
        const _output = (0, func_1.csvg)('circle', {
            cx: (0, func_1.rbb)(x + _nameLength + 8 + 160, 8),
            cy: (0, func_1.rbb)(y + 8, 8),
            r: 8,
            fill: '#93B7BE',
            stroke: '#494949',
            'stroke-width': '4',
            'pointer-events': 'all',
        }, ['stepOutput', `${uiid}`]);
        let _d = `M${(0, func_1.rbb)(x + _nameLength + 8 + 64, 8)} ${(0, func_1.rbb)(y + 8, 8)}v 28 q 0 4 4 4 h 56 q 4 0 4 -4 v -28`;
        const _outputLine = (0, func_1.csvg)('path', {
            d: _d,
            stroke: '#A1C3FF',
            'stroke-width': '1',
            fill: 'none',
        }, ['stepOutputLine', `${uiid}`]);
        const contentGroupField = document.createElementNS(func_1.NS, 'g');
        contentGroupField.classList.add('stepContentGroupField');
        contentGroupField.classList.add(uiid);
        contentGroupField.setAttribute('pointer-events', 'none');
        const _contentFieldAnchor = (0, func_1.csvg)('rect', {
            x: (0, func_1.rbb)(x + _nameLength + 24 + 64, 8),
            y: (0, func_1.rbb)(y - 8, 8),
            width: 64,
            height: 32,
            fill: 'transparent',
            stroke: 'rgb(129, 129, 129)',
            'stroke-dasharray': '5, 5',
            'stroke-width': '1',
            rx: 4,
            'pointer-events': 'all',
        }, ['stepContentFieldAnchor', `${uiid}`]);
        contentGroupField.appendChild(_contentFieldAnchor);
        // resulted group
        const resGroup = document.createElementNS(func_1.NS, 'g');
        resGroup.classList.add('step');
        resGroup.classList.add(uiid);
        resGroup.setAttribute('pointer-events', 'all');
        // combine all elements
        rectGroup.appendChild(rect);
        resGroup.appendChild(rectGroup);
        resGroup.appendChild(text);
        resGroup.appendChild(_inputLine);
        // contentGroup.appendChild(_outputLine)
        contentGroup.appendChild(contentGroupField);
        contentGroup.appendChild(_input);
        contentGroup.appendChild(_output);
        resGroup.appendChild(contentGroup);
        (0, func_1.enableDrag)(resGroup);
        (0, func_1.enableDrag)(contentGroup);
        contentGroupBehavior(resGroup, uiid);
        return resGroup;
    };
    // step group behavior
    const contentGroupBehavior = (resGroup, _uiid) => {
        // enable drag for content group
        const _contentGroup = resGroup.querySelector(`.stepContentGroup.${_uiid}`);
        _contentGroup.addEventListener('pointerdown', (e) => {
            e.stopImmediatePropagation();
        });
        // enable _inputLine redraw on drag
        const contentGroupOnDrag = (e) => {
            step.updateStepGroupLink(store_1.store.step.getItem(resGroup.id));
        };
        let lline;
        let inLink = false;
        // create link from input to external element
        const _input = resGroup.querySelector(`.stepInput.${_uiid}`);
        // get rects for all unions and microelements
        const _fillRectArr = () => {
            // remove all .mpts_temp_circle
            document.querySelectorAll('.mpts_temp_circle').forEach(el => el.remove());
            let _cbbArr = [];
            const CNV_T = (0, func_1.getSvgTranslate3d)(app_1.app.getApp().ui.mpts.item.svgCanvas);
            // find inside Microelements
            store_1.store.ml.items.forEach((el) => {
                if (el.pres.locked === true)
                    return;
                let _cbb = el.view.querySelector('.baseRect').getBoundingClientRect();
                _cbb.x -= 24 + CNV_T.x;
                _cbb.y -= 40 + CNV_T.y;
                _cbbArr.push({ el: el, cbb: _cbb });
                // _c_c(app.getApp().ui.mpts.item.svgCanvas, _cbb.x, _cbb.y, 4, 'blue')
            });
            // find inside Unions
            store_1.store.union.items.forEach((el) => {
                if (el.pres.locked === true)
                    return;
                let _cbb = el.view.querySelector('.unionRect > .baseRect').getBoundingClientRect();
                _cbb.x -= 24 + CNV_T.x;
                _cbb.y -= 40 + CNV_T.y;
                _cbbArr.push({ el: el, cbb: _cbb });
                // _c_c(app.getApp().ui.mpts.item.svgCanvas, _cbb.x, _cbb.y, 4, 'blue')
            });
            return _cbbArr;
        };
        const _cr = [];
        // start link from input to external element
        const inputOnPointerDown = (e) => {
            // if (e.altKey) {
            inLink = true;
            // remove stepInputToExternalLine
            document.querySelectorAll(`.stepInputToExternalLine.${_uiid}`).forEach(el => el.remove());
            let _dc_t = (0, func_1.getSvgTranslate3d)(resGroup);
            let _canvas_t = (0, func_1.getSvgTranslate3d)(app_1.app.getApp().ui.mpts.item.svgCanvas);
            let _scg_t = (0, func_1.getSvgTranslate3d)(_contentGroup);
            lline = (0, func_1.csvg)('line', {
                x1: _input.cx.baseVal.value + _scg_t.x,
                y1: _input.cy.baseVal.value + _scg_t.y,
                x2: e.clientX - 24 - _dc_t.x - _canvas_t.x,
                y2: e.clientY - 40 - _dc_t.y - _canvas_t.y,
                stroke: '#00603980',
                'stroke-width': '4',
                'stroke-dasharray': '16, 4',
            }, ['stepInputToExternalLine', `${_uiid}`]);
            resGroup.appendChild(lline);
        };
        // update line from input to external element
        document.addEventListener('pointermove', (e) => {
            let _dc_t = (0, func_1.getSvgTranslate3d)(resGroup);
            let _canvas_t = (0, func_1.getSvgTranslate3d)(app_1.app.getApp().ui.mpts.item.svgCanvas);
            if (inLink && lline) {
                lline.setAttribute('x2', (e.clientX - 24 - _dc_t.x - _canvas_t.x).toString());
                lline.setAttribute('y2', (e.clientY - 40 - _dc_t.y - _canvas_t.y).toString());
            }
        });
        document.addEventListener('pointerdown', (e) => {
            if (inLink) {
                console.log('step');
                _cr.push(..._fillRectArr());
                const CNV_T = (0, func_1.getSvgTranslate3d)(app_1.app.getApp().ui.mpts.item.svgCanvas);
                const _x = e.clientX - 24 - CNV_T.x;
                const _y = e.clientY - 40 - CNV_T.y;
                _cr.forEach((el) => {
                    let _cbb = el.cbb;
                    if (_x > _cbb.x &&
                        _x < _cbb.x + _cbb.width && _y > _cbb.y &&
                        _y < _cbb.y + _cbb.height) {
                        // ========================================================================================= ELEMENT TO ADD
                        // stepContentGroupField
                        const _contentGroup = resGroup.querySelector(`.stepContentGroup.${_uiid}`);
                        const _contentGroupFieldAnchore = resGroup.querySelector(`.stepContentFieldAnchor.${_uiid}`);
                        const _gf_bb = _contentGroupFieldAnchore.getBoundingClientRect();
                        const pointerUpEvent = new PointerEvent('pointerup', {
                            bubbles: true,
                            cancelable: true,
                            clientX: e.clientX - CNV_T.x - 24,
                            clientY: e.clientY - CNV_T.y - 40,
                            pointerId: e.pointerId
                        });
                        el.el.view.dispatchEvent(pointerUpEvent);
                        _contentGroup.appendChild(el.el.view);
                        el.el.view.style.pointerEvents = 'all';
                        // find el.el.id in store.ml.items or store.union.items
                        let _item = null;
                        const _step = store_1.store.step.items.find(i => i.core.id === resGroup.id);
                        if (el.el.core.coreType === core_1.CoreTypes.microelement) {
                            _item = store_1.store.ml.getItem(el.el.core.id);
                            store_1.store.step.addItemToStep(_step, _item);
                        }
                        else if (el.el.core.coreType === core_1.CoreTypes.union) {
                            _item = store_1.store.union.getItem(el.el.core.id);
                            store_1.store.step.addItemToStep(_step, _item);
                        }
                        (0, mobx_1.runInAction)(() => {
                            _item.pres.locked = true;
                            _item.pres.inStep.state = 'locked';
                            _item.pres.inStep.step = _step;
                        });
                        (0, func_1.updateTranslate3d)(el.el.view, 0, 0, true);
                        const cur_bb = el.el.view.getBoundingClientRect();
                        (0, func_1.updateTranslate3d)(el.el.view, _gf_bb.x - cur_bb.x + 8, _gf_bb.y - cur_bb.y + 8, true);
                        el.el.view.addEventListener('pointerdown', (e) => {
                            e.stopPropagation();
                        });
                        step.updateStepField(_step);
                    }
                    else {
                    }
                });
                // remove line reset state
                document.querySelectorAll(`.stepInputToExternalLine.${_uiid}`).forEach(el => el.remove());
                inLink = false;
                lline = null;
                _cr.length = 0;
            }
        });
        _contentGroup.addEventListener('dragMove', contentGroupOnDrag);
        _input.addEventListener('pointerdown', inputOnPointerDown);
    };
    // update link from step to content group
    step.updateStepGroupLink = (_s) => {
        const _rect = _s.view.querySelector(`.stepMainRect`);
        const _rect_bb = _rect.getBBox(); // =============================================================== rect coordinates
        const _rect_translate = (0, func_1.getSvgTranslate3d)(_rect);
        const _rect_bb_fixed = new DOMRect(_rect_bb.x + _rect_translate.x, _rect_bb.y + _rect_translate.y, _rect_bb.width, _rect_bb.height);
        const _rect_center = [_rect_bb.x + _rect_translate.x + _rect_bb.width / 2, _rect_bb.y + _rect_translate.y + _rect_bb.height / 2];
        const _group = _s.view.querySelector(`.stepContentGroupField`);
        const _group_bb = _group.getBBox(); // ============================================================= group coordinates
        const _group_translate = (0, func_1.getSvgTranslate3d)(_s.view.querySelector(`.stepContentGroup`));
        const _group_bb_fixed = new DOMRect(_group_bb.x + _group_translate.x, _group_bb.y + _group_translate.y, _group_bb.width, _group_bb.height);
        const _group_center = [_group_bb.x + _group_translate.x + _group_bb.width / 2, _group_bb.y + _group_translate.y + _group_bb.height / 2];
        const _rect_group_center_line = [..._rect_center, ..._group_center];
        const p1 = (0, func_1.getRectWithLineIntersection)(_rect_bb_fixed, _rect_group_center_line)[0];
        const p2 = (0, func_1.getRectWithLineIntersection)(_group_bb_fixed, _rect_group_center_line)[0];
        if (!p1 || !p2)
            return;
        const _inputLine = _s.view.querySelector(`.stepInputLine`);
        _inputLine.setAttribute('x1', (0, func_1.rbb)(p1.point[0], 8).toString());
        _inputLine.setAttribute('y1', (0, func_1.rbb)(p1.point[1], 8).toString());
        _inputLine.setAttribute('x2', (0, func_1.rbb)(p2.point[0], 8).toString());
        _inputLine.setAttribute('y2', (0, func_1.rbb)(p2.point[1], 8).toString());
    };
    step.updateStepField = (_s) => {
        // remove .mpts_temp_rect
        document.querySelectorAll('.mpts_temp_rect').forEach(el => el.remove());
        // get canvas translate
        const CNV_T = (0, func_1.getSvgTranslate3d)(app_1.app.getApp().ui.mpts.item.svgCanvas);
        function getBoundingBox(elements) {
            if (elements.length === 0)
                return new DOMRect(0, 0, 0, 0);
            let minX = Infinity, minY = Infinity;
            let maxX = -Infinity, maxY = -Infinity;
            // canvas translate
            // const CNV_T = getSvgTranslate3d(app.getApp().ui.mpts.item.svgCanvas)
            elements.forEach(el => {
                const bbox = el.getBoundingClientRect();
                bbox.x = bbox.x - 24 - 2 * CNV_T.x;
                bbox.y = bbox.y - 40 - 2 * CNV_T.y;
                minX = Math.min(minX, bbox.x);
                minY = Math.min(minY, bbox.y);
                maxX = Math.max(maxX, bbox.x + bbox.width);
                maxY = Math.max(maxY, bbox.y + bbox.height);
            });
            return new DOMRect(minX, minY, maxX - minX, maxY - minY);
        }
        // combine microelements and unions .baseRect
        const _sels = _s.view.querySelectorAll('.stepContentGroup > .microelement > .baseRect, .stepContentGroup > .unionRect > .baseRect');
        const _resRect = getBoundingBox(Array.from(_sels));
        const _resRectFixed = new DOMRect(_resRect.x + CNV_T.x, _resRect.y + CNV_T.y, _resRect.width, _resRect.height);
        const _stepContentGroup = _s.view.querySelector('.stepContentGroup');
        const _stepContentGroup_tt = (0, func_1.getSvgTranslate3d)(_stepContentGroup);
        const _contentGroupFieldAnchor = _s.view.querySelector('.stepContentFieldAnchor');
        const _c_view_tt = (0, func_1.getSvgTranslate3d)(_s.view);
        _contentGroupFieldAnchor.setAttribute('x', (0, func_1.rbb)(_resRectFixed.x - _stepContentGroup_tt.x - _c_view_tt.x - 16, 8).toString());
        _contentGroupFieldAnchor.setAttribute('y', (0, func_1.rbb)(_resRectFixed.y - _stepContentGroup_tt.y - _c_view_tt.y - 8, 8).toString());
        _contentGroupFieldAnchor.setAttribute('width', (0, func_1.rbb)(_resRectFixed.width + 32, 8).toString());
        _contentGroupFieldAnchor.setAttribute('height', (0, func_1.rbb)(_resRectFixed.height + 16, 8).toString());
        const _stepInput = _s.view.querySelector('.stepInput');
        _stepInput.setAttribute('cx', (0, func_1.rbb)(_resRectFixed.x - _stepContentGroup_tt.x - _c_view_tt.x - 16, 8).toString());
        _stepInput.setAttribute('cy', (0, func_1.rbb)(_resRectFixed.y - _stepContentGroup_tt.y - _c_view_tt.y + _resRectFixed.height / 2, 8).toString());
        const _stepOutput = _s.view.querySelector('.stepOutput');
        _stepOutput.setAttribute('cx', (0, func_1.rbb)(_resRectFixed.x - _stepContentGroup_tt.x - _c_view_tt.x + _resRectFixed.width + 16, 8).toString());
        _stepOutput.setAttribute('cy', (0, func_1.rbb)(_resRectFixed.y - _stepContentGroup_tt.y - _c_view_tt.y + _resRectFixed.height / 2, 8).toString());
        step.updateStepGroupLink(_s);
    };
    // store - canvas, - instance
    step.create = (x, y, uiid) => {
        function _extend(...objs) {
            return Object.assign({}, ...objs);
        }
        const _name = (0, tr_1.tr_procitm)(app_1.app.getApp().lang, 'step');
        const _s = core_1.st.create(1, _name);
        const T0 = (0, mobx_1.observable)(_s, {}, { deep: true });
        const _pres = presentation_1.presentation.create();
        const T1 = (0, mobx_1.observable)(_pres, {}, { deep: true });
        const _view = createStepView(x, y, uiid, _name);
        _view.id = _s.core.id;
        const _cd = { dragController: { allow: true } };
        const T4 = _extend(_cd, T1, { view: _view }, T0);
        store_1.store.step.add(T4);
        return T4;
    };
})(step || (exports.step = step = {}));
