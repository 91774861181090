"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ttarea = void 0;
const ttarea = (v) => {
    const ttareaCont = document.createElement('div');
    ttareaCont.classList.add('mpt_ttextareaContainer');
    const _tta = document.createElement('textarea');
    _tta.classList.add('mpt_ttextarea');
    _tta.setAttribute('placeholder', 'comment/komen/一則評論');
    _tta.setAttribute('maxlength', '162');
    v ? _tta.value = v : null;
    ttareaCont.appendChild(_tta);
    /**  */
    _tta.onkeydown = (e) => {
        if (e.ctrlKey && e.key == 'Enter') {
            _tta.blur();
        }
        if (e.key == 'Enter') {
            e.preventDefault();
        }
    };
    return ttareaCont;
};
exports.ttarea = ttarea;
