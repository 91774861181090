"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.microelement = exports.model = exports.pres = void 0;
// is is core for app
const mobx_1 = require("mobx");
const core_1 = require("../core/core");
const drag_1 = require("./drag");
const fnc = __importStar(require("../scripts/func"));
const transport = __importStar(require("../scripts/transport"));
const tr_1 = require("../core/tr");
const app_1 = require("../scripts/app");
const ml_prosp_panel_1 = require("../site_components/mpts_panel/ml_prosp_panel");
const mark_1 = require("../core/ui/mark");
// #region Pres
// ===================================================================== PRES
var pres;
(function (pres) {
    pres.create = () => {
        return {
            pres: {
                uiid: fnc.hrb(),
                xy: [0, 0],
                selected: false,
                comment: { show: false, xy: [0, 0] },
                aliasName: { show: false, value: '' },
                combined: { show: false, xy: [0, 0], state: 'free', invaider: null },
                linkRect: () => null,
                overRect: () => null,
                select: () => { },
                translate: (l) => null,
                save: () => { },
                load: () => { },
            }
        };
    };
})(pres || (exports.pres = pres = {}));
// #region Model 
// ===================================================================== MODEL
var model;
(function (model) {
    //#region Store
    model.store = (0, mobx_1.observable)({
        items: [],
        selected: [],
        add: (0, mobx_1.action)((i) => model.store.items.push(i)),
        remove: (0, mobx_1.action)((i) => model.store.items.splice(model.store.items.indexOf(i), 1)),
        getOneByUIId: (id) => model.store.items.find(e => e.pres.uiid === id),
        getOneByCoreId: (id) => model.store.items.find(e => e.core.id === id),
        select: (0, mobx_1.action)((i) => {
            if (Array.isArray(i)) {
                model.store.selected = i;
                i.forEach(e => {
                    e.pres.selected = true;
                    e.pres.select(true);
                });
            }
            else {
                model.store.unselectAll();
                model.store.selected = [i];
                i.pres.selected = true;
                i.pres.select(true);
                showPanel(i);
            }
        }),
        unselect: (0, mobx_1.action)((i) => {
            if (Array.isArray(i)) {
                model.store.selected = model.store.selected.filter(e => !i.includes(e));
                i.forEach(e => {
                    e.pres.selected = false;
                    e.pres.select(false);
                });
            }
            else {
                model.store.selected = model.store.selected.filter(e => e !== i);
                i.pres.selected = false;
                i.pres.select(false);
            }
        }),
        selectAll: () => {
            model.store.items.forEach(e => {
                e.pres.selected = true;
                e.pres.select(true);
            });
            model.store.selected = model.store.items;
        },
        unselectAll: () => {
            var _a;
            (_a = model.store.selected) === null || _a === void 0 ? void 0 : _a.forEach(e => {
                e.pres.selected = false;
                e.pres.select(false);
            });
            model.store.selected = [];
        },
        setPosition: (_uiid, p) => {
            model.store.selected[0].pres.xy = p;
        }
    });
    const isMicroelement = (i) => {
        return i.core.coreType === core_1.CoreTypes.microelement;
    };
    // #region Reaction
    (0, mobx_1.autorun)(() => {
        if (model.store.selected.length === 0)
            return;
        const selectedItem = model.store.selected[0];
        if (!selectedItem)
            return;
        if (selectedItem.core.coreType === core_1.CoreTypes.microelement) {
            document.getElementById('mpts-counter-value').textContent = core_1.ml.getResult(selectedItem).toFixed(2);
            ml_prosp_panel_1.ml_props_panel.updateFooter(selectedItem);
        }
    });
    /** reaction for combined items QTY changed */
    // COMB QTY ON CHANGE ==================================================================== COMB QTY ON CHANGE
    (0, mobx_1.reaction)(() => {
        const si = model.store.selected[0];
        if (!si || !isMicroelement(si))
            return null;
        if (!si.pres.combined.invaider)
            return null;
        if (si.pres.combined.state !== 'locked')
            return null;
        return {
            si: si,
            comb: 'combined' in si.pres.combined.invaider.core ? si.pres.combined.invaider.core.combined.items.length : 0
        };
    }, (data) => {
        if (!data)
            return;
        // console.log('comb QTY changed:', data);
        if (data.si.pres.combined.invaider.view.querySelector(`.microelement[data-uiid="${data.si.pres.uiid}"]`))
            return; // already combined
        handleCombined(data.si);
        data.si.pres.combined.invaider.pres.combined.show ? data.si.pres.combined.invaider.pres.combined.show = true : null;
        handleCombinedVisibility(data.si.pres.combined.invaider);
        markItem(data.si.pres.combined.invaider);
        commentHandler(data.si.pres.combined.invaider);
        drawCombinedCover(data.si.pres.combined.invaider);
        shakeCombined(data.si.pres.combined.invaider).then(() => {
            drawCombinedLine(data.si.pres.combined.invaider);
        });
        if (data.si.pres.combined.invaider.pres.combined.invaider) {
            drawCombinedCover(data.si.pres.combined.invaider.pres.combined.invaider);
            shakeCombined(data.si.pres.combined.invaider.pres.combined.invaider).then(() => {
                drawCombinedLine(data.si.pres.combined.invaider.pres.combined.invaider);
            });
        }
    }, {
        equals: (prev, next) => {
            return prev && next && prev.comb !== next.comb;
        },
    });
    /** reaction on combine items visibility changes */
    // COMB SHOW ON CHANGE =================================================================== COMB SHOW ON CHANGE
    (0, mobx_1.reaction)(() => {
        const si = model.store.selected[0];
        if (!si || !isMicroelement(si))
            return null;
        if (si.core.combined.items.length >= 1) {
            return {
                si: si,
                comb: si.pres.combined.show
            };
        }
    }, (data) => {
        if (!data)
            return;
        // console.log('comb VIEW changed:', data);
        handleCombinedVisibility(data.si);
        markItem(data.si);
        if (data.si.pres.combined.state === 'locked') {
            drawCombinedCover(data.si.pres.combined.invaider);
            shakeCombined(data.si.pres.combined.invaider).then(() => {
                drawCombinedLine(data.si.pres.combined.invaider);
            });
        }
    }, {
        equals: (prev, next) => {
            return prev && next && prev.comb === next.comb;
        },
    });
    /** reaction for microelement core changes */
    // CORE ON CHANGE ======================================================================== CORE ON CHANGE
    (0, mobx_1.reaction)(() => {
        const si = model.store.selected[0];
        if (!si || !isMicroelement(si))
            return null;
        return {
            si: si,
            comment: si.core.comment,
            multiplier: si.core.multiplier,
            employment: si.core.employment,
            pres: si.pres.comment.show,
        };
    }, (data) => {
        if (!data)
            return;
        // console.log('core changed', data.si);
        markItem(data.si);
        commentHandler(data.si);
        if (data.si.core.combined.items.length >= 1) {
            drawCombinedLine(data.si);
        }
        if (data.si.pres.combined.state === 'locked') {
            drawCombinedCover(data.si.pres.combined.invaider);
            shakeCombined(data.si.pres.combined.invaider);
            drawCombinedLine(data.si.pres.combined.invaider);
        }
    });
    /** on change alias name */
    // ALIAS ON CHANGE ======================================================================= ALIAS ON CHANGE
    (0, mobx_1.reaction)(() => {
        const si = model.store.selected[0];
        if (!si || !isMicroelement(si))
            return null;
        if (si.pres.aliasName.show) {
            return {
                si: si,
                comb: si.pres.aliasName.value,
                fr: 'alias view'
            };
        }
        if (si.pres.aliasName.value === '') {
            return {
                si: si,
                comb: si.pres.aliasName.value,
                fr: 'alias empty'
            };
        }
    }, (data) => {
        if (!data)
            return;
        // console.log('alias changed:', data);
        setAliasName(data.si);
        commentHandler(data.si);
        markItem(data.si);
        if (data.si.core.combined.items.length >= 1) {
            drawCombinedLine(data.si);
        }
        if (data.si.pres.combined.state === 'locked') {
            drawCombinedCover(data.si.pres.combined.invaider);
            shakeCombined(data.si.pres.combined.invaider).then(() => {
                drawCombinedLine(data.si.pres.combined.invaider);
            });
        }
    }, {
        equals: (prev, next) => {
            return prev && next && prev.comb === next.comb;
        },
    });
    // #endregion Reaction
})(model || (exports.model = model = {}));
const markItem = (i) => {
    (0, mark_1.marksGroup)(i);
};
//#region HandleCombined
// ===================================================================== HANDLECOMBINED
const handleCombined = (el) => {
    const _elemClasses = {
        combinedGroup: 'combined-group',
        combinedCover: 'combined-cover',
        combConnLine: 'combConnLine',
    };
    const _inv = el.pres.combined.invaider;
    if (!_inv)
        return;
    // console.log('handleCombined');
    // check is item already combined to invaider
    let _r = 'combined' in _inv.core ? _inv.core.combined.items.find(e => e.core.id === el.core.id) : null;
    let _r1 = _inv.view.querySelector(`.microelement[data-uiid="${el.pres.uiid}"]`);
    if (_r && _r1) {
        console.log('already combined');
        return;
    }
    // get current length of combined items
    const _curlen = 'combined' in _inv.core ? _inv.core.combined.items.length : 0;
    let _ci = _curlen == 1 ? 0 : (_curlen - 1) * 16; // it is height of combined elements
    // invaider base rect
    const _invBB = _inv.view.querySelector(`.baseRect[data-uiid="${_inv.pres.uiid}"]`);
    // create or get combined group
    let _group;
    if (_curlen === 1 && !_inv.view.querySelector(`.combined-group[data-uiid="${_inv.pres.uiid}"]`)) {
        // create new combined group
        _group = document.createElementNS(fnc.NS, 'g');
        _group.classList.add(_elemClasses.combinedGroup);
        _group.setAttribute('data-uiid', _inv.pres.uiid);
        // add group to invaider
        _inv.view.appendChild(_group);
        // add element to group
        _group.appendChild(el.view);
        // enable drag for combined group
        (0, drag_1.createDragController)(_group, { enabled: true });
        _group.addEventListener('pointerdown', (e) => {
            e.stopPropagation();
        });
        _group.addEventListener('dragMove', (e) => {
            drawCombinedLine(_inv);
        });
        // move element to position
        requestAnimationFrame(() => {
            el.view.style.transform = `translate3d(${_invBB.getBBox().width + 96}px, ${_ci}px, 0px)`;
            restoreStack(_inv);
            // ! HANDLE GROUP BEHAVIOR =========================================================================== HANDLE GROUP BEHAVIOR
            handleGroupBehavior(_group, _inv.pres.uiid);
            // ! HANDLE GROUP BEHAVIOR =========================================================================== HANDLE GROUP BEHAVIOR
        });
    }
    else {
        // get existing combined group
        _group = _inv.view.querySelector(`.${_elemClasses.combinedGroup}[data-uiid="${_inv.pres.uiid}"]`);
        const rrx = fnc.getSvgTranslate3d(_group.firstElementChild).x;
        // move element to position
        requestAnimationFrame(() => {
            el.view.style.transform = `translate3d(${rrx}px, ${_ci}px, 0px)`;
            restoreStack(_inv);
        });
    }
    // add element to group
    _group.appendChild(el.view);
};
// enable select items inside group
const handleGroupBehavior = (group, inv_uiid) => {
    // get combined cover
    const _cover = group.querySelector(`.combined-cover[data-uiid="${inv_uiid}"]`);
    // TIMERS
    let pressTimer = null;
    let delayTimer = null;
    let startTime = null;
    const DELAY_BEFORE_HOLD = 200;
    const HOLD_DURATION = 200;
    let isPressing = false;
    let _abb = _cover.getBBox();
    let _line;
    const maxWidth = () => { return _abb.width; };
    const reset = () => {
        isPressing = false;
        startTime = null;
        if (delayTimer !== null) {
            clearTimeout(delayTimer);
            delayTimer = null;
        }
        if (pressTimer !== null) {
            clearTimeout(pressTimer);
            pressTimer = null;
        }
        clearProgressLine();
    };
    const createProgressLine = () => {
        let _cx = Number(_cover.getAttribute('x'));
        let _cw = Number(_cover.getAttribute('width'));
        const _y = _abb.y;
        _line = fnc._c_l(group, _cx, _y, _cx + _cw, _y, 8, '#FFA564', [`group-progress`, `${inv_uiid}`]);
    };
    const clearProgressLine = () => {
        if (_line) {
            _line.remove();
            _line = null;
        }
    };
    const updateProgressLine = (dx) => {
        _line.setAttribute('x2', String((maxWidth() * dx) + _abb.x));
    };
    const updateProgress = () => {
        if (!isPressing || startTime === null)
            return;
        const elapsedTime = Date.now() - startTime;
        const progress = Math.min(elapsedTime / HOLD_DURATION, 1);
        if (!_line) {
            createProgressLine();
        }
        updateProgressLine(progress);
        if (progress < 1) {
            requestAnimationFrame(updateProgress);
        }
    };
    const handlePointerDown = (e) => {
        e.stopPropagation();
        isPressing = true;
        delayTimer = window.setTimeout(() => {
            startTime = Date.now();
            pressTimer = window.setTimeout(() => {
                console.log('ALARM THEY HOLD ME');
                handleElementSeletion(e);
                reset();
            }, HOLD_DURATION);
            requestAnimationFrame(updateProgress);
        }, DELAY_BEFORE_HOLD);
    };
    const handleElementSeletion = (e) => {
        const lastMouseX = e.clientX;
        const lastMouseY = e.clientY;
        const elements = document.elementsFromPoint(lastMouseX, lastMouseY);
        const _m = elements[1].closest('.microelement');
        if (!_m) {
            return;
        }
        const _etf = model.store.getOneByUIId(_m.dataset.uiid);
        model.store.select(_etf);
        //! FREE ELEMENT from GROUP ======================================================================== FREE ELEMENT from GROUP
        if (e.shiftKey) {
            if (!_etf)
                return;
            // move view to canvas
            app_1.app.getApp().ui.mpts.item.svgCanvas.appendChild(_etf.view);
            // get mouse position
            const _clpos = fnc.getMousePosition({ x: e.x, y: e.y }, app_1.app.getApp().ui.mpts.item.svgCanvas);
            const _viewBB = _etf.view.querySelector(`.baseRect[data-uiid="${_etf.pres.uiid}"]`).getBBox();
            const _cltr = fnc.getSvgTransformInfo(app_1.app.getApp().ui.mpts.item.svgCanvas);
            console.log('_cltr', _cltr);
            const _point = [_clpos.x - _viewBB.width / 2, _clpos.y - _viewBB.height / 2];
            (0, mobx_1.runInAction)(() => {
                var _a, _b;
                console.log(_etf.view.style.transform);
                if (!_etf.pres.combined.invaider)
                    return;
                if ('combined' in _etf.pres.combined.invaider.core) {
                    console.log('before', _etf.pres.combined.invaider.core.combined.items.length);
                    // move view to mouse position
                    _etf.view.style.transform = `translate3d(${_point[0]}px, ${_point[1]}px, 0px)`;
                    // remove element from invaider
                    _etf.pres.combined.invaider.core.combined.items = _etf.pres.combined.invaider.core.combined.items.filter(e => e.core.id !== _etf.core.id);
                    // init pointer up on group
                    group.dispatchEvent(new PointerEvent('pointerup', {
                        bubbles: false,
                        cancelable: true,
                        clientX: _point[0],
                        clientY: _point[1],
                        pointerId: e.pointerId,
                    }));
                    // init pointer down on element
                    _etf.view.dispatchEvent(new PointerEvent('pointerdown', {
                        bubbles: false,
                        cancelable: true,
                        clientX: e.clientX,
                        clientY: e.clientY,
                        pointerId: e.pointerId
                    }));
                    // remove group if 0 elements left
                    if (_etf.pres.combined.invaider.core.combined.items.length === 0) {
                        (_a = _etf.pres.combined.invaider.view.querySelector(`.combined-group[data-uiid="${_etf.pres.combined.invaider.pres.uiid}"]`)) === null || _a === void 0 ? void 0 : _a.remove();
                        (_b = _etf.pres.combined.invaider.view.querySelector(`.combConnLine[data-uiid="${_etf.pres.combined.invaider.pres.uiid}"]`)) === null || _b === void 0 ? void 0 : _b.remove();
                    }
                    else {
                        drawCombinedCover(_etf.pres.combined.invaider);
                        shakeCombined(_etf.pres.combined.invaider);
                        drawCombinedLine(_etf.pres.combined.invaider);
                    }
                    _etf.pres.combined.state = 'free';
                    _etf.pres.combined.invaider = null;
                }
            });
        }
    };
    group.addEventListener('pointerdown', handlePointerDown);
    group.addEventListener('pointerup', reset);
    group.addEventListener('pointercancel', reset);
    group.addEventListener('pointermove', reset);
};
// #region CmbCovLine
// ===================================================================== COMBLINE COVER
const drawCombinedCover = (_inv) => {
    const _invBB = _inv.view.querySelector(`.baseRect[data-uiid="${_inv.pres.uiid}"]`);
    // get current length of combined items
    const _curlen = 'combined' in _inv.core ? _inv.core.combined.items.length : 0;
    // get longest element 
    let _longest = 0;
    if ('combined' in _inv.core) {
        const _elmvs = _inv.core.combined.items
            .map(e => model.store.getOneByCoreId(e.core.id).view.querySelector(`.baseRect`))
            .map(e => e.getBBox().width);
        _longest = Math.max(..._elmvs);
    }
    // remove previous cover
    const _coverEl = _inv.view.querySelector(`.combined-cover[data-uiid="${_inv.pres.uiid}"]`);
    _coverEl === null || _coverEl === void 0 ? void 0 : _coverEl.remove();
    let rrx = 0;
    const _group = _inv.view.querySelector(`.combined-group[data-uiid="${_inv.pres.uiid}"]`);
    let _fch = fnc.getSvgTranslate3d(_group.firstElementChild).x;
    // console.log('_fch', _fch);
    // console.log('_inv.pres.xy', _inv.pres.combined.xy);
    if (_curlen === 1) {
        if (_inv.pres.combined.xy[0] == 0) {
            rrx = _invBB.getBBox().width + 96;
            _inv.pres.combined.xy[0] = rrx;
        }
        else {
            rrx = _inv.pres.combined.xy[0];
        }
    }
    else {
        rrx = _fch;
        _inv.pres.combined.xy[0] = rrx;
    }
    // rrx = _curlen == 1 ? _invBB.getBBox().width + 96 : _fch
    // console.log('rrx', rrx);
    // draw combined covers
    const _cover = fnc.csvg('rect', {
        x: rrx,
        y: 0,
        width: _longest,
        height: _curlen * 16,
        fill: 'rgba(255, 255, 255, 0.1)',
        // stroke: 'rgb(156, 156, 156)',
        stroke: 'rgba(27, 107, 147,0.8)',
        'stroke-width': 1,
        // 'stroke-dasharray': '2, 2',
        'stroke-dasharray': '4, 4',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
        'rx': 4,
    }, ['combined-cover']);
    _cover.setAttribute('data-uiid', _inv.pres.uiid);
    _group.appendChild(_cover);
};
// ===================================================================== DRAW LINE
const drawCombinedLine = (inv) => {
    var _a;
    if (!inv)
        return;
    // if (!inv?.pres.combined.show) return;
    (_a = inv.view.querySelector(`.combConnLine[data-uiid="${inv.pres.uiid}"]`)) === null || _a === void 0 ? void 0 : _a.remove();
    // get base rect bbox
    const _inv = inv.view.querySelector(`.baseRect[data-uiid="${inv.pres.uiid}"]`);
    const _invBB = _inv.getBBox();
    // get combined group bbox
    const _group = inv.view.querySelector(`.combined-group[data-uiid="${inv.pres.uiid}"]`);
    const _groupCov = inv.view.querySelector(`.combined-cover[data-uiid="${inv.pres.uiid}"]`);
    if (!_group || !_groupCov)
        return;
    const _groupTR = fnc.getSvgTranslate3d(_group);
    const _groupBB = _groupCov.getBBox();
    const _groupXY = [_groupTR.x + _groupBB.x, _groupTR.y + _groupBB.y];
    const _groupRect = new DOMRect(_groupXY[0], _groupXY[1], _groupBB.width, _groupBB.height);
    // line beetwen centers
    const _centersLine = [
        _invBB.x + _invBB.width / 2,
        _invBB.y + _invBB.height / 2,
        _groupXY[0] + _groupBB.width / 2,
        _groupXY[1] + _groupBB.height / 2
    ];
    const p1 = fnc.getRectWithLineIntersection(_invBB, _centersLine)[0];
    const p2 = fnc.getRectWithLineIntersection(_groupRect, _centersLine)[0];
    if (!p1 || !p2)
        return;
    // finnaly create and add line to view
    const _line = fnc.csvg('line', {
        'x1': fnc.rbb(p1.point[0], 8),
        'y1': fnc.rbb(p1.point[1], 8),
        'x2': fnc.rbb(p2.point[0], 8),
        'y2': fnc.rbb(p2.point[1], 8),
        'stroke': 'rgb(15, 58, 80)',
        'stroke-width': '1',
        'stroke-dasharray': '4, 4',
        'marker-end': 'url(#circle)',
        'marker-start': 'url(#circle)',
    }, ['combConnLine']);
    _line.setAttribute('data-uiid', inv.pres.uiid);
    inv.view.appendChild(_line);
    if (!(inv === null || inv === void 0 ? void 0 : inv.pres.combined.show)) {
        _line.style.display = 'none';
    }
};
const shakeCombined = (inv) => {
    return new Promise(resolve => {
        // requestAnimationFrame(() => {
        /**
         * Recursively checks if an element or its children have a negative x value in getBBox.
         * @param element - SVGGraphicsElement to check.
         * @returns true if an element with a negative x is found, otherwise false.
         */
        function checkElementForNegativeX(element) {
            try {
                const bbox = element.getBBox();
                if (bbox.x < 0) {
                    return true;
                }
            }
            catch (e) {
                // Some elements may not support getBBox
                console.warn('Error on BBox reciving', element, e);
            }
            // Recursively go through all children if they are SVGGraphicsElement
            for (const child of Array.from(element.children)) {
                if (child instanceof SVGGraphicsElement) {
                    if (checkElementForNegativeX(child)) {
                        return true;
                    }
                }
            }
            return false;
        }
        /**
         * Goes through the entire NodeList and checks if there is at least one element with a negative x.
         * @param elements - NodeListOf<SVGGraphicsElement> to check.
         * @returns true, if at least one element with a negative x is found, otherwise false.
         */
        function hasNegativeX(elements) {
            for (const element of elements) {
                if (checkElementForNegativeX(element)) {
                    return true;
                }
            }
            return false;
        }
        // get all microelements
        const _mls = inv.view.querySelectorAll(`.combined-group[data-uiid="${inv.pres.uiid}"] > .microelement`);
        // get all microelements rects
        const _mlsr = inv.view.querySelectorAll(`.combined-group[data-uiid="${inv.pres.uiid}"] > .microelement > .baseRect`);
        // get cover
        const _cover = inv.view.querySelector(`.combined-cover[data-uiid="${inv.pres.uiid}"]`);
        let _wiMinX = 0;
        let _wiMaxX = 0;
        _mlsr.forEach((e, i) => {
            let bb = e.getBBox();
            _wiMinX = _wiMinX == 0 ? bb.x : Math.min(_wiMinX, bb.x);
            _wiMaxX = _wiMaxX == 0 ? bb.x + bb.width : Math.max(_wiMaxX, bb.x + bb.width);
        });
        let _rr = 0;
        _mls.forEach((e, i) => {
            const match = e.style.transform.match(/translate3d\(([^,]+),\s*([^,]+),\s*([^)]+)\)/);
            if (match) {
                const x = match[1];
                const y = `${i * 16}px`;
                const z = match[3];
                e.style.transform = `translate3d(${x}, ${y}, ${z})`;
            }
            if (checkElementForNegativeX(_mlsr[i])) {
                if (_mlsr[i].getBoundingClientRect().x - _cover.getBoundingClientRect().x === 0)
                    return;
                if (_rr == 0)
                    _cover.setAttribute('x', (Number(_cover.getAttribute('x')) - 16).toString());
                _rr = 1;
            }
            if (_rr == 1)
                return;
        });
        _cover.setAttribute('width', `${_wiMaxX - _wiMinX}`);
        resolve(1);
    });
};
// ================================================================= RESTORE STACK ON INSERTED
const restoreStack = (_inv) => {
    // get elements from group
    const _gg = _inv.view.querySelector(`.combined-group[data-uiid="${_inv.pres.uiid}"]`);
    const _els = _inv.view.querySelectorAll(`.combined-group[data-uiid="${_inv.pres.uiid}"] > .microelement`);
    let _bx = fnc.getSvgTranslate3d(_els[0]).x;
    if ('combined' in _inv.core) {
        //  
        _inv.core.combined.items.forEach((e, i) => {
            let _cy = i == 0 ? 0 : (i * 16);
            let _cel = model.store.getOneByCoreId(e.core.id);
            if (_cel) {
                fnc.updateTranslate3d(_cel.view, _bx, _cy, 1);
            }
            _gg.appendChild(_cel.view);
        });
        _gg.appendChild(_gg.firstElementChild);
    }
};
// ================================================================= RESTORE STACK ON INSERTED
const handleCombinedVisibility = (el) => {
    if (el.core.coreType !== core_1.CoreTypes.microelement)
        return;
    const _g = el.view.querySelector(`.combined-group[data-uiid="${el.pres.uiid}"]`);
    const _l = el.view.querySelector(`.combConnLine[data-uiid="${el.pres.uiid}"]`);
    if (!_g || !_l)
        return;
    if (el.pres.combined.show) {
        _g.style.display = '';
        _l.style.display = '';
        return;
    }
    if (!el.pres.combined.show) {
        _g.style.display = 'none';
        _l.style.display = 'none';
        return;
    }
};
// #region Comment
// ===================================================================== COMMENT
const commentHandler = (i) => {
    var _a, _b, _d, _e;
    // comment class names
    const _comClassNames = {
        base: 'comment',
        rect: 'comment-rect',
        connLine: 'conn-line',
        sideLine: 'side-line',
    };
    // remove comment
    (_a = i.view.querySelector(`.${_comClassNames.base}[data-uiid="${i.pres.uiid}"]`)) === null || _a === void 0 ? void 0 : _a.remove();
    (_b = i.view.querySelector(`.${_comClassNames.rect}[data-uiid="${i.pres.uiid}"]`)) === null || _b === void 0 ? void 0 : _b.remove();
    (_d = i.view.querySelector(`.${_comClassNames.connLine}[data-uiid="${i.pres.uiid}"]`)) === null || _d === void 0 ? void 0 : _d.remove();
    (_e = i.view.querySelector(`.${_comClassNames.sideLine}[data-uiid="${i.pres.uiid}"]`)) === null || _e === void 0 ? void 0 : _e.remove();
    // return if comment is not shown
    if (!i.pres.comment.show)
        return;
    // group for text and outline
    const group = document.createElementNS(fnc.NS, 'g');
    group.classList.add(_comClassNames.base);
    group.setAttribute('data-uiid', i.pres.uiid);
    group.setAttribute('pointer-events', 'all');
    // anchor rect
    const _br = i.view.querySelector(`.baseRect[data-uiid="${i.pres.uiid}"]`).getBBox();
    // return 'block' from microelement comment
    let _c = fnc.splitStringByRegex(i.core.comment);
    let mx = 208;
    if (_c.blocks.length) {
        mx = Math.min(208, fnc.countStringWidth(_c.blocks[0]));
    }
    let _op = [96, 0];
    _op[0] += i.pres.comment.xy[0];
    _op[1] += i.pres.comment.xy[1];
    // visual shape for outline rect
    const groupRect = fnc.csvg('rect', {
        'x': _br.width + _op[0],
        'y': _op[1],
        'width': mx,
        'height': (_c.count * 16),
        fill: 'transparent',
        stroke: 'transparent',
        strokeWidth: '2',
    }, [`${_comClassNames.rect}`]);
    groupRect.setAttribute('data-uiid', i.pres.uiid);
    group.appendChild(groupRect);
    // add text
    const _comm = fnc.csvg('text', {
        'x': _br.width + _op[0],
        'y': 12 + _op[1],
    }, ['microelement-comment-cont', `${i.pres.uiid}`]);
    // add text lines to comment
    const lineHeight = 16;
    _c.blocks.forEach((b, i) => {
        const _t = fnc.csvg('tspan', {
            'x': _br.width + _op[0] + 4,
            'dy': i === 0 ? 0 : lineHeight,
        }, ['microelement-comment-line']);
        _t.textContent = b;
        _comm.appendChild(_t);
    });
    group.appendChild(_comm);
    i.view.appendChild(group);
    // #region Comment line
    const drawLine = () => {
        var _a, _b;
        if (!i.pres.comment.show)
            return;
        let _brBBox = i.view.querySelector(`.baseRect[data-uiid="${i.pres.uiid}"]`).getBBox();
        (_a = i.view.querySelector(`.${_comClassNames.connLine}[data-uiid="${i.pres.uiid}"]`)) === null || _a === void 0 ? void 0 : _a.remove();
        (_b = i.view.querySelector(`.${_comClassNames.sideLine}[data-uiid="${i.pres.uiid}"]`)) === null || _b === void 0 ? void 0 : _b.remove();
        const _grCPtranslate = fnc.getSvgTranslate3d(group);
        const _gBBox = group.getBBox();
        const _ctRLBB = new DOMRect(_gBBox.x + _grCPtranslate.x, _gBBox.y + _grCPtranslate.y, _gBBox.width, _gBBox.height);
        const cl = [
            _brBBox.x + _brBBox.width / 2,
            _brBBox.y + _brBBox.height / 2,
            _gBBox.x + _gBBox.width / 2 + _grCPtranslate.x,
            _gBBox.y + _gBBox.height / 2 + _grCPtranslate.y,
        ];
        let p1 = fnc.getRectWithLineIntersection(_brBBox, cl)[0];
        let p2 = fnc.getRectWithLineIntersection(_ctRLBB, cl)[0];
        if (!p1 || !p2)
            return;
        const _line = fnc.csvg('line', {
            'x1': fnc.rbb(p1.point[0], 8),
            'y1': fnc.rbb(p1.point[1], 8),
            'x2': fnc.rbb(p2.point[0], 8),
            'y2': fnc.rbb(p2.point[1], 8),
            'stroke': '#03A7FF',
            // 'stroke': '#006039',
            'stroke-width': '1',
            'stroke-dasharray': '5, 5',
            'marker-end': 'url(#circle)',
            'marker-start': 'url(#circle)',
        }, [`${_comClassNames.connLine}`]);
        _line.setAttribute('data-uiid', i.pres.uiid);
        i.view.appendChild(_line);
        // line for side
        let _xy = { from: [0, 0], to: [0, 0] };
        switch (p2.side) {
            case 'left':
                _xy.from = [_ctRLBB.x, _ctRLBB.y];
                _xy.to = [_ctRLBB.x, _ctRLBB.y + _ctRLBB.height];
                break;
            case 'right':
                _xy.from = [_ctRLBB.x + _ctRLBB.width, _ctRLBB.y];
                _xy.to = [_ctRLBB.x + _ctRLBB.width, _ctRLBB.y + _ctRLBB.height];
                break;
            case 'top':
                _xy.from = [_ctRLBB.x, _ctRLBB.y];
                _xy.to = [_ctRLBB.x + _ctRLBB.width, _ctRLBB.y];
                break;
            case 'bottom':
                _xy.from = [_ctRLBB.x, _ctRLBB.y + _ctRLBB.height];
                _xy.to = [_ctRLBB.x + _ctRLBB.width, _ctRLBB.y + _ctRLBB.height];
                break;
            default:
                break;
        }
        let optimal = [[_xy.from[0], _xy.from[1]], [_xy.to[0], _xy.to[1]]];
        const _line1 = fnc.csvg('line', {
            'x1': optimal[0][0],
            'y1': optimal[0][1],
            'x2': optimal[1][0],
            'y2': optimal[1][1],
            // 'stroke': 'rgb(255, 0, 0)',
            'stroke': 'rgb(173, 181, 189)',
            // 'stroke': 'rgb(147, 197, 114)',
            'stroke-width': '4',
            'pointer-events': 'none',
        }, [`${_comClassNames.sideLine}`,]);
        _line1.setAttribute('data-uiid', i.pres.uiid);
        i.view.appendChild(_line1);
    };
    drawLine();
    (0, drag_1.createDragController)(group, { enabled: true });
    group.addEventListener('pointerdown', (e) => {
        e.stopPropagation();
    });
    // draw line and highlight side on drag
    group.addEventListener('dragMove', (e) => {
        drawLine();
    });
    group.addEventListener('dragEnd', (e) => {
        console.log(e.detail.finalX, e.detail.finalY);
        i.pres.comment.xy = [i.pres.comment.xy[0] + e.detail.finalX, i.pres.comment.xy[1] + e.detail.finalY];
    });
};
// #region ShowPanel
const showPanel = (i) => {
    let elp = null;
    i.core.coreType == core_1.CoreTypes.microelement ? elp = { m: i } : null;
    if (elp)
        ml_prosp_panel_1.ml_props_panel.create('mpts-cont', elp);
};
// #region GET COORD
function getElementRectRelativeToGroup(element, group) {
    const elemRect = element.getBoundingClientRect();
    const groupStyle = window.getComputedStyle(group);
    const transform = groupStyle.transform;
    const originStr = groupStyle.transformOrigin;
    const [originXStr, originYStr] = originStr.split(' ');
    const originX = parseFloat(originXStr);
    const originY = parseFloat(originYStr);
    const matrix = new DOMMatrix(transform);
    const scale = matrix.a;
    const tX = matrix.e - (1 - scale) * originX;
    const tY = matrix.f - (1 - scale) * originY;
    const localTopLeft = {
        x: (elemRect.left - tX - 24) / scale,
        y: (elemRect.top - tY - 40) / scale
    };
    const localBottomRight = {
        x: (elemRect.right - tX) / scale,
        y: (elemRect.bottom - tY) / scale
    };
    return new DOMRect(localTopLeft.x, localTopLeft.y, localBottomRight.x - localTopLeft.x, localBottomRight.y - localTopLeft.y);
}
// #region ALIAS
const setAliasName = (m) => {
    const _r = m.view.querySelector(`.mainRect[data-uiid ="${m.pres.uiid}"]`);
    const _t = m.view.querySelector(`.baseText[data-uiid ="${m.pres.uiid}"]`);
    if (!m.pres.aliasName.show) {
        let r_n = (0, tr_1.tr_ml)(app_1.app.getApp().lang, m).group[1];
        const textWidth = fnc.countStringWidth(r_n);
        _r.setAttribute('width', `${textWidth}`);
        _t.textContent = r_n;
        return;
    }
    // get text width
    const textWidth = fnc.countStringWidth(m.pres.aliasName.value);
    // change widht for .mainRect
    _r.setAttribute('width', `${textWidth}`);
    // get set text
    _t.textContent = m.pres.aliasName.value;
};
// #region Microelement
// ===================================================================== MICROELEMENT
var microelement;
(function (microelement) {
    microelement.create = (m_1, ...args_1) => __awaiter(this, [m_1, ...args_1], void 0, function* (m, p = [0, 0]) {
        function _extend(...objs) {
            return Object.assign({}, ...objs);
        }
        // create core microelement
        const _ml = core_1.ml.create(m.g, m.ex);
        // get id from server
        _ml.core.id = yield transport.getId();
        const T0 = (0, mobx_1.observable)(_ml, {}, { deep: true });
        // create presentation
        const _pres = pres.create();
        _pres.pres.xy = [...p];
        _pres.pres.select = (s) => setSelect(T4, s);
        _pres.pres.save = () => save(T4);
        _pres.pres.load = () => load();
        const T1 = (0, mobx_1.observable)(_pres, {}, { deep: true });
        // create view and make it draggable
        const _view = createView(_ml, p, _pres.pres.uiid);
        const _dc = (0, drag_1.createDragController)(_view);
        const T4 = _extend({ view: _view }, { dc: _dc }, T0, T1);
        pointerEventHandler(T4);
        handleCombined(T4);
        model.store.add(T4);
        return T4;
    });
    const pointerEventHandler = (_ml) => {
        // select on pointerdown
        _ml.view.addEventListener('pointerdown', (e) => {
            // bring to front
            const pointerId = e.pointerId;
            requestAnimationFrame(() => {
                var _a;
                (_a = _ml.view.parentElement) === null || _a === void 0 ? void 0 : _a.appendChild(_ml.view);
                if (pointerId !== undefined) {
                    _ml.view.setPointerCapture(pointerId);
                }
            });
            // select microelement
            model.store.select(_ml);
        });
        // delete on backspace
        _ml.view.addEventListener('keydown', (e) => {
            if (e.key === 'Backspace') {
                model.store.remove(_ml);
                _ml.view.remove();
                _ml = null;
            }
        });
    };
    const createView = (_ml, p, uiid) => {
        // create microelement view as group
        const _el = document.createElementNS(fnc.NS, 'g');
        _el.classList.add('microelement');
        _el.setAttribute('data-uiid', uiid);
        // create microelement base rect as group
        const _baseRect = document.createElementNS(fnc.NS, 'g');
        _baseRect.classList.add('baseRect');
        _baseRect.setAttribute('data-uiid', uiid);
        // get microelement name length
        const n = (0, tr_1.tr_ml)(app_1.app.getApp().lang, _ml).group[1];
        const _nl = n ? fnc.countStringWidth(n) : 16;
        // create microelement main rect
        const _mainRect = fnc.csvg('rect', {
            x: 0,
            y: 0,
            width: _nl,
            height: 16,
            fill: 'white',
            stroke: 'rgb(172, 172, 172)',
            'stroke-width': 1,
            'rx': 4,
            'tabindex': 0,
        }, ['mainRect']);
        _mainRect.setAttribute('data-uiid', uiid);
        _baseRect.appendChild(_mainRect);
        _el.appendChild(_baseRect);
        // create microelement name text
        const _nameText = fnc.csvg('text', {
            x: 4,
            y: 12,
            fill: 'black',
        }, ['baseText']);
        _nameText.setAttribute('data-uiid', uiid);
        _nameText.textContent = n;
        _el.appendChild(_nameText);
        // move element to position
        if (p) {
            _el.style.transform = `translate3d(${p[0]}px, ${p[1]}px, 0)`;
        }
        return _el;
    };
    const setSelect = (m, s) => {
        const _rect = m.view.querySelector('.mainRect');
        const _text = m.view.querySelector('.baseText');
        if (s) {
            _rect.setAttribute('stroke', 'rgb(0, 0, 0)');
            _rect.setAttribute('fill', 'rgb(3, 167, 255)');
            _text.setAttribute('fill', 'white');
        }
        else {
            _rect.setAttribute('stroke', 'rgb(172, 172, 172)');
            _rect.setAttribute('fill', 'white');
            _text.setAttribute('fill', 'black');
        }
    };
    // #region Combined
    const handleCombined = (m) => {
        // get all microelements
        let _cbbArr = [];
        // fill _cbbArr on drag start
        m.view.addEventListener('dragStart', (e) => {
            const drbs = (_m) => {
                let _mv = _m.view.querySelector(`.baseRect[data-uiid="${_m.pres.uiid}"]`);
                let _r = {
                    x: 0,
                    y: 0,
                    width: _mv.getBBox().width,
                };
                if (_m.pres.combined.state === 'locked') {
                    const _locked = getElementRectRelativeToGroup(_mv, app_1.app.getApp().ui.mpts.item.svgCanvas);
                    _r.x = _locked.x;
                    _r.y = _locked.y;
                }
                else {
                    const _locked1 = getElementRectRelativeToGroup(_mv, app_1.app.getApp().ui.mpts.item.svgCanvas);
                    _r.x = _locked1.x;
                    _r.y = _locked1.y;
                    //! ================================
                    //?
                    // _r.x = _m.pres.xy[0]
                    // _r.y = _m.pres.xy[1]
                }
                return _r;
            };
            model.store.items
                .filter(e => e.pres.uiid != m.pres.uiid && e.core.coreType === core_1.CoreTypes.microelement)
                .map(e => {
                _cbbArr.push({
                    el: e,
                    cbb: drbs(e),
                });
            });
        });
        // find nearest element on drag
        m.view.addEventListener('dragMove', (e) => {
            // m.view.addEventListener('pointermove', () => {
            document.querySelectorAll('.mpts_temp_circle').forEach(e => e.remove());
            document.querySelectorAll('.mpts_temp_line').forEach(e => e.remove());
            let _w = m.view.querySelector('.baseRect');
            let _wd = getElementRectRelativeToGroup(_w, app_1.app.getApp().ui.mpts.item.svgCanvas);
            // #region //! FIND COMBINED =============================================================== FIND COMBINED
            let _elfc = _cbbArr.find((el) => {
                return Math.abs((el.cbb.y + 8) - (_wd.y + 8)) <= 16 &&
                    Math.abs(el.cbb.x + el.cbb.width - _wd.x) < 16;
            });
            if (_elfc) {
                (0, mobx_1.runInAction)(() => {
                    m.pres.combined.state = 'captured';
                    m.pres.combined.invaider = _elfc.el;
                });
                fnc._c_c(app_1.app.getApp().ui.mpts.item.svgCanvas, _elfc.cbb.x + _elfc.cbb.width, _elfc.cbb.y + 8, 16, 'rgba(210, 86, 86, 0.9)');
                return;
            }
            else {
                (0, mobx_1.runInAction)(() => {
                    m.pres.combined.state = 'free';
                    m.pres.combined.invaider = null;
                });
            }
            // #endregion //! FIND COMBINED =============================================================== FIND COMBINED
            // #region //! FIND INSERTED =============================================================== FIND INSERTED
            // elements for insertion
            let _elfi = _cbbArr.find((el) => {
                if (el.el.pres.combined.state === 'free')
                    return false;
                return Math.abs((el.cbb.y + 8) - (_wd.y + 8)) <= 16 &&
                    Math.abs(el.cbb.x + el.cbb.width / 2 - _wd.x) < 16;
            });
            if (_elfi) {
                (0, mobx_1.runInAction)(() => {
                    m.pres.combined.state = 'autoembed';
                    m.pres.combined.invaider = _elfi.el.pres.combined.invaider;
                    _aiuiid = _elfi.el.pres.uiid;
                });
                fnc._c_l(app_1.app.getApp().ui.mpts.item.svgCanvas, _elfi.cbb.x, _elfi.cbb.y, _elfi.cbb.x + _elfi.cbb.width, _elfi.cbb.y, 8, 'rgba(54, 159, 117, 0.9)');
            }
            else {
                _aiuiid = '';
                (0, mobx_1.runInAction)(() => {
                    m.pres.combined.state = 'free';
                    m.pres.combined.invaider = null;
                });
            }
            // #endregion //! FIND INSERTED =============================================================== FIND INSERTED
        });
        let _aiuiid = '';
        // on drag end 
        m.view.addEventListener('dragEnd', (e) => {
            document.querySelectorAll('.mpts_temp_circle').forEach(e => e.remove());
            document.querySelectorAll('.mpts_temp_line').forEach(e => e.remove());
            _cbbArr = [];
            // if no invaider
            if (m.pres.combined.state === 'free' || !m.pres.combined.invaider)
                return;
            (0, mobx_1.runInAction)(() => {
                const _inv = m.pres.combined.invaider;
                if ('combined' in _inv.core) {
                    if (m.pres.combined.state === 'captured') {
                        _inv.core.combined.add(_inv.core.combined, m);
                    }
                    if (m.pres.combined.state === 'autoembed') {
                        let _r = model.store.getOneByUIId(_aiuiid);
                        let _ri = _inv.core.combined.items.findIndex(e => e.core.id === _r.core.id);
                        _inv.core.combined.insert(_inv.core.combined, m, _ri);
                    }
                }
                _aiuiid = '';
                m.pres.combined.state = 'locked';
                _inv.pres.combined.show = true;
            });
            _aiuiid = '';
        });
    };
    const translate = () => { };
    const getLinkRect = () => { };
    const getOverRect = () => { };
    const save = (_m) => {
        const saveOne = (m) => {
            return [
                m.core.id,
                m.pres.xy[0],
                m.pres.xy[1],
                m.core.group,
                m.core.extension,
                m.core.multiplier,
                m.core.
                    qualityFactors.
                    values.
                    map(el => { return [el.kind, el.value]; }),
                m.core.
                    numericalFactors.
                    values.
                    map(el => { return [el.kind, el.value]; }),
                m.core.employment,
                [
                    m.core.tags.values[0].value,
                    m.core.tags.values[1].value
                ],
                m.core.comment,
                []
            ];
        };
        let _saveJSON = JSON.stringify(saveOne(_m));
        let r = performance.now();
        console.log(JSON.stringify(saveOne(_m)), '---', performance.now() - r);
        const blob = new Blob([JSON.stringify(_saveJSON)], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${Date.now()}.mptm`;
        a.click();
    };
    const load = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.mptm';
        // НЕ ставим display: none!
        input.style.opacity = '0';
        input.style.position = 'fixed';
        input.style.top = '0';
        input.style.left = '0';
        input.style.width = '100%';
        input.style.height = '100%';
        input.style.zIndex = '9999'; // чтобы быть поверх
        document.body.appendChild(input);
        input.addEventListener('change', () => {
            var _a;
            const file = (_a = input.files) === null || _a === void 0 ? void 0 : _a[0];
            if (file) {
                file.text().then((r) => {
                    const rd = JSON.parse(r);
                    console.log(rd);
                });
            }
            document.body.removeChild(input);
        });
        input.click();
    };
})(microelement || (exports.microelement = microelement = {}));
