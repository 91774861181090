"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.m_sliders = void 0;
const mobx_1 = require("mobx");
const func_1 = require("../../../assest/func");
const app_1 = require("../../../scripts/app");
const tr_1 = require("../../tr");
const tslider_1 = require(".././baseitems/tslider");
const m_sliders = (m) => {
    // create default panel
    const cont = (0, func_1.bh)({ tag: 'div' });
    if (m.core.numericalFactors.values.length <= 0) {
        // empty cont for no quality factors
        return cont;
    }
    else {
        // translate microelement
        const _tr = (0, tr_1.tr_ml)(app_1.app.getApp().lang, m);
        m.core.numericalFactors.values.forEach((nf, i) => {
            // /** types of measured factors */
            // export enum NumericalFactor {
            //     movingDistance,
            //     transitionDistance,
            //     objectMass,
            //     largestSideSize,
            //     angelOfRotation,
            //     penetrationLength,
            //     threadDiameter,
            //     appliedForce,
            // }
            // /** correspondence of the numerical factors and the unit of measurement
            //   [NumericalFactor, Unit] values from corresp. enums */
            // const nfunitdictionary = [
            //     [0, 0],
            //     [1, 1],
            //     [2, 2],
            //     [3, 0],
            //     [4, 3],
            //     [5, 0],
            //     [6, 0],
            //     [7, 4],
            // ]
            const _step_dic = [
                [0, 1],
                [1, 1],
                [2, 0.00],
                [3, 1],
                [4, 1],
                [5, 1],
                [6, 1],
                [7, 1],
            ];
            const _step = _step_dic.find(el => el[0] === nf.kind)[1];
            const _slider = (0, tslider_1.tslider)({ min: nf.min.toString(), max: nf.max.toString(), value: nf.value.toString(), step: _step.toString(), text: _tr.nf[1][i][1] + ', ' + _tr.nf[1][i][4][1] });
            _slider.view.addEventListener('input', (e) => {
                (0, mobx_1.runInAction)(() => {
                    (0, mobx_1.runInAction)(() => {
                        nf.value = Number(e.target.value);
                    });
                });
            });
            cont.appendChild(_slider.view);
        });
    }
    return cont;
};
exports.m_sliders = m_sliders;
