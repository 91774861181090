"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mpts_panel = void 0;
const func_1 = require("../../../assest/func");
var mpts_panel;
(function (mpts_panel) {
    //#region TYPES
    // items type
    let PanelItemsType;
    (function (PanelItemsType) {
        PanelItemsType[PanelItemsType["simple"] = 0] = "simple";
        PanelItemsType[PanelItemsType["group"] = 1] = "group";
        PanelItemsType[PanelItemsType["separator"] = 2] = "separator";
    })(PanelItemsType = mpts_panel.PanelItemsType || (mpts_panel.PanelItemsType = {}));
    //#region DEMO STRUCTURE
    // demo structure
    const demo_attr = [
        [
            'Microelements', [24, 120], undefined, 'mpts-panel-demo'
        ],
        [
            // [2, '', []],
            [0, 'Square', []],
            [1, 'Rectangle',
                [
                    [0, 'red item', []],
                    [2, '', []],
                    [1, 'blue item', [
                            [0, 'therd leval', []],
                            [2, '', []],
                            [0, 'therd leval1', []],
                            [1, 'therd leval2', [
                                    [0, 'fort leval1', []],
                                    [2, '', []],
                                    [0, 'fort leval2', []],
                                    [0, 'fort leval3', []],
                                    [0, 'fort leval4', []],
                                    [2, '', []],
                                    [0, 'fort leval5', []],
                                ]],
                            [0, 'therd leval3', []],
                        ]],
                    [0, 'gray item', []],
                ]],
            [1, 'Red colored line', []],
            [1, 'Curved line', []],
            [0, 'Oval in blue color', []],
            [2, '', []],
            [0, 'Circle', []],
            [0, 'Triangle', []],
            [1, 'Wonder ShApe',
                [
                    [0, 'therd leval', []],
                    [2, '', []],
                    [0, 'therd leval1', []],
                    [1, 'therd leval2', [
                            [0, 'fort leval1', []],
                            [2, '', []],
                            [0, 'fort leval2', []],
                            [0, 'fort leval3', []],
                            [0, 'fort leval4', []],
                            [2, '', []],
                            [0, 'fort leval5', []],
                        ]]
                ]],
            [0, 'Triple deck yellow', []],
            [2, '', []],
            [0, 'Other', []],
            [1, 'Group', []],
        ]
    ];
    //#region HELPERS FUNCTIONS
    // single element processing
    const getOne = (_c1) => {
        var _a;
        let _td = { tag: 'template' };
        switch (_c1[0]) {
            case 2:
                _td = { tag: 'div', classList: ['mpts-panel-h-separator'] };
                break;
            case 0:
                _td = {
                    tag: 'div',
                    classList: ['mpts-panel-item'],
                    textContent: _c1[1],
                    data: _c1[3] ? _c1[3] : undefined
                };
                break;
            case 1:
                _td = {
                    tag: 'div',
                    classList: ['mpts-panel-item', 'mpts-panel-group-item'],
                    textContent: _c1[1],
                    children: [
                        { tag: 'span', textContent: '>' },
                    ],
                    data: _c1[3] ? _c1[3] : undefined
                };
                let _tdc = [];
                if (_c1[2].length > 0) {
                    _c1[2].forEach(el => _tdc.push(getOne(el)));
                    (_a = _td.children) === null || _a === void 0 ? void 0 : _a.push({
                        tag: 'div',
                        classList: ['mpts-popup-panel'],
                        children: _tdc.length > 0 ? [..._tdc] : null,
                    });
                }
                else {
                    break;
                }
                break;
        }
        return _td;
    };
    // structure processing
    const prepareChildren = (_val) => {
        if (_val.length === 0)
            return [];
        let _ca = [];
        for (let i = 0; i < _val.length; i++) {
            const element = _val[i];
            _ca.push(getOne(element));
        }
        return _ca;
    };
    // make panel draggable
    const makeDrgable = (el, pickArea) => {
        let isDragging = false;
        let offsetX = 0;
        let offsetY = 0;
        pickArea.addEventListener('pointerdown', (e) => {
            isDragging = true;
            const parentRect = el.offsetParent ? el.offsetParent.getBoundingClientRect() : { left: 0, top: 0 };
            offsetX = e.clientX - el.getBoundingClientRect().left + parentRect.left;
            offsetY = e.clientY - el.getBoundingClientRect().top + parentRect.top;
            pickArea.setPointerCapture(e.pointerId);
        });
        pickArea.addEventListener('pointermove', (e) => {
            if (!isDragging)
                return;
            const left = e.clientX - offsetX;
            const top = e.clientY - offsetY;
            el.style.left = `${left}px`;
            el.style.top = `${top}px`;
        });
        pickArea.addEventListener('pointerup', (e) => {
            if (isDragging) {
                isDragging = false;
            }
            pickArea.releasePointerCapture(e.pointerId);
        });
    };
    /**
     * creating a vertical panel with a structure of elements
     * @param parID parrent id
     * @param attr attributes describing the starting state
     */
    mpts_panel.create = (parID = 'ui-layer', attr = demo_attr) => {
        if (!attr[0][2]) {
            attr[0][2] = [0, 0, 0, 0];
        }
        // create top-level container as DocumentFragment
        const _panel = document.createDocumentFragment();
        const _id = (0, func_1.ID)();
        // processing structure
        _panel.appendChild((0, func_1.bh)({
            tag: "div",
            classList: ['mpts-panel'],
            attributes: {
                'id': `${_id}`,
                'style': `left:${attr[0][1][0]}px; top:${attr[0][1][1]}px; padding: ${attr[0][2][0] ? attr[0][2][0] : 0}px ${attr[0][2][1] ? attr[0][2][1] : 0}px ${attr[0][2][2] ? attr[0][2][2] : 0}px ${attr[0][2][3] ? attr[0][2][3] : 0}px;`
            },
            children: [
                // header
                {
                    tag: 'div',
                    classList: ['mpts-panel-header'],
                    children: [
                        { tag: 'span', textContent: attr[0][0].at(0) },
                        { tag: 'span', textContent: attr[0][0].slice(1), classList: ['span-norm-color'] }
                    ]
                },
                // content
                {
                    tag: 'div',
                    classList: ['mpts-panel-content'],
                    children: [...prepareChildren(attr[1])]
                },
                // footer
                { tag: 'div', classList: ['mpts-panel-footer'] },
            ]
        }));
        // append panell to parent
        const root = document.getElementById(parID);
        if (root) {
            root.appendChild(_panel);
        }
        // get created elements to make panell draggable
        const panel = document.getElementById(_id);
        const header = panel.querySelector('.mpts-panel-header');
        makeDrgable(panel, header);
        return panel;
    };
})(mpts_panel || (exports.mpts_panel = mpts_panel = {}));
