"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ml_items_panel = void 0;
const core_1 = require("../../core/core");
const tr = __importStar(require("../../core/tr"));
const mpts_panel_1 = require("./panel_core/mpts_panel");
var ml_items_panel;
(function (ml_items_panel) {
    // separators line indexes
    const sln = [9, 14, 17, 19, 21];
    /**
     * create panel for microelements by parent ID and Language
     */
    ml_items_panel.create = (parent = 'ui-layer', position = [32, 40], l = tr.lang.rus) => {
        // group - exension 
        let t = core_1.ml.mldic.map((el) => { return [el[0], el[1]]; });
        // unique group 
        let tg = [...new Set(t.map(el => { return el[0]; }))];
        // group extension list
        const gex = (g) => {
            return t
                .filter(el => el[0] == g)
                .map(el => el[1]);
        };
        // names for groups and extensions
        const gn = (g) => {
            return tr.
                getGroupName(l, g);
        };
        const xn = (e) => {
            return tr
                .getExtName(l, e);
        };
        // output type
        let res = [];
        tg.forEach((el, i) => {
            // find extensions for group
            let ge = [];
            gex(el).forEach(el1 => {
                ge.push([0, xn(el1), [], `M_G${el}_E${el1}`]);
            });
            let em = ge.length === 1 ? 'N' : 'Y';
            // add group to result
            res.push([
                (ge.length == 1 || 0 ? 0 : 1),
                gn(el),
                ge,
                `M_G${el}_${em}`
            ]);
            // add separator
            if (sln.includes(i)) {
                res.push([2, '', []]);
            }
        });
        let _pt = [
            [
                tr.tr_panel(l, 'microelements'),
                position,
                [4, 4, 6, 4]
            ],
            [...res]
        ];
        return mpts_panel_1.mpts_panel.create(parent, _pt);
    };
})(ml_items_panel || (exports.ml_items_panel = ml_items_panel = {}));
