"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.showComment = exports.m_tarea = void 0;
const func_1 = require("../../../assest/func");
const mobx_1 = require("mobx");
const app_1 = require("../../../scripts/app");
const tr_1 = require("../../tr");
const textarea_1 = require(".././baseitems/textarea");
const tcheckbox_1 = require(".././baseitems/tcheckbox");
const func_2 = require("../../../scripts/func");
const core_1 = require("../../core");
const m_tarea = (m) => {
    // create default panel
    const cont = (0, func_1.bh)({ tag: 'div' });
    // translate microelement
    let _tr;
    if (m.core.coreType == core_1.CoreTypes.microelement) {
        _tr = (0, tr_1.tr_ml)(app_1.app.getApp().lang, m);
    }
    if (m.core.coreType == core_1.CoreTypes.union) {
        _tr = (0, tr_1.tr_u)(app_1.app.getApp().lang, m);
    }
    // get current comment
    const _tc = m.core.comment == '' ? '' : m.core.comment;
    // create textarea
    const _ta = (0, textarea_1.ttarea)(_tc);
    let _a = _ta.querySelector('.mpt_ttextarea');
    let _v = '';
    // store comment value
    _a.oninput = (e) => {
        _v = _a.value;
        (0, mobx_1.runInAction)(() => {
            m.core.comment = _v;
        });
    };
    // export type tchVT = [text: string, noval: string, yesval: string, val: boolean]
    let _sc = (0, tcheckbox_1.tcheckbox)([_tr.comment[1], _tr.comment[1], _tr.comment[1], m.pres.comment.show]);
    // fixed show comment
    _sc.switch.addEventListener('change', () => {
        (0, mobx_1.runInAction)(() => {
            m.pres.comment.show = _sc.switch.checked;
        });
    });
    cont.appendChild(_ta);
    cont.appendChild(_sc.view);
    return cont;
};
exports.m_tarea = m_tarea;
const showComment = (m) => {
    var _b, _d;
    // remove microelement comment
    m.view.querySelectorAll(`.microelement-comment.${m.pres.uiid}`).forEach((c) => {
        c.remove();
    });
    (_b = m.view.querySelector(`.microelement-conn-line.${m.pres.uiid}`)) === null || _b === void 0 ? void 0 : _b.remove();
    // m.view.querySelector(`.microelement-conn-circle.${m.pres.uiid}`)?.remove()
    (_d = m.view.querySelector(`.microelement-comm-sideline.${m.pres.uiid}`)) === null || _d === void 0 ? void 0 : _d.remove();
    if (!m.pres.comment.show)
        return;
    // return 'block' from microelement comment
    let _c = (0, func_2.splitStringByRegex)(m.core.comment);
    // group for text and outline
    const group = document.createElementNS(func_2.NS, 'g');
    group.classList.add('microelement-comment');
    group.classList.add(`${m.pres.uiid}`);
    // anchor for connection line
    const _br = m.view.querySelector(`.baseRect.${m.pres.uiid}`);
    const _brBBox = _br.getBBox();
    // anchor element for comment placement
    let _br1;
    if (m.core.coreType == core_1.CoreTypes.microelement) {
        _br1 = m.view.querySelector(`.mainRect.${m.pres.uiid}`);
    }
    if (m.core.coreType == core_1.CoreTypes.union) {
        _br1 = m.view.querySelector(`.baseRect.${m.pres.uiid}`);
    }
    const _brBBox1 = _br1.getBBox();
    let mx = 208;
    if (_c.blocks.length) {
        mx = Math.min(208, (0, func_2.countStringWidth)(_c.blocks[0]));
    }
    // visual shape for outline rect
    const groupRect = (0, func_2.csvg)('rect', {
        'x': _brBBox1.x + _brBBox1.width + 96,
        'y': _brBBox1.y - (_c.count * 16) / 2 + 8,
        'width': mx,
        'height': (_c.count * 16),
        // 'fill': 'white',
        // 'stroke': 'gray',
        // 'stroke-width': '2',
        fill: 'transparent',
        stroke: 'transparent',
        strokeWidth: '2',
    }, ['microelement-comment-rect', `${m.pres.uiid}`]);
    group.appendChild(groupRect);
    // add text
    const _comm = (0, func_2.csvg)('text', {
        'x': (0, func_2.rbb)(_brBBox1.x + _brBBox1.width, 8) + 19 + 96,
        'y': (0, func_2.rbb)(_brBBox1.y - (_c.count * 16) / 2, 8) + 20,
        // color: 'black',
    }, ['microelement-comment-cont', `${m.pres.uiid}`]);
    // add text lines to comment
    const lineHeight = 16;
    _c.blocks.forEach((b, i) => {
        const _t = (0, func_2.csvg)('tspan', {
            'x': _brBBox1.x + _brBBox1.width + 3 + 96,
            'dy': i === 0 ? 0 : lineHeight,
        }, ['microelement-comment-line', `${m.pres.uiid}`]);
        _t.textContent = b;
        _comm.appendChild(_t);
    });
    group.appendChild(_comm);
    m.view.appendChild(group);
    // enable drag for comment
    (0, func_2.enableDrag)(group);
    group.addEventListener('pointerdown', (e) => {
        e.stopPropagation();
    });
    group.style.transform = `translate(${m.pres.comment.xy[0]}px, ${m.pres.comment.xy[1]}px)`;
    // create connection line between comment and microelement
    const drawLine = () => {
        var _b, _d, _e;
        (_b = m.view.querySelector(`.microelement-conn-line.${m.pres.uiid}`)) === null || _b === void 0 ? void 0 : _b.remove();
        (_d = m.view.querySelector(`.microelement-conn-circle.${m.pres.uiid}`)) === null || _d === void 0 ? void 0 : _d.remove();
        (_e = m.view.querySelector(`.microelement-comm-sideline.${m.pres.uiid}`)) === null || _e === void 0 ? void 0 : _e.remove();
        if (!m.pres.comment.show)
            return;
        const _grCPtranslate = (0, func_2.getSvgTranslate3d)(group);
        const _gBBox = group.getBBox();
        const _ctRLBB = new DOMRect(_gBBox.x + _grCPtranslate.x, _gBBox.y + _grCPtranslate.y, _gBBox.width, _gBBox.height);
        const cl = [
            _brBBox.x + _brBBox.width / 2,
            _brBBox.y + _brBBox.height / 2,
            _gBBox.x + _gBBox.width / 2 + _grCPtranslate.x,
            _gBBox.y + _gBBox.height / 2 + _grCPtranslate.y,
        ];
        let p1 = (0, func_2.getRectWithLineIntersection)(_brBBox, cl)[0];
        let p2 = (0, func_2.getRectWithLineIntersection)(_ctRLBB, cl)[0];
        if (!p1 || !p2)
            return;
        const _line = (0, func_2.csvg)('line', {
            'x1': (0, func_2.rbb)(p1.point[0], 8),
            'y1': (0, func_2.rbb)(p1.point[1], 8),
            'x2': (0, func_2.rbb)(p2.point[0], 8),
            'y2': (0, func_2.rbb)(p2.point[1], 8),
            'stroke': '#03A7FF',
            // 'stroke': '#006039',
            'stroke-width': '1',
            'stroke-dasharray': '5, 5',
            'marker-end': 'url(#circle)',
            'marker-start': 'url(#circle)',
        }, [`microelement-conn-line`, `${m.pres.uiid}`]);
        m.view.appendChild(_line);
        // line for side
        let _xy = { from: [0, 0], to: [0, 0] };
        switch (p2.side) {
            case 'left':
                _xy.from = [_ctRLBB.x, _ctRLBB.y];
                _xy.to = [_ctRLBB.x, _ctRLBB.y + _ctRLBB.height];
                break;
            case 'right':
                _xy.from = [_ctRLBB.x + _ctRLBB.width, _ctRLBB.y];
                _xy.to = [_ctRLBB.x + _ctRLBB.width, _ctRLBB.y + _ctRLBB.height];
                break;
            case 'top':
                _xy.from = [_ctRLBB.x, _ctRLBB.y];
                _xy.to = [_ctRLBB.x + _ctRLBB.width, _ctRLBB.y];
                break;
            case 'bottom':
                _xy.from = [_ctRLBB.x, _ctRLBB.y + _ctRLBB.height];
                _xy.to = [_ctRLBB.x + _ctRLBB.width, _ctRLBB.y + _ctRLBB.height];
                break;
            default:
                break;
        }
        let optimal = [[_xy.from[0], _xy.from[1]], [_xy.to[0], _xy.to[1]]];
        const _line1 = (0, func_2.csvg)('line', {
            'x1': optimal[0][0],
            'y1': optimal[0][1],
            'x2': optimal[1][0],
            'y2': optimal[1][1],
            // 'stroke': 'rgb(255, 0, 0)',
            'stroke': 'rgb(173, 181, 189)',
            'stroke-width': '4',
            'pointer-events': 'none',
        }, [`microelement-comm-sideline`, `${m.pres.uiid}`]);
        m.view.appendChild(_line1);
    };
    drawLine();
    // draw line and highlight side on drag
    group.addEventListener('dragMove', (e) => {
        drawLine();
    });
    // store position of comment in .pres.comment.xy
    group.addEventListener('dragEnd', (e) => {
        (0, mobx_1.runInAction)(() => {
            m.pres.comment.xy = [e.detail.snappedX, e.detail.snappedY];
        });
        // litle make up for line
        // let _l = m.view.querySelector('.microelement-conn-line') as SVGLineElement;
        // _l.setAttribute('x1', rbb(Number(_l.getAttribute('x1')), 8).toString())
        // _l.setAttribute('y1', rbb(Number(_l.getAttribute('y1')), 8).toString())
        // _l.setAttribute('x2', rbb(Number(_l.getAttribute('y1')), 8).toString())
        // _l.setAttribute('y2', rbb(Number(_l.getAttribute('y2')), 8).toString())
    });
};
exports.showComment = showComment;
