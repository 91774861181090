"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mpts = void 0;
const app_1 = require("../../scripts/app");
const func_1 = require("../../scripts/func");
const ml_items_panel_1 = require("../mpts_panel/ml_items_panel");
const ml_prosp_panel_1 = require("../mpts_panel/ml_prosp_panel");
const proc_items_panel_1 = require("../mpts_panel/proc_items_panel");
// import { microelement } from "./microelement";
const core75_1 = require("../../stage75/core75");
const store_1 = require("./store");
// #region mpts
/** it is main point, create the future */
var mpts;
(function (mpts) {
    let _mpts = null;
    const _show = (v) => {
        if (!v) {
            _mpts.item.cont.style.display = 'none';
            _mpts.item.svgLayer.style.display = 'none';
            return;
        }
        _mpts.item.cont.style.display = 'flex';
        _mpts.item.svgLayer.style.display = 'flex';
        app_1.app.getApp().ui.header.foldLogo();
    };
    // #region microelements pallet
    // create microelements pallet
    const setItemsPallet = () => {
        // microelements pallet
        const plt = ml_items_panel_1.ml_items_panel.create('mpts-cont', [16, 16]);
        // handle pointerdown event
        // create new element on canvas
        plt.addEventListener('pointerdown', (e) => __awaiter(this, void 0, void 0, function* () {
            const _e = e.target;
            if (!_e)
                return;
            const parseData = (data) => {
                const _d = data.split('_');
                return {
                    type: _d[0],
                    f1: { type: _d[1].at(0), value: _d[1].slice(1) },
                    f2: { type: _d[2].at(0), value: _d[2].slice(1) }
                };
            };
            // check if clicked on item
            if (_e.classList.contains('mpts-panel-item')) {
                // lets parse the data- attribute
                const _da = _e.dataset.mptsPayload;
                const _dap = parseData(_da);
                //? group
                const _g = Number(_dap.f1.value);
                //? extension
                let _ex = null;
                switch (_dap.f2.type) {
                    case 'N':
                        _ex = 35;
                        break;
                    case 'Y':
                        const fc = _e.querySelectorAll('.mpts-panel-item').item(0);
                        _ex = fc ? Number(parseData(fc.dataset.mptsPayload).f2.value) : 35;
                        break;
                    case 'E':
                        _ex = Number(_dap.f2.value);
                        break;
                    default:
                        break;
                }
                let ddx = (0, func_1.getSvgTranslate3d)(_mpts.item.svgCanvas);
                function getMousePosition(p, transformedGroup) {
                    const svg = transformedGroup.ownerSVGElement;
                    if (!svg || typeof svg.createSVGPoint !== 'function') {
                        throw new Error('SVG element not found or invalid');
                    }
                    const pt = svg.createSVGPoint();
                    pt.x = p.x;
                    pt.y = p.y;
                    const matrix = transformedGroup.getScreenCTM();
                    if (!matrix) {
                        throw new Error('Cannot get screen CTM from group');
                    }
                    const svgCoords = pt.matrixTransform(matrix.inverse());
                    return { x: svgCoords.x, y: svgCoords.y };
                }
                // create element near the cursor
                // nobody knows why 24 and 74
                // const elem = microelement.createMicroElement(
                //     { g: _g, ex: _ex },
                //     rbb(e.x - 24, 8),
                //     rbb(e.y - 74, 8))
                const bc = getMousePosition({ x: e.x, y: e.y }, _mpts.item.svgCanvas);
                const elem = core75_1.microelement.create({ g: _g, ex: _ex }, [bc.x, bc.y]);
                // remember z-index`s before move pallet to back
                let zi1 = window.getComputedStyle(_mpts.item.cont).zIndex;
                let zi2 = window.getComputedStyle(_mpts.item.svgLayer).zIndex;
                _mpts.item.cont.style.zIndex = '2';
                _mpts.item.svgLayer.style.zIndex = '2000';
                // add element to canvas
                _mpts.item.svgCanvas.appendChild((yield elem).view);
                // add element to store
                // store.ml.add(elem)
                // enable drag for new element
                // enableDrag(elem.view);
                // simulate pointerdown event for new element
                const rect = (yield elem).view.getBoundingClientRect();
                const centerX = rect.x + rect.width / 2;
                const centerY = rect.y + rect.height / 2;
                let rp = getMousePosition({ x: e.x, y: e.y }, app_1.app.getApp().ui.mpts.item.svgCanvas);
                const pointerDownEvent = new PointerEvent('pointerdown', {
                    bubbles: false,
                    cancelable: true,
                    clientX: centerX,
                    clientY: centerY,
                    pointerId: e.pointerId
                });
                (yield elem).view.dispatchEvent(pointerDownEvent);
                // (await elem).view.style.transform = `translate(${rbb(16 - (rect.width / 2) - ddx.x, 8)}px, ${rbb(24 - ddx.y, 8)}px)`;
                // set z-index back
                (yield elem).view.addEventListener('pointerup', () => {
                    _mpts.item.cont.style.zIndex = zi1;
                    _mpts.item.svgLayer.style.zIndex = zi2;
                });
            }
        }));
    };
    // #region process items pallet
    const setProcessItemsPallet = () => {
    };
    // create mpts instance
    mpts.create = (l) => {
        if (_mpts)
            return _mpts;
        _mpts = {
            id: crypto.randomUUID(),
            item: {
                cont: document.getElementById('mpts-cont'),
                svgLayer: document.getElementById('svg-layer'),
                svgBackground: document.getElementById('svg-background'),
                svgCanvas: document.getElementById('svg-canvas'),
            },
            show: _show,
            translate: (l) => { }
        };
        // set zoom and panning
        (0, func_1.enableCanvasPanAndZoom)(_mpts.item.svgLayer, _mpts.item.svgCanvas, _mpts.item.svgBackground);
        setItemsPallet();
        setProcessItemsPallet();
        _mpts.item.svgBackground.addEventListener('pointerdown', (e) => {
            // check is clicked on canvas
            const _t = e.target;
            // console.log(_t);
            if (_t.parentElement === _mpts.item.svgLayer) {
                // console.log('remove selection');
                store_1.store.all.resetSelection();
            }
        });
        ml_prosp_panel_1.ml_props_panel.create('mpts-cont');
        proc_items_panel_1.proc_items_panel.create('mpts-cont');
        return _mpts;
    };
})(mpts || (exports.mpts = mpts = {}));
