"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const nml = __importStar(require("../stage75/core75"));
const core_1 = require("../core/core");
const tr_1 = require("../core/tr");
const microelement_1 = require("../site_components/mpts/microelement");
const store_1 = require("../site_components/mpts/store");
const app_1 = require("./app");
const func_1 = require("./func");
const _dd = performance.now();
const run = () => {
    // const _app = app.getApp(lang.rus)
    const _app = app_1.app.getApp(tr_1.lang.eng);
    setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
        _app.setView(app_1.AppView.app);
        // DummyMicroelements(_app)
        nml.microelement.create({ g: 0, ex: 35 }, [320, 320]).then((r) => {
            app_1.app.getApp().ui.mpts.item.svgCanvas.appendChild(r.view);
        });
        const r1 = nml.microelement.create({ g: 0, ex: 35 }, [360, 360]);
        const r2 = nml.microelement.create({ g: 12, ex: 35 }, [480, 360]);
        const r3 = nml.microelement.create({ g: core_1.ml.Group.gaze, ex: 35 }, [448, 360 + 8 * 16]);
        const r4 = nml.microelement.create({ g: core_1.ml.Group.gaze, ex: 35 }, [400, 360 + 8 * 16]);
        const r5 = nml.microelement.create({ g: core_1.ml.Group.takeALook, ex: 35 }, [440, 360 + 2 * 16]);
        const r6 = nml.microelement.create({ g: core_1.ml.Group.takeALook, ex: 35 }, [440, 360 + 7 * 16]);
        const r7 = nml.microelement.create({ g: core_1.ml.Group.takeALook, ex: 35 }, [440, 360 + 12 * 16]);
        const r8 = nml.microelement.create({ g: core_1.ml.Group.takeALook, ex: 35 }, [440, 360 + 15 * 16]);
        app_1.app.getApp().ui.mpts.item.svgCanvas.appendChild((yield r1).view);
        app_1.app.getApp().ui.mpts.item.svgCanvas.appendChild((yield r2).view);
        app_1.app.getApp().ui.mpts.item.svgCanvas.appendChild((yield r3).view);
        app_1.app.getApp().ui.mpts.item.svgCanvas.appendChild((yield r4).view);
        app_1.app.getApp().ui.mpts.item.svgCanvas.appendChild((yield r5).view);
        app_1.app.getApp().ui.mpts.item.svgCanvas.appendChild((yield r6).view);
        app_1.app.getApp().ui.mpts.item.svgCanvas.appendChild((yield r7).view);
        app_1.app.getApp().ui.mpts.item.svgCanvas.appendChild((yield r8).view);
    }), 100);
};
run();
console.log('build in ', performance.now() - _dd);
// create 10 dummy microelements
const DummyMicroelements = (a) => {
    for (let i = 0; i < 5; i++) {
        let _x = (0, func_1.rbb)((0, func_1.rndInt)(160, 560), 8);
        let _y = (0, func_1.rbb)((0, func_1.rndInt)(160, 640), 8);
        const _el = microelement_1.microelement.createMicroElement({ g: core_1.ml.Group.sitDown, ex: core_1.ml.Extension._ }, _x, _y);
        let f = 1;
        f = (0, func_1.rndInt)(0, 1);
        if (f === 0) {
            _el.core.multiplier = (0, func_1.rndInt)(0, 10);
        }
        f = (0, func_1.rndInt)(0, 1);
        if (f === 0) {
            _el.core.employment = core_1.ml.Employment.overlapped;
        }
        f = (0, func_1.rndInt)(0, 1);
        _el.pres.comment.show = f ? true : false;
        f = (0, func_1.rndInt)(0, 1);
        if (f === 0) {
            _el.core.comment = (0, func_1.rndString)((0, func_1.rndInt)(40, 140));
        }
        a.ui.mpts.item.svgCanvas.appendChild(_el.view);
        // add element to store
        store_1.store.ml.add(_el);
        microelement_1.microelement.selectMicroelement(_el);
        // enable drag for new element
        (0, func_1.enableDrag)(_el.view);
        console.log((0, func_1.hrb)());
    }
};
