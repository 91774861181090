"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.output = void 0;
const mobx_1 = require("mobx");
const sim_1 = require("../../core/sim");
const func_1 = require("../../scripts/func");
const presentation_1 = require("./presentation");
const store_1 = require("./store");
var output;
(function (output) {
    // instance
    output.create = (x, y) => {
        function _extend(...objs) {
            return Object.assign({}, ...objs);
        }
        const _i = sim_1.sim.input.create();
        const T0 = (0, mobx_1.observable)(_i, {}, { deep: true });
        const _pres = presentation_1.presentation.create();
        const T1 = (0, mobx_1.observable)(_pres, {}, { deep: true });
        const _view = output.createView(x, y, _pres.pres.uiid);
        _view.id = _i.core.id;
        const _cd = { dragController: { allow: true } };
        const T4 = _extend(_cd, T1, { view: _view }, T0);
        store_1.store.output.add(T4);
        return T4;
    };
    // view
    output.createView = (x, y, _uiid) => {
        // create group for circle
        const outputGroup = document.createElementNS(func_1.NS, 'g');
        outputGroup.classList.add('output');
        outputGroup.classList.add(_uiid);
        // create circle
        const _output = (0, func_1.csvg)('circle', {
            cx: x,
            cy: y,
            r: 16,
            fill: '#93B7BE',
            stroke: 'black',
            'stroke-width': 4,
            'pointer-events': 'all',
        }, ['mpts_input', 'baseRect', _uiid]);
        outputGroup.appendChild(_output);
        (0, func_1.enableDrag)(outputGroup);
        return outputGroup;
    };
})(output || (exports.output = output = {}));
