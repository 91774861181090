"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ID = void 0;
exports.bh = bh;
/**
 * Build an HTML element from a descriptor object.
 * @param descriptor An object describing the element to build.
 * @returns An HTMLElement object.
 */
function bh(descriptor) {
    const { tag, classList = [], attributes = {}, textContent, children = [], data, id } = descriptor;
    const element = document.createElement(tag);
    if (classList.length > 0) {
        element.classList.add(...classList);
    }
    for (const [attr, value] of Object.entries(attributes)) {
        element.setAttribute(attr, value);
    }
    if (textContent) {
        element.textContent = textContent;
    }
    if (children) {
        for (const childDescriptor of children) {
            const childElement = bh(childDescriptor);
            element.appendChild(childElement);
        }
    }
    if (data) {
        element.dataset.mptsPayload = data;
    }
    if (id) {
        element.id = id;
    }
    if (descriptor.innerHTML) {
        element.innerHTML = descriptor.innerHTML;
    }
    return element;
}
/**
 * generate a UUID
 * @returns A string containing a randomly generated, 36 character long  UUID.
 */
const ID = () => {
    return self.crypto.randomUUID();
};
exports.ID = ID;
