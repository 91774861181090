"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.docs = void 0;
const func_1 = require("../../assest/func");
const tr_1 = require("../../core/tr");
const app_1 = require("../../scripts/app");
const func_2 = require("../../scripts/func");
const breef_1 = require("./breef");
const docsnavbar_1 = require("./docsnavbar");
const toc_1 = require("./toc");
var docs;
(function (docs) {
    let _docs = null;
    let _toc = null;
    let _nav = null;
    const _element = {
        setToc: (v) => { _toc.setValue(v); },
        docsCont: null,
        main: {
            cont: document.getElementById('ui-layer')
        },
        docs: {
            signButton: null,
            cont: null,
            toc: {
                item: null,
                cont: null,
                tocHeader: null,
                favHeader: null,
                fav: null,
                list: null,
            },
            content: {
                cont: null,
                nav: null,
                top: null
            }
        }
    };
    const initDocs = (l) => __awaiter(this, void 0, void 0, function* () {
        const _dp = `content/${tr_1.lang[l]}/homeDocs.html`;
        const _h = yield (0, func_2._fetch)(_dp);
        (0, func_2.setObserv)(_element.main.cont, 'mpts-docs', [
            // () => { console.log('initDocsElms'); },
            initDocsElms,
            initTOC,
            initDocsNavbar,
            hadleStartFall,
            handleSignButton
            // setupSmoothScrolling,
        ]);
        _element.main.cont.append(_h);
    });
    const initDocsElms = () => {
        // 
        _element.docsCont = document.getElementById('mpts-docs');
        //
        _element.docs.signButton = document.getElementById('mpts-docs-header-user'); //!! sign button
        //
        _element.docs.cont = document.getElementById('toc');
        _element.docs.toc.cont = document.getElementById('mpts-toc');
        _element.docs.toc.fav = document.getElementById('mpts-favorites');
        // 
        _element.docs.toc.favHeader = document.getElementById('fav-title');
        _element.docs.toc.tocHeader = document.getElementById('toc-title');
        //
        _element.docs.toc.list = document.getElementById('mpts-toc-list');
        _element.docs.content.cont = document.getElementById('mpts-content');
        _element.docs.content.top = document.getElementById('docs-top');
    };
    const handleSignButton = () => {
        _element.docs.signButton.addEventListener('pointerdown', () => {
            console.log('sign button clicked');
            // navigate to the sign in page
            app_1.app.getApp().setView(app_1.AppView.signin);
            // set the header underline to the sign in button
            app_1.app.getApp().ui.header.underLine(5);
        });
    };
    const hadleStartFall = () => {
        const _sf = (event) => {
            const container = event.currentTarget;
            for (let i = 0; i < 10; i++) {
                const star = document.createElement('div');
                star.classList.add('star1');
                container.appendChild(star);
                const angle = Math.random() * 2 * Math.PI;
                const distance = Math.random() * 80 + 20;
                const x = Math.cos(angle) * distance;
                const y = Math.sin(angle) * distance;
                star.style.setProperty('--x', `${x}px`);
                star.style.setProperty('--y', `${y}px`);
                star.style.left = `${event.offsetX}px`;
                star.style.top = `${event.offsetY}px`;
                star.style.animation = 'explode 0.6s ease-out forwards';
                setTimeout(() => {
                    star.remove();
                }, 600);
            }
        };
        // _element.docs.toc.favHeader.addEventListener('pointerdown', _sf)
        _element.docs.toc.tocHeader.addEventListener('pointerdown', _sf);
    };
    const initTOC = () => {
        var _a;
        _toc = toc_1.toc.create();
        (_a = _element.docs.cont) === null || _a === void 0 ? void 0 : _a.appendChild(_toc.element);
        _element.docs.toc.item = _toc;
    };
    const initDocsNavbar = () => {
        _nav = docsnavbar_1.docsnavbar.create(document.getElementById('body-mid'), _toc.fullContent);
        _element.docs.content.nav = _nav;
    };
    const initBreef = () => {
    };
    let _currentPageID = 'd1';
    const st = (v, b = 'instant') => {
        _currentPageID = v;
        _toc.setValue(v, b);
        sd(v);
    };
    /** --------------------------------------------------------------- */
    /** --------------------------------------------------------------- */
    /**/ /! ------------------- SHOW DOCUMENT--------------------------- */;
    /** --------------------------------------------------------------- */
    /** --------------------------------------------------------------- */
    /** --------------------------------------------------------------- */
    const sd = (_id) => __awaiter(this, void 0, void 0, function* () {
        _currentPageID = _id; // not sure that its need here
        let _r = _toc.flatContent.get(_id);
        const _h = yield (0, func_2._fetch)(`content/${tr_1.lang[_l]}/docs/${_r.val.toLowerCase()}.html`);
        if (_h) {
            _element.docs.content.cont.replaceChildren(_h);
        }
        else {
            _element.docs.content.cont.replaceChildren((0, func_1.bh)({
                tag: 'div',
                textContent: 'No content',
                classList: ['mpts-docs-no-content']
            }));
        }
        let _b = breef_1.breef.create(_element.docs.content.cont)._cont;
        _b ? document.getElementById('body-right').appendChild(_b) : null;
    });
    /** --------------------------------------------------------------- */
    /** --------------------------------------------------------------- */
    /**/ /! ------------------- SHOW DOCUMENT--------------------------- */;
    /** --------------------------------------------------------------- */
    /** --------------------------------------------------------------- */
    /** --------------------------------------------------------------- */
    let _l = tr_1.lang.eng;
    // remove sign in button if user is loged in
    const checkSignIn = () => {
        if (app_1.app.getApp().logedIn) {
            _element.docs.signButton.style.display = 'none';
            _element.docs.content.top.style.display = 'none';
        }
        else {
            _element.docs.signButton.style.display = '';
            _element.docs.content.top.style.display = '';
        }
    };
    const _show = (v) => {
        if (v) {
            _element.docsCont.style.display = 'flex';
            checkSignIn();
        }
        else {
            _element.docsCont.style.display = 'none';
        }
    };
    docs.create = (l) => {
        _l = l;
        // console.log('create docs');
        initDocs(l);
        _docs = {
            id: crypto.randomUUID(),
            currentPageID: _currentPageID,
            item: _element,
            show: _show,
            translate: (l) => { },
            setToc: st,
            setDoc: sd,
        };
        return _docs;
    };
})(docs || (exports.docs = docs = {}));
