"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.u_repRate = exports.repRateClasses = void 0;
const mobx_1 = require("mobx");
const func_1 = require("../../../assest/func");
const app_1 = require("../../../scripts/app");
const core_1 = require("../../core");
const tr_1 = require("../../tr");
const tcombo_1 = require("../baseitems/tcombo");
var repRateClasses;
(function (repRateClasses) {
    repRateClasses["cont"] = "mpt_repRate_cont_u";
    repRateClasses["combo"] = "mpt_repRate_combo_u";
    repRateClasses["numinput"] = "mpt_repRate_numInput_u";
    repRateClasses["comboValue"] = "mpt_intens_kund";
})(repRateClasses || (exports.repRateClasses = repRateClasses = {}));
/** current values */
let mcur = { int: 0, v: 0 };
let cont;
/**  main container */
const createCont = () => {
    cont ? cont.remove() : 0;
    cont = (0, func_1.bh)({ tag: 'div', classList: [repRateClasses.cont] });
};
const u_repRate = (u) => {
    createCont();
    /** translated strings */
    let _ustr = (0, tr_1.tr_u)(app_1.app.getApp().lang, u);
    /** combo for intensity type */
    const u_rr_combo = (0, tcombo_1.tcombo)({ top: `${_ustr.intens[1]}, ${_ustr.unit[1]}`, ch: _ustr.intens[3] });
    let _sel = u_rr_combo.querySelector(`.mpt_select`);
    _sel.classList.add(repRateClasses.combo);
    _sel.selectedIndex = Number(u.core.intensity.t);
    console.log('CI', Number(u.core.intensity.t));
    _sel.onchange = () => {
        mcur.int = _sel.selectedIndex;
        core_1.un.changeIntensity(u, core_1.ins.base.create(mcur.int, mcur.v));
        let _ustr1 = (0, tr_1.tr_u)(app_1.app.getApp().lang, u);
        cont.querySelector('.mpt_tlabel').textContent = `${_ustr1.intens[1]}, ${_ustr1.unit[1]}`;
    };
    /** number input */
    const u_rr_numInput = (0, func_1.bh)({ tag: 'input', classList: [repRateClasses.numinput] });
    u_rr_numInput.setAttribute('type', 'number');
    let _step = '';
    if (u.core.intensity.u.kind == core_1.nf.Unit.pcs) {
        _step = '1';
    }
    else if (u.core.intensity.u.kind == core_1.nf.Unit.s) {
        _step = '0.1';
    }
    else {
        _step = '1';
    }
    u_rr_numInput.value = u.core.intensity.v.toString();
    mcur.v = u.core.intensity.v;
    u_rr_numInput.oninput = () => {
        if (u_rr_numInput.value == '') {
            u_rr_numInput.value = '0';
        }
        (0, mobx_1.runInAction)(() => {
            u.core.intensity.v = Number(u_rr_numInput.value);
        });
        u_rr_numInput.value = Number(u_rr_numInput.value).toFixed(0);
        console.log(`before: ${mcur.int ? mcur.int : '0'}_${mcur.v} after: ${u.core.intensity.t ? u.core.intensity.t : '0'}_${u.core.intensity.v} `);
        mcur.v = u.core.intensity.v;
    };
    u_rr_numInput.onkeyup = (e) => {
        if (e.ctrlKey) {
            if (e.key === 'Enter') {
                u_rr_numInput.blur();
            }
        }
        if (e.key === 'Enter') {
            return;
        }
        if (e.key === 'Escape') {
            u_rr_numInput.blur();
        }
        Number(u_rr_numInput.value) >= 9999 ? u_rr_numInput.value = '9999' : 0;
    };
    cont.appendChild(u_rr_combo);
    cont.appendChild(u_rr_numInput);
    return cont;
};
exports.u_repRate = u_repRate;
