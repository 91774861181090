"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// let _user = user().signIn('', '')
console.log('hello iam browser app', crypto.randomUUID());
const words = Array.from(document.querySelectorAll('.word'));
const wordsLenght = words.length;
let currentIndex = 0;
function getSurroundingIndices(index, arrayLength) {
    return [
        (index - 1 + arrayLength) % arrayLength,
        index % arrayLength,
        (index + 1) % arrayLength,
        (index + 2) % arrayLength
    ];
}
words.forEach((word, index) => {
    word.addEventListener('pointerdown', () => {
        currentIndex = getSurroundingIndices(index, wordsLenght)[0];
        updateClasses();
    });
});
function updateClasses() {
    words.forEach((word, index) => {
        word.classList.remove('previous', 'active', 'next', 'previous1', 'previous2');
        if (index === (currentIndex - 2 + words.length) % words.length) {
            word.classList.add('previous2');
        }
        if (index === (currentIndex - 1 + words.length) % words.length) {
            word.classList.add('previous1');
        }
        else if (index === currentIndex) {
            word.classList.add('previous');
        }
        else if (index === (currentIndex + 1) % words.length) {
            word.classList.add('active');
        }
        else if (index === (currentIndex + 2) % words.length) {
            word.classList.add('next');
        }
    });
}
function changeText(direction) {
    currentIndex = (currentIndex + direction + words.length) % words.length;
    updateClasses();
}
window.addEventListener('wheel', (event) => {
    if (event.deltaY > 0) {
        changeText(1);
    }
    else if (event.deltaY < 0) {
        changeText(-1);
    }
});
updateClasses();
const menuToggle = document.getElementsByClassName('menu-toggle')[0];
const headerItems = document.getElementsByClassName('items')[0];
const menuItems = document.querySelectorAll('.items label');
menuToggle === null || menuToggle === void 0 ? void 0 : menuToggle.addEventListener('click', function () {
    headerItems === null || headerItems === void 0 ? void 0 : headerItems.classList.toggle('active');
});
menuItems.forEach(item => {
    item.addEventListener('click', function () {
        headerItems === null || headerItems === void 0 ? void 0 : headerItems.classList.remove('active');
    });
});
const topLayer = document.querySelector('.top-layer');
document.addEventListener('mousemove', (event) => {
    const x = event.clientX;
    const y = event.clientY;
    const size = 100; // Размер прозрачной зоны
    // Создаем радиальную прозрачную область вокруг указателя
    const mask = `radial-gradient(circle ${size}px at ${x}px ${y}px, transparent 0%, red 100%)`;
    // Применяем маску к верхнему слою с анимацией
    topLayer.style.maskImage = mask;
    topLayer.style.webkitMaskImage = mask; // Для браузеров с префиксом
});
