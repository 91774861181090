"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.u_color = exports.UColors = exports.u_colorClasses = void 0;
const mobx_1 = require("mobx");
const func_1 = require("../../../assest/func");
var u_colorClasses;
(function (u_colorClasses) {
    u_colorClasses["cont"] = "mpt_color_cont_u";
    u_colorClasses["input"] = "mpt_color_input";
    u_colorClasses["label"] = "mpt_color_label";
    u_colorClasses["span"] = "mpt_color_span";
})(u_colorClasses || (exports.u_colorClasses = u_colorClasses = {}));
var UColors;
(function (UColors) {
    UColors["grey"] = "#93B7BE";
    UColors["yelow"] = "#FACC48";
    UColors["green"] = "#74C69D";
    UColors["blue"] = "#84D2F6";
    UColors["pink"] = "#F5CAC3";
    UColors["red"] = "#F28482";
})(UColors || (exports.UColors = UColors = {}));
let pcol = '';
let cont;
/**  main container */
const createCont = () => {
    cont ? cont.remove() : 0;
    cont = (0, func_1.bh)({ tag: 'div', classList: [u_colorClasses.cont] });
};
const gl = () => {
    return Object
        .values(UColors)
        .filter(v => typeof v === 'string');
};
const u_color = (u) => {
    createCont();
    pcol = u.pres.color;
    const u_cur_back = u.view.getElementsByClassName('sideHighlight')[0];
    const u_cur_color = u_cur_back.getAttribute('fill');
    /** for each string value in color list */
    gl().forEach((el) => {
        const tid = crypto.randomUUID();
        let u_in = (0, func_1.bh)({ tag: 'input', classList: [u_colorClasses.input] });
        u_in.setAttribute('type', 'radio');
        u_in.setAttribute('name', 'color');
        u_in.setAttribute('id', tid);
        let u_lab = (0, func_1.bh)({ tag: 'label', classList: [u_colorClasses.label] });
        u_lab.setAttribute('for', tid);
        u_lab.setAttribute('value', el);
        u_lab.style.backgroundColor = el;
        let u_span = (0, func_1.bh)({ tag: 'span', classList: [u_colorClasses.span] });
        u_span.classList.add(tid);
        u_lab.appendChild(u_span);
        if (u_cur_color == el) {
            u_in.setAttribute('checked', '');
        }
        cont.appendChild(u_in);
        cont.appendChild(u_lab);
        u_in.onchange = () => {
            let color = u_lab.getAttribute('value');
            let u_joint = u.view.getElementsByClassName('sideHighlight')[0];
            u_joint.setAttribute('fill', color);
            u_joint.setAttribute('stroke', color);
            console.log('union ch color from:', pcol, 'to', color);
            (0, mobx_1.runInAction)(() => {
                u.pres.color = color;
            });
        };
    });
    return cont;
};
exports.u_color = u_color;
