"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.m_overlapped = void 0;
const func_1 = require("../../../assest/func");
const app_1 = require("../../../scripts/app");
const tr_1 = require("../../tr");
const tcheckbox_1 = require(".././baseitems/tcheckbox");
const mobx_1 = require("mobx");
const core_1 = require("../../core");
const m_overlapped = (m) => {
    // create default panel
    const cont = (0, func_1.bh)({ tag: 'div', classList: ['mpt_slider_topcont'] });
    // translate microelement
    const _tr = (0, tr_1.tr_ml)(app_1.app.getApp().lang, m);
    // export type tchVT = [text: string, noval: string, yesval: string, val: boolean]
    const _over = (0, tcheckbox_1.tcheckbox)([_tr.emp[1], _tr.emp[3][0], _tr.emp[3][1], m.core.employment == core_1.ml.Employment.overlapped ? true : false]).view;
    _over.addEventListener('change', (e) => {
        (0, mobx_1.runInAction)(() => {
            if (e.target.checked) {
                m.core.employment = core_1.ml.Employment.overlapped;
            }
            else {
                m.core.employment = core_1.ml.Employment.nonOverlapped;
            }
            console.log('m.core.employment', m.core.employment);
        });
    });
    cont.appendChild(_over);
    return cont;
};
exports.m_overlapped = m_overlapped;
