"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tcombo = void 0;
const comboListDef = {
    top: 'How long you should wait',
    ch: ['two days', 'till evening', 'forever', '00more then forever']
};
const el = (v) => {
    let _el = document.createElement(v.t);
    v.cl.split(" ").forEach(c => _el.classList.add(c));
    v.id ? (_el.setAttribute('id', self.crypto.randomUUID())) : 0;
    v.txt ? _el.innerText = v.txt : 0;
    return _el;
};
const tcombo = (list = comboListDef, checkIndex = 0) => {
    // select
    const csel = el({ t: 'select', cl: 'mpt_select' });
    // add options
    list.ch.forEach((elm, i) => {
        const cselit = el({ t: 'option', cl: 'mpt_select', txt: elm });
        cselit.setAttribute('value', i.toString());
        checkIndex == i ? cselit.setAttribute('selected', '') : 0;
        csel.appendChild(cselit);
    });
    // container for combo
    const cont = el({ t: 'div', cl: 'mpt_tcombo', id: true });
    // label
    const clab = el({ t: 'div', cl: 'mpt_tlabel', txt: list.top + ':' });
    cont.appendChild(clab);
    cont.appendChild(csel);
    return cont;
};
exports.tcombo = tcombo;
