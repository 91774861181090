"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tr_1 = require("../core/tr");
const app_1 = require("./app");
console.log('mpts', crypto.randomUUID(), `${new Date().toISOString()}`);
const _dd = performance.now();
const run = () => {
    const _app = app_1.app.getApp(tr_1.lang.eng);
    // const _app = app.getApp(lang.rus)
    setTimeout(() => {
        // document.getElementById('toc').append(toc.create())
    }, 2000);
};
run();
console.log('build in ', performance.now() - _dd);
