"use strict";
//? /** ---------------------------------------------------------------- MARKS ----------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.marksGroup = exports.markPref = exports.markColors = exports.markCls = exports.markPos = void 0;
const func_1 = require("../../scripts/func");
const core_1 = require("../core");
/** mark position */
var markPos;
(function (markPos) {
    markPos["left"] = "left";
    markPos["right"] = "right";
})(markPos || (exports.markPos = markPos = {}));
/** mark classes */
var markCls;
(function (markCls) {
    markCls["empl"] = "mpts_employment_mark";
    markCls["comm"] = "mpts_comment_mark";
    markCls["mult"] = "mpts_mult_mark";
    markCls["rept"] = "mpts_rept_mark";
    markCls["comb"] = "mpts_comb_mark";
    markCls["sequ"] = "mpts_sequ_mark";
    markCls["simu"] = "mpts_simu_mark";
    markCls["coit"] = "mpts_coit_mark";
})(markCls || (exports.markCls = markCls = {}));
exports.markColors = {
    empl: '#A1C3FF',
    comm: '#ADB5BD',
    mult: 'green',
    rept: 'yelow'
};
var markPref;
(function (markPref) {
    markPref["empl"] = "O";
    markPref["comm"] = "C";
    markPref["mult"] = "x";
    markPref["rept"] = "r";
    markPref["comb"] = "U";
    markPref["sequ"] = "S";
    markPref["simu"] = "M";
    markPref["coit"] = "T";
})(markPref || (exports.markPref = markPref = {}));
const _br = (m) => {
    m.view.dataset.uiid;
    // return m.view.querySelector(`.baseRect.${_uiid(m)}`) as SVGGraphicsElement;
    return m.view.querySelector(`.baseRect`);
};
const _uiid = (m) => {
    return m.pres.uiid;
};
const marksGroup = (m) => {
    var _a;
    (_a = m.view.querySelector(`.marksGroup.${_uiid(m)}`)) === null || _a === void 0 ? void 0 : _a.remove();
    // create group for marks
    const marksGroup = document.createElementNS(func_1.NS, 'g');
    marksGroup.classList.add(`marksGroup`);
    marksGroup.classList.add(`${_uiid(m)}`);
    // append marks group
    //! incase of microelement
    if (m.core.coreType == core_1.CoreTypes.microelement) {
        _br(m).insertBefore(marksGroup, _br(m).lastElementChild);
    }
    //! incase of union
    if (m.core.coreType == core_1.CoreTypes.union) {
        _br(m).parentElement.insertBefore(marksGroup, _br(m).parentElement.firstElementChild);
    }
    const _repMark = mark(m, markCls.rept, markPos.left);
    _repMark ? marksGroup.insertBefore(_repMark, marksGroup.firstElementChild) : null;
    // create marks
    // ================================================== COMBINATION MARKS
    // create combination mark
    const _combMark = mark(m, markCls.comb, markPos.left);
    _combMark ? marksGroup.insertBefore(_combMark, null) : null;
    // ================================================== COMBINATION MARKS
    // create employment mark
    const _emplMark = mark(m, markCls.empl, markPos.right);
    _emplMark ? marksGroup.insertBefore(_emplMark, null) : null;
    // create comment mark
    const _commMark = mark(m, markCls.comm, markPos.right);
    _commMark ? marksGroup.insertBefore(_commMark, _emplMark) : null;
    // create multiplier mark
    const _multMark = mark(m, markCls.mult, markPos.right);
    if (m.core.coreType == core_1.CoreTypes.union) {
        _multMark ? marksGroup.insertBefore(_multMark, marksGroup.lastElementChild) : null;
        if (_commMark && _multMark) {
            (0, func_1.updateTranslate3d)(_multMark, 16, 0);
        }
    }
    if (m.core.coreType == core_1.CoreTypes.microelement) {
        _multMark ? marksGroup.insertBefore(_multMark, _commMark ? _commMark : _emplMark ? _emplMark : null) : null;
    }
};
exports.marksGroup = marksGroup;
/** a small mark of 2-3 characters located on the left or right side of the microelement, union, ...etc */
const mark = (m, cls, pos = markPos.right) => {
    var _a, _b, _c, _d, _e;
    // microelement view BaseRect - MainRect + markers
    const _bb = _br(m).getBBox();
    // container for one mark
    const markCont = document.createElementNS(func_1.NS, 'g');
    markCont.classList.add('markCont');
    markCont.classList.add(_uiid(m));
    // baseXY for mark position 
    let rectBaseXY = [0, 0];
    let textBaseXY = [0, 0];
    if (pos == markPos.right) {
        rectBaseXY = [_bb.x + _bb.width - 16, _bb.y];
        textBaseXY = [_bb.x + _bb.width + 4, _bb.y + 9];
    }
    else {
        rectBaseXY = [_bb.x - 16, _bb.y];
        textBaseXY = [_bb.x - 12, _bb.y + 9];
    }
    // rect 
    const rect = (0, func_1.csvg)('rect', {
        'x': rectBaseXY[0],
        'y': rectBaseXY[1],
        'width': 32,
        'height': 16,
        'fill': exports.markColors.empl,
        'stroke': exports.markColors.empl,
        'stroke-width': '1',
        'rx': 4
    });
    // text
    const text = (0, func_1.csvg)('text', {
        x: textBaseXY[0],
        y: textBaseXY[1],
        fill: 'white',
        'font-size': '12px',
        'text-anchor': 'left',
        'dominant-baseline': 'middle'
    });
    // get all marks
    const mg = m.view.querySelector(`.marksGroup.${_uiid(m)}`);
    const markContElements = mg.querySelectorAll(`.markCont.${_uiid(m)}`);
    // count for dx
    let dx = 0;
    if (markContElements) {
        markContElements.forEach((mrk, i) => {
            dx += Number(mrk.getBoundingClientRect().width - (i * 16));
        });
    }
    // create mark according to class
    switch (cls) {
        // ---------------------------------------------------- employment mark
        case markCls.empl:
            (_a = m.view.querySelector(`.markCont-empl.${_uiid(m)}`)) === null || _a === void 0 ? void 0 : _a.remove();
            markCont.classList.add('markCont-empl');
            markCont.classList.add(`${_uiid(m)}`);
            // check microelement employment state
            const emplState = 'employment' in m.core && m.core.employment == core_1.ml.Employment.overlapped ? 'O' : null;
            if (!emplState)
                return;
            text.textContent = emplState;
            break;
        // ---------------------------------------------------- comment mark
        case markCls.comm:
            (_b = m.view.querySelector(`.markCont-comm.${_uiid(m)}`)) === null || _b === void 0 ? void 0 : _b.remove();
            if (m.core.comment === '' || undefined) {
                return;
            }
            markCont.classList.add('markCont-comm');
            markCont.classList.add(`${_uiid(m)}`);
            rect.style.fill = '#ADB5BD';
            rect.style.stroke = '#ADB5BD';
            const commState = m.pres.comment.show ? null : 'C';
            if (commState === null)
                return;
            text.textContent = commState;
            break;
        // ---------------------------------------------------- multiplier mark
        case markCls.mult:
            (_c = m.view.querySelector(`.markCont-mult.${_uiid(m)}`)) === null || _c === void 0 ? void 0 : _c.remove();
            const multState = m.core.multiplier !== 1 ? `x${m.core.multiplier}` : null;
            if (multState === null)
                return;
            markCont.classList.add('markCont-mult');
            markCont.classList.add(`${_uiid(m)}`);
            rect.style.width = ((0, func_1.countStringWidth)(multState) + 16).toString();
            rect.style.fill = '#8EA599';
            rect.style.stroke = '#8EA599';
            text.textContent = multState;
            text.style.x += 8;
            break;
        // ---------------------------------------------------- combination mark
        case markCls.comb:
            (_d = m.view.querySelector(`.markCont-comb.${_uiid(m)}`)) === null || _d === void 0 ? void 0 : _d.remove();
            const combState = ('combined' in m.core && m.core.combined.items.length >= 1 && !m.pres.combined.show) ? 'U' : null;
            if (combState === null)
                return;
            markCont.classList.add('markCont-comb');
            markCont.classList.add(`${_uiid(m)}`);
            rect.style.width = '32';
            rect.style.fill = '#8EA599';
            rect.style.stroke = '#8EA599';
            text.textContent = combState;
            break;
        // ---------------------------------------------------- repetition mark
        case markCls.rept:
            (_e = m.view.querySelector(`.markCont-rept.${_uiid(m)}`)) === null || _e === void 0 ? void 0 : _e.remove();
            let _sgn = '';
            // type guard for Union
            if ('intensity' in m.core) {
                let _f = m.core.intensity.t == 1 ? 't' : m.core.intensity.t == 2 ? '%' : 'q';
                if ((_f == 'q' && m.core.intensity.v !== 1)) {
                    _sgn = `${m.core.intensity.v}i`;
                }
                else if (_f == '%') {
                    _sgn = `${m.core.intensity.v}%`;
                }
                else if (_f == 't') {
                    _sgn = `${m.core.intensity.v}s`;
                }
                else {
                    return;
                }
            }
            else {
                return;
            }
            markCont.classList.add('markCont-rept');
            markCont.classList.add(`${_uiid(m)}`);
            rect.style.width = ((0, func_1.countStringWidth)(_sgn) + 16).toString();
            rect.setAttribute('x', (rectBaseXY[0] - (0, func_1.countStringWidth)(_sgn) + 16).toString());
            rect.style.x = (rectBaseXY[0] - (0, func_1.countStringWidth)(_sgn) + 16).toString();
            // rect.style.fill = '#8EA599'
            // rect.style.stroke = '#8EA599'
            rect.style.fill = '#DCA73C';
            rect.style.stroke = '#DCA73C';
            text.setAttribute('x', (textBaseXY[0] - (0, func_1.countStringWidth)(_sgn) + 16).toString());
            text.textContent = _sgn;
            break;
        default:
            break;
    }
    // append elements
    markCont.appendChild(rect);
    markCont.appendChild(text);
    return markCont;
};
