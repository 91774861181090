"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.u_notITAction = exports.notITActionClasses = void 0;
const func_1 = require("../../../assest/func");
const app_1 = require("../../../scripts/app");
const tr_1 = require("../../tr");
const tcombo_1 = require("../baseitems/tcombo");
var notITActionClasses;
(function (notITActionClasses) {
    notITActionClasses["cont"] = "mpt_notITAction_cont_u";
    notITActionClasses["combo"] = "mpt_notITAction_combo_u";
    notITActionClasses["comboVal"] = "mpt_notITAction_comboValue_u";
})(notITActionClasses || (exports.notITActionClasses = notITActionClasses = {}));
/** current value */
let mcur = 0;
/** html instance */
let cont;
/**  main container */
const createCont = () => {
    cont ? cont.remove() : 0;
    // cont = fnc.el({ t: 'div', cl: notITActionClasses.cont })
    cont = (0, func_1.bh)({ tag: 'div', classList: [notITActionClasses.cont] });
};
const u_notITAction = (u) => {
    createCont();
    mcur = u.core.nita.nita;
    let _ustr = (0, tr_1.tr_u)(app_1.app.getApp().lang, u);
    const _nitaCombo = (0, tcombo_1.tcombo)({ top: _ustr.nita[1], ch: _ustr.nita[3] });
    cont.appendChild(_nitaCombo);
    let _sel = _nitaCombo.getElementsByTagName('select')[0];
    let options = _sel.childNodes;
    options.forEach((el, i) => {
        let r = el;
        r.setAttribute(notITActionClasses.comboVal, i.toString());
        if (i == u.core.nita.nita) {
            r.setAttribute('selected', '');
        }
    });
    _sel.onchange = () => {
        u.core.nita.nita = Number(_sel.value);
        console.log(`nita changed from ${mcur} to ${Number(_sel.value)}`);
        mcur = Number(_sel.value);
    };
    return cont;
};
exports.u_notITAction = u_notITAction;
