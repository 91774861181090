"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.presentation = void 0;
const func_1 = require("../../scripts/func");
var presentation;
(function (presentation) {
    presentation.create = () => {
        return {
            pres: {
                uiid: (0, func_1.hrb)(),
                selected: false,
                comment: { show: false, xy: [0, 0] },
                combined: { show: false, xy: [0, 0] },
                captured: { state: 'free', invaider: null },
                inUnion: { state: 'free', union: null, xy: [0, 0] },
                inStep: { state: 'free', step: null, xy: [0, 0] },
                locked: false,
                xy: [0, 0],
                color: '#000000',
                result: { show: false, format: null },
                aliasName: { show: false, value: '' },
                unionHeader: 'Фрезерование торца детали',
                select: null
            }
        };
    };
})(presentation || (exports.presentation = presentation = {}));
