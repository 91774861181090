"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.send = exports.getId = exports.MessageTypes = void 0;
const login1_1 = require("../site_components/login/login1");
const app_1 = require("./app");
const socket = io();
var MessageTypes;
(function (MessageTypes) {
    MessageTypes["userRequest"] = "";
    MessageTypes["demo"] = "demo";
    MessageTypes["get_users"] = "get_users";
    MessageTypes["login"] = "login";
    MessageTypes["chech_otp"] = "chech_otp";
    MessageTypes["getId"] = "getId";
    MessageTypes["getName"] = "getName";
    MessageTypes["setName"] = "setName";
    MessageTypes["signOut"] = "signOut";
})(MessageTypes || (exports.MessageTypes = MessageTypes = {}));
const getId = () => {
    return new Promise((resolve, reject) => {
        try {
            socket.emit(MessageTypes.getId.toString(), (resp) => {
                // Если сервер возвращает "0000000000", генерируем новый id, иначе используем полученное значение
                const id = resp === '0000000000' ? crypto.randomUUID() : resp;
                // Можно добавить проверку, если resp пустой или null
                if (id) {
                    resolve(id);
                }
                else {
                    reject(new Error('Не получен идентификатор от сервера'));
                }
            });
        }
        catch (error) {
            reject(error);
        }
    });
};
exports.getId = getId;
const send = (_m, _p) => {
    try {
        switch (_m) {
            case MessageTypes.setName:
                socket.emit(_m.toString(), _p, (resp) => { console.log('new name: ', resp); });
                break;
            case MessageTypes.getName:
                socket.emit(_m.toString(), (resp) => { _p.value = resp; });
                break;
            case MessageTypes.getId:
                // console.log('Requesting ID');
                socket.emit(_m.toString(), (resp) => {
                    // if (resp === '0000000000') console.log('ID not generated', 'set dummy ID')
                    // _p ? _p.id = resp === '0000000000' ? crypto.randomUUID() : resp : null
                    _p = resp;
                });
                break;
            case MessageTypes.demo:
                socket.emit(_m);
                break;
            case MessageTypes.get_users:
                socket.emit(_m.toString());
                break;
            case MessageTypes.login:
                socket.emit(_m, _p);
                break;
            case MessageTypes.chech_otp:
                socket.emit(_m, _p);
                break;
            case MessageTypes.signOut:
                socket.emit(_m, (resp) => {
                    console.log('sign out:', resp);
                    app_1.app.getApp().ui.signin.sigOutProcceed();
                    login1_1.login.logout().then(() => {
                        console.log('User logged out');
                    });
                });
                break;
            default:
                console.warn(`Unhandled message type: ${_m}`);
                break;
        }
    }
    catch (error) {
        console.error(`Error sending message: ${error}`);
    }
};
exports.send = send;
socket.on('it_is_users_list', (val) => {
    console.log(val);
});
socket.on('user_exists', (val) => {
    console.log('User exists:', val);
});
socket.on('otp_request', (val) => {
    if (val.ft) {
        // it is first time login
        console.log('OTP requested');
        app_1.app.getApp().ui.signin.setView(login1_1.loginStates.otp);
        app_1.app.getApp().ui.signin.setNotinotifier().info('Please check your email for OTP setup');
        console.log('Registred first time', val.v);
    }
    else {
        console.log('OTP requested');
        app_1.app.getApp().ui.signin.setView(login1_1.loginStates.otp);
        console.log('Registred', val.v);
    }
});
socket.on('login_success', (val) => {
    console.log('User logged in:', val);
    // set user data
    let _user = app_1.app.getApp().user;
    _user.email = val.email;
    _user.name = val.name;
    _user.id = val.id;
    _user.key = val.keys.publ;
    app_1.app.getApp().ui.signin.setView(login1_1.loginStates.user);
    login1_1.login.initialize().then(() => {
        console.log('Login verified');
    });
});
socket.on('wrong_password', (val) => {
    console.log('Wrong password for', val);
    app_1.app.getApp().ui.signin.setNotinotifier().error('Wrong password');
    app_1.app.getApp().ui.signin.setView(login1_1.loginStates.login);
});
socket.on('wrong_otp', (val) => {
    console.log('Wrong OTP:', val);
    app_1.app.getApp().ui.signin.setView(login1_1.loginStates.otp);
    app_1.app.getApp().ui.signin.clearOTPInputs();
    app_1.app.getApp().ui.signin.setNotinotifier().error('Wrong OTP');
});
socket.on('takeId', (v) => {
    const uint8Array = new Uint8Array(v);
    const hexString = Array.from(uint8Array)
        .map(byte => byte
        .toString(16)
        .padStart(2, '0'))
        .join('');
    // console.log(hexString)
});
