"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ui_inp = void 0;
const func_1 = require("../../../assest/func");
const sim_1 = require("../../sim");
const tr_1 = require("../../tr");
const tcombo_1 = require("../baseitems/tcombo");
const ml_prosp_panel_1 = require("../../../site_components/mpts_panel/ml_prosp_panel");
var inpClasses;
(function (inpClasses) {
    inpClasses["cont"] = "mpt_input_cont";
    inpClasses["elem"] = "mpt_input_elem";
    inpClasses["header"] = "mpt_input_header";
    inpClasses["numinp"] = "mpt_input_number";
    inpClasses["inpbut"] = "mpt_input_button";
    inpClasses["stcont"] = "mpt_input_sourcedtype_cont";
    inpClasses["stlab"] = "mpt_input_sourcedtype_label";
    inpClasses["sttext"] = "mpt_input_sourcedtype_text";
    inpClasses["sttcl"] = "mpt_input_sourcedtype_color";
    inpClasses["chcont"] = "mpt_input_characteristic_cont";
    inpClasses["chlab"] = "mpt_input_characteristic_lab";
    inpClasses["chtext"] = "mpt_input_characteristic_text";
    inpClasses["chcl"] = "mpt_input_characteristic_color";
})(inpClasses || (inpClasses = {}));
let cont;
const initCont = () => {
    cont ? cont.remove() : 0;
    // cont = fnc.el({ t: 'div', cl: inpClasses.cont })
    cont = (0, func_1.bh)({ tag: 'div', classList: [inpClasses.cont] });
};
const ui_inp = (l, inp) => {
    initCont();
    let _ustr = (0, tr_1.tr_inp)(l, inp);
    /** header */
    // let ihdr = fnc.el({ t: 'div', cl: inpClasses.header, txt: _ustr.itm[0] })
    let ihdr = (0, func_1.bh)({ tag: 'div', classList: [inpClasses.header], textContent: _ustr.itm[0] });
    cont.append(ihdr);
    (0, ml_prosp_panel_1.separator)(cont);
    /** arrival rate */
    let arcombo = (0, tcombo_1.tcombo)({ top: _ustr.arr[0], ch: _ustr.arr[1] }, inp.core.art.type);
    moComboForArrivalRate(arcombo, inp);
    cont.appendChild(arcombo);
    let sel = arcombo.getElementsByTagName('select')[0];
    sel.onchange = () => {
        /** set value for input on arr type changed */
        inp.core.art.type = Number(sel.value);
        moComboForArrivalRate(arcombo, inp);
    };
    // fnc.separator(cont, true)
    (0, ml_prosp_panel_1.separator)(cont);
    /** runtime */
    let rtc = (0, tcombo_1.tcombo)({ top: _ustr.rtm[0], ch: _ustr.rtm[1] }, inp.core.rtm.type);
    moComboForRuntime(rtc, inp);
    cont.appendChild(rtc);
    let rtcsel = rtc.getElementsByTagName('select')[0];
    rtcsel.onchange = () => {
        inp.core.rtm.type = Number(rtcsel.value);
        moComboForRuntime(rtc, inp);
    };
    (0, ml_prosp_panel_1.separator)(cont);
    /** sourced type */
    function getColor() {
        return "hsl(" + (360 * Math.random()).toFixed(2) + ',' +
            (25 + 70 * Math.random()).toFixed(2) + '%,' +
            (85 + 10 * Math.random()).toFixed(2) + '%)';
    }
    let stcont = (0, func_1.bh)({ tag: 'div', classList: [inpClasses.stcont] });
    let stlab = (0, func_1.bh)({ tag: 'div', classList: [inpClasses.stlab], textContent: _ustr.srt[0] + ":" });
    let stcolordiv = (0, func_1.bh)({ tag: 'div', classList: [inpClasses.sttcl] });
    let sttext = (0, func_1.bh)({ tag: 'input', classList: [inpClasses.sttext] });
    sttext.type = 'text';
    sttext.value = inp.core.sourcedType;
    sttext.maxLength = 26;
    stcont.appendChild(stlab);
    stcont.appendChild(sttext);
    stcont.appendChild(stcolordiv);
    cont.appendChild(stcont);
    // separator(cont)
    /** characteristic */
    let chcont = (0, func_1.bh)({ tag: 'div', classList: [inpClasses.chcont] });
    let chlab = (0, func_1.bh)({ tag: 'div', classList: [inpClasses.chlab], textContent: _ustr.char + ":" });
    let chtext = (0, func_1.bh)({ tag: 'input', classList: [inpClasses.chtext] });
    let chcolordiv = (0, func_1.bh)({ tag: 'div', classList: [inpClasses.chcl] });
    chtext.type = 'text';
    chtext.value = inp.core.char;
    chtext.maxLength = 26;
    chcont.appendChild(chlab);
    chcont.appendChild(chtext);
    chcont.appendChild(chcolordiv);
    cont.append(chcont);
    /** quality */
    let qc = (0, tcombo_1.tcombo)({ top: _ustr.qty[0], ch: _ustr.qty[1] }, inp.core.qty);
    cont.appendChild(qc);
    let qsel = qc.getElementsByTagName('select')[0];
    qsel.onchange = () => {
        inp.core.qty = Number(qsel.value);
    };
    // fnc.separator(cont)
    //!! -------------------------------------------------------------------
    //!! ----------------------------- APP CORE-----------------------------
    //!! -------------------------------------------------------------------
    // /** current app instance */
    // const _app = getApp().core
    // /** text representation */
    // const _apptr = tr.tr_app(l, _app)
    // enum modelClasses {
    //     model = 'mpt_app_model_text',
    //     ID = 'mpt_app_ID_text',
    //     bd = 'mpt_app_bd_text',
    //     cd = 'mpt_app_cd_text',
    //     code = 'mpt_app_codeCont_text',
    //     codeDiv = 'mpt_app_codeDiv_text',
    //     codeInp = 'mpt_app_codeInt_text',
    //     vers = 'mpt_app_vers_text',
    //     versDiv = 'mpt_app_versDiv_text',
    //     versInp = 'mpt_app_versInp_text',
    //     var = 'mpt_app_var_text',
    //     varDiv = 'mpt_app_varDiv_text',
    //     varInp = 'mpt_app_varInp_text',
    //     name = 'mpt_app_name_text',
    //     nameDiv = 'mpt_app_nameDiv_text',
    //     nameInp = 'mpt_app_nameInp_text',
    //     owner = 'mpt_app_owner_text',
    //     ownerDiv = 'mpt_app_ownerDiv_text',
    //     ownerInp = 'mpt_app_ownerInp_text',
    //     desc = 'mpt_app_desc_text',
    //     descAr = 'mpt_app_descAr_text',
    // }
    // // time on ISO 8601
    // // -------------- Model
    // cont.appendChild(fnc.el({ t: 'div', cl: modelClasses.model, txt: _apptr.itm[0] }))
    // fnc.separator(cont)
    // // -------------- Name
    // let _nameDiv = fnc.el({ t: 'div', cl: modelClasses.nameDiv, txt: _apptr.name[0] + ':' })
    // let _nameInp = fnc.el({ t: 'input', cl: modelClasses.nameInp }) as HTMLInputElement
    // _nameInp.type = 'text'
    // _nameInp.maxLength = 26
    // _nameInp.value = _app.appCore.name
    // _nameInp.onchange = () => {
    //     _app.appCore.name = _nameInp.value
    // }
    // _nameInp.onkeyup = (e) => {
    //     fnc.anime(inp)
    //     if (e.ctrlKey) {
    //         if (e.key === 'Enter') {
    //             _nameInp.blur()
    //         }
    //     }
    //     if (e.key === 'Enter') {
    //         return;
    //     }
    //     if (e.key === 'Escape') {
    //         _nameInp.blur()
    //     }
    // }
    // let _nameCont = fnc.el({ t: 'div', cl: modelClasses.name })
    // _nameCont.appendChild(_nameDiv)
    // _nameCont.appendChild(_nameInp)
    // cont.append(_nameCont)
    // fnc.separator(cont)
    // // -------------- Code
    // let _codeDiv = fnc.el({ t: 'div', cl: modelClasses.codeDiv, txt: _apptr.code[0] + ':' })
    // let _codeInp = fnc.el({ t: 'input', cl: modelClasses.codeInp }) as HTMLInputElement
    // _codeInp.type = 'text'
    // _codeInp.maxLength = 13
    // _codeInp.value = _app.appCore.code
    // _codeInp.onchange = () => {
    //     _app.appCore.code = _codeInp.value
    // }
    // _codeInp.onkeyup = (e) => {
    //     fnc.anime(inp)
    //     if (e.ctrlKey) {
    //         if (e.key === 'Enter') {
    //             _codeInp.blur()
    //         }
    //     }
    //     if (e.key === 'Enter') {
    //         return;
    //     }
    //     if (e.key === 'Escape') {
    //         _codeInp.blur()
    //     }
    // }
    // let _codeCont = fnc.el({ t: 'div', cl: modelClasses.code })
    // _codeCont.appendChild(_codeDiv)
    // _codeCont.appendChild(_codeInp)
    // cont.append(_codeCont)
    // // fnc.separator(cont)
    // // -------------- Version 
    // let _versDiv = fnc.el({ t: 'div', cl: modelClasses.versDiv, txt: _apptr.version[0] + ':' })
    // let _versInp = fnc.el({ t: 'input', cl: modelClasses.versInp }) as HTMLInputElement
    // _versInp.type = 'text'
    // _versInp.maxLength = 13
    // _versInp.value = _app.appCore.version
    // _versInp.onchange = () => {
    //     _app.appCore.version = _versInp.value
    // }
    // _versInp.onkeyup = (e) => {
    //     fnc.anime(inp)
    //     if (e.ctrlKey) {
    //         if (e.key === 'Enter') {
    //             _versInp.blur()
    //         }
    //     }
    //     if (e.key === 'Enter') {
    //         return;
    //     }
    //     if (e.key === 'Escape') {
    //         _versInp.blur()
    //     }
    // }
    // let _versCont = fnc.el({ t: 'div', cl: modelClasses.vers })
    // _versCont.appendChild(_versDiv)
    // _versCont.appendChild(_versInp)
    // cont.append(_versCont)
    // // -------------- Variant
    // let _varDiv = fnc.el({ t: 'div', cl: modelClasses.varDiv, txt: _apptr.variant[0] + ':' })
    // let _varInp = fnc.el({ t: 'input', cl: modelClasses.varInp }) as HTMLInputElement
    // _varInp.type = 'text'
    // _varInp.maxLength = 13
    // _varInp.value = _app.appCore.variant
    // _varInp.onchange = () => {
    //     _app.appCore.variant = _varInp.value
    // }
    // _varInp.onkeyup = (e) => {
    //     fnc.anime(inp)
    //     if (e.ctrlKey) {
    //         if (e.key === 'Enter') {
    //             _varInp.blur()
    //         }
    //     }
    //     if (e.key === 'Enter') {
    //         return;
    //     }
    //     if (e.key === 'Escape') {
    //         _varInp.blur()
    //     }
    // }
    // let _varCont = fnc.el({ t: 'div', cl: modelClasses.var })
    // _varCont.appendChild(_varDiv)
    // _varCont.appendChild(_varInp)
    // cont.append(_varCont)
    // // -------------- Owner 
    // let _ownerDiv = fnc.el({ t: 'div', cl: modelClasses.ownerDiv, txt: _apptr.owner[0] + ':' })
    // let _ownerInp = fnc.el({ t: 'input', cl: modelClasses.ownerInp }) as HTMLInputElement
    // _ownerInp.type = 'text'
    // _ownerInp.maxLength = 13
    // _ownerInp.value = _app.appCore.owner
    // _ownerInp.onchange = () => {
    //     _app.appCore.owner = _ownerInp.value
    // }
    // _ownerInp.onkeyup = (e) => {
    //     fnc.anime(inp)
    //     if (e.ctrlKey) {
    //         if (e.key === 'Enter') {
    //             _ownerInp.blur()
    //         }
    //     }
    //     if (e.key === 'Enter') {
    //         return;
    //     }
    //     if (e.key === 'Escape') {
    //         _ownerInp.blur()
    //     }
    // }
    // let _ownerCont = fnc.el({ t: 'div', cl: modelClasses.owner })
    // _ownerCont.appendChild(_ownerDiv)
    // _ownerCont.appendChild(_ownerInp)
    // cont.append(_ownerCont)
    // fnc.separator(cont)
    // // -------------- Description
    // let _desc = fnc.el({ t: 'div', cl: modelClasses.desc })
    // let _descArr = ttarea()
    // let realT = _descArr.getElementsByClassName('mpt_ttextarea')[0] as HTMLInputElement
    // realT.setAttribute('placeholder', 'description/penerangan/描述, 描述')
    // realT.value = _app.appCore.desc
    // realT.onchange = () => { _app.appCore.desc = realT.value; fnc.anime(inp) }
    // realT.oninput = () => { fnc.anime(inp) }
    // _desc.appendChild(_descArr)
    // cont.appendChild(_desc)
    // fnc.separator(cont)
    // // -------------- ID 
    // let _idd = fnc.el({ t: 'div', cl: modelClasses.ID, txt: fnc.trstr(_app.appCore.id, 28, fnc.toTrimPos.mid, '...') })
    // _idd.onpointerdown = () => { navigator.clipboard.writeText(_app.appCore.id) }
    // cont.appendChild(_idd)
    // // -------------- Creation date
    // let _bd = fnc.el({ t: 'div', cl: modelClasses.bd, txt: new Date(_app.appCore.bd).toISOString() })
    // _bd.onpointerdown = () => { navigator.clipboard.writeText(_app.appCore.bd.toString()) }
    // cont.appendChild(_bd)
    // // -------------- Modified
    // let _cd = fnc.el({ t: 'div', cl: modelClasses.cd, txt: new Date(_app.appCore.cd).toISOString() })
    // _cd.onpointerdown = () => { navigator.clipboard.writeText(_app.appCore.cd.toString()) }
    // cont.appendChild(_cd)
    //!! -------------------------------------------------------------------
    //!! ----------------------------- APP CORE-----------------------------
    //!! -------------------------------------------------------------------
    stcolordiv.onpointerdown = () => {
        navigator.clipboard.writeText(stcolordiv.innerText);
    };
    sttext.onchange = () => {
        inp.core.sourcedType = sttext.value;
    };
    sttext.oninput = () => {
        let c = getColor();
        stcolordiv.innerText = c;
        sttext.style.backgroundColor = c;
    };
    sttext.onkeyup = (e) => {
        if (e.ctrlKey) {
            if (e.key === 'Enter') {
                sttext.blur();
            }
        }
        if (e.key === 'Enter') {
            return;
        }
        if (e.key === 'Escape') {
            sttext.blur();
        }
    };
    chtext.onchange = () => {
        inp.core.char = chtext.value;
    };
    chtext.oninput = () => {
        let c = getColor();
        chcolordiv.innerText = c;
        chtext.style.backgroundColor = c;
    };
    chtext.onkeyup = (e) => {
        if (e.ctrlKey) {
            if (e.key === 'Enter') {
                chtext.blur();
            }
        }
        if (e.key === 'Enter') {
            return;
        }
        if (e.key === 'Escape') {
            chtext.blur();
        }
    };
    /** footer */
    // fnc.setFooter(cont, inp)
    return cont;
};
exports.ui_inp = ui_inp;
let numb1;
let numb2;
const moComboForRuntime = (comb, inp) => {
    let sel = comb.getElementsByTagName('select')[0];
    numb1 ? numb1.remove() : 0;
    numb2 ? numb2.remove() : 0;
    switch (inp.core.rtm.type) {
        case sim_1.sim.Runtime.time:
            sel.style.width = '130px';
            sel.style.marginTop = '4px';
            /** add number input */
            numb1 = (0, func_1.bh)({ tag: 'input', classList: [inpClasses.numinp] });
            numb1.type = 'number';
            numb1.value = inp.core.rtm.value.toString();
            comb.appendChild(numb1);
            console.log('num added');
            numb1.onchange = () => {
                inp.core.rtm.value = Number(numb1.value);
            };
            numb1.onkeyup = (e) => {
                if (e.ctrlKey) {
                    if (e.key === 'Enter') {
                        numb1.blur();
                    }
                }
                if (e.key === 'Enter') {
                    return;
                }
                if (e.key === 'Escape') {
                    numb1.blur();
                }
            };
            break;
        case sim_1.sim.Runtime.qty:
            sel.style.width = '130px';
            sel.style.marginTop = '4px';
            /** add num */
            numb2 = (0, func_1.bh)({ tag: 'input', classList: [inpClasses.numinp] });
            numb2.type = 'number';
            numb2.value = inp.core.rtm.value.toString();
            comb.appendChild(numb2);
            console.log('num added');
            numb2.onchange = () => {
                inp.core.rtm.value = Number(numb2.value);
            };
            numb2.onkeyup = (e) => {
                if (e.ctrlKey) {
                    if (e.key === 'Enter') {
                        numb2.blur();
                    }
                }
                if (e.key === 'Enter') {
                    return;
                }
                if (e.key === 'Escape') {
                    numb2.blur();
                }
            };
            break;
        case sim_1.sim.Runtime.constantly:
            sel.style.width = '95%';
            sel.style.marginTop = '4px';
            break;
        default:
            break;
    }
};
let numb;
let btn;
const moComboForArrivalRate = (comb, inp) => {
    let sel = comb.getElementsByTagName('select')[0];
    numb ? numb.remove() : 0;
    btn ? btn.remove() : 0;
    switch (inp.core.art.type) {
        case sim_1.sim.InputArrivalType.rate:
            sel.style.width = '130px';
            sel.style.marginTop = '4px';
            /** add number input */
            numb = (0, func_1.bh)({ tag: 'input', classList: [inpClasses.numinp] });
            numb.type = 'number';
            numb.value = inp.core.art.value.toString();
            comb.appendChild(numb);
            console.log('num added');
            numb.onchange = () => {
                inp.core.art.value = Number(numb.value);
            };
            numb.onkeyup = (e) => {
                if (e.ctrlKey) {
                    if (e.key === 'Enter') {
                        numb.blur();
                    }
                }
                if (e.key === 'Enter') {
                    return;
                }
                if (e.key === 'Escape') {
                    numb.blur();
                }
            };
            break;
        case sim_1.sim.InputArrivalType.manually:
            sel.style.width = '130px';
            sel.style.marginTop = '4px';
            /** add button */
            btn = (0, func_1.bh)({ tag: 'button', classList: [inpClasses.inpbut], textContent: 'RUN' });
            comb.appendChild(btn);
            break;
        case sim_1.sim.InputArrivalType.external:
            sel.style.width = '95%';
            sel.style.marginTop = '4px';
            break;
        default:
            break;
    }
};
