"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.app = exports.AppView = void 0;
// Code Created with love to my son, and my wife, and my mother
const tr_1 = require("../core/tr");
const docs_1 = require("../site_components/docs/docs");
const footer_1 = require("../site_components/footer/footer");
const header_1 = require("../site_components/header/header");
var AppView;
(function (AppView) {
    AppView[AppView["home"] = 0] = "home";
    AppView[AppView["docs"] = 1] = "docs";
    AppView[AppView["roadmap"] = 2] = "roadmap";
    AppView[AppView["prices"] = 3] = "prices";
    AppView[AppView["app"] = 4] = "app";
    AppView[AppView["signin"] = 5] = "signin";
})(AppView || (exports.AppView = AppView = {}));
/** core front app item */
var app;
(function (app_1) {
    let app = null;
    app_1.getApp = (l = tr_1.lang.eng) => {
        return app ? app : create(l);
    };
    const create = (l) => {
        const _header = header_1.header.create(l);
        const _footer = footer_1.footer.create(l);
        // const _home = home.create(l)
        const _home = null;
        const _docs = docs_1.docs.create(l);
        // const _docs = null
        app = {
            id: crypto.randomUUID(),
            lang: l,
            logedIn: false,
            // logedIn: true,
            translate: _translate,
            setView: _setView,
            ui: {
                header: _header,
                footer: _footer,
                home: _home,
                docs: _docs
            }
        };
        return app;
    };
    const _translate = (newLang) => {
        app.lang = newLang;
        app.ui.header.translate(newLang);
        app.ui.footer.translate(newLang);
        app.ui.home.translate(newLang);
        // app.ui.docs.translate(newLang)
    };
    const _setView = (v) => {
        switch (v) {
            case AppView.home:
                break;
            case AppView.docs:
                break;
            case AppView.roadmap:
                break;
            case AppView.prices:
                break;
            case AppView.app:
                break;
            case AppView.signin:
                break;
        }
    };
})(app || (exports.app = app = {}));
