"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.breef = void 0;
const func_1 = require("../../assest/func");
const func_2 = require("../../scripts/func");
var breef;
(function (breef) {
    breef.create = (p) => {
        var _a;
        (_a = document.querySelector('.mpts-breef')) === null || _a === void 0 ? void 0 : _a.remove();
        let _els = p.querySelectorAll('strong');
        let _words = [];
        let _c = (0, func_2.getCookie)('mpts-breef');
        const createList = () => {
            let _r = [];
            _els.forEach((el) => {
                _words.push(el.textContent);
                _r.push({
                    tag: 'div',
                    classList: ['mpts-breef-item', _c === 'hide' ? 'mpts-breef-item-hide' : 'a'],
                    textContent: (0, func_2.trstr)(el.textContent.replace(/\s+/g, ' ').trim(), 22, func_2.toTrimPos.end, '...')
                });
            });
            return _r;
        };
        let _cont = (0, func_1.bh)({ tag: 'div', classList: ['mpts-breef'] });
        if (_els.length === 0) {
            _cont.style.display = 'none';
            return { _cont, _words };
        }
        _cont = (0, func_1.bh)({
            tag: 'div',
            classList: ['mpts-breef'],
            children: [
                {
                    tag: 'div',
                    classList: ['mpts-breef-cont', _c === 'hide' ? 'mpts-breef-cont-hide' : 'a'],
                    children: [
                        {
                            tag: 'div', classList: ['mpts-breef-title-cont'], children: [
                                { tag: 'span', classList: ['mpts-breef-title'], textContent: 'Breef' },
                                { tag: 'span', classList: ['mpts-breef-title-button'], textContent: _c === 'hide' ? '+' : '-' }
                            ]
                        },
                        ...createList()
                    ]
                },
            ]
        });
        let _bs = 0;
        _c === 'hide' ? _bs = 1 : _bs = 0;
        const hide = () => {
            let _r = document.querySelector('.mpts-breef');
            _r.classList.add('mpts-breef-cont-hide');
            let _b = document.querySelector('.mpts-breef-cont');
            _b.querySelectorAll('.mpts-breef-item').forEach((el) => {
                el.classList.add('mpts-breef-item-hide');
            });
            (0, func_2.setCookie)('mpts-breef', 'hide', 365);
            _bs = 1;
        };
        const show = () => {
            let _r = document.querySelector('.mpts-breef');
            _r.classList.remove('mpts-breef-cont-hide');
            let _b = document.querySelector('.mpts-breef-cont');
            _b.querySelectorAll('.mpts-breef-item').forEach((el) => {
                el.classList.remove('mpts-breef-item-hide');
            });
            (0, func_2.setCookie)('mpts-breef', 'show', 365);
            _bs = 0;
        };
        const _bb = _cont.querySelector('.mpts-breef-title-button');
        _bb.addEventListener('pointerdown', () => {
            if (_bs === 0) {
                hide();
                _bb.textContent = '+';
            }
            else {
                show();
                _bb.textContent = '-';
            }
        });
        return { _cont, _words };
    };
})(breef || (exports.breef = breef = {}));
