"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateTranslate3d = exports._c_l = exports._c_r = exports._c_c = exports.getRandomColor = exports.lineSegmentsIntersect = exports.getRectSidesCoordinates = exports.getRectWithLineIntersection = exports.rectCenter = exports.getRectCentersLineCoordinates = exports.getGroupAbsolutePosition = exports.getSvgTransformInfo = exports.getSvgTranslate3d = exports.getRectSideByQuarter = exports.RectSide = exports.getQuarter = exports.point = exports.countStringWidth = exports.NS = exports.TDOFF = exports.CHLEN = exports.vTo01 = exports.trstr = exports.toTrimPos = exports.setObserv = exports._fetch = exports.checkPassword = exports.checkEmail = exports.getHash = void 0;
exports.executeFunctions = executeFunctions;
exports.parseTOCJSON = parseTOCJSON;
exports.flattenTOC = flattenTOC;
exports.setCookie = setCookie;
exports.getCookie = getCookie;
exports.enableDrag = enableDrag;
exports.enableCanvasPanAndZoom = enableCanvasPanAndZoom;
exports.rbb = rbb;
exports.rndString = rndString;
exports.rndInt = rndInt;
exports.extend = extend;
exports.createSVGElement = createSVGElement;
exports.splitStringByRegex = splitStringByRegex;
exports.getRelativeCoordinates = getRelativeCoordinates;
exports.csvg = csvg;
exports.hrb = hrb;
exports.cloneEventListeners = cloneEventListeners;
exports.crnd = crnd;
exports.getMousePosition = getMousePosition;
const mobx_1 = require("mobx");
const store_1 = require("../site_components/mpts/store");
/**
 * Generates a hash of the input string using specified algorithm
 * @param _s - Input string to hash
 * @param _alg - Hash algorithm to use (defaults to SHA-256)
 * @throws Error if invalid algorithm specified or hashing fails
 * @returns Promise<string> - Hexadecimal hash string
 */
const getHash = (_s_1, ...args_1) => __awaiter(void 0, [_s_1, ...args_1], void 0, function* (_s, _alg = 'SHA-256') {
    // Input validation
    if (!_s) {
        throw new Error('Input string cannot be empty');
    }
    const supportedAlgorithms = ['SHA-1', 'SHA-256', 'SHA-384', 'SHA-512'];
    if (!supportedAlgorithms.includes(_alg)) {
        throw new Error(`Unsupported algorithm: ${_alg}. Use one of: ${supportedAlgorithms.join(', ')}`);
    }
    try {
        const utf8 = new TextEncoder().encode(_s);
        const hashBuffer = yield crypto.subtle.digest(_alg, utf8);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hashHex;
    }
    catch (error) {
        throw new Error(`Failed to generate hash: ${error.message}`);
    }
});
exports.getHash = getHash;
/**
 * Validates email address
 * Requirements:
 * - Maximum length 254 characters
 * - Valid email format according to RFC 5322
 * - Supports IDN (International Domain Names)
 * - Supports IP literal addresses
 * @param _email - Email address to validate
 * @returns EmailValidationResult - Validation result with potential errors
 */
const checkEmail = (_email) => {
    const errors = [];
    if (!_email) {
        errors.push('Email address cannot be empty');
        return { isValid: false, errors };
    }
    if (_email.length > 254) {
        errors.push('Email address cannot be longer than 254 characters');
        return { isValid: false, errors };
    }
    try {
        const normalizedEmail = _email.split('@').map(part => {
            try {
                return part.includes('xn--') || /[^\x00-\x7F]/.test(part)
                    ? new URL('http://' + part).hostname
                    : part;
            }
            catch (_a) {
                return part;
            }
        }).join('@');
        const emailPattern = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
        if (!emailPattern.test(normalizedEmail)) {
            errors.push('Invalid email format. Please check if email address is correct');
        }
    }
    catch (error) {
        errors.push('Error processing email address: ' + error.message);
    }
    return {
        isValid: errors.length === 0,
        errors
    };
};
exports.checkEmail = checkEmail;
/**
 * Validates password strength
 * Requirements:
 * - Minimum length 12 characters
 * - Contains at least 2 digits
 * - Contains at least 2 uppercase letters
 * - Contains at least 2 special characters
 */
const checkPassword = (_password) => {
    const errors = [];
    const validations = [
        {
            test: (pwd) => pwd.length >= 12 && pwd.length <= 128,
            message: 'Password must be between 12 and 128 characters'
        },
        {
            test: (pwd) => (pwd.match(/[0-9]/g) || []).length >= 2,
            message: 'Password must contain at least 2 digits'
        },
        {
            test: (pwd) => (pwd.match(/[A-Z]/g) || []).length >= 2,
            message: 'Password must contain at least 2 uppercase letters'
        },
        {
            test: (pwd) => (pwd.match(/[^0-9a-zA-Z]/g) || []).length >= 2,
            message: 'Password must contain at least 2 special characters'
        }
    ];
    validations.forEach(validation => {
        if (!validation.test(_password)) {
            errors.push(validation.message);
        }
    });
    return {
        isValid: errors.length === 0,
        errors
    };
};
exports.checkPassword = checkPassword;
/**
 * Fetches an HTML file from the given path and returns it as an HTMLElement.
 *
 * @param {string} p - The path to the HTML file to fetch.
 * @returns {Promise<HTMLElement>} - A promise that resolves to the fetched HTMLElement.
 */
const _fetch = (p) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        // Fetch the HTML file from the given path
        const response = yield fetch(p);
        // Check if the response is not ok (status code is not in the range 200-299)
        if (!response.ok) {
            console.error("Fetch error:", p);
            return null;
            // throw new Error("Network response was not ok");
        }
        // Parse the response text as HTML
        const data = yield response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, 'text/html');
        // Return the first child of the body element as an HTMLElement
        return doc.body.firstChild;
    }
    catch (error) {
        // Log any errors that occur during the fetch or parsing process
        console.error("Fetch error:", error);
        return null;
    }
});
exports._fetch = _fetch;
function executeFunctions(funcs) {
    funcs.forEach(func => func());
}
// set observer
const setObserv = (parent, childID, todo) => {
    const observer = new MutationObserver((mutations, observer) => {
        mutations.forEach(mutation => {
            if (mutation.type === 'childList') {
                const newElement = document.querySelector(`#${childID}`);
                if (newElement) {
                    observer.disconnect();
                    executeFunctions(todo);
                }
            }
            ;
        });
    });
    observer.observe(parent, { childList: true, subtree: true });
};
exports.setObserv = setObserv;
/**
 * Transforms a nested JSON structure into a Node's. parseTOCJSON
 */
function parseTOCJSON(json) {
    const root = { key: json.key, val: json.val, children: [] };
    const lookup = new Map();
    lookup.set(root.key, root);
    const stack = [];
    if (json.sub)
        stack.push({ parent: root, sub: json.sub });
    while (stack.length > 0) {
        const { parent, sub } = stack.pop();
        for (const node of sub) {
            const child = { key: node.key, val: node.val, children: [] };
            parent.children.push(child);
            lookup.set(child.key, child);
            if (node.sub) {
                stack.push({ parent: child, sub: node.sub });
            }
        }
    }
    return { root, lookup };
}
/**
 * Returns a flat array of TOCItem elements from the given ParsedTree.
 * @param parsedTree - Result of parseTOCJSON
 * @returns TOCItem[] - Flat array of elements
 */
function flattenTOC(parsedTree) {
    const result = [];
    function traverse(node) {
        result.push(node);
        if (node.children) {
            node.children.forEach(traverse);
        }
    }
    traverse(parsedTree.root);
    return result;
}
/**
 * Sets a cookie with the given name and value.
 * Optionally, sets an expiration in days.
 *
 * @param name - The name of the cookie.
 * @param value - The value to store in the cookie.
 * @param days - (Optional) Number of days until the cookie expires.
 */
function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${encodeURIComponent(value)}${expires}; path=/`;
}
/**
 * Retrieves the value of a cookie by its name.
 *
 * @param name - The name of the cookie.
 * @returns The cookie's value if found, otherwise null.
 */
function getCookie(name) {
    const nameEQ = `${name}=`;
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
        const trimmed = cookie.trim();
        if (trimmed.startsWith(nameEQ)) {
            return decodeURIComponent(trimmed.substring(nameEQ.length));
        }
    }
    return null;
}
/**
 * part of string
 */
var toTrimPos;
(function (toTrimPos) {
    toTrimPos[toTrimPos["start"] = 0] = "start";
    toTrimPos[toTrimPos["mid"] = 1] = "mid";
    toTrimPos[toTrimPos["end"] = 2] = "end";
})(toTrimPos || (exports.toTrimPos = toTrimPos = {}));
/**
 * trimming a string to a specific length
 * @param str string to trm
 * @param targetLen resulted lenght
 * @param trpos part of string to trim
 * @param char wildcard
 * @returns string of specified size
  //! @abstract not good at edge values
 */
const trstr = (str = crypto.randomUUID(), targetLen = 16, trpos = toTrimPos.end, char = '...') => {
    // Return unchanged if string is already within target length
    if (str.length <= targetLen)
        return str;
    const effectiveLen = targetLen - char.length;
    if (effectiveLen <= 0)
        return char.slice(0, targetLen);
    switch (trpos) {
        case toTrimPos.start: // trim from start: show ending part
            return char + str.slice(-effectiveLen);
        case toTrimPos.mid: { // trim middle: split preserved parts
            const left = Math.floor(effectiveLen / 2);
            const right = effectiveLen - left;
            return str.slice(0, left) + char + str.slice(-right);
        }
        case toTrimPos.end: // trim from end: show beginning part
        default:
            return str.slice(0, effectiveLen) + char;
    }
};
exports.trstr = trstr;
/**
*  scale value to 0 - 1 row, if value < min or > max, result can be less 0 or gretter then 1
*
* @param v input value
* @param vMin input value min
* @param vMax input value max
* @param d number of digits in 'return', if '0' => round up to integer
*/
const vTo01 = (v, vMin, vMax, d) => {
    return !(d || d === 0)
        ? (v - vMin) / (vMax - vMin)
        : Number(((v - vMin) / (vMax - vMin)).toFixed(d));
};
exports.vTo01 = vTo01;
//#region DRAG
/** enable drag behavior for element */
function enableDrag(el) {
    let isDragging = false;
    let startX = 0, startY = 0;
    let initialTranslateX = 0, initialTranslateY = 0;
    let currentTranslateX = 0, currentTranslateY = 0;
    const gridSize = 8;
    function getCurrentTranslate(el) {
        const style = window.getComputedStyle(el);
        if (!style.transform || style.transform === 'none') {
            return { x: 0, y: 0 };
        }
        const matrix = new DOMMatrixReadOnly(style.transform);
        return { x: matrix.m41, y: matrix.m42 };
    }
    const onPointerDown = (e) => {
        isDragging = true;
        // Преобразуем координаты указателя в локальную систему координат родительского элемента
        const svg = el.ownerSVGElement;
        const pt = svg.createSVGPoint();
        pt.x = e.clientX;
        pt.y = e.clientY;
        const localPoint = pt.matrixTransform(el.parentElement.getScreenCTM().inverse());
        startX = localPoint.x;
        startY = localPoint.y;
        const { x, y } = getCurrentTranslate(el);
        initialTranslateX = x;
        initialTranslateY = y;
        currentTranslateX = x;
        currentTranslateY = y;
        el.dispatchEvent(new CustomEvent('dragStart', {
            detail: { startX, startY, initialTranslateX, initialTranslateY }
        }));
        el.style.willChange = 'transform';
        el.setPointerCapture(e.pointerId);
        // Подписываемся на глобальные события, чтобы поймать pointerup даже при изменении системы координат
        document.addEventListener('pointerup', onPointerUp);
        document.addEventListener('pointercancel', onPointerUp);
    };
    const onPointerMove = (e) => {
        if (!isDragging)
            return;
        // Преобразуем текущие координаты указателя в локальную систему родительского элемента
        const svg = el.ownerSVGElement;
        const pt = svg.createSVGPoint();
        pt.x = e.clientX;
        pt.y = e.clientY;
        const localPoint = pt.matrixTransform(el.parentElement.getScreenCTM().inverse());
        // Вычисляем смещение в локальной системе координат
        const dx = localPoint.x - startX;
        const dy = localPoint.y - startY;
        // Привязка к сетке: если зажата клавиша shift, используем шаг gridSize, иначе шаг 1
        const gridStep = e.shiftKey ? gridSize : 1;
        const dxRounded = rbb(dx, gridStep);
        const dyRounded = rbb(dy, gridStep);
        const newX = initialTranslateX + dxRounded;
        const newY = initialTranslateY + dyRounded;
        currentTranslateX = newX;
        currentTranslateY = newY;
        el.style.transform = `translate3d(${newX}px, ${newY}px, 0)`;
        let _storedItem = store_1.store.all.getItem(el.id);
        (0, mobx_1.runInAction)(() => {
            if (_storedItem)
                _storedItem.pres.xy = [newX, newY];
        });
        el.dispatchEvent(new CustomEvent('dragMove', {
            detail: { shift: e.shiftKey, ctrl: e.ctrlKey, currentTranslateX, currentTranslateY, dx: dxRounded, dy: dyRounded }
        }));
    };
    const onPointerUp = (e) => {
        if (!isDragging)
            return;
        isDragging = false;
        // Финальное позиционирование с привязкой к сетке
        const snappedX = rbb(currentTranslateX, gridSize);
        const snappedY = rbb(currentTranslateY, gridSize);
        el.style.transform = `translate3d(${snappedX}px, ${snappedY}px, 0)`;
        currentTranslateX = snappedX;
        currentTranslateY = snappedY;
        el.releasePointerCapture(e.pointerId);
        el.style.willChange = 'auto';
        el.dispatchEvent(new CustomEvent('dragEnd', {
            detail: { shift: e.shiftKey, ctrl: e.ctrlKey, snappedX, snappedY }
        }));
        // Убираем глобальные слушатели, так как перетаскивание завершено
        document.removeEventListener('pointerup', onPointerUp);
        document.removeEventListener('pointercancel', onPointerUp);
    };
    el.addEventListener('pointerdown', onPointerDown);
    el.addEventListener('pointermove', onPointerMove);
    // Убираем локальные подписки на pointerup/pointercancel, чтобы избежать проблем при смене контейнера
    // el.addEventListener('pointerup', onPointerUp);
    // el.addEventListener('pointercancel', onPointerUp);
}
// ===================================================== AS OPTION TO USE IN FUTURE
/** enable drag behavior for element */
// export function enableDrag(el: SVGElement) {
//     let isDragging = false;
//     let startX = 0, startY = 0;
//     let initialTranslateX = 0, initialTranslateY = 0;
//     let currentTranslateX = 0, currentTranslateY = 0;
//     const gridSize = 8;
//     function getCurrentTranslate(el: SVGElement) {
//         const style = window.getComputedStyle(el);
//         if (!style.transform || style.transform === 'none') {
//             return { x: 0, y: 0 };
//         }
//         const matrix = new DOMMatrixReadOnly(style.transform);
//         return { x: matrix.m41, y: matrix.m42 };
//     }
//     const onPointerDown = (e: PointerEvent) => {
//         isDragging = true;
//         const svg = el.ownerSVGElement;
//         const pt = svg.createSVGPoint();
//         pt.x = e.clientX;
//         pt.y = e.clientY;
//         // Преобразуем координаты по отношению к родительскому элементу
//         const parent = el.parentElement as unknown as SVGGraphicsElement;
//         const ctmInverse = parent.getScreenCTM()?.inverse();
//         if (!ctmInverse) return;
//         const localPoint = pt.matrixTransform(ctmInverse);
//         startX = localPoint.x;
//         startY = localPoint.y;
//         const { x, y } = getCurrentTranslate(el);
//         initialTranslateX = x;
//         initialTranslateY = y;
//         currentTranslateX = x;
//         currentTranslateY = y;
//         el.dispatchEvent(new CustomEvent('dragStart', {
//             detail: { startX, startY, initialTranslateX, initialTranslateY }
//         }));
//         el.style.willChange = 'transform';
//         el.setPointerCapture(e.pointerId);
//         // Добавляем глобальные слушатели, чтобы отслеживать события вне элемента
//         window.addEventListener('pointermove', onPointerMove);
//         window.addEventListener('pointerup', onPointerUp);
//         window.addEventListener('pointercancel', onPointerUp);
//     };
//     const onPointerMove = (e: PointerEvent) => {
//         if (!isDragging) return;
//         const svg = el.ownerSVGElement;
//         const pt = svg.createSVGPoint();
//         pt.x = e.clientX;
//         pt.y = e.clientY;
//         const parent = el.parentElement as unknown as SVGGraphicsElement;
//         const ctmInverse = parent.getScreenCTM()?.inverse();
//         if (!ctmInverse) return;
//         const localPoint = pt.matrixTransform(ctmInverse);
//         const dx = localPoint.x - startX;
//         const dy = localPoint.y - startY;
//         const gridStep = e.shiftKey ? gridSize : 1;
//         // Функция rbb округляет смещение к ближайшему кратному gridStep
//         const dxRounded = rbb(dx, gridStep);
//         const dyRounded = rbb(dy, gridStep);
//         const newX = initialTranslateX + dxRounded;
//         const newY = initialTranslateY + dyRounded;
//         currentTranslateX = newX;
//         currentTranslateY = newY;
//         el.style.transform = `translate3d(${newX}px, ${newY}px, 0)`;
//         // Обновляем позицию в хранилище через MobX
//         const _storedItem = store.all.getItem(el.id);
//         runInAction(() => {
//             if (_storedItem) _storedItem.pres.xy = [newX, newY];
//         });
//         el.dispatchEvent(new CustomEvent('dragMove', {
//             detail: {
//                 shift: e.shiftKey,
//                 ctrl: e.ctrlKey,
//                 currentTranslateX,
//                 currentTranslateY,
//                 dx: dxRounded,
//                 dy: dyRounded
//             }
//         }));
//     };
//     const onPointerUp = (e: PointerEvent) => {
//         if (!isDragging) return;
//         isDragging = false;
//         const snappedX = rbb(currentTranslateX, gridSize);
//         const snappedY = rbb(currentTranslateY, gridSize);
//         el.style.transform = `translate3d(${snappedX}px, ${snappedY}px, 0)`;
//         currentTranslateX = snappedX;
//         currentTranslateY = snappedY;
//         el.releasePointerCapture(e.pointerId);
//         el.style.willChange = 'auto';
//         el.dispatchEvent(new CustomEvent('dragEnd', {
//             detail: { shift: e.shiftKey, ctrl: e.ctrlKey, snappedX, snappedY }
//         }));
//         // Удаляем глобальные слушатели после завершения перетаскивания
//         window.removeEventListener('pointermove', onPointerMove);
//         window.removeEventListener('pointerup', onPointerUp);
//         window.removeEventListener('pointercancel', onPointerUp);
//     };
//     // Начинаем отслеживание перетаскивания по событию pointerdown
//     el.addEventListener('pointerdown', onPointerDown);
// }
// /** enable drag behavior for element */
// export function enableDrag(el: SVGElement) {
//     let isDragging = false;
//     let startX = 0, startY = 0;
//     let initialTranslateX = 0, initialTranslateY = 0;
//     let currentTranslateX = 0, currentTranslateY = 0;
//     const gridSize = 8;
//     function getCurrentTranslate(el: SVGElement) {
//         const style = window.getComputedStyle(el);
//         if (!style.transform || style.transform === 'none') {
//             return { x: 0, y: 0 };
//         }
//         const matrix = new DOMMatrixReadOnly(style.transform);
//         return { x: matrix.m41, y: matrix.m42 };
//     }
//     const onPointerDown = (e: PointerEvent) => {
//         console.log('down for drag');
//         // e.stopPropagation()
//         isDragging = true;
//         const svg = el.ownerSVGElement;
//         const pt = svg.createSVGPoint();
//         pt.x = e.clientX;
//         pt.y = e.clientY;
//         const localPoint = pt.matrixTransform((el.parentElement as unknown as SVGGraphicsElement).getScreenCTM().inverse());
//         startX = localPoint.x;
//         startY = localPoint.y;
//         const { x, y } = getCurrentTranslate(el);
//         initialTranslateX = x;
//         initialTranslateY = y;
//         currentTranslateX = x;
//         currentTranslateY = y;
//         el.dispatchEvent(new CustomEvent('dragStart', {
//             detail: { startX, startY, initialTranslateX, initialTranslateY }
//         }));
//         el.style.willChange = 'transform';
//         el.setPointerCapture(e.pointerId);
//     };
//     const onPointerMove = (e: PointerEvent) => {
//         if (!isDragging) return;
//         const svg = el.ownerSVGElement;
//         const pt = svg.createSVGPoint();
//         pt.x = e.clientX;
//         pt.y = e.clientY;
//         const localPoint = pt.matrixTransform((el.parentElement as unknown as SVGGraphicsElement).getScreenCTM().inverse());
//         const dx = localPoint.x - startX;
//         const dy = localPoint.y - startY;
//         const gridStep = e.shiftKey ? gridSize : 1;
//         const dxRounded = rbb(dx, gridStep);
//         const dyRounded = rbb(dy, gridStep);
//         const newX = initialTranslateX + dxRounded;
//         const newY = initialTranslateY + dyRounded;
//         currentTranslateX = newX;
//         currentTranslateY = newY;
//         el.style.transform = `translate3d(${newX}px, ${newY}px, 0)`;
//         el.dispatchEvent(new CustomEvent('dragMove', {
//             detail: { shift: e.shiftKey, ctrl: e.ctrlKey, currentTranslateX, currentTranslateY, dx: dxRounded, dy: dyRounded }
//         }));
//     };
//     const onPointerUp = (e: PointerEvent) => {
//         console.log('up for drag');
//         e.preventDefault();
//         if (!isDragging) return;
//         isDragging = false;
//         const snappedX = rbb(currentTranslateX, gridSize);
//         const snappedY = rbb(currentTranslateY, gridSize);
//         el.style.transform = `translate3d(${snappedX}px, ${snappedY}px, 0)`;
//         currentTranslateX = snappedX;
//         currentTranslateY = snappedY;
//         el.releasePointerCapture(e.pointerId);
//         el.style.willChange = 'auto';
//         el.dispatchEvent(new CustomEvent('dragEnd', {
//             detail: { shift: e.shiftKey, ctrl: e.ctrlKey, snappedX, snappedY }
//         }));
//     };
//     el.addEventListener('pointerdown', onPointerDown);
//     el.addEventListener('pointermove', onPointerMove);
//     el.addEventListener('pointerup', onPointerUp);
//     el.addEventListener('pointercancel', onPointerUp);
// }
/**
 * panning and zooming canvas
 * @param {HTMLElement} svgEl svg
 * @param {HTMLElement} canvasEl group on svg with elements
 * @param {HTMLElement} backgroundEl background element for panning
 */
function enableCanvasPanAndZoom(svgEl, canvasEl, backgroundEl) {
    let scale = 1;
    let translateX = 0;
    let translateY = 0;
    const gridSize = 8; // шаг сетки
    const scaleStep = 0.1;
    canvasEl.style.willChange = 'transform';
    function updateTransform() {
        canvasEl.style.transform = `translate3d(${translateX}px, ${translateY}px, 0) scale(${scale})`;
    }
    // Функция привязки холста к сетке: корректирует translate так, чтобы getBoundingClientRect() давал left/top кратные gridSize.
    function snapCanvas() {
        const rect = canvasEl.getBoundingClientRect();
        const desiredLeft = Math.round(rect.left / gridSize) * gridSize;
        const desiredTop = Math.round(rect.top / gridSize) * gridSize;
        const offsetX_screen = desiredLeft - rect.left;
        const offsetY_screen = desiredTop - rect.top;
        // При масштабировании холста масштаб (scale) применяется ко translate,
        // поэтому смещение в локальной системе получается делением на scale.
        translateX += offsetX_screen / scale;
        translateY += offsetY_screen / scale;
        updateTransform();
    }
    // Zoom с привязкой: используем debounce, чтобы после прекращения события wheel применить snap.
    let wheelTimeout;
    svgEl.addEventListener('wheel', (e) => {
        e.preventDefault();
        const rect = svgEl.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;
        const oldScale = scale;
        if (e.deltaY < 0) {
            scale += scaleStep;
        }
        else {
            scale = Math.max(0.1, scale - scaleStep);
        }
        // Корректируем translate так, чтобы точка под курсором оставалась на месте
        translateX = mouseX - ((mouseX - translateX) / oldScale) * scale;
        translateY = mouseY - ((mouseY - translateY) / oldScale) * scale;
        updateTransform();
        clearTimeout(wheelTimeout);
        wheelTimeout = setTimeout(snapCanvas, 200);
    }, { passive: false });
    // Panning холста через прозрачный фон
    let isPanning = false;
    let panStartX = 0, panStartY = 0;
    let panInitialTranslateX = 0, panInitialTranslateY = 0;
    backgroundEl.addEventListener('pointerdown', (e) => {
        // if (e.target.id !== 'background') return;
        if (e.shiftKey)
            return;
        isPanning = true;
        panStartX = e.clientX;
        panStartY = e.clientY;
        panInitialTranslateX = translateX;
        panInitialTranslateY = translateY;
        backgroundEl.setPointerCapture(e.pointerId);
        // backgroundEl.style.cursor = 'grabbing';
    });
    backgroundEl.addEventListener('pointermove', (e) => {
        if (!isPanning)
            return;
        const dx = e.clientX - panStartX;
        const dy = e.clientY - panStartY;
        translateX = panInitialTranslateX + dx;
        translateY = panInitialTranslateY + dy;
        requestAnimationFrame(updateTransform);
    });
    backgroundEl.addEventListener('pointerup', (e) => {
        isPanning = false;
        backgroundEl.releasePointerCapture(e.pointerId);
        // backgroundEl.style.cursor = 'grab';
        requestAnimationFrame(snapCanvas);
    });
    backgroundEl.addEventListener('pointercancel', (e) => {
        isPanning = false;
        backgroundEl.releasePointerCapture(e.pointerId);
        // backgroundEl.style.cursor = 'grab';
        requestAnimationFrame(snapCanvas);
    });
}
// use panning and zooming
// const svgCanvas = document.getElementById('svgCanvas');
// const canvas = document.getElementById('canvas');
// const background = document.getElementById('background');
// enableCanvasPanAndZoom(svgCanvas, canvas, background);
/** round a number to nearest multiple
     * @param x the number to be rounded
     * @param m multiple
     */
function rbb(x, m) {
    return Math.round(x / m) * m;
}
//#region CONST
/** character length */
exports.CHLEN = 7.345;
/** offset for text in rect */
exports.TDOFF = { x: 4, y: 12 };
/** SVG namespace */
exports.NS = 'http://www.w3.org/2000/svg';
// count string width for 12px JBMono
const countStringWidth = (s, ll = false) => {
    if (s == '')
        return 16;
    function countCJK(text) {
        const matches = text.match(/[\u4E00-\u9FFF]/g);
        return matches ? matches.length : 0;
    }
    let _ic = countCJK(s) * exports.CHLEN;
    // let _add = countCJK(s) ? 8 : 0
    return (rbb((s.length * exports.CHLEN) + _ic, 8) + (!ll ? 8 : 16));
};
exports.countStringWidth = countStringWidth;
/**
 * create custom lenght string
 * @param length lenght for resultrs string
 * @returns
 */
function rndString(length) {
    let result = '';
    // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789ЦУКЕНГШЩЗХФЫВАПРОЛДЖЯСМИТЬБЮйцукенгшщзхъфывапролджэячсмитьбю';
    // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijk俀俁係促俄俅俆俇俈俉俊俋俌俍俎俏丑丒专且丕世丗丘丙业丛东丝lmnopqrstuvwxyz0123456789ЦУКЕНГШЩЗХФЫВАПРОЛДЖЯСМИТЬБЮйцукенгшщзхъфывапролджэячсмитьбю';
    // const characters = '俀俁係促俄俅俆俇俈俉俊俋俌俍俎俏丑丒专且丕世丗丘丙业丛东丝';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}
// export function measureTextWidth(text, font = 'JetBrains Mono') {
//     const canvas = document.createElement("canvas");
//     const context = canvas.getContext("2d");
//     context.font = font + " 12px";
//     return context.measureText(text).width;
// }
/** random int in range */
function rndInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}
/**
 * union two objects
 * @param source 1
 * @param addition 2
 * @returns 1 + 2
 */
function extend(source, addition) {
    let result = source;
    for (let key of Object.keys(addition)) {
        result[key] = addition[key];
    }
    return result;
}
var point;
(function (point) {
    point.create = (x, y) => {
        return [x, y];
    };
    point.move = (p, d) => {
        p[0] += d[0];
        p[1] += d[1];
        return p;
    };
    point.getDistance = (p1, p2) => {
        let a = p1[0] - p2[0];
        let b = p1[1] - p2[1];
        return Math.sqrt(a * a + b * b);
    };
    /**
     * text representation of point coordinates
     * @param p the point that we need to stringylise
     * @returns string like 'x,y'
     */
    point.toString = (p) => {
        return `${p[0]},${p[1]}`;
    };
})(point || (exports.point = point = {}));
/**
 *              |
 *         2    |   1
 *              |
 *      ------------------
 *              |
 *         3    |   4
 *              |
 * returns a quarter in the coordinate plane
 */
const getQuarter = (from, to) => {
    const vectorX = to[0] - from[0];
    const vectorY = to[1] - from[1];
    if (vectorX > 0 && vectorY > 0) {
        return 1;
    }
    else if (vectorX > 0 && vectorY < 0) {
        return 2;
    }
    else if (vectorX < 0 && vectorY < 0) {
        return 3;
    }
    else if (vectorX < 0 && vectorY > 0) {
        return 4;
    }
    else {
        return 0;
    }
};
exports.getQuarter = getQuarter;
/** rect sides */
var RectSide;
(function (RectSide) {
    RectSide["top"] = "top";
    RectSide["bottom"] = "bottom";
    RectSide["left"] = "left";
    RectSide["right"] = "right";
})(RectSide || (exports.RectSide = RectSide = {}));
/** get rect sides by quarter in the coordinate plane */
const getRectSideByQuarter = (rs) => {
    switch (rs) {
        case 1:
            return [RectSide.top, RectSide.left];
        case 2:
            return [RectSide.left, RectSide.bottom];
        case 3:
            return [RectSide.bottom, RectSide.right];
        case 4:
            return [RectSide.right, RectSide.top];
        default:
            return [-1, -1];
    }
};
exports.getRectSideByQuarter = getRectSideByQuarter;
/**
    * create svg element
    * @param s innerHTML for new SVGElement
    */
function createSVGElement(s) {
    const _template = document.createElementNS(exports.NS, 'template');
    _template.innerHTML = s;
    return _template.firstElementChild;
}
/**
 * Return translate3d components from the transform CSS property for the SVG element.
 *
 * @param element - SVG element for which the transformation values are extracted.
 * @returns Object with x, y, and z fields representing the translation, or null if no transformation is present.
 */
const getSvgTranslate3d = (element) => {
    // console.log('getSvgTranslate3d');
    if (!element)
        return null;
    // Get the string value of the transform property
    const transform = window.getComputedStyle(element).transform;
    // console.log(transform);
    // If no transform is set, return zero translations
    if (!transform || transform === 'none') {
        return { x: 0, y: 0, z: 0 };
    }
    // If transform starts with 'matrix3d', extract the last 3 translation values
    if (transform.startsWith('matrix3d')) {
        const values = transform
            .slice(9, -1)
            .split(',')
            .map(v => parseFloat(v.trim()));
        return { x: values[12], y: values[13], z: values[14] };
    }
    // If transform starts with 'matrix', extract the translation values for 2D transformation
    if (transform.startsWith('matrix')) {
        const values = transform
            .slice(7, -1)
            .split(',')
            .map(v => parseFloat(v.trim()));
        return { x: values[4], y: values[5], z: 0 };
    }
    return null;
};
exports.getSvgTranslate3d = getSvgTranslate3d;
const getSvgTransformInfo = (element) => {
    if (!element)
        return null;
    const ctm = element.getCTM();
    if (!ctm)
        return null;
    const x = ctm.e;
    const y = ctm.f;
    const scaleX = Math.sqrt(Math.pow(ctm.a, 2) + Math.pow(ctm.b, 2));
    const scaleY = Math.sqrt(Math.pow(ctm.c, 2) + Math.pow(ctm.d, 2));
    const rotation = Math.atan2(ctm.b, ctm.a) * (180 / Math.PI);
    const toTranslate3dString = (z = 0) => `translate3d(${x}px, ${y}px, ${z}px)`;
    return { x, y, scaleX, scaleY, rotation, toTranslate3dString };
};
exports.getSvgTransformInfo = getSvgTransformInfo;
// Функция, возвращающая абсолютную позицию группы с учетом смещения transform
const getGroupAbsolutePosition = (group) => {
    // Получаем смещение группы из свойства transform
    const translate = (0, exports.getSvgTranslate3d)(group) || { x: 0, y: 0, z: 0 };
    // Вычисляем ограничивающий прямоугольник группы, который учитывает координаты дочерних элементов
    const bbox = group.getBBox();
    // Если в bbox.x или bbox.y будут отрицательные значения, они скорректируют итоговое положение
    return {
        x: translate.x + bbox.x,
        y: translate.y + bbox.y,
    };
};
exports.getGroupAbsolutePosition = getGroupAbsolutePosition;
function splitStringByRegex(str, chunkSize = 27) {
    const regex = new RegExp(`.{1,${chunkSize}}`, "g");
    const blocks = (str.match(regex) || []).map(block => block.trimStart().trimEnd());
    return {
        blocks,
        count: blocks.length,
    };
}
// #region getRelativeCoordinates
// ~  Get relative coordinates of an element
// 1. Get the screen CTM of the element and the relativeTo element.
// 2. Calculate the matrix to transform the element's coordinates to the relativeTo element's coordinates.
// 3. Create an SVG point at the element's top-left corner.
// 4. Transform the point to the relativeTo element's coordinates.
// 5. Calculate the width and height of the element's bounding box.
// 6. Return the relative coordinates and dimensions.
function getRelativeCoordinates(element, relativeTo) {
    const svg = element.ownerSVGElement;
    const elementCTM = element.getScreenCTM();
    const relativeCTM = relativeTo.getScreenCTM();
    const toRelativeMatrix = relativeCTM.inverse().multiply(elementCTM);
    const bbox = element.getBBox();
    const point = svg.createSVGPoint();
    point.x = bbox.x;
    point.y = bbox.y;
    const transformed = point.matrixTransform(toRelativeMatrix);
    return {
        x: transformed.x,
        y: transformed.y,
        width: bbox.width,
        height: bbox.height
    };
}
/**
 * line beetwen two rect
 * @param r1 DOMRect
 * @param r2 DOMRect
 */
const getRectCentersLineCoordinates = (r1, r2) => {
    let c1 = (0, exports.rectCenter)(r1);
    let c2 = (0, exports.rectCenter)(r2);
    return [c1[0], c1[1], c2[0], c2[1]];
};
exports.getRectCentersLineCoordinates = getRectCentersLineCoordinates;
/** return [x,y] for rect center */
const rectCenter = (r) => {
    return [(r.x + r.width / 2 + window.scrollX), (r.y + r.height / 2 + window.scrollY)];
};
exports.rectCenter = rectCenter;
/**
 * get Rect With Line Untersect
 * @param r DOMREct
 * @param l line [x, y ,x1, y1]
 */
// export const getRectWithLineUntersect = (r: DOMRect, l: [number, number, number, number]) => {
//     let rsc = getRectSidesCoordinates(r)
//     let res: [number, number][] = []
//     rsc.forEach(((el) => {
//         let int = lineSegmentsIntersect(el[1], el[2], el[3], el[4], ...l)
//         if (int != false) { res.push(int) }
//     }))
//     return res
// }
const getRectWithLineIntersection = (r, l) => {
    const rectSides = (0, exports.getRectSidesCoordinates)(r);
    const intersections = [];
    rectSides.forEach(([side, x, y, x1, y1]) => {
        const point = (0, exports.lineSegmentsIntersect)(x, y, x1, y1, ...l);
        if (point) {
            intersections.push({ side, point });
        }
    });
    return intersections;
};
exports.getRectWithLineIntersection = getRectWithLineIntersection;
/**
 * get Rect Sides Coordinates
 * @returns [RectSide, x, y ,x1, y1]
 */
const getRectSidesCoordinates = (r) => {
    let wdx = window.scrollX;
    let wdy = window.scrollY;
    let res = [];
    res.push([RectSide.top, r.x + wdx, r.y + wdy, r.x + r.width + wdx, r.y + wdy]);
    res.push([RectSide.bottom, r.x + wdx, r.y + wdy + r.height, r.x + r.width + wdx, r.y + wdy + r.height]);
    res.push([RectSide.left, r.x + wdx, r.y + wdy + r.height, r.x + wdx, r.y + wdy]);
    res.push([RectSide.right, r.x + wdx + r.width, r.y + wdy, r.x + wdx + r.width, r.y + wdy + r.height]);
    return res;
};
exports.getRectSidesCoordinates = getRectSidesCoordinates;
/** two line segment intersection point */
const lineSegmentsIntersect = (x1, y1, x2, y2, x3, y3, x4, y4) => {
    let a_dx = x2 - x1;
    let a_dy = y2 - y1;
    let b_dx = x4 - x3;
    let b_dy = y4 - y3;
    let s = (-a_dy * (x1 - x3) + a_dx * (y1 - y3)) / (-b_dx * a_dy + a_dx * b_dy);
    let t = (+b_dx * (y1 - y3) - b_dy * (x1 - x3)) / (-b_dx * a_dy + a_dx * b_dy);
    return (s >= 0 && s <= 1 && t >= 0 && t <= 1) ? [x1 + t * a_dx, y1 + t * a_dy] : false;
};
exports.lineSegmentsIntersect = lineSegmentsIntersect;
function csvg(tag, attributes = {}, classes = [], textContent) {
    const elem = document.createElementNS(exports.NS, tag);
    Object.entries(attributes).forEach(([key, value]) => {
        elem.setAttribute(key, value.toString());
    });
    classes.forEach(cls => elem.classList.add(cls));
    if (textContent && elem instanceof SVGTextElement) {
        elem.textContent = textContent;
    }
    return elem;
}
// 8 bytes random
function grb() {
    const bytes = new Uint8Array(8);
    window.crypto.getRandomValues(bytes);
    return bytes;
}
// 8 bytes random to hex
function hrb() {
    const bytes = grb();
    return 'P' + Array.from(bytes)
        .map(byte => byte.toString(16).padStart(2, '0'))
        .join('');
}
/** random hex color */
const getRandomColor = () => {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};
exports.getRandomColor = getRandomColor;
const eventMap = new WeakMap();
function cloneEventListeners(source, target) {
    const listeners = eventMap.get(source);
    if (!listeners)
        return;
    Object.entries(listeners).forEach(([type, handlers]) => {
        handlers.forEach(({ listener, options }) => {
            target.addEventListener(type, listener, options);
        });
    });
}
(() => {
    const originalAddEventListener = EventTarget.prototype.addEventListener;
    const originalRemoveEventListener = EventTarget.prototype.removeEventListener;
    EventTarget.prototype.addEventListener = function (type, listener, options) {
        if (!eventMap.has(this)) {
            eventMap.set(this, {});
        }
        const listeners = eventMap.get(this);
        if (!listeners[type]) {
            listeners[type] = [];
        }
        listeners[type].push({ listener, options });
        originalAddEventListener.call(this, type, listener, options);
    };
    EventTarget.prototype.removeEventListener = function (type, listener, options) {
        const listeners = eventMap.get(this);
        if (listeners && listeners[type]) {
            listeners[type] = listeners[type].filter(l => l.listener !== listener);
            if (listeners[type].length === 0) {
                delete listeners[type];
            }
        }
        originalRemoveEventListener.call(this, type, listener, options);
    };
})();
// create circle
/**
 * circle
 * @param parent - The parent SVG element to append the circle to
 * @param x - The x-coordinate of the circle's center
 * @param y - The y-coordinate of the circle's center
 * @param r - The radius of the circle
 * @param c - The fill color of the circle (default is 'black')
 */
const _c_c = (parent, x, y, r, c = 'black') => {
    const circle = csvg('circle', { cx: x, cy: y, r: r, fill: c }, ['mpts_temp_circle']);
    parent.appendChild(circle);
    return circle;
};
exports._c_c = _c_c;
/**
 * Creates a circle SVG element and appends it to the parent element.
 * @param parent - The parent SVG element to append the circle to.
 * @param x - The x-coordinate of the circle's center.
 * @param y - The y-coordinate of the circle's center.
 * @param r - The radius of the circle.
 * @param c - The fill color of the circle (default is 'black').
 */
// create rect
const _c_r = (parent, x, y, w, h, c = 'black') => {
    parent.appendChild(csvg('rect', { x: x, y: y, width: w, height: h, fill: c }, ['mpts_temp_rect']));
};
exports._c_r = _c_r;
/**
 * Creates a line SVG element and appends it to the parent element.
 * @param parent - The parent SVG element to append the line to.
 * @param x1 - The x-coordinate of the line's starting point.
 * @param y1 - The y-coordinate of the line's starting point.
 * @param x2 - The x-coordinate of the line's ending point.
 * @param y2 - The y-coordinate of the line's ending point.
 * @param c - The stroke color of the line (default is 'black').
 */
// create line 
const _c_l = (parent, x1, y1, x2, y2, w = 1, c = 'black', cl = [], strlk = 'butt') => {
    const _line = csvg('line', { x1: x1, y1: y1, x2: x2, y2: y2, stroke: c, 'stroke-width': `${w}`, 'stroke-linecap': strlk }, ['mpts_temp_line', ...cl]);
    parent.appendChild(_line);
    return _line;
};
exports._c_l = _c_l;
const updateTranslate3d = (element, dx, dy, d) => {
    if (d) {
        element.style.transform = `translate3d(${dx}px, ${dy}px, 0px)`;
        return;
    }
    let transform = element.style.transform;
    const regex = /translate3d\((-?\d+)px, (-?\d+)px, (-?\d+)px\)/;
    const match = transform.match(regex);
    if (match) {
        const x = parseInt(match[1], 10) + dx;
        const y = parseInt(match[2], 10) + dy;
        const z = match[3];
        element.style.transform = transform.replace(regex, `translate3d(${x}px, ${y}px, ${z}px)`);
    }
    else {
        element.style.transform += `translate3d(${dx}px, ${dy}px, 0px)`;
    }
};
exports.updateTranslate3d = updateTranslate3d;
/**
 * round number to 2 decimal places if less than 100, 1 decimal place if less than 1000, and no decimal places if greater than 1000
 * @param value - The number to round
 * @returns The rounded number as a string
 */
function crnd(value) {
    if (!value)
        return '0';
    if (value < 1) {
        return value.toFixed(3);
    }
    else if (value < 10) {
        return value.toFixed(2);
    }
    else if (value < 100) {
        return value.toFixed(1);
    }
    else if (value < 1000) {
        return value.toFixed(0);
    }
    else {
        return value.toFixed(0);
    }
}
/** get mouse position in scaled svg element */
function getMousePosition(p, transformedGroup) {
    const svg = transformedGroup.ownerSVGElement;
    if (!svg || typeof svg.createSVGPoint !== 'function') {
        throw new Error('SVG element not found or invalid');
    }
    const pt = svg.createSVGPoint();
    pt.x = p.x;
    pt.y = p.y;
    const matrix = transformedGroup.getScreenCTM();
    if (!matrix) {
        throw new Error('Cannot get screen CTM from group');
    }
    const svgCoords = pt.matrixTransform(matrix.inverse());
    return { x: svgCoords.x, y: svgCoords.y };
}
