"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.tslider = void 0;
const func_1 = require("../../../assest/func");
const fnc = __importStar(require("../../../scripts/func"));
var sliderClasses;
(function (sliderClasses) {
    sliderClasses["topcont"] = "mpt_slider_topcont";
    sliderClasses["cont"] = "mpt_slider";
    sliderClasses["sliderCont"] = "mpt_slider_cont";
    sliderClasses["label"] = "mpt_slider_label";
    sliderClasses["slider"] = "mpt_slider_input";
    sliderClasses["textboxcont"] = "mpt_textboxcont";
    sliderClasses["textbox"] = "mpt_slider_textbox";
})(sliderClasses || (sliderClasses = {}));
const stprgipr = (v, min, max) => {
    return (fnc.vTo01(Number(v), Number(min), Number(max))) * 100;
};
const create = (attr) => {
    let cont;
    /** reset and create main container */
    const createCont = () => {
        cont ? cont.remove() : 0;
        return (0, func_1.bh)({ tag: 'div', classList: [sliderClasses.topcont], id: crypto.randomUUID() });
    };
    let label;
    let slider;
    let textbox;
    /** overal cont for component */
    cont = createCont();
    /** label */
    label = (0, func_1.bh)({ tag: 'div', classList: [sliderClasses.label], textContent: attr.text });
    cont.appendChild(label);
    /** container for slider and label */
    let sliderCont = (0, func_1.bh)({ tag: 'div', classList: [sliderClasses.sliderCont] });
    /** slider */
    slider = (0, func_1.bh)({
        tag: 'input',
        classList: [sliderClasses.slider],
        attributes: {
            type: 'range',
            min: attr.min,
            max: attr.max,
            value: attr.value,
            step: attr.step
        }
    });
    sliderCont.appendChild(slider);
    slider.style.setProperty('--sliderLinGra', `linear-gradient(90deg, #acacac ${stprgipr(attr.value, attr.min, attr.max)}%, rgba(255,255,255,1) ${stprgipr(attr.value, attr.min, attr.max)}%)`);
    /** textbox */
    textbox = (0, func_1.bh)({
        tag: 'input',
        classList: [sliderClasses.textbox],
        attributes: {
            type: 'number',
            min: attr.min,
            max: attr.max,
            value: attr.value,
            step: attr.step
        }
    });
    sliderCont.appendChild(textbox);
    /** send containers to output */
    cont.appendChild(sliderCont);
    /** share value between slider and textbox */
    slider.oninput = (e) => {
        let t_one = fnc.vTo01(Number(slider.value), Number(slider.min), Number(slider.max)) * 100;
        slider.style.setProperty('--sliderLinGra', `linear-gradient(90deg, #acacac ${t_one}%, rgba(255,255,255,1) ${t_one}%)`);
        textbox.value = slider.value;
        // slider.value = fnc.rbb(Number(slider.value), 5).toString()
    };
    textbox.onchange = (e) => {
        slider.value = textbox.value;
        slider.dispatchEvent(new Event('change'));
        slider.dispatchEvent(new Event('input'));
    };
    /** blur textbox on ctrl+enter */
    textbox.onkeydown = (e) => {
        if (e.key == 'Enter' && e.ctrlKey) {
            textbox.blur();
        }
        if (Number(textbox.value) > Number(textbox.max)) {
            textbox.value = textbox.max;
        }
    };
    return {
        view: cont,
        label: label,
        slider: slider,
        textbox: textbox
    };
};
const tslider = (attr) => {
    return create(attr);
};
exports.tslider = tslider;
