"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.proc_items_panel = exports.ProcessItems = void 0;
const tr = __importStar(require("../../core/tr"));
const app_1 = require("../../scripts/app");
const func_1 = require("../../scripts/func");
const input_1 = require("../mpts/input");
const output_1 = require("../mpts/output");
const step_1 = require("../mpts/step");
const mpts_panel_1 = require("./panel_core/mpts_panel");
var ProcessItems;
(function (ProcessItems) {
    ProcessItems[ProcessItems["step"] = 0] = "step";
    ProcessItems[ProcessItems["operation"] = 1] = "operation";
    ProcessItems[ProcessItems["input"] = 2] = "input";
    ProcessItems[ProcessItems["output"] = 3] = "output";
    ProcessItems[ProcessItems["queue"] = 4] = "queue";
    ProcessItems[ProcessItems["branch"] = 5] = "branch";
})(ProcessItems || (exports.ProcessItems = ProcessItems = {}));
/** panel for process elements
 *      step
 *      -------
 *      input
 *      output
 *      -------
 *      queue
 *      branch
 *      ...
 */
var proc_items_panel;
(function (proc_items_panel) {
    const _names = Object.keys(ProcessItems).filter((key) => isNaN(Number(key)));
    // separators line indexes
    const sln = [1];
    let _pnl;
    proc_items_panel.create = (parent = 'ui-layer', position = [16, 480 + 32], l = tr.lang.rus) => {
        _names.map(el => { return tr.tr_procitm(l, el); });
        let res = [];
        _names.forEach(el => { res.push([0, tr.tr_procitm(l, el), [], el]); });
        res.splice(2, 0, [2, '', []]);
        res.splice(5, 0, [2, '', []]);
        let _pt = [
            [
                tr.tr_panel(l, 'processItems'),
                position,
                [4, 4, 6, 4]
            ],
            [...res]
        ];
        _pnl = mpts_panel_1.mpts_panel.create(parent, _pt);
    };
    document.addEventListener('DOMContentLoaded', () => {
        _pnl.addEventListener('pointerdown', (e) => {
            const _item = e.target;
            if (!_item.classList.contains('mpts-panel-item'))
                return;
            const _data = _item.dataset.mptsPayload;
            // get creation point
            const _point = [(0, func_1.rbb)(e.clientX, 8), (0, func_1.rbb)(e.clientY, 8)];
            // canvas translate
            const _canvas_t = (0, func_1.getSvgTranslate3d)(app_1.app.getApp().ui.mpts.item.svgCanvas);
            // translate point
            const _tPoint = [_point[0], _point[1]];
            // field items
            const _app = app_1.app.getApp().ui.mpts.item;
            const _bhndl = (el) => {
                // remember z-index`s before move pallet to back
                let zi1 = window.getComputedStyle(_app.cont).zIndex;
                let zi2 = window.getComputedStyle(_app.svgLayer).zIndex;
                _app.cont.style.zIndex = '2';
                _app.svgLayer.style.zIndex = '2000';
                const pointerDownEvent = new PointerEvent('pointerdown', {
                    bubbles: false,
                    cancelable: true,
                    clientX: _tPoint[0] + _canvas_t.x,
                    clientY: _tPoint[1] + _canvas_t.y,
                    pointerId: e.pointerId
                });
                el.view.dispatchEvent(pointerDownEvent);
                (0, func_1.updateTranslate3d)(el.view, -_canvas_t.x, -_canvas_t.y);
                el.view.onpointerup = () => {
                    _app.cont.style.zIndex = zi1;
                    _app.svgLayer.style.zIndex = zi2;
                };
            };
            switch (_data) {
                case 'step':
                    console.log('step create');
                    const _step = step_1.step.create(_tPoint[0] - 16 - 24, _tPoint[1] - 8 - 40, 'A' + crypto.randomUUID());
                    _app.svgCanvas.appendChild(_step.view);
                    _bhndl(_step);
                    break;
                case 'input':
                    console.log('input');
                    const _input = input_1.input.create(_tPoint[0] - 24 - 8, _tPoint[1] - 40 - 8);
                    _app.svgCanvas.appendChild(_input.view);
                    _bhndl(_input);
                    break;
                case 'output':
                    console.log('output');
                    const _output = output_1.output.create(_tPoint[0] - 24 - 8, _tPoint[1] - 40 - 8);
                    _app.svgCanvas.appendChild(_output.view);
                    _bhndl(_output);
                    break;
                case 'queue':
                    console.log('queue');
                    break;
                case 'branch':
                    console.log('branch');
                    break;
                default:
                    console.log('default');
                    break;
            }
        });
    });
})(proc_items_panel || (exports.proc_items_panel = proc_items_panel = {}));
