"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.m_alias = exports.m_setAliasName = void 0;
const mobx_1 = require("mobx");
const func_1 = require("../../../assest/func");
const app_1 = require("../../../scripts/app");
const func_2 = require("../../../scripts/func");
const tr_1 = require("../../tr");
const m_setAliasName = (m) => {
    const _r = m.view.querySelector(`.mainRect.${m.pres.uiid}`);
    const _t = m.view.querySelector(`.baseText.${m.pres.uiid}`);
    console.log('m_setAliasName', m.pres.aliasName.value);
    if (!m.pres.aliasName.show) {
        let r_n = (0, tr_1.tr_ml)(app_1.app.getApp().lang, m).group[1];
        const textWidth = (0, func_2.countStringWidth)(r_n);
        _r.setAttribute('width', `${textWidth}`);
        _t.textContent = r_n;
        return;
    }
    // get text width
    const textWidth = (0, func_2.countStringWidth)(m.pres.aliasName.value);
    // change widht for .mainRect
    _r.setAttribute('width', `${textWidth}`);
    // get set text
    _t.textContent = m.pres.aliasName.value;
};
exports.m_setAliasName = m_setAliasName;
const m_alias = (m) => {
    // create default panel
    const cont = (0, func_1.bh)({ tag: 'div', classList: ['mps_alias_cont'] });
    let _tr = (0, tr_1.tr_ml)(app_1.app.getApp().lang, m);
    const _aliasAskDiv = (0, func_1.bh)({ tag: 'div', classList: ['mps_alias_ask'] });
    _aliasAskDiv.textContent = _tr.alias + ":";
    const _aliasImput = (0, func_1.bh)({ tag: 'input', classList: ['mps_alias_input'] });
    _aliasImput.value = m.pres.aliasName.value;
    cont.appendChild(_aliasAskDiv);
    cont.appendChild(_aliasImput);
    const _setAlias = () => {
        (0, mobx_1.runInAction)(() => {
            if (_aliasImput.value !== '') {
                m.pres.aliasName.value = _aliasImput.value;
                m.pres.aliasName.show = true;
            }
            else {
                m.pres.aliasName.value = '';
                m.pres.aliasName.show = false;
            }
        });
    };
    _aliasImput.oninput = (e) => _setAlias();
    _aliasImput.onchange = (e) => _setAlias();
    return cont;
};
exports.m_alias = m_alias;
