"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.login = exports.loginStates = void 0;
const app_1 = require("../../scripts/app");
const func_1 = require("../../scripts/func");
const transport_1 = require("../../scripts/transport");
var loginStates;
(function (loginStates) {
    loginStates["login"] = "login";
    loginStates["otp"] = "otp";
    loginStates["user"] = "user";
})(loginStates || (exports.loginStates = loginStates = {}));
var login;
(function (login_1) {
    let _login = null;
    const _element = {
        parent: document.getElementById('ui-layer'),
        main: {
            cont: null
        },
        item: {
            notyfire: null,
            instr: null,
            login: {
                cont: null,
                button: null,
                emailInput: null,
                passwordInput: null,
            },
            otp: {
                cont: null,
                loginHeader: null,
                loginHeaderButton: null,
                input: null,
                button: null,
            },
            user: {
                cont: null,
                emailField: null,
                nameInput: null,
                upNameButton: null,
                chNameButton: null,
                signOUTButton: null,
            },
        }
    };
    // init all the elements 
    // this is called after the login.html is fetched
    const initElements = () => {
        _element.main.cont = document.getElementById('content-signin');
        _element.item.notyfire = document.getElementById('login-notifier');
        _element.item.login.cont = document.getElementById('direct-login');
        _element.item.login.button = document.getElementById('mpt-submit-user');
        _element.item.login.emailInput = document.getElementById('mpt-email');
        _element.item.login.passwordInput = document.getElementById('mpt-password');
        _element.item.otp.cont = document.getElementById('otp-login');
        _element.item.otp.loginHeader = document.getElementById('otp-login-header');
        _element.item.otp.loginHeaderButton = document.getElementById('otp-login-header-button');
        _element.item.user.cont = document.getElementById('user-view');
        _element.item.user.emailField = document.getElementById('user-email-value');
        _element.item.user.nameInput = document.getElementById('user-name-input');
        _element.item.user.upNameButton = document.getElementById('user-name-update-button');
        _element.item.user.chNameButton = document.getElementById('user-name-change-button');
        _element.item.user.signOUTButton = document.getElementById('mtps_signout_button');
        _element.item.instr = document.getElementById('user-login-instruction');
    };
    // sign out procedure
    const sigOutProcceed = () => {
        // reset app user props
        app_1.app.getApp().user.email = '';
        app_1.app.getApp().user.name = '';
        app_1.app.getApp().user.id = '';
        app_1.app.getApp().user.key = '';
        // set app user login state
        app_1.app.getApp().logedIn = false;
        // set header user name to default 'Sign in'
        app_1.app.getApp().ui.header.setUserName();
        // allo app button
        app_1.app.getApp().ui.header.allowApp(false);
        // show login
        switchLoginState(loginStates.login);
        // clear inputs
        clearInputs();
        // clear otp inputs
        clearOTPInputs();
        // hide notifier
        login_1.setNotinotifier().hide();
        // show instruction
        _element.item.instr.style.display = 'flex';
    };
    // init SignOut button
    const initSignOut = () => {
        _element.item.user.signOUTButton.addEventListener('pointerdown', () => {
            (0, transport_1.send)(transport_1.MessageTypes.signOut);
        });
    };
    // set the notifier for the login page
    // small red words in case of wrong input
    login_1.setNotinotifier = (el) => {
        if (!el)
            el = document.getElementById('login-notifier');
        return {
            hide: () => {
                el.style.display = 'none';
            },
            error: (message) => {
                el.style.display = 'block';
                el.innerText = message;
                el.className = 'error-message';
            },
            info: (message) => {
                el.style.display = 'block';
                el.innerText = message;
                el.className = 'info-message';
            }
        };
    };
    // set the checkers for the email and password
    // called after input is changed for email and password
    const setCheckers = () => {
        const debounce = (func, wait) => {
            let timeout;
            return (...args) => {
                clearTimeout(timeout);
                timeout = setTimeout(() => func(...args), wait);
            };
        };
        const handleEmailValidation = debounce(() => {
            const result = (0, func_1.checkEmail)(_element.item.login.emailInput.value);
            if (!result.isValid) {
                login_1.setNotinotifier(_element.item.notyfire).error(result.errors[0] || 'Invalid email format');
            }
            else {
                login_1.setNotinotifier(_element.item.notyfire).hide();
            }
        }, 10);
        const handlePasswordValidation = debounce(() => {
            const result = (0, func_1.checkPassword)(_element.item.login.passwordInput.value);
            if (!result.isValid) {
                login_1.setNotinotifier(_element.item.notyfire).error(result.errors.join('\n'));
            }
            else {
                login_1.setNotinotifier(_element.item.notyfire).hide();
            }
        }, 10);
        _element.item.login.emailInput.addEventListener('input', handleEmailValidation);
        _element.item.login.passwordInput.addEventListener('input', handlePasswordValidation);
        return {
            cleanup: () => {
                _element.item.login.emailInput.removeEventListener('input', handleEmailValidation);
                _element.item.login.passwordInput.removeEventListener('input', handlePasswordValidation);
            }
        };
    };
    // handle the change email on otp
    const handleChangeEmailOnOtp = () => {
        _element.item.otp.loginHeaderButton.addEventListener('pointerdown', () => {
            // reset the email in App
            app_1.app.getApp().user.email = '';
            // reset email 
            clearInputs();
            login_1.setNotinotifier().hide();
            // switch to login
            switchLoginState(loginStates.login);
            // clear tokens
            window.localStorage.removeItem('accesToken'); // ?offline
            logout();
        });
    };
    // get the otp inputs
    const getOTPInputs = () => {
        return document.querySelectorAll("#otp-login-inputs input");
    };
    // clear the otp inputs
    const clearOTPInputs = () => {
        const inputs = getOTPInputs();
        inputs.forEach((input) => { input.value = ""; input.classList.remove("disabled"); });
        inputs[0].focus();
    };
    // set the submit button event 
    // called after Singin button is clicked
    const setSubmit = () => {
        _element.item.login.button.addEventListener('pointerdown', () => {
            const email = _element.item.login.emailInput.value;
            const password = _element.item.login.passwordInput.value;
            const emailValidation = (0, func_1.checkEmail)(email);
            if (!emailValidation.isValid) {
                login_1.setNotinotifier(_element.item.notyfire).error('Invalid email format');
                return;
            }
            const passwordValidation = (0, func_1.checkPassword)(password);
            if (!passwordValidation.isValid) {
                login_1.setNotinotifier(_element.item.notyfire).error('Invalid password format');
                return;
            }
            // send the data to the server
            try {
                (0, func_1.getHash)(`${email}${password}`).then((hash) => {
                    (0, transport_1.send)(transport_1.MessageTypes.login, { e: email, h: hash });
                });
            }
            catch (error) {
                console.error('Login error:', error);
                login_1.setNotinotifier(_element.item.notyfire).error('Login error');
            }
        });
    };
    // handle the OTP input
    // called after the OTP input is focused
    const otpHandler = () => {
        const inputs = getOTPInputs();
        inputs[0].focus();
        inputs.forEach((input) => {
            input.addEventListener("keyup", handleOtp);
            input.addEventListener("paste", handleOnPasteOtp);
        });
        function handleOtp(e) {
            const input = e.target;
            let value = input.value;
            let isValidInput = value.match(/[0-9]/gi);
            input.value = "";
            input.value = isValidInput ? value[0] : "";
            let fieldIndex = Number(input.getAttribute('data-index'));
            if (fieldIndex < inputs.length - 1 && isValidInput) {
                input.nextElementSibling.focus();
            }
            if (e.key === "Backspace" && fieldIndex > 0) {
                input.previousElementSibling.focus();
            }
            if (fieldIndex == inputs.length - 1 && isValidInput) {
                submit();
            }
        }
        function handleOnPasteOtp(e) {
            const data = e.clipboardData.getData("text");
            const value = data.split("");
            if (value.length === inputs.length) {
                inputs.forEach((input, index) => (input.value = value[index]));
                submit();
            }
        }
        function submit() {
            console.log("Submitting...");
            // 👇 Entered OTP
            let otp = "";
            inputs.forEach((input) => { otp += input.value; });
            //? chech otp
            otp.length === 6 ? inputs.forEach((input) => { input.classList.add("disabled"); input.blur(); }) : 0;
            console.log(otp);
            // 👉 Call API below
            (0, transport_1.send)(transport_1.MessageTypes.chech_otp, otp);
            // socket.emit('verify_otp', otp)
        }
    };
    // handle the user name update and change
    const handleUserName = () => {
        _element.item.user.upNameButton.addEventListener('pointerdown', () => {
            // get the name from the server
            (0, transport_1.send)(transport_1.MessageTypes.getName, _element.item.user.nameInput);
            (0, transport_1.send)(transport_1.MessageTypes.getId);
        });
        _element.item.user.chNameButton.addEventListener('pointerdown', () => {
            (0, transport_1.send)(transport_1.MessageTypes.setName, _element.item.user.nameInput.value);
            console.log('user name changed', _element.item.user.nameInput.value);
            // set the user name in the app
            app_1.app.getApp().user.name = _element.item.user.nameInput.value;
            // set the user name in the header
            app_1.app.getApp().ui.header.setUserName(app_1.app.getApp().user.name);
        });
    };
    const initLogin = (l) => __awaiter(this, void 0, void 0, function* () {
        _element.main.cont ? _element.main.cont.remove() : null;
        // fetch the login.html file
        const _h = yield (0, func_1._fetch)(`pages/login.html`);
        // set observ for the fetched element
        (0, func_1.setObserv)(_element.parent, 'content-signin', [
            initElements,
            setCheckers,
            setSubmit,
            otpHandler,
            handleChangeEmailOnOtp,
            handleUserName,
            initSignOut,
        ]);
        //  append the fetched HTML element to the main container
        _element.parent.prepend(_h);
    });
    // show the login page
    const _show = (v) => __awaiter(this, void 0, void 0, function* () {
        if (v) {
            _element.main.cont.style.display = 'none';
            if (app_1.app.getApp().logedIn) {
                switchLoginState(loginStates.user);
                app_1.app.getApp().ui.header.underLine(5);
            }
            else {
                console.log('check token');
                const _ct = yield checkToken();
                if (_ct) {
                    switchLoginState(loginStates.otp);
                }
                else {
                    switchLoginState(loginStates.login);
                }
            }
            _element.main.cont.style.display = 'flex';
        }
        else {
            _element.main.cont.style.display = 'none';
        }
    });
    const setLang = (l) => {
    };
    // clear email and password inputs 
    const clearInputs = () => {
        _element.item.login.emailInput.value = '';
        _element.item.login.passwordInput.value = '';
    };
    //!! switch between login states
    const switchLoginState = (s) => {
        // hide all
        _element.item.login.cont.style.display = 'none';
        _element.item.otp.cont.style.display = 'none';
        _element.item.user.cont.style.display = 'none';
        // show the one
        switch (s) {
            //!! show login 
            case loginStates.login:
                // show login
                _element.item.login.cont.style.display = 'grid';
                break;
            //!! show otp
            case loginStates.otp:
                // set the email in the user object
                app_1.app.getApp().user.email == '' ? app_1.app.getApp().user.email = _element.item.login.emailInput.value : null;
                // show email in otp-login-header
                _element.item.otp.loginHeader.innerText = app_1.app.getApp().user.email;
                // clear the inputs
                clearInputs();
                // show the otp input
                _element.item.otp.cont.style.display = 'flex';
                // focus the first input
                clearOTPInputs();
                getOTPInputs()[0].blur();
                getOTPInputs()[0].focus();
                break;
            //!! show user
            case loginStates.user:
                _element.item.user.cont.style.display = 'grid';
                _element.item.instr.style.display = 'none';
                login_1.setNotinotifier().hide();
                // set the user name and email
                _element.item.user.nameInput.value = app_1.app.getApp().user.name;
                _element.item.user.emailField.innerText = app_1.app.getApp().user.email;
                // set app user login state
                app_1.app.getApp().logedIn = true;
                // set header user name
                app_1.app.getApp().ui.header.setUserName(app_1.app.getApp().user.name);
                // allo app button 
                app_1.app.getApp().ui.header.allowApp(true);
                break;
        }
    };
    login_1.create = (l) => {
        initLogin(l);
        _login = {
            id: crypto.randomUUID(),
            item: _element,
            show: _show,
            translate: setLang,
            setView: switchLoginState,
            setNotinotifier: login_1.setNotinotifier,
            clearOTPInputs: clearOTPInputs,
            clearInputs: clearInputs,
            sigOutProcceed: sigOutProcceed,
        };
        return _login;
    };
    // ---------------------------------------------------- WORK WITH JWT
    function checkToken() {
        return __awaiter(this, void 0, void 0, function* () {
            let accessToken = yield getAccesTokenLocaly();
            if (!accessToken) {
                console.log('Token not found');
                return false;
            }
            try {
                const response = yield fetch('auth/token', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ value: accessToken }),
                    credentials: 'include',
                });
                const data = yield response.json();
                const isValid = Boolean(data.message);
                if (isValid) {
                    console.log('welcome back', data.email);
                    app_1.app.getApp().user.email = data.email;
                }
                return isValid;
            }
            catch (err) {
                console.log('Token state error:', err);
                return false;
            }
        });
    }
    login_1.checkToken = checkToken;
    function initialize() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log('Initializing application...');
            let accessToken = yield getAccesTokenLocaly();
            if (!accessToken) {
                try {
                    const response = yield fetch('auth/initialize', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            value: accessToken
                        }),
                        credentials: 'include',
                    });
                    if (response.status === 200) {
                        const data = yield response.json();
                        accessToken = data.accessToken;
                        storeAccesTokenLocaly(accessToken);
                        console.log('Access Token initialized:', accessToken);
                    }
                    else {
                        console.log('Initialization failed. Please log in again.');
                        login();
                    }
                }
                catch (err) {
                    console.log('Error during initialization:', err);
                }
            }
            else {
                console.log('Access Token already available:', accessToken);
            }
        });
    }
    login_1.initialize = initialize;
    function getAccesTokenLocaly() {
        return __awaiter(this, void 0, void 0, function* () {
            return window.localStorage.getItem('accesToken');
        });
    }
    login_1.getAccesTokenLocaly = getAccesTokenLocaly;
    function storeAccesTokenLocaly(token) {
        return __awaiter(this, void 0, void 0, function* () {
            window.localStorage.setItem('accesToken', token);
        });
    }
    login_1.storeAccesTokenLocaly = storeAccesTokenLocaly;
    function removeAccesTokenLocaly() {
        return __awaiter(this, void 0, void 0, function* () {
            window.localStorage.removeItem('accesToken');
        });
    }
    login_1.removeAccesTokenLocaly = removeAccesTokenLocaly;
    function login() {
        return __awaiter(this, arguments, void 0, function* (username = app_1.app.getApp().user.email, password = app_1.app.getApp().user.id) {
            try {
                console.log('Login requested:', username, password);
                const response = yield fetch('auth/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ username, password }),
                    credentials: 'include'
                });
                if (response.ok) {
                    const data = yield response.json();
                    storeAccesTokenLocaly(data.accessToken);
                }
                else {
                    console.error('Login failed:', response.statusText);
                }
            }
            catch (err) {
                console.error('Error during login:', err);
            }
        });
    }
    login_1.login = login;
    function logout() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield fetch('auth/logout', {
                    method: 'POST',
                    credentials: 'include'
                });
                if (response.ok) {
                    removeAccesTokenLocaly();
                    console.log('Logged out successfully');
                }
                else {
                    console.error('Failed to log out:', response.statusText);
                }
            }
            catch (err) {
                console.error('Error during logout:', err);
            }
        });
    }
    login_1.logout = logout;
    // ---------------------------------------------------- ENC DEC 
    /**
     *
     * @param v value to encrypt
     * @param k key
     */
    function hidenEnc(v, k) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                encryptWithPemPublicKey(v, k).then((_v) => __awaiter(this, void 0, void 0, function* () {
                    const response = yield fetch('auth/calc', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            value: _v
                        }),
                    });
                    if (response.ok) {
                        console.log('enc : ');
                    }
                    else {
                        console.error('enc : ', response.statusText);
                    }
                }));
            }
            catch (err) {
                console.error('enc : ', err);
            }
        });
    }
    login_1.hidenEnc = hidenEnc;
    function importPemPublicKey(pemKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const base64Key = pemKey
                .replace(/-----BEGIN PUBLIC KEY-----/, "")
                .replace(/-----END PUBLIC KEY-----/, "")
                .replace(/[\n\r]/g, "")
                .trim();
            const binaryKey = Uint8Array.from(atob(base64Key), c => c.charCodeAt(0)).buffer;
            return yield window.crypto.subtle.importKey("spki", binaryKey, {
                name: "RSA-OAEP",
                hash: "SHA-256",
            }, true, ["encrypt"]);
        });
    }
    function encryptWithPemPublicKey(message, pemKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const publicKey = yield importPemPublicKey(pemKey);
            const encodedMessage = new TextEncoder().encode(message);
            const encrypted = yield window.crypto.subtle.encrypt({
                name: "RSA-OAEP",
            }, publicKey, encodedMessage);
            return btoa(String.fromCharCode(...new Uint8Array(encrypted)));
        });
    }
    function bufferToHex(buffer) {
        return Array.from(new Uint8Array(buffer))
            .map(b => b.toString(16).padStart(2, "0"))
            .join("");
    }
})(login || (exports.login = login = {}));
