"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.m_tags = void 0;
const mobx_1 = require("mobx");
const func_1 = require("../../../assest/func");
const app_1 = require("../../../scripts/app");
const tr_1 = require("../../tr");
const tcombo_1 = require(".././baseitems/tcombo");
const core_1 = require("../../core");
const m_tags = (m) => {
    // create default panel
    const cont = (0, func_1.bh)({ tag: 'div' });
    // translate microelement
    const _tr = (0, tr_1.tr_ml)(app_1.app.getApp().lang, m);
    // create combo for each tag
    m.core.tags.values.forEach((tag, i) => {
        let _sel = tag.values.findIndex(v => v.kind === tag.value);
        const _combo = (0, tcombo_1.tcombo)({ top: _tr.tag[1][i][1], ch: [..._tr.tag[1][i][3]] }, _sel);
        if (m.core.group == core_1.ml.Group.machineTime && tag.coreType === core_1.tg.Tags.mechanization) {
            for (let i = 0; i < 5; i++) {
                _combo.childNodes[1].childNodes.item(0).remove();
            }
        }
        _combo.addEventListener('change', (e) => {
            (0, mobx_1.runInAction)(() => {
                tag.value = tag.values[e.target.value].kind;
            });
        });
        cont.appendChild(_combo);
    });
    return cont;
};
exports.m_tags = m_tags;
