"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shift = exports.simItemsFactory = exports.gap = exports.sim = void 0;
const core_1 = require("./core");
var sim;
(function (sim) {
    let formulas;
    (function (formulas) {
        const MECH_FACTOR = [2, 1.67, 1.42, 1.25, 1.11, 1.005];
        const WORKABILITY = [0.5, 0.6, 0.65, 0.7];
        const VALUE_BOOST = [0.34, 0.22, 0.14, 0.08, 0.04, 0.001];
        formulas.gate = (v, _mech) => {
            return [2 * v - v * MECH_FACTOR[_mech], v * (1 + VALUE_BOOST[_mech]), v * MECH_FACTOR[_mech]];
        };
        formulas.getValue = (v, _mech, _work) => {
            let _gate = formulas.gate(v, _mech);
            return formulas.rand_normal(_gate[0], _gate[2], WORKABILITY[_work]);
        };
        /**
         * get random in range
         * @param min min value
         * @param max max value
         */
        formulas.rand_flat = (min, max) => {
            return Math.random() * (max - min) + min;
        };
        /**
         * get random value in range with normal distribution
         * @param min lower value
         * @param max upper value
         * @param w WORKABILITY [0.5, 0.6, 0.65 - default, 0.7]
         */
        formulas.rand_normal = (min, max, w = 0.65) => {
            let u = Math.random();
            let v = Math.random();
            let num = (Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v)) / 100 + formulas.rand_flat(0.4, w);
            num *= max - min;
            num += min;
            return num;
        };
        /**
            * Returns a pseudorandom number drawn from a triangular distribution with minimum support `a`, maximum support `b` and mode `c`.
            * @param {number} a - minimum support
            * @param {number} b - maximum support
            * @param {number} c - mode
            * @returns {number} pseudorandom number
        */
        formulas.triangular = (a, b, c) => {
            let x = 0;
            let fc = (c - a) / (b - a);
            let u = Math.random();
            if (u < fc) {
                x = (b - a) * (c - a);
                return a + Math.sqrt(x * u);
            }
            x = (b - a) * (b - c);
            return b - Math.sqrt(x * (1.0 - u));
        };
    })(formulas = sim.formulas || (sim.formulas = {}));
    /** items enum */
    let Items;
    (function (Items) {
        Items[Items["item"] = 0] = "item";
        Items[Items["input"] = 1] = "input";
        Items[Items["output"] = 2] = "output";
        Items[Items["stack"] = 3] = "stack";
        Items[Items["branch"] = 4] = "branch";
        Items[Items["link"] = 5] = "link";
        Items[Items["port"] = 6] = "port";
        Items[Items["cell"] = 7] = "cell";
    })(Items = sim.Items || (sim.Items = {}));
    let ItemQuality;
    (function (ItemQuality) {
        ItemQuality[ItemQuality["writeOff"] = 0] = "writeOff";
        ItemQuality[ItemQuality["bad"] = 1] = "bad";
        ItemQuality[ItemQuality["undef"] = 2] = "undef";
        ItemQuality[ItemQuality["common"] = 3] = "common";
        ItemQuality[ItemQuality["normal"] = 4] = "normal";
        ItemQuality[ItemQuality["good"] = 5] = "good";
        ItemQuality[ItemQuality["AAA"] = 6] = "AAA";
    })(ItemQuality = sim.ItemQuality || (sim.ItemQuality = {}));
    let InputArrivalType;
    (function (InputArrivalType) {
        InputArrivalType[InputArrivalType["rate"] = 0] = "rate";
        InputArrivalType[InputArrivalType["manually"] = 1] = "manually";
        InputArrivalType[InputArrivalType["external"] = 2] = "external";
    })(InputArrivalType = sim.InputArrivalType || (sim.InputArrivalType = {}));
    let Runtime;
    (function (Runtime) {
        Runtime[Runtime["time"] = 0] = "time";
        Runtime[Runtime["qty"] = 1] = "qty";
        Runtime[Runtime["constantly"] = 2] = "constantly";
    })(Runtime = sim.Runtime || (sim.Runtime = {}));
    let Capacity;
    (function (Capacity) {
        Capacity[Capacity["limited"] = 0] = "limited";
        Capacity[Capacity["unlimited"] = 1] = "unlimited";
    })(Capacity = sim.Capacity || (sim.Capacity = {}));
    let DepartureOrder;
    (function (DepartureOrder) {
        DepartureOrder[DepartureOrder["stack"] = 0] = "stack";
        DepartureOrder[DepartureOrder["line"] = 1] = "line";
        DepartureOrder[DepartureOrder["batch"] = 2] = "batch";
    })(DepartureOrder = sim.DepartureOrder || (sim.DepartureOrder = {}));
    let link;
    (function (link) {
        link.create = (attr) => {
            return attr ? attr : {
                coreType: Items.link,
                bd: Date.now(),
                id: core_1.cm.ID(),
                delay: 0
            };
        };
    })(link = sim.link || (sim.link = {}));
    let branch;
    (function (branch) {
        branch.create = (attr) => {
            return attr ? attr : {
                coreType: Items.branch,
                bd: Date.now(),
                id: core_1.cm.ID(),
                safetyValve: false,
                intensity: core_1.ins.base.create()
            };
        };
    })(branch = sim.branch || (sim.branch = {}));
    let stack;
    (function (stack) {
        stack.create = (attr) => {
            return attr ? attr : {
                coreType: Items.stack,
                bd: Date.now(),
                id: core_1.cm.ID(),
                cap: {
                    type: Capacity.unlimited,
                    value: -1
                },
                nita: core_1.nita.create(),
                depord: DepartureOrder.line
            };
        };
    })(stack = sim.stack || (sim.stack = {}));
    let output;
    (function (output) {
        output.create = (attr) => {
            return attr ? attr : {
                core: {
                    coreType: core_1.CoreTypes.output,
                    simCoreType: Items.output,
                    bd: Date.now(),
                    id: core_1.cm.ID()
                }
            };
        };
    })(output = sim.output || (sim.output = {}));
    let input;
    (function (input) {
        input.create = (attr) => {
            return attr ? attr : {
                core: {
                    coreType: core_1.CoreTypes.input,
                    simCoreType: Items.input,
                    bd: Date.now(),
                    id: core_1.cm.ID(),
                    art: {
                        type: InputArrivalType.rate,
                        value: 3.12
                    },
                    qty: ItemQuality.undef,
                    char: 'green',
                    sourcedType: 'apple',
                    rtm: {
                        type: Runtime.constantly,
                        value: 1
                    }
                }
            };
        };
    })(input = sim.input || (sim.input = {}));
})(sim || (exports.sim = sim = {}));
//#region  ShiftGap
var gap;
(function (gap) {
    gap.ShiftGap = (params) => {
        return params ?
            {
                simcore: Object.assign({}, params)
            } : {
            simcore: {
                id: crypto.randomUUID(),
                type: 'work',
                payable: 'payable',
                duration: 60,
                payableRate: 10
            }
        };
    };
})(gap || (exports.gap = gap = {}));
var simItemsFactory;
(function (simItemsFactory) {
    simItemsFactory.release = () => {
    };
    simItemsFactory.restore = () => {
    };
})(simItemsFactory || (exports.simItemsFactory = simItemsFactory = {}));
//#region  Shift
var shift;
(function (shift) {
    shift.Shift = (params) => {
        return params ? { simcore: Object.assign({}, params) } :
            {
                simcore: {
                    id: crypto.randomUUID(),
                    startType: 'none',
                    name: '',
                    gaps: [gap.ShiftGap()]
                }
            };
    };
    shift.getFullDuration = (s) => {
        return s.simcore.gaps
            .map(el => {
            return el.simcore.duration;
        })
            .reduce((a, b) => a + b);
    };
    shift.getDurationByType = (s, gt) => {
        return s.simcore.gaps
            .filter(el => el.simcore.type == gt)
            .map(el => {
            return el.simcore.duration;
        })
            .reduce((a, b) => a + b);
    };
    shift.gapOperation = (ot, s, g, i) => {
        let _g = g ? g : gap.ShiftGap();
        switch (ot) {
            case 'add':
                s.simcore.gaps.push(_g);
                break;
            case 'remove':
                let gi = s.simcore.gaps.
                    findIndex(el => {
                    el.simcore.id == _g.simcore.id;
                });
                s.simcore.gaps.splice(gi, 1);
                break;
            case 'insert':
                s.simcore.gaps.splice(i, 0, _g);
                break;
            default:
                break;
        }
    };
    function editPlain(object, key, property, value) {
        object[key][property] = value;
    }
    function editArray(object, payload) {
        const notify = (senderID, O, P, payload) => {
            let _payload = {
                senderID: senderID,
                d: Date.now(),
                element: O,
                prop: P,
                prev: payload.prev,
                now: payload.now,
                action: payload.action,
                payload: payload.payload
            };
            dispatchEvent(new CustomEvent('', {
                bubbles: true, detail: _payload
            }));
        };
        const append = () => {
        };
    }
    let r1 = shift.Shift();
    editArray(r1.simcore.gaps, { action: 'insert' });
})(shift || (exports.shift = shift = {}));
